export default function () {
  return {
    language: 'pt-BR',
    'pt-BR': {
      components: {
        action: {
          requisition: {
            request: 'Requisição',
            method: 'Método',
            run: 'Run',
            messageError: 'Oops, está faltando dados.'
          }
        },
        advancedSearch: {
          index: {
            myFilters: 'Meus filtros de pesquisa',
            custom: 'Personalizado',
            today: 'Hoje',
            yesterday: 'Ontem',
            thisMonth: 'Este mês',
            lastSevenDays: 'Últimos 7 dias',
            lastFifteenDays: 'Últimos 15 dias',
            lastThirtyDays: 'Últimos 30 dias',
            search: 'Pesquisar',
            initialDate: 'Data inicial',
            initialHour: 'Hora inicial',
            endDate: 'Data final',
            endHour: 'Hora final',
            chatbots: 'Chatbots',
            channel: 'Canal',
            all: 'Todos',
            webchat: 'Webchat',
            dialogue: 'Diálogo',
            dialogueDescription: 'Você pode filtrar por quem iniciou<br/> a conversa, atendente ou cliente.',
            botDeleted: 'Bot excluído',
            active: 'Chatbot/Ativos',
            receptive: 'Cliente/Receptivos',
            whatsapp: 'Whatsapp',
            messenger: 'Messenger',
            instagram: 'Instagram',
            telegram: 'Telegram',
            gbm: 'GBM',
            rcs: 'RCS',
            identifiers: 'Identificadores',
            filter: 'Filtrar',
            fill: 'Preenchimento',
            filled: 'Preenchido',
            notFilled: 'Não preenchido',
            transhipment: 'Transbordo',
            transhipmentDescription: 'Esta é uma nova funcionalidade, filtros baseados neste<br/> campo não são aplicados à datas anteriores a 19/03/2020.',
            indifferent: 'Indiferente',
            attended: 'Atendidos',
            notAttended: 'Não atendidos',
            unrealized: 'Não realizados',
            none: 'Nenhum',
            attendants: 'Atendentes',
            departments: 'Departamentos',
            otherOptions: 'Outras opções',
            onlyConversations: 'Somente conversas',
            allConversations: 'Todas',
            incompleteConversations: 'Incompletas',
            conversationFinished: 'Finalizadas',
            satisfaction: 'Satisfação',
            oneStar: '1 estrela',
            twoStar: '2 estrelas',
            threeStar: '3 estrelas',
            fourStar: '4 estrelas',
            fiveStar: '5 estrelas',
            tags: 'Tags',
            term: 'Termo',
            inIdentifier: 'No identificador',
            clear: 'Limpar',
            createFilter: 'Criar filtro',
            title: 'Título',
            save: 'Salvar',
            messageError: 'Oops, ocorreu algum erro.',
            listBy: 'Lista de ',
            savedFilter: 'Seu filtro foi salvo!',
            of: 'de',
            selectAll: 'Todos'
          }
        },
        analytics: {
          quickview: {
            conversation: {
              noDataAvailable: 'Nenhum dado disponível.'
            },
            knowledge: {
              noDataAvailable: 'Nenhum dado disponível.'
            },
            rating: {
              noDataAvailable: 'Nenhum dado disponível.'
            },
            usersConnected: {
              noDataAvailable: 'Nenhum dado disponível.'
            }
          },
          attendants: {
            attendants: 'Atendentes',
            satisfaction: 'Satisfação',
            attendance: 'Atendimentos',
            onlineTime: 'Tempo Online',
            awayTime: 'Tempo Away',
            responses: 'Tempo Away',
            reviewsAnswered: 'Avaliações Respondidas',
            tta: 'TTA',
            tma: 'TMA',
            tme: 'TME',
            tte: 'TTE',
            noDataAvailable: 'Nenhum dado disponível.',
            name: 'Nome'
          },
          botAndAttendant: {
            attendants: 'Atendentes',
            noDataAvailable: 'Nenhum dado disponível.',
            chatbots: 'Chatbots'
          },
          bubble: {
            sunday: 'Domingo',
            hours: ' horas',
            attendance: ' atendimentos'
          },
          campaign: {
            utmSource: 'UTM Source',
            tip: 'TIP',
            utmMedium: 'UTM Medium',
            utmCampaign: 'UTM Campaign',
            utmTerm: 'UTM Term',
            utmContent: 'UTM Content',
            withCampaign: 'Com campanha',
            withoutCampaign: 'Sem campanha'
          },
          channel: {
            noDataAvailable: 'Nenhum dado disponível.',
            total: 'Total',
            impressions: 'Impressões',
            impressionsDescription: 'No WebChat, é o volume de vezes que o chatbot apareceu disponível para clique/acionamento.',
            attendance: 'Atendimentos',
            attendanceDescription: 'Quando um usuário (cliente) aciona o chatbot para iniciar a conversa e um atendimento é iniciado.',
            messages: 'Mensagens',
            messagesDescription: 'Volume total de mensagens trocadas em todas as conversas (atendimentos). Entre usuário (cliente), chatbots e os atendentes (quando houver).',
            ttAttendance: 'TT Atendimentos',
            ttAttendanceDescription: 'É o tempo total em atendimento do canal, desde o momento do acionamento até o final da conversa.',
            tmAttendance: 'TM Atendimentos',
            tmAttendanceDescription: 'É o tempo médio dos atendimentos do canal, considerando o período selecionado.',
            totalTransshipments: 'Transbordos totais',
            totalTransshipmentsDescription: 'Quantidade de requisições em que o chatbot solicita atendimento de um atendente, seja acionado por alguma regra do fluxo ou pelo usuário (cliente).',
            transshipmentsAttended: 'Transbordos atendidos',
            transshipmentsAttendedDescription: 'Quantidade de atendimentos onde o chatbot passou a conversa para o atendente e ele conseguiu atender.',
            unattendedTransshipments: 'Transbordos não atendidos',
            unattendedTransshipmentsDescription: 'Quantidade de atendimentos onde o chatbot passou a conversa para o atendente e ele não conseguiu atender.',
            unrealizedTransshipments: 'Transbordados não realizados',
            unrealizedTransshipmentsDescription: 'Quantidade de atendimentos onde o chatbot não conseguiu passar a conversa para um atendente (não haviam atendentes on-line no momento). O chatbot retomou a conversa.',
            ttWait: 'TT Espera',
            ttWaitDescription: 'É o tempo total que os usuários (clientes) permaneceram em espera para serem atendidos pelos atendentes (quando a fila de atendimento está habilitada).',
            tmWait: 'TM Espera',
            tmWaitDescription: 'É o tempo médio que os usuários (clientes) permaneceram em espera para serem atendidos pelos atendentes (quando a fila de atendimento está habilitada).',
            satisfaction: 'Satisfação',
            satisfactionDescription: 'É a taxa de satisfação do atendimento em geral (pesquisa acionada ao final do atendimento). Escala de 0 a 5 e número de respondentes em parênteses.',
            monetaryValueDescription: 'Confira o valor total gerado pelas conversas no período (muito útil para quem vende produtos e serviços). Para aparecer aqui, o valor deve ser atribuído em cada conversa individualmente.',
            january: 'Janeiro',
            february: 'Fevereiro',
            march: 'Março',
            april: 'Abril',
            may: 'Maio',
            june: 'Junho',
            july: 'Julho',
            august: 'Agosto',
            september: 'Setembro',
            october: 'Outubro',
            november: 'Novembro',
            december: 'Dezembro',
            shortJan: 'Jan',
            shortFeb: 'Fev',
            shortMar: 'Mar',
            shortApr: 'Abr',
            shortMay: 'Mai',
            shortJun: 'Jun',
            shortJul: 'Jul',
            shortAug: 'Ago',
            shortSep: 'Set',
            shortOct: 'Out',
            shortNov: 'Nov',
            shortDec: 'Dez',
            sunday: 'Domingo',
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sábado',
            shortSunday: 'Dom',
            shortMonday: 'Seg',
            shortTuesday: 'Ter',
            shortWednesday: 'Qua',
            shortThursday: 'Qui',
            shortFriday: 'Sex',
            shortSaturday: 'Sab',
            exportToSVG: 'Exportar para SVG',
            exportToPNG: 'Exportar para PNG',
            exportToCSV: 'Exportar para CSV',
            menu: 'Menu',
            selection: 'Selecionar',
            selectionZoom: 'Selecionar zoom',
            zoomIn: 'Mais zoom',
            zoomOut: 'Menos zoom',
            pan: 'Mover',
            reset: 'Retornar ao padrão'
          },
          city: {
            noDataAvailable: 'Nenhum dado disponível.',
            others: 'Outros'
          },
          country: {
            noDataAvailable: 'Nenhum dado disponível.',
            others: 'Outros'
          },
          department: {
            noDataAvailable: 'Nenhum dado disponível.'
          },
          heat: {
            sunday: 'Domingo',
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sabado',
            hours: ' horas',
            attendance: ' atendimentos'
          },
          identifier: {
            selectIdentifier: 'Selecione um identificador',
            noDataAvailable: 'Nenhum dado disponível.',
            others: 'Outros',
            limitCards: 'Volume máximo de 12 visões. Para criar uma nova, favor apagar uma existente.'
          },
          knowledgeAnswered: {
            noDataAvailable: 'Nenhum dado disponível.',
            answered: 'Respondidas',
            unambiguous: 'Desambiguadas',
            unanswered: 'Não respondidas'
          },
          knowledgeResponses: {
            noDataAvailable: 'Nenhum dado disponível.',
            others: 'Outros'
          },
          pivot: {
            origin: 'Origem',
            total: 'Total'
          },
          platform: {
            noDataAvailable: 'Nenhum dado disponível.',
            incognito: 'Incógnito'
          },
          sankey: {
            noDataAvailable: 'Nenhum dado disponível.',
            to: 'De',
            from: 'Para',
            amount: 'Quantidade'
          },
          state: {
            noDataAvailable: 'Nenhum dado disponível.',
            others: 'Outros'
          }
        },
        drawers: {
          broadcast: {
            template: {
              email: {
                save: 'Salvar',
                cancel: 'Cancelar',
                messageEditor: 'Editor de Mensagem',
                personalizeEmail: 'Você pode personalizar seu e-mail utilizando variáveis.',
                library: 'Biblioteca',
                messageErrorToCompile: 'Erro ao compilar email!',
                templateSaved: 'Seu template foi salvo!',
                messageErrorConnection: 'Oops, ocorreu um erro de conexão!'
              },
              sms: {
                save: 'Salvar',
                cancel: 'Cancelar',
                messageEditor: 'Editor de Mensagem',
                message: 'Mensagem *',
                messageHas: 'Sua mensagem tem',
                attention: '  Informe o número do celular do cliente com DDD com 2 dígitos, exemplo: (XX) XXXXX-XXXX.',
                characters: 'caracteres.',
                discountCredits: 'Será descontados',
                discountCreditsFinal: 'créditos para cada número',
                overcomeTheCharacters: 'Nos casos que o conteúdo do SMS superar 160 caracteres, será tarifado mais de um crédito a cada 153 caracteres.',
                notSupportConcatenation: 'Algumas operadoras como a Oi e Sercomtel não suportam concatenação da mensagens, então serão recebidos SMS separadamente.',
                templateSaved: 'Seu template foi salvo!',
                messageErrorConnection: 'Oops, ocorreu um erro de conexão!',
                messageNeedFilled: 'Os campos nome e mensagem precisam ser preenchidos!'
              },
              whatsapp: {
                save: 'Salvar',
                cancel: 'Cancelar',
                messageEditor: 'Editor de Mensagem',
                message: 'Mensagem *',
                messageHas: 'Sua mensagem tem',
                characters: 'caracteres.',
                discountCredits: 'Será descontados dois créditos para cada número',
                integrations: 'A integração entre Globalbot e o WhatsApp permite envios de mensagens ativas considerando as seguintes orientações:',
                activeMessages: 'Mensagens Ativas:',
                activeMessagesDescription: 'Neste caso o chatbot WhatsApp é usado para notificação e mensagem aos clientes. E a origem do atendimento (primeira mensagem) é pela empresa. Caso o cliente responda a mensagem, será iniciada uma nova sessão de conversa (ou atendimento).',
                dispatchesInLargeScale: 'Envios em massa:',
                dispatchesInLargeScaleDescription: 'É importante ter atenção a este tipo de uso, pois o envio em massa (quando relacionado a promoções, divulgações, publicidade e afins) não são permitidos. São aceitáveis os envios automatizados para grupos de clientes como por exemplo notificações e avisos (dentro de um contexto que o cliente tenha optado por receber a mensagem, conhecido como opt-in).',
                modelSaved: 'Seu modelo foi salvo!',
                youCannotChange: 'Você não pode alterar mensagens no WhatsApp oficial. Favor, entre em contato com a equipe de suporte.',
                messageErrorConnection: 'Oops, ocorreu um erro de conexão!',
                messageNeedFilled: 'Os campos nome e mensagem precisam ser preenchidos!'
              },
              save: 'Salvar',
              cancel: 'Cancelar',
              modelData: 'Dados do Modelo',
              name: 'Nome *',
              tags: 'Tags',
              observations: 'Observações',
              integrationsId: 'ID para uso em integrações: ',
              modelMessageRequired: 'Para realizar um Envio, você deverá sempre utilizar um Modelo de Mensagem já criado.',
              newMessageModelData: 'Aqui você irá informar os dados do novo Modelo de Mensagem, que poderá ser utilizado para realizar Envios aos destinatários que você designar.',
              clientsWaba: 'Para os clientes da WABA (WhatsApp Business API) as notificações no formato HSM são obrigatórias e devem seguir os formatos já pré-aprovados.',
              whatsappTerm: 'Termos de Uso do WhatsApp:',
              whatsappTermDescription: ' É muito importante salientar que para utilização da integração entre Globalbot e WhatsApp o cliente esteja ciente e aceite as Políticas de Uso do WhatsApp, utilizando a aplicação de forma a não infringi-los. Caso o faça, o número poderá ser bloqueado. ',
              whatsappUsagePolicies: 'Políticas de Uso do WhatsApp',
              messageSavedModel: 'Seu modelo foi salvo!',
              messageErrorConnection: 'Oops, ocorreu um erro de conexão!',
              fieldNameRequired: 'O campo nome precisa ser preeenchido!'
            },
            dispatch: {
              save: 'Salvar',
              cancel: 'Cancelar',
              dispatchData: 'Dados do Envio',
              newDispatch: 'Novo Envio |',
              draftData: 'Dados do Rascunho',
              createDispatch: 'Criar Envio',
              dispatchName: 'Nome do envio *',
              observations: 'Observações',
              originAndInitialization: 'Origem e Inicialização',
              indicateServer: 'Indique qual servidor WhatsApp você quer realizar o envio (corresponde a seu número habilitado).',
              optional: 'Opcional: Customize o comportamento do chatbot caso o usuário responda a esta mensagem. O número refere-se ao ponto do fluxo conversacional que será acionado após a resposta. Descubra qual ponto do fluxo e suas numerações no módulo Fluxo. Deixe o valor 0 para o fluxo seguir normalmente a partir do início.',
              whatsappServer: 'Servidor de WhatsApp *',
              flowIndex: 'Índice do fluxo',
              message: 'Mensagem *',
              replaceWith: 'Substituir por',
              subjectAndSender: 'Assunto e Remetente',
              personalizeEmail: 'Você pode personalizar seu e-mail utilizando variáveis. Veja as dicas.',
              subjectEmail: 'O assunto do seu email tem ',
              characters: ' caracteres. O número de caracteres exibidos em dispositivos móveis é de 35 e em desktops 70, em média.',
              senderName: 'Nome remetente *',
              sender: 'Remetente *',
              replyTo: 'Responder para *',
              recipients: 'Destinatários',
              recipientsDesc: 'Total de destinatários selecionados para o envio, considerando os atributos e segmentações que você escolheu.',
              dispatchTest: 'Teste de Envio',
              replaceVar: 'Substituição de variáveis',
              combineLists: 'Você pode combinar listas e filtros ou mesmo importar uma nova lista. ',
              selectList: 'Selecione uma lista de envio já salva ou faça uma importação.',
              clickHere: 'Clique aqui',
              downloadModelList: 'para baixar um modelo.',
              importList: 'Importar Lista',
              lists: 'Listas',
              filters: 'Filtros',
              notSend: 'Não enviar para...',
              includeCampaign: 'Incluir em uma campanha',
              campaign: 'Campanha *',
              messageCampaignOne: 'Uma campanha é um conjunto de envios relacionados.',
              messageCampaignTwo: 'As campanhas fornecem uma série de relatórios e estatísticas agrupados para seus envios.',
              messageCampaignThree: 'Utilize as campanhas para agrupar os seus envios e analisar os resultados com o passar do tempo. Exemplos: Campanha Semanel, Envios de Janeiro, etc.',
              integrationsId: 'ID para uso em integrações: ',
              found: 'Encontrados',
              repeated: 'Repetidos',
              excluded: 'Retirados',
              range: 'Alcance',
              var: 'Variáveis da lista',
              deliveries: 'Entregas',
              totalRecipients: 'Total de destinatários selecionados para o envio, considerando os atributos e segmentações que você previamente escolheu.',
              sent: 'Enviados',
              sentDesc: 'Total de destinatários que tiveram a mensagem entregue pela Globalbot ao servidor do WhatsApp.',
              notSent: 'Não enviados',
              read: 'Lidos',
              readDesc: 'Total de destinatários que efetivamente visualizaram a mensagem. Esta estatística pode ser aproximada, pois algumas pessoas não habilitam a permissão de visualização.',
              readDesc2: 'Porcentagem referente ao total de Entregues.',
              error: 'Não Entregues',
              errorDesc: 'Total de destinatários cujas mensagens não foram entregues, por razões como: número WhatsApp inexistente, usuário está em sessão de conversa aberta, problema específico com Template HSM, dentre outros.',
              errorCodesWhatsApp: {
                ///Gupshup errors
                '400': 'Em Conversa',
                '403': 'Em Conversa',
                '472': 'O número de telefone do usuário faz parte de um experimento',
                '471': 'Restrição aplicada pela META (Mensagens bloqueadas ou Sinalizadas como spam)',
                '500': 'Erro no server da broker favor contatar Globalbot',
                '1001': 'Remetente Inválido',
                '1002': 'Número Inexistente',
                '1003': 'Créditos Globalbot',
                '1004': 'Problema com Template HSM',
                '1005': 'Problema com Template HSM',
                '1006': 'Problema com Template HSM',
                '1007': 'Problema com Template HSM',
                '1026': 'Não entregável',
                '1008': 'Opt-out',
                '1010': 'URL inválida',
                '1011': 'Tamanho da Mídia Inválido',
                '1012': 'O envio da mensagem falhou porque o número de telefone optou por sair deste canal de comunicação.',
                '1013': 'Número Inexistente',
                '2000': 'Problema com Template HSM',
                '4002': 'Resposta inválida do WhatsApp',
                '4003': 'O envio da mensagem falhou porque o template não correspondia.',
                '4004': 'O tipo de mensagem só é compatível com API de nuvem hospedada Meta.',
                '4005': 'O envio da mensagem falhou porque o template está pausado.',
                ///META Errors
                //'400':'Erro de download de mídia - Falha ao baixar a mídia do remetente.',
                '402': 'Elegibilidade da empresa (problema de pagamento) - Falha ao enviar mensagem devido a um ou mais erros relacionados à forma de pagamento.',
                '408': 'A mensagem não é válida - Falha ao enviar a mensagem porque ela ficou pendente por muito tempo (por exemplo, mais de 1 dia). Isso pode acontecer ao reiniciar o cliente da WhatsApp Business API após mais de um dia de inatividade.',
                '410': 'Mensagem expirada - Mensagem não enviada durante seu TTL (tempo de vida).',
                '429': 'Limite de taxa atingido - o número enviou muitas mensagens.',
                '430': 'Certificado não assinado - Falha ao enviar a mensagem porque ocorreu um erro relacionado ao certificado.',
                '432': 'Incompatibilidade na identificação do certificado - Falha ao enviar a mensagem porque ocorreu um erro relacionado ao certificado.',
                '433': 'Assinatura de certificado inválida - Ocorre quando uma mensagem é enviada, mas o certificado do cliente da empresa não está assinado de forma adequada com as chaves de identidade correspondentes. Isso indica que o cliente provavelmente refez o cadastro com chaves de identidade novas, mas não concluiu todo o fluxo de criação do certificado.',
                '470': 'Mensagem de reengajamento - Falha ao enviar a mensagem porque mais de 24 horas se passaram desde a última vez que o cliente respondeu a este número.',
                //'471':'Limite de taxa de spam atingido - Falha ao enviar a mensagem porque há um limite de envios que podem ser feitos deste número de telefone. É possível que muitas mensagens anteriores tenham sido bloqueadas ou marcadas como spam.',
                //'472':'O número do usuário faz parte de um experimento - Falha ao enviar a mensagem porque o número de telefone do usuário faz parte de um',
                '480': 'Possível mudança de usuário - Falha ao enviar a mensagem devido a uma possível alteração no número de telefone do usuário.',
                //'500':'Erro genérico - Falha ao enviar a mensagem devido a um erro desconhecido.',
                '501': 'O tipo de mensagem não é aceito no momento. - Atualmente, as mensagens de documentos com legendas não são compatíveis.',
                '1000': 'Erro genérico',
                //'1001':'Mensagem muito longa - O tamanho da mensagem é superior a 4.096 caracteres.',
                //'1002':'Tipo de destinatário inválido - Tipos de destinatário válidos:',
                //'1004':'Recurso já existente - Possível conflito de UUID para solicitações de carregamento de mídia ou UUID já existente atribuído a outra mídia.',
                //'1005':'Acesso negado -',
                //'1006':'Recurso não encontrado - Arquivo ou recurso não encontrado.',
                //'1007':'Destinatário bloqueado para receber mensagens ( - O destinatário não está na lista de permissão.',
                //'1008':'Parâmetro obrigatório ausente - Um parâmetro obrigatório está ausente.',
                '1009': 'Valor do parâmetro inválido - O valor inserido para um parâmetro não é do tipo correto ou há outro problema.',
                //'1010':'Parâmetro não obrigatório - Contém um parâmetro que não é obrigatório.',
                //'1011':'O serviço não está pronto -',
                //'1013':'O usuário não é válido -',
                '1014': 'Erro interno',
                '1015': 'Número excessivo de solicitações - O limite de taxa do cliente foi atingido.',
                '1016': 'Sobrecarga do sistema - Se o sistema receber carga muito intensa, esse erro é retornado para permitir a recuperação do sistema.',
                '1017': 'Mestre não primário - Esse erro ocorre quando uma solicitação somente de nível mestre (como definir ou obter configurações, importar, exportar, solicitar código, registrar, entre outros) é enviada a um nó que não é um mestre primário. Isso poderá acontecer caso o cliente da WhatsApp Business API não esteja configurado corretamente ou se houver erros internos.',
                '1018': 'Coreapp não primário - Esse erro ocorre quando solicitações são enviadas a um nó do Coreapp que não é proprietário do fragmento nem está em vias de se tornar o proprietário. Você poderá ver esse erro quando estivermos fazendo failover de fragmento na configuração de multiconexão.',
                '1021': 'Usuário incorreto - Esse erro ocorre quando um usuário envia uma mensagem para si mesmo.',
                '1022': 'URL de webhooks não configurada - Esse erro ocorrerá se o formato de webhooks da API de REST não for configurado.',
                '1023': 'Erro de banco de dados',
                '1024': 'Alteração de senha solicitada - É necessário alterar a senha.',
                '1025': 'Solicitação inválida - A solicitação não é válida.',
                //'1026':'Destinatário indisponível - Mensagem não entregue. Consulte "Por que minha taxa de entrega não é de 100%?".',
                '1028': 'A notificação de sistema do tipo  - Você enviou uma mensagem a um usuário do WhatsApp que pode ter sido alterado. Uma notificação',
                '1031': 'A conta do remetente está bloqueada - Sua conta foi bloqueada e não poderá enviar mensagens devido a uma violação da política de integridade. Consulte',
                //'2000':'Incompatibilidade na contagem de parâmetros do modelo - O número de parâmetros fornecidos não corresponde à quantidade esperada.',
                '2001': 'Modelo ausente - O modelo não está com status de ativo ou não existe para um idioma e local.',
                '2002': 'Falha na busca do modelo - O destinatário falhou ao baixar o modelo.',
                '2003': 'Pacote de modelos ausente - Não há modelos para determinado idioma e localidade.',
                '2004': 'Parâmetro do modelo longo demais - O parâmetro é longo demais.',
                '2005': 'Texto hidratado do modelo longo demais - O texto traduzido é longo demais.',
                '2006': 'Política de espaço em branco do modelo violada - A política de espaço em branco foi violada.',
                '2007': 'Política de caracteres do formato do modelo violada - A política de caracteres do formato foi violada.',
                '2008': 'Formato de mídia do modelo incompatível - O formato de mídia usado não é compatível.',
                '2009': 'Componente obrigatório do modelo ausente - Um componente obrigatório do modelo não está presente.',
                '2010': 'URL hidratada do modelo inválida - A URL no componente de botão não é válida.',
                '2011': 'Número de telefone do modelo inválido - O número de telefone no componente de botão não é válido.',
                '2012': 'Incompatibilidade no formato do parâmetro do modelo - O formato do parâmetro não corresponde ao do modelo criado.',
                '2013': 'Botões do modelo incompatíveis - Os botões não são aceitos pelo destinatário.',
                '2014': 'O namespace esperado está vazio - Esse erro ocorre quando o pacote de idiomas de um modelo de mensagem ainda não foi baixado.',
                '2015': 'Número de seções inválido - O número de seções da solicitação de mensagem está abaixo ou acima do limite permitido. Consulte o',
                '2016': 'Número de linhas inválido - O número de linhas não é válido. Para',
                '2017': 'Política de caracteres violada - A política de caracteres do formato foi violada.',
                '2023': 'Número inválido de produto - O objeto da seção não tem produtos ou a contagem total de produtos ultrapassou o número máximo permitido.',
                '2024': 'Identificação de catálogo não encontrada - A identificação do catálogo fornecida não existe ou não pertence à sua conta do WhatsApp Business (WABA, pela sigla em inglês).',
                '2025': 'Identificação de catálogo não vinculada ao número da API - A identificação de catálogo que você forneceu não está vinculada ao número de telefone usado para enviar mensagens.',
                '2026': 'Produtos não encontrados - Alguns produtos fornecidos na sua solicitação não foram encontrados no catálogo.',
                '2027': 'Nenhum produto encontrado - Nenhum produto foi encontrado no catálogo fornecido.',
                '2028': 'Lista de todos os produtos com falha de conformidade',
                '2029': 'Lista de alguns produtos com falha de conformidade',
                '2030': 'Lista que combina produtos inválidos e com falha de conformidade',
                '2036': 'Estrutura de cabeçalho inválida - Esse erro é retornado quando a estrutura do',
                '2050': 'Informação de conformidade ausente',
                '2060': 'Modelo rejeitado - O status do modelo é ',
                '2061': 'Modelo em pausa - O status do modelo é',
                '2062': 'Modelo desabilitado - O status do modelo é',
                '2064': 'ID do Fluxo inválido - O fluxo com ID especificado não existe, não pertence à sua conta do WhatsApp Business ou está em estado inválido.',
                '2068': 'Fluxo bloqueado - O fluxo está no estado bloqueado.',
                '2069': 'Fluxo limitado - O fluxo está no estado limitado, e 10 mensagens usando esse fluxo já foram enviadas na última hora.',
                ///CLOUD API META ERROS https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes
                ///Erros de autorização
                '0': 'AuthException - Não foi possível autenticar o usuário do app.',
                '3': 'Método de API - Indica um problema que envolve recursos ou permissões.',
                '10': 'Permissão negada - A permissão não foi concedida ou foi removida.',
                '190': 'O token de acesso expirou.',
                '200': 'Permissão da API - A permissão não foi concedida ou foi removida.',
                '299': 'Permissão da API - A permissão não foi concedida ou foi removida.',
                ///Erros de limitação
                '4': 'O app atingiu o limite da taxa de chamadas de API. - O app atingiu o limite da taxa de chamadas de API.',
                '80007': 'A conta do WhatsApp Business atingiu o limite de volume. - A conta do WhatsApp Business atingiu o limite de volume.',
                '130429': 'A taxa de transferência de dados de mensagens da API de Nuvem foi atingida. - A taxa de transferência de dados de mensagens da API de Nuvem foi atingida.',
                '131048': 'Falha ao enviar a mensagem devido a um limite de envios que podem ser feitos deste número de telefone. É possível que muitas mensagens anteriores tenham sido bloqueadas ou marcadas como spam. - Falha ao enviar a mensagem devido a um limite de envios que podem ser feitos deste número de telefone. É possível que muitas mensagens anteriores tenham sido bloqueadas ou marcadas como spam.',
                '131056': 'Foram enviadas muitas mensagens do número de telefone do remetente para o mesmo número de telefone do destinatário em um curto período. - Foram enviadas muitas mensagens do número de telefone do remetente para o mesmo número de telefone do destinatário em um curto período.',
                '133016': 'O registo ou a exclusão do registro falhou porque foram feitas muitas tentativas neste número de telefone em um curto período de tempo. - O registo ou a exclusão do registro falhou porque foram feitas muitas tentativas neste número de telefone em um curto período de tempo.',
                ///Erros de integridade
                '368': 'Bloqueado temporariamente por violações de políticas - A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma.',
                '131031': 'Conta bloqueada - A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma. Ou então, não foi possível verificar os dados incluídos na solicitação em relação ao conjunto de dados na conta do WhatsApp Business (por exemplo, o pin de duas etapas incluído na solicitação está incorreto).',
                ///Outros erros
                '1': 'Há uma solicitação inválida ou um possível erro de servidor. - Há uma solicitação inválida ou um possível erro de servidor.',
                '2': 'Há um erro temporário por inatividade ou sobrecarga. - Há um erro temporário por inatividade ou sobrecarga.',
                '33': 'O número de telefone comercial foi excluído. - O número de telefone comercial foi excluído.',
                '100': 'O pedido incluía um ou mais parâmetros incompatíveis ou escritos de forma errada. - O pedido incluía um ou mais parâmetros incompatíveis ou escritos de forma errada.',
                '130472': 'A mensagem não foi enviada como parte de um experimento.',
                '131000': 'Falha ao enviar a mensagem devido a um erro desconhecido. - Falha ao enviar a mensagem devido a um erro desconhecido.',
                '131005': 'A permissão não foi concedida ou foi removida. - A permissão não foi concedida ou foi removida.',
                '131008': 'Falta um parâmetro obrigatório no pedido. - Falta um parâmetro obrigatório no pedido.',
                '131009': 'Um ou mais valores de parâmetros são inválidos. - Um ou mais valores de parâmetros são inválidos.',
                '131016': 'Um serviço está temporariamente indisponível. - Um serviço está temporariamente indisponível.',
                '131021': 'O número de telefone do destinatário e do remetente é o mesmo. - O número de telefone do destinatário e do remetente é o mesmo.',
                '131026': 'Não foi possível entregar a mensagem. Os motivos podem ser os seguintes: - O número de telefone do destinatário não está registrado no WhatsApp. / Houve tentativa de envio de um modelo de autenticação a um usuário do WhatsApp com o código de ligação de país +91 (Índia). Atualmente, não é possível enviar modelos de autenticação a usuários do WhatsApp na Índia.  / O destinatário não aceitou os novos Termos de Serviço e a nova Política de Privacidade. / O destinatário está usando uma versão antiga do WhatsApp / A mensagem não foi entregue para criar uma experiência de alta qualidade para o usuário.',
                '131042': 'Ocorreu um erro relacionado à forma de pagamento. - Ocorreu um erro relacionado à forma de pagamento.',
                '131045': 'Ocorreu uma falha ao enviar a mensagem devido a um erro de registro do número de telefone. - Ocorreu uma falha ao enviar a mensagem devido a um erro de registro do número de telefone.',
                '131047': 'Mais de 24 horas se passaram desde que o destinatário respondeu pela última vez ao número do remetente. - Mais de 24 horas se passaram desde que o destinatário respondeu pela última vez ao número do remetente.',
                '131051': 'O tipo de mensagem não é compatível. - O tipo de mensagem não é compatível.',
                '131052': 'Não foi possível baixar a mídia enviada pelo usuário. - Não foi possível baixar a mídia enviada pelo usuário.',
                '131053': 'Não foi possível carregar a mídia usada na mensagem. - Não foi possível carregar a mídia usada na mensagem.',
                '131057': 'A conta comercial está em modo de manutenção. - A conta comercial está em modo de manutenção.',
                '132000': 'O número de valores de parâmetros variáveis incluídos na solicitação não corresponde ao número de parâmetros variáveis definidos no modelo. - O número de valores de parâmetros variáveis incluídos na solicitação não corresponde ao número de parâmetros variáveis definidos no modelo.',
                '132001': 'O modelo não existe no idioma especificado ou não foi aprovado. - O modelo não existe no idioma especificado ou não foi aprovado.',
                '132005': 'O texto traduzido é longo demais. - O texto traduzido é longo demais.',
                '132007': 'O conteúdo do modelo viola uma política do WhatsApp. - O conteúdo do modelo viola uma política do WhatsApp.',
                '132012': 'Os valores de parâmetros variáveis foram formatados incorretamente. - Os valores de parâmetros variáveis foram formatados incorretamente.',
                '132015': 'O modelo foi pausado por  - O modelo foi pausado por ',
                '132016': 'O modelo foi pausado muitas vezes por  - O modelo foi pausado muitas vezes por ',
                '132068': 'O fluxo está no estado bloqueado. - O fluxo está no estado bloqueado.',
                '132069': 'O fluxo está no estado limitado, e 10 mensagens usando esse fluxo já foram enviadas na última hora. - O fluxo está no estado limitado, e 10 mensagens usando esse fluxo já foram enviadas na última hora.',
                '133000': 'Uma tentativa anterior de cancelamento do registro falhou. - Uma tentativa anterior de cancelamento do registro falhou.',
                '133004': 'O servidor está temporariamente indisponível. - O servidor está temporariamente indisponível.',
                '133005': 'O PIN de confirmação em duas etapas está incorreto. - O PIN de confirmação em duas etapas está incorreto.',
                '133006': 'O número de telefone precisa ser verificado antes do registro. - O número de telefone precisa ser verificado antes do registro.',
                '133008': 'Ocorreram muitas tentativas do PIN de confirmação em duas etapas para este número de telefone. - Ocorreram muitas tentativas do PIN de confirmação em duas etapas para este número de telefone.',
                '133009': 'O PIN de confirmação em duas etapas foi inserido muito rapidamente. - O PIN de confirmação em duas etapas foi inserido muito rapidamente.',
                '133010': 'O número de telefone não está registrado na Plataforma do WhatsApp Business. - O número de telefone não está registrado na Plataforma do WhatsApp Business.',
                '133015': 'O número de telefone que você está tentando registrar foi excluído recentemente, e a exclusão ainda não foi concluída. - O número de telefone que você está tentando registrar foi excluído recentemente, e a exclusão ainda não foi concluída.',
                '135000': 'Falha ao enviar a mensagem devido a um erro desconhecido com seus parâmetros de solicitação. - Falha ao enviar a mensagem devido a um erro desconhecido com seus parâmetros de solicitação.',
              },
              internalError: 'Erro Interno',
              totalEmails: 'Total de e-mails efetivamente enviados, descontando os endereços de e-mail inválidos e descartados pelo serviço de disparo de e-mails.',
              handedOut: 'Entregues',
              handedOutDesc: 'Total de destinatários que tiveram a mensagem entregue pelo WhatsApp aos dispositivos dos clientes.',
              handedOutDesc2: 'Porcentagem referente ao total de Enviadas.',
              receivedTheEmail: 'Total de destinatários que receberam o e-mail, descontando os que ocorreram bounce. A porcentagem é sobre o número de e-mails enviados.',
              softBounce: 'Soft Bounce',
              totalEmailsNotHandedTemporary: 'Total de e-mails que não foram entregues por razões temporárias, tais como: caixa de entrada cheia do destinatário ou servidor offline do destinatário. O provedor de e-mail tentará enviar novamente a mensagem durante as próximas 72h. A porcentagem é sobre o número de e-mails enviados.',
              hardBounce: 'Hard Bounce',
              totalEmailsNotHandedPermanent: 'Total de e-mails que não foram entregues por razões permanentes, tais como: e-mails inexistentes ou bloqueio por parte do servidor de e-mail do destinatário, por considerá-lo spam. A porcentagem é sobre o número de e-mails enviados.',
              engagement: 'Engajamento',
              openings: 'Aberturas',
              totalUniqueEmailOpenings: 'Total de aberturas únicas do e-mail. A porcentagem é sobre o número de e-mails entregues.',
              clicks: 'Cliques',
              totalClickUnique: 'Total de cliques únicos em links do e-mail. A porcentagem é sobre o número de e-mails abertos.',
              markedSpam: 'Marcados como spam',
              totalMarkedSpam: 'Total de usuários que marcaram este e-mail como Spam. A porcentagem é sobre o número de e-mails entregues.',
              unsubscriptions: 'Descadastramentos',
              totalUnsubscriptions: 'Total de usuários que cancelaram a inscrição a partir do e-mail enviado. A porcentagem é sobre o número de e-mails entregues.',
              totalRecipientsSent: 'Total de destinatários com mensagem efetivamente enviada, descontando os destinatários descartados por conterem números inválidos ou inexistentes.',
              totalRecipientsSms: 'Total de destinatários que tiveram a mensagem entregue pelas operadoras de SMS.',
              totalRecipientsNotSend: 'Total de destinatários que não foi possível entregar a mensagem por razões permanentes, tais como: conter número inválido ou inexistente.',
              waiting: 'Aguardando',
              numberOfRecipientsWaiting: 'Número de destinatários que ainda estão aguardando o processo de envio ser finalizado.',
              handedOutServer: 'Entregues (Servidor)',
              totalRecipientsFromGlobalbotToWhatsapp: 'Total de destinatários que tiveram a mensagem entregue pela Globalbot ao servidor do WhatsApp.',
              handedOutDevice: 'Entregues (Dispositivo)',
              totalRecipientsFromWhatsappToClients: 'Total de destinatários que tiveram a mensagem entregue pelo WhatsApp aos dispositivos dos clientes.',
              totalRecipientsNotHandedTemporary: 'Total de destinatários cujas mensagens não foram entregues por razões temporárias, tais como: destinatário está em sessão de conversa aberta. O sistema tentará enviar novamente a mensagem durante as próximas 24h. A porcentagem é sobre o número de destinatários enviados.',
              preview: 'Visualização',
              totalRecipientsViewedMessage: 'Total de destinatários que efetivamente visualizou a mensagem.',
              important: 'Importante:',
              messageImportantOne: ' Você deve importar uma lista no formato de arquivo .CSV, lembrando que para envios SMS e',
              messageImportantTwo: 'WhatsApp o campo "telefone" (phone) é obrigatório.',
              messageImportantThree: 'Para os envios de e-mail o campo "email" também é obrigatório.',
              messageImportantFour: 'Para os envios de WhatsApp é obrigatório o número de telefone ter o DDI do usuário, exemplo: 554899999999 (Brasil)',
              totalFound: 'Encontramos um total de ',
              items: ' itens.',
              import: 'Importar',
              modelSelectedNotHaveChannel: 'O modelo selecionado não tem um canal, por favor crie novamente um novo modelo.',
              modelSelectedNotMessageValid: 'O modelo selecionado não tem mensagem válida, favor verificar a mensagem deste modelo.',
              notIncludeDomain: 'Não inclua o domínio nos campos "remetente" e "responder para", esses campos são selecionados através dos domínios autorizados!',
              weNeedDomain: 'Infelizmente precisamos de um domínio autorizado para continuar, você pode autenticar um nas preferências.',
              fieldsNeedFilledOne: 'O campo nome, remetente, responder para, e listas ou filtros precisam ser preenchidos!',
              selectServer: 'Por favor selecione um servidor, caso não tenha, solicite um com a Globalbot!',
              fieldsNeedFilledTwo: 'O campo nome e listas ou filtros precisam ser preenchidos!',
              dispatchSaved: 'Seu envio foi salvo!',
              draftSaved: 'Seu envio foi salvo!',
              messageErrorConnection: 'Oops, ocorreu um erro de conexão!',
              columnEmailRequired: 'A coluna de "email" é obrigatório.',
              columnPhoneRequired: 'A coluna de "phone" é obrigatório.',
              messageError: 'Oops, ocorreu algum erro.',
              listSaved: 'Sua lista foi salva!',
              exportData: 'Você realmente deseja exportar todos os seus registros?'
            },
            list: {
              title: 'Título *',
              toggleLabel: 'Alterar rótulo',
              filter: 'Filtro *',
              save: 'Salvar',
              messageError: 'Oops, ocorreu algum erro.',
              listSaved: 'Sua lista foi salva!',
              messageErrorConnection: 'Oops, ocorreu um erro de conexão',
              fieldsRequired: 'Os campos campanha, assunto e email de origem precisam ser preenchidos!'
            }
          },
          conversation: {
            save: 'Salvar',
            cancel: 'Cancelar',
            conversationData: 'Dados da Conversa',
            protocol: 'Protocolo',
            identifierId: 'Identificador',
            attendants: 'Atendente(s)',
            tags: 'Tags',
            device: 'Dispositivo',
            dispatchEmail: 'Email de lead enviado',
            dispatchEmailToClient: 'Email enviado para o cliente',
            emailViewedByClient: 'Email visualizado pelo cliente',
            dateAndHour: 'Data e Hora',
            channel: 'Canal',
            satisfaction: 'Satisfação',
            punctuation: 'Pontuação',
            search: 'Pesquisar',
            location: 'Localização',
            origin: 'Origem',
            campaigns: 'Campanhas',
            messageSent: 'Mensagem enviada:',
            comments: 'Comentários',
            messageNotFound: 'Infelizmente não achamos essa conversa.',
            messageTryAgain: 'Oops, tivemos um problema ao recuperar essa conversa, tente novamente. Caso o erro persista por favor entre em contato.'
          },
          integration: {
            save: 'Salvar',
            cancel: 'Cancelar',
            integrationDetails: 'Detalhes da integração',
            activeIntegration: 'Integração ativa',
            identifier: 'Identificador',
            hash: 'Hash',
            token: 'Token',
            hasToken: 'Possui API token',
            webhook: 'Webhook',
            host: 'Host',
            key: 'Key',
            haveRestriction: 'Tem restrição?',
            contactPurpose: 'Coloque abaixo os valores do botão "Objetivo do contato" que você não deseja que vá ao VistaSoft',
            add: 'Adicionar',
            customIdFields: 'Defina abaixo os ids dos campos personalizados e seus identificadores, exemplo %NAME%.',
            idField: 'Id do campo ',
            websiteAddress: 'Endereço do site',
            userProvidedByKSI: 'Usuário fornecido pela KSI',
            passwordProvidedByKSI: 'Senha fornecida pela KSI',
            salesFlow: 'Fluxo de Venda',
            salesFlowValue: 'Qual o identificador que tem o valor de venda no fluxo?',
            salesValue: 'Valor do identificador de venda',
            createIdSales: 'Crie um id padrão de imóvel de venda e coloque aqui',
            rentalFlow: 'Fluxo de Aluguel',
            rentalFlowValue: 'Qual o identificador que tem o valor de aluguel no fluxo?',
            rentalValue: 'Valor do identificador de aluguel',
            createIdRental: 'Crie um id padrão de imóvel de aluguel e coloque aqui',
            releasesFlow: 'Fluxo de Lançamentos',
            releasesFlowValue: 'Qual o identificador que tem o valor de lançamento no fluxo?',
            releasesValue: 'Valor do identificador de lançamento',
            createIdSalesInReleases: 'Crie um id padrão de imóvel de venda e coloque lançamento',
            endpoint: 'Endpoint',
            enterprises: 'Empreendimento',
            sendToAttendant: 'Enviar ao atendente',
            includeInternalMessages: 'Incluir mesagens internas',
            enterpriseId: 'ID do Empreendimento',
            subdomain: 'Subdomínio',
            clientSecret: 'Client_Secret',
            clientId: 'Client_Id',
            code: 'Code',
            user: 'Usuário',
            password: 'Senha',
            project: 'Projeto',
            problemType: 'Tipo do problema',
            customField: 'Campo Customizado',
            customFieldValue: 'Valor do campo customizado',
            title: 'Título (opcional)',
            sendOnlyLivechat: 'Enviar conversas apenas do Livechat',
            loadLink: 'Link da carga',
            savedPreferences: 'Preferência salva com sucesso',
            hasForms: 'Possui formulário',
            formId: 'ID do formulário',
            textFormId: 'ID do formulário que está presente no zendesk na aba de "Configurações" na página "Formulários de ticket", após selecionar o seu formulário o ID do mesmo estara presente na URL após o /edit.',
            fieldId: 'ID do campo',
            textFieldId: 'ID do campo que está presente no zendesk na aba de "Configurações" na página "Campos de ticket", após entrar na página o ID do campo estará ao lado do nome.',
            responsible: 'Responsável(Id)',
            createdBy: 'Criado por(Id)',
            businessPhaseId: 'Fase de negócios(Id)',
            salerCode: 'Código do vendedor',
            methodologyCode: 'Código da metodologia',
            salesChannelCode: 'Código do canal de vendas',
            baseURL: 'URL base. ex: https://sua-loja-de-desenvolvimento.myshopify.com'
          },
          knowledge: {
            close: 'Fechar',
            cancel: 'Cancelar',
            answered: 'Respondidas',
            unambiguous: 'Desambiguadas',
            unanswered: 'Não respondidas',
            channel: 'Canal',
            date: 'Data',
            question: 'Pergunta',
            response: 'Resposta',
            responsible: 'Responsável',
            createdBy: 'Criado por',
            testEnvironment: 'Ambiente de teste',
            mode: 'Modo',
            test: 'Teste',
            public: 'Público'
          },
          liveAttendants: {
            close: 'Fechar',
            attendants: 'Atendentes',
            avatar: 'Avatar',
            attendance: 'Conversas ativas',
            name: 'Nome',
            departments: 'Departamentos',
            role: 'Cargo',
            away: 'Away',
            clear: 'Limpar',
            offline: 'Offline',
            rooms: 'Salas ativas',
            actions: 'Ações'
          },
          connectedUsers: {
            close: 'Fechar',
            users: 'Usuários conectados',
            avatar: 'Avatar',
            attendance: 'Conversas ativas',
            name: 'Nome',
            departments: 'Departamentos',
            role: 'Cargo',
            away: 'Away',
            clear: 'Limpar',
            offline: 'Offline',
            rooms: 'Salas ativas',
            actions: 'Ações'
          },
          liveConfig: {
            save: 'Salvar',
            cancel: 'Cancelar',
            settings: 'Configurações',
            soundNewAttendance: 'Som de novo atendimento',
            messageSoundReceived: 'Som de mensagem recebida',
            listOrdering: 'Ordenação de lista',
            ascendingArrivalDate: 'Data de chegada crescente',
            descendingArrivalDate: 'Data de chegada decrescente',
            lastMessageCrescent: 'Última mensagem crescente',
            lastMessageDecreasing: 'Última mensagem decrescente',
            crescentGroup: 'Por grupo crescente',
            decreasingGroup: 'Por grupo decrescente',
            amountMessageCrescent: 'Quantidade de mensagens crescente',
            amountMessageDecreasing: 'Quantidade de mensagens decrescente',
            activeTimer: 'Habilitar Cronômetro',
            totalTimeOfAttendant: 'Tempo total de atendimento',
            lastClientInteraction: 'Contador de última interação do cliente',
            exhibitionAlertTime: 'Limite do Aviso de Alerta',
            minutes: 'minutos'
          },
          notifications: {
            notifications: 'Central de Notificações',
            noNotifications: 'Não há notificações para você no momento.'
          }
        },
        dashboard:{
          connectedUsers: 'Usuários Conectados',
          connectedUsersTip: 'Confira os usuários que estão conectados na plataforma Globalbot neste momento.'
        },
        editable: {
          carousel: {
            idealResolution: 'Resolução ideal',
            librarySearch: 'Buscar na biblioteca',
            key: 'Chave',
            value: 'Valor',
            jump: 'Salto',
            title: 'Título',
            description: 'Descrição',
            select: 'Selecionar',
            image: 'Imagem',
            buttons: 'Botões'
          }
        },
        emailBuilder: {
          add: {
            campaigns: 'Campanhas ',
            addEmail: ' Adicionar e-mail',
            subject: 'Assunto *',
            sender: 'Remetente *',
            replyTo: 'Responder para',
            tags: 'Tags',
            save: 'Salvar',
            attendanceRealized: 'Atendimento Realizado',
            attendanceProtocol: 'Protocolo de atendimento',
            historic: 'Histórico',
            templateSaved: 'Seu template foi salvo!',
            messageErrorConnection: 'Oops, ocorreu um erro de conexão!',
            fieldsRequired: 'Os campos campanha, assunto e email de origem precisam ser preenchidos!'
          },
          emailBuilder: {
            messageEmailSaved: 'Mensagens de email salvas',
            subject: 'Assunto: ',
            addNewTemplate: 'Adicionar novo template'
          }
        },
        lists: {
          push: {
            insertList: 'Inserir em Lista',
            list: 'Lista',
            cancel: 'Cancelar',
            insert: 'Inserir',
            updatedList: 'Lista atualizada!',
            failedUpdateList: 'Falha ao atualizar lista!'
          }
        },
        live: {
          container: {
            message: {
              cards: {
                startConversation: 'Iniciar Conversa',
                insertList: 'Inserir em Lista'
              },
              media: {
                speechToText: 'Speech to Text (transcrição de áudio para texto)',
                notSupport: 'O seu navegador não tem suporte, por favor use o Chrome.',
                notRegistered: 'Não registrado',
                openOriginalImage: 'Abrir imagem original'
              },
              yesterday: 'Ontem',
              today: 'Hoje',
              userNotFound: 'Usuário não encontrado',
              status: {
                noSubmitted: 'Mensagem não enviada(clique para tentar novamente)',
                sending: 'Enviando mensagem',
                sended: 'Mensagem enviada',
                delivered: 'Mensagem entregue',
                read: 'Mensagem lida'
              },
              networkRequired: 'Você precisa estar conectado para enviar a mensagem novamente.'
            },
            conversation: {
              identifierId: 'Identificador',
              changeName: 'Alterar nome',
              protocol: 'Protocolo',
              tags: 'Tags',
              device: 'Dispositivo',
              dispatchEmailLead: 'Email de lead enviado',
              dispatchEmailClient: 'Email enviado para o cliente',
              emailViewedClient: 'Email visualizado pelo cliente',
              dateAndTime: 'Data e Hora',
              channel: 'Canal',
              satisfaction: 'Satisfação',
              punctuation: 'Pontuação',
              search: 'Pesquisar',
              location: 'Localização',
              origin: 'Origem'
            },
            knowledge: {
              reply: 'Responder',
              copyReply: 'Copiar resposta',
              messageErrorKnowledge: 'Erro ao consultar bases de conhecimento',
              messageErrorConsult: 'Erro ao consultar perguntas.'
            }
          },
          footer: {
            actions: {
              annex: {
                sendImage: 'Enviar imagem! (5 MB tamanho máximo)',
                sendVideo: 'Enviar Vídeo! (16 MB tamanho máximo)',
                sendFile: 'Enviar arquivo! (100 MB tamanho máximo)',
                selectLibrary: 'Selecionar da biblioteca!',
                library: 'Biblioteca',
                preview: 'Pré-visualizar',
                addLegend: 'Adicionar legenda',
                cancel: 'Cancelar',
                send: 'Enviar',
                file: 'Arquivo',
                avatar: 'Avatar',
                maxFileSize: 'O tamanho do arquivo é maior que o máximo permitido.',
                errorProcessingDoc: 'Erro ao processar documento.',
                fileFormatNotAccepted: 'Formato de arquivo não aceito!'
              },
              audio: {
                recordAudio: 'Gravar áudio (máximo 2 minutos)',
                cancel: 'Cancelar',
                send: 'Enviar',
                preview: 'Pré-visualizar',
                addLegend: 'Adicionar legenda',
                sendText: 'Enviar texto',
                sendAudio: 'Enviar áudio',
                errorProcessingAudio: 'Erro ao processar audio.',
                accessMicrophoneRequired: 'Para o pleno funcionamento do chat ao vivo é necessário permitir o acesso para o microfone.',
                transcribe: 'Transcrição do áudio'
              },
              quickResponse: {
                quickResponse: 'Resposta rápida',
                add: 'Adicionar',
                close: 'Fechar',
                unshare: 'Descompartilhar',
                reply: 'Responder',
                edit: 'Colar na caixa de texto',
                share: 'Compartilhar',
                exclude: 'Excluir',
                createQuickResponse: 'Adicionar resposta rápida',
                title: 'Título',
                message: 'Mensagem',
                cancel: 'Cancelar',
                errorConsultQuickResponse: 'Erro ao consultar respostas rápida.',
                errorRegisterQuickResponse: 'Erro ao cadastrar resposta rápida.',
                errorExcludeQuickResponse: 'Erro ao excluir resposta rápida.',
                errorShareQuickResponse: 'Erro ao compartilhar resposta rápida.'
              }
            }
          },
          utils: {
            attendants: {
              attendant: 'Atendente',
              attendants: 'Atendentes',
              actualCalls: 'Total atendimentos',
              avatar: 'Avatar',
              name: 'Nome',
              role: 'Cargo',
              select: 'Selecionar',
              actions: 'Ações'
            },
            departments: {
              department: 'Departamento',
              attendants: 'Atendentes'
            },
            roomOptions: {
              endAttendance: 'Finalizar atendimento',
              archiveAttendance: 'Arquivar atendimento',
              reactivateNotifications: 'Reativar notificações',
              muteNotifications: 'Silenciar notificações',
              unpinAttendance: 'Desafixar atendimento',
              fixedAttendance: 'Fixar atendimento',
              transferAttendance: 'Transferir atendimento',
              transferAttendanceSelected: 'Transferir selecionadas',
              endAttendanceSelected: 'Finalizar selecionadas',
              transferRestrictedAttendanceSelected: 'Transferir selecionadas (apenas para Gerentes e Administradores)',
              archiveAttendanceSelected: 'Arquivar selecionadas'
            }
          },
          createGroup: {
            newGroup: 'Novo grupo',
            newConversation: 'Nova conversa'
          },
          createHsm: {
            limitCharactersExceeded: 'Limite de caracteres excedido!',
            credits: 'Créditos',
            creditsDescription: 'Para envio de mensagens para clientes que estão inativos no WhatsApp (24h após a última mensagem enviada pelo cliente) é necessário utilizar um Template de Mensagem (HSM) pré-aprovado pelo WhatsApp. Este envio consome 1 crédito da plataforma.',
            creditsInsufficient: 'Infelizmente você não possui créditos suficientes.',
            attention: 'Atenção:',
            attentionDescription: ' Após 24h de inatividade do cliente, a empresa somente poderá enviar mensagem padronizada e pré-aprovada pelo WhatsApp, conhecida como HSM. Cada mensagem HSM consome 01 crédito na plataforma, pois são passíveis de cobrança pelo WhatsApp.',
            requestCredits: 'Solicitar mais créditos',
            whoToSendMessage: 'Para quem você quer enviar a mensagem?',
            originNumber: 'Número de origem *',
            nameClient: 'Nome do Cliente *',
            country: 'País *',
            nameClientWhatsapp: 'Número do Whatsapp do Cliente *',
            attentionNinthNumber: ' Informe o número do celular do cliente com DDD com 2 dígitos, exemplo: (XX) XXXXX-XXXX. As mensagens só serão enviadas para clientes que possuem conta WhatsApp ativa.',
            messageYouCanNot: 'Ops! Você não poderá enviar mensagem para',
            messageActiveConversationWith: 'porque ele já está em uma conversa ATIVA com ',
            messageActiveConversationAttendants: 'porque ele já está em uma conversa ATIVA com outro atendente.',
            messageConversationWithYou: 'já está em conversa com você! Envie mensagens através da sala de conversa.',
            goToRoom: 'Ir para sala',
            messageTemplate: 'Template de Mensagem (HSM)',
            notAuthorized: 'Ops! Você não poderá enviar mensagem para esta pessoa pois ela não autorizou o recebimento de mensagens (Opt-In) conforme políticas do WhatsApp.',
            messageUserOptIn: 'Para sua empresa enviar mensagem para o cliente (estando fora da sessão de conversa), este cliente precisa necessariamente ser um usuário OPT-IN (ou seja, deu consentimento para receber mensagens). Saiba mais sobre Opt-In.',
            messageSent: 'Mensagem enviada!',
            return: 'Voltar',
            advance: 'Avançar',
            missingMessage: 'Mensagem ausente',
            notPossibleStartConversation: 'Não foi possível iniciar a conversa pois o usuário já está conversando com o chatbot.',
            numberNotValid: 'Ops! O número informado não é uma conta válida no WhatsApp! Verifique o número e tente novamente. Lembre-se, não informar o DDI.',
            nameNotValid: 'Favor informar o nome do cliente.',
            requiredMessage: 'Ops! Você precisa preencher a mensagem a ser enviada!'
          },
          pushGroup: {
            add: 'Adicionar',
            attendantAdded: 'Atendente adicionado!',
            alreadyInGroup: 'Este atendente já está no grupo!'
          },
          resendHsm: {
            limitCharactersExceeded: 'Limite de caracteres excedido!',
            credits: 'Créditos',
            creditsDescription: 'Para envio de mensagens para clientes que estão inativos no WhatsApp (24h após a última mensagem enviada pelo cliente) é necessário utilizar um Template de Mensagem (HSM) pré-aprovado pelo WhatsApp. Este envio consome 1 crédito da plataforma.',
            creditsInsufficient: 'Infelizmente você não possui créditos suficientes.',
            attention: 'Atenção:',
            attentionDescription: 'Após 24h de inatividade do cliente, a empresa somente poderá enviar mensagem padronizada e pré-aprovada pelo WhatsApp, conhecida como HSM. Cada mensagem HSM consome 01 crédito na plataforma, pois são passíveis de cobrança pelo WhatsApp.',
            requestCredits: 'Solicitar mais créditos',
            messageTemplate: 'Template de Mensagem (HSM)',
            send: 'Enviar',
            impossibleRecover: 'Impossível recuperar o clientId'
          }
        },
        micro: {
          cdt: {
            yesterday: 'Ontem',
            today: 'Hoje'
          },
          cdtWithHours: {
            yesterday: 'Ontem',
            today: 'Hoje',
            sunday: 'Domingo',
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sabado'
          }
        },
        modals: {
          feedback: {
            newFunctions: 'Ajude a gente a desenvolver novas funcionalidades para o painel!',
            help: 'Vamos ajudar a melhorar nosso painel?',
            info: 'Esse feedback é somente para melhorias, caso você queira reportar algum problema técnico entre em contato com o suporte.',
            nowYouCan: 'Agora você pode ajudar a melhorar a nossa plataforma!',
            feedbackWillBeApplied: 'Essa é a página que será aplicado o feedback',
            whatCanBeImproved: 'Conte pra gente o que pode ser melhorado nesta página?',
            example: 'Ex: Essa página poderia ter um botão informativo.',
            send: 'Enviar',
            feedbackSentError: 'Ocorreu um erro no envio do feedback',
            feedbackSentSuccessfully: 'Feedback enviado com sucesso!',
            requireFields: 'Você precisa preencher os campos.',
            contactSupport: 'Ocorreu um erro, favor contatar o suporte',
            feedbackExcludeError: 'Ocorreu um erro ao excluir o feedback!',
            feedbackExcludeSuccessfully: 'Feedback excluído com sucesso!'
          },
          image: {
            image: 'Imagem'
          },
          recharge: {
            requestCredits: 'Solicitar Créditos',
            requestCreditsDescription: 'Você pode solicitar créditos adicionais para utilização no modulo Campanhas.',
            type: 'Tipo *',
            value: 'Quantidade',
            cancel: 'Cancelar',
            request: 'Solicitar',
            messageFailedRequestCredits: 'Oops, falha ao solicitar créditos.',
            creditsRequested: 'Créditos solicitados.',
            fieldsRequired: 'Você precisa preencher os campos.'
          }
        },
        tags: {
          removeTag: 'Retirar tag',
          addTags: 'Adicionar tag',
          labelTag: 'Procurar tag',
          tagAlreadyExistsInConversation: 'Essa tag já existe na conversa.',
          categoryNotFound: 'Categoria não encontrada, verifique o componente miniTags.'
        },
        exporter: {
          mountingReport: 'Montando relatório...',
          exportToCsv: 'Exporta para CSV',
          type: 'Tipo',
          answered: 'Respondida',
          response: 'Resposta',
          disambiguated: 'Desambiguada',
          notResponse: 'Não respondida',
          active: 'Ativo',
          inactive: 'Inativo',
          reportGeneratedSuccessfully: 'Relatório gerado com sucesso.',
          messageFailedReport: 'Oops, a geração de relatórios falhou, tente novamente.'
        },
        loading: {
          connectingToServer: 'Conectando ao servidor...',
          assemblingTheInformation: 'Montando as informações...'
        },
        zendesk: {
          title: 'Zendesk',
          brands: 'Marcas',
          forms: 'Formulários',
          fields: 'Tags',
          group: 'Grupo',
        }
      },
      layouts: {
        default: {
          messageErrorInfo: 'Oops, algum erro aconteceu ao salvar as informações.',
          tolerancePeriod: 'Período de Tolerância',
          finishedJourneyDescription: 'Sua jornada de trabalho encerrou, mas você entrou no período de tolerância.',
          timeLeft: 'Encerre seus trabalhos no tempo restante de',
          disconnectedFromThePlatform: 'Após o término do período de tolerância você será desconactado da plataforma.',
          iKnow: 'Estou Ciente',
          finishedJourney: 'Jornada Encerrada',
          workingEnded: 'Sua jornada de trabalho foi encerrada.',
          freeTime: 'Aproveite seu tempo livre!',
          seeYouLater: 'Até logo',
          lostConnection: 'Conexão perdida'
        }
      },
      pages: {
        actions: {
          create: {
            addAction: 'Adicionar ação',
            title: 'Título',
            description: 'Descrição',
            request: 'Requisição',
            getAndPost: 'GET, POST',
            newAction: 'Nova ação',
            newActionDescription: 'Descrição de nova ação'
          },
          list: {
            actions: 'Ações',
            addAction: 'Adicionar ação'
          }
        },
        advanced: {
          logs: {
            list: {
              addLog: 'Adicionar log',
              version: 'Versão',
              change: 'Módulo',
              visibility: 'Visualização',
              description: 'Descrição',
              cancel: 'Cancelar',
              add: 'Adicionar',
              update: 'Atualizar',
              removedColumn: 'Retirado coluna de categorias por inutilização',
              deprecateModal: 'Modal de observação depreciado.',
              updateVisualsAndProgrammatic: 'Atualizações visuais e programáticas.'
            }
          },
          conversation: {
            conversationDetails: 'Detalhes da conversa',
            search: 'Pesquisar',
            account: 'Conta',
            attendants: 'Atendente(s)',
            device: 'Dispositivo',
            desktop: 'Desktop',
            mobile: 'Mobile',
            chrome: 'Chrome',
            safari: 'Safari',
            mozilla: 'Mozilla Firefox',
            edge: 'Edge',
            explorer: 'Internet Explorer',
            whatsapp: 'Whatsapp',
            facebook: 'Facebook Messenger',
            dispatchEmail: 'Email de lead enviado',
            dispatchEmailToClient: 'Email enviado para o cliente',
            viewedEmailByClient: 'Email visualizado pelo cliente',
            dateAndHour: 'Data e Hora',
            channel: 'Canal',
            origin: 'Origem',
            messageNotFoundConversation: 'Infelizmente não achamos essa conversa'
          },
          edit: {
            delPayment: 'Deletar pagamento',
            paid: 'Pago',
            consumption: 'Consumo',
            status: 'Status',
            editAccount: 'Editar conta',
            clientsList: 'Lista de clientes',
            editAccountTwo: 'Editar conta - ',
            credits: 'Créditos',
            recurrentCredits: 'Franquia recorrente de mensageria',
            sharedFranchise: 'Franquia compartilhada',
            newFranchise: 'Novo modelo',
            shared: 'Compartilhada',
            otherChannels: 'Outros canais',
            add: 'Adicionar',
            del: 'Deletar',
            fa: '2FA',
            email: 'E-mail',
            active: 'Ativo',
            inactive: 'Inativo',
            phone: 'Telefone',
            security: 'Módulos habilitados',
            realTime: 'Tempo real',
            statistics: 'Estatísticas',
            people: 'Pessoas',
            conversation: 'Conversas',
            interface: 'Interface',
            flow: 'Fluxo',
            intelligence: 'Inteligência',
            campaigns: 'Campanhas',
            liveChat: 'Chat ao vivo',
            library: 'Biblioteca',
            preferences: 'Preferências',
            limiter: 'Usuários  - Limitador ',
            decraseLimit: 'Diminuir limite',
            increaseLimit: 'Aumentar limite',
            createMaster: 'Criar master',
            accountDetails: 'Detalhes da conta',
            accountName: 'Nome da conta',
            addPermissions: 'Adicionar permissões',
            addBriefing: 'Adicionar briefing',
            companyName: 'Razão social',
            fantasyName: 'Nome fantasia',
            cnpj: 'CNPJ',
            address: 'Endereço',
            zipCode: 'CEP',
            street: 'Rua',
            edit: 'Editar',
            contacts: 'Contatos',
            plan: 'Plano e Contrato',
            uploadContractLabel: 'Contrato',
            contractValidityLabel: 'Validade do contrato',
            contractDateLabel: 'Data do contrato',
            seeDocument: 'Ver documento',
            successUpdate: 'Cliente atualizado!',
            errorUpdate: 'Erro ao atualizar cliente!',
            type: 'Tipo',
            characteristics: 'Franquia e canais',
            numberAttendances: 'Whatsapp - Franquia mensal',
            otherChannelAttendances: 'Outros canais - Franquia mensal',
            whatsAppAttendances: 'Whatsapp - Franquia mensal',
            emailAttendances: 'E-mail - Franquia mensal',
            smsAttendances: 'SMS - Franquia mensal',
            webchat: 'Webchat',
            facebook: 'Facebook',
            whatsapp: 'Whatsapp',
            whatsappFranchise: 'Whatsapp Franquia',
            sms: 'SMS',
            integration: 'Integração',
            observations: 'Observações',
            none: 'Nenhuma',
            simple: 'Simples',
            intermediate: 'Intermediário',
            advanced: 'Avançado',
            payments: 'Pagamentos',
            form: 'Forma',
            setup: 'Setup',
            description: 'Descrição',
            valueReal: 'Valor: R$ ',
            date: 'Data',
            monthlyPayment: 'Mensalidade',
            cronogram: 'Cronograma',
            planning: 'Planejamento',
            initialDate: 'Data inicial',
            implementation: 'Implementação',
            endDate: 'Data final',
            observation: 'Observação',
            customerSuccess: 'Customer Success',
            chatbots: 'Chatbots',
            online: 'Online',
            offline: 'Offline',
            versioning: 'Versionamento',
            old: 'ANTIGO',
            stable: 'ESTÁVEL',
            beta: 'BETA',
            alpha: 'ALFA',
            editAddress: 'Editar endereço',
            number: 'Número',
            district: 'Bairro',
            city: 'Cidade',
            state: 'Estado',
            cancel: 'Cancelar',
            save: 'Salvar',
            addContact: 'Adicionar contato',
            delContact: 'Deletar contato',
            editContact: 'Editar contato',
            role: 'Cargo',
            name: 'Nome',
            projectManager: 'Gerente de projeto',
            title: 'Titulo',
            addImplementationCronogram: 'Adicionar cronograma de implementação',
            addCronogramByCs: 'Adicionar cronograma de CS',
            addCronogramByPlanning: 'Adicionar cronograma de planejamento',
            addRateBySetup: 'Adicionar taxa de SETUP',
            value: 'Valor',
            values: 'Valores',
            ongoing: 'OnGoing',
            onboarding: 'OnBoarding',
            csStage: 'Etapa CS',
            currentStatus: 'Status atual',
            accountStatusOnline: 'Online',
            accountStatusPartialOnline: 'Online parcial',
            accountStatusPaused: 'Pausado',
            accountStatusChurn: 'Churn',
            flag: 'Flag',
            addRateMonthly: 'Adicionar taxa de mensalidade',
            newUser: 'Adicionar usuário',
            password: 'Senha',
            addCredits: 'Adicionar créditos',
            veryLow: 'Muito baixa',
            low: 'Baixa',
            medium: 'Média',
            high: 'Alta',
            veryHigh: 'Muito Alta',
            important: 'IMPORTANTE',
            starter: 'G-Starter',
            professional: 'G-Professional',
            enterprise: 'G-Enterprise',
            gAdvanced: 'G-Advanced',
            orion: 'G-Orion',
            planLite: 'Lite',
            planStarter: 'Starter',
            planProfessional: 'Professional',
            planAdvanced: 'Advanced',
            planEnterprise: 'Enterprise',
            planGlobal: 'Global',
            planLite22: 'LITE-22',
            planStarter22: 'STARTER-22',
            planProfessional22: 'PROFESSIONAL-22',
            planAdvanced22: 'ADVANCED-22',
            planEnterprise22: 'ENTERPRISE-22',
            planLite23: 'LITE-23',
            planStarter23: 'STARTER-23',
            planProfessional23: 'PROFESSIONAL-23',
            planEnterprise23: 'ENTERPRISE-23',
            january: 'Janeiro',
            february: 'Fevereiro',
            march: 'Março',
            april: 'Abril',
            may: 'Maio',
            june: 'Junho',
            july: 'Julho',
            august: 'Agosto',
            september: 'Setembro',
            october: 'Outubro',
            november: 'Novembro',
            december: 'Dezembro',
            genAi: 'IA Generativa',
            instagram: 'Instagram',
          },
          list: {
            exportAccounts: 'Exportar contas',
            cs: 'CS Responsável',
            csStage: 'Etapa CS',
            accountStatus: 'Status da conta',
            plan: 'Plano',
            listClients: 'Lista de clientes',
            createNewAccount: 'Criar nova conta',
            wantToCreateNewAccount: 'Quer criar uma nova conta? Clique aqui :)',
            version: 'Versão',
            stable: 'Estável',
            beta: 'Beta',
            alpha: 'Alpha',
            old: 'Old',
            updateNewVersion: 'Atualizar para nova versão',
            edit: 'Editar',
            exclude: 'Excluir',
            addAccount: 'Adicionar conta',
            companyName: 'Nome da empresa',
            steps: 'Agora siga estes passos',
            open: 'Abra a',
            spreadsheetClients: 'planilha de clientes',
            insertEmail: 'Coloque este e-mail',
            insertPassword: 'Ao lado, insira a senha desejada para a conta, ela precisa ser uma senha muito segura.',
            insertPasswordAndAdd: 'Insira no campo abaixo a senha digitada na planilha e clique em Adicionar.',
            passwordMaster: 'Senha do Master',
            cancel: 'Cancelar',
            add: 'Adicionar',
            excludeAccount: 'Excluir conta',
            sureExcludeAccount: 'Tem certeza que deseja excluir essa conta?',
            allConversationsAndChatbotsDeleted: 'Todas as conversas e todos os chatbots serão apagados.',
            updateVersionToStable: 'Atualizar a versão para Stable',
            beAware: 'Antes de seguir, esteja ciente que',
            thisChangesVersionPanel: 'Essa é uma ação que irá mudar a versão do painel do cliente.',
            losesAllAttendance: 'Essa opção que irá fazer o cliente perder todos os atendimentos atuais do Old, e ele não terá mais acesso, somente pela aba Conversas.',
            beforeToClick: 'Antes de clicar em atualizar, verifique se já tem departamentos criados acessando pelo https://panel.globalbot.ai na conta do cliente (se já tiver exclua pois pode interferir na migração).',
            createNewDepartment: 'Esta ação irá criar novos departamentos (com os mesmos nomes) e atribuir nos fluxos e nos usuários, então por segurança não se pode estar editando usuários nem o fluxo na hora da atualização.',
            switchToStableVersion: 'Mudar para versão Estável',
            name: 'Nome',
            actions: 'Ações',
            jan: 'Jan.',
            feb: 'Fev.',
            mar: 'Mar.',
            apr: 'Abr.',
            may: 'Mai.',
            jun: 'Jun.',
            jul: 'Jul',
            aug: 'Ago.',
            sep: 'Set.',
            oct: 'Out.',
            nov: 'Nov.',
            dec: 'Dez.',
            migratedAccountSuccess: 'Conta foi migrada com sucesso!',
            errorHasOccurred: 'Ocorreu um erro, contate um desenvolvedor urgente!',
            normalStatus: 'Normal',
            endingStatus: 'Acabando',
            exceededStatus: 'Excedido',
            columnFranchise: 'Consumo da franquia'
          },
          server: {
            serversWhatsapp: 'Servidores de WhatsApp',
            updateGit: 'Atualizar Git',
            requestUpdateGit: 'Solicitar atualização de git em todos os servidores.',
            update: 'Atualizar',
            servers: 'Servidores',
            serversDescription: 'Adicionar um novo servidor.',
            add: 'Adicionar',
            numbering: 'Numeração',
            version: 'Versão',
            responsible: 'Responsável',
            whatsapp: 'Whatsapp',
            indifferent: 'Indiferente',
            normal: 'Normal',
            business: 'Business',
            chip: 'Chip',
            client: 'Cliente',
            globalbot: 'Globalbot',
            server: 'Servidor',
            instanceType: 'Tipo de Instância',
            ip: 'IP',
            account: 'Conta',
            chatbot: 'Chatbot',
            info: 'Informações',
            actions: 'Ações',
            running: 'Rodando!',
            serverAvailable: 'Servidor disponível',
            serverDown: 'Serviço fora do ar, aguardar gerente.',
            qrCode: 'QR Code',
            gitPull: 'Git Pull',
            restartServer: 'Reiniciar servidor',
            edit: 'Editar',
            excludePairing: 'Excluir pareamento',
            save: 'Salvar',
            cancel: 'Cancelar',
            serverData: 'Dados do Servidor',
            instanceId: 'ID da Instância',
            serverName: 'Nome do servidor',
            sureRestartServer: 'Tem certeza que deseja reiniciar este servidor? Isso pode levar alguns minutos.',
            restart: 'Reiniciar',
            exclude: 'Excluir',
            sureExcludePairing: 'Tem certeza que deseja excluir o pareamento deste servidor? Você terá que ler novamente o QR Code.',
            cellphone: 'Celular',
            manager: 'Gerente',
            isBusiness: 'is Business',
            isChipResponsible: 'is Chip Responsible',
            messageError: 'Erro, veja o console!',
            routeNotFound: 'Rota não encontrada, servidor ',
            serverAddedSuccessfully: 'Servidor adicionado com sucesso!',
            pairingDeleted: 'Pareamento excluído com sucesso!'
          },
          tasks: {
            tasks: 'Tarefas | ',
            newTask: 'Nova Tarefa',
            plan: 'Plan',
            status: 'Status',
            notStarted: 'Não iniciado',
            ready: 'Pronto',
            stoppedGlobalbot: 'Parado Globalbot',
            stoppedClient: 'Parado Cliente',
            date: 'Data',
            invalidDate: 'Data inválida',
            noDate: 'Sem data',
            description: 'Descrição',
            notDescription: 'Sem descrição',
            advancedSettings: 'Configurações avançadas',
            do: 'Do',
            go: 'Go',
            learn: 'Learn',
            editTask: 'Editar Tarefa',
            selectUsers: 'Selecionar usuários',
            selectAccount: 'Selecionar conta',
            selectBot: 'Selecionar bot',
            selectType: 'Selecionar tipo',
            title: 'Título',
            selectStatus: 'Selecionar status',
            done: 'Feito'
          },
          users: {
            listUsers: 'Lista de usuários',
            user: 'Usuário',
            name: 'Nome',
            email: 'Email',
            role: 'Cargo',
            newPassword: 'Nova senha',
            avatar: 'Avatar',
            permission: 'Permissão',
            done: 'Feito',
            supreme: 'Supreme',
            master: 'Master',
            administrator: 'Administrador',
            manager: 'Gerente',
            attendant: 'Atendente',
            junior: 'Junior',
            messageErrorInfo: 'Oops, algum erro aconteceu ao salvar as informações.',
            messageErrorUser: 'Oops, algum erro aconteceu ao salvar o usuário.',
            supremeCommander: 'Supreme Commander',
            textExports: 'Você realmente deseja exportar todos os seus logs?'
          }
        },
        analytics: {
          attendant: {
            attendant: {
              indicatorsByAttendants: 'Indicadores por Atendentes',
              attendantsByTime: 'Atendimentos por horário do dia',
              chatbotsAndAttendants: 'Chatbots x Atendentes',
              departments: 'Departamentos',
              attendants: 'Atendentes',
              statistics: 'Estatísticas',
              exportAttendants: 'Exportar atendimentos',
              textExports: 'Você realmente deseja exportar todos os seus atendimentos?',
              knowledgeTextExports: 'Você realmente deseja exportar?'
            }
          },
          channels: {
            channels: {
              indicatorsByChannels: 'Indicadores por Canais',
              attendantsByTime: 'Atendimentos por horário do dia',
              chatbotsAndAttendants: 'Chatbots x Atendentes',
              channelOverview: 'Visão Geral por Canal',
              statistics: 'Estatísticas'
            }
          },
          flow: {
            flow: {
              flowAnalysis: 'Análise de Fluxo',
              statistics: 'Estatísticas'
            }
          },
          identifiers: {
            identifiers: {
              customIdentifiers: 'Identificadores Customizados',
              statistics: 'Estatísticas'
            }
          },
          knowledge: {
            knowledge: {
              intelligencePerformance: 'Performance da Inteligência',
              conventionalIA: 'IA Convencional',
              conventionalIADescription: 'Respostas padronizadas. Interpreta a intenção do usuário e apresenta respostas cadastradas (texto, vídeo, imagens, documentos e links).',
              generativeIA: 'IA Generativa',
              generativeIADescription: 'Respostas criadas a partir de grande massa de dados (modelo GPT). Interpreta a intenção do usuário e apresenta respostas geradas (texto e links).',
              knowledgeBase: 'Base de conhecimento',
              commonQuestions: 'Perguntas Frequentes',
              advanced: 'Avançado',
              artificialIntelligence: 'Inteligência',
              statistics: 'Estatísticas'
            },
            responses: {
              response: 'Resposta'
            }
          },
          location: {
            location: {
              countries: 'Países',
              states: 'Estados',
              cities: 'Cidades',
              geolocationAdvanced: 'Avançado',
              geolocation: 'Geolocalização',
              statistics: 'Estatísticas'
            }
          },
          origin: {
            origin: {
              platformAndBrowsers: 'Plataforma e Navegadores',
              campaign: 'Campanhas',
              originAttendance: 'Origem dos Atendimentos',
              statistics: 'Estatísticas',
              url: 'Localizador Uniforme de Recursos'
            }
          },
          cards: {
            attendance: 'Atendimentos',
            descriptionAttendance: 'Volume total de atendimentos (todos os canais) do<br/>período selecionado. O comparativo é relativo ao<br/> período imediatamente anterior.',
            satisfaction: 'Satisfação',
            descriptionSatisfaction: 'Taxa de satisfação relativa à Pesquisa de Satisfação dos<br/>usuários no período selecionado. O comparativo é relativo<br/>ao período imediatamente anterior. Este indicador é visível<br/>somente quando habilitada a Pesquisa de Satisfação na<br/>rota Preferências > Chatbot > Comportamento.',
            intelligence: 'Inteligência',
            descriptionIntelligenceOne: 'Taxa de sucesso da(s) Inteligência(s) Artificial(is) dos chatbots <br>  e período selecionados. Para IA Convencional é o somatório<br> das Perguntas Respondidas e Perguntas Desambiguadas.<br> Para a IA Generativa é considera-se apenas as Respondidas, pois <br> não há desambiguação.<br> O comparativo é relativo ao período imediatamente anterior.',
            connected: 'Conectados',
            descriptionConnected: 'Total de usuários (colaboradores) atualmente<br/>conectados na Plataforma Globalbot. Refere-se<br/>aos indivíduos utilizadores da plataforma<br/>(tais como administradores, gerentes ou<br/>atendentes), não se referindo ao número de<br/>clientes em atendimento.',
            beta: 'BETA',
            descriptionBeta: 'O recurso identificado como BETA está em desenvolvimento<br/> e testes pela equipe Globalbot. Aguarde novidades em<br/>relação à evolução deste recurso.',
            viewByChannel: 'Visão por Canal',
            descriptionViewByChannel: 'Confira os principais indicadores de atendimento por canal, quantitativos e qualitativos.',
            attendants: 'Atendentes',
            descriptionAttendants: 'Veja os indicadores de performance dos atendentes de forma individual e coletiva.',
            descriptionIntelligenceTwo: 'Analise a performance da Inteligência Artificial, incluindo questões respondidas, desambiguadas e não respondidas.',
            geolocation: 'Geolocalização',
            descriptionGeolocation: 'Confira de quais localidades os atendimentos estão chegando, por país, estado e cidade.',
            origin: 'Origem',
            descriptionOrigin: 'Analise a origem dos atendimentos, considerando as fontes de tráfego, por exemplo, campanhas digitais.',
            flowAnalysis: 'Análise de Fluxo',
            descriptionFlowAnalysis: 'Veja como os usuários interagem com os fluxos conversacionais, considerando engajamento e abandono.',
            customized: 'Customizados',
            descriptionCustomized: 'Confira as métricas dos seus identificadores customizados que fazem parte dos fluxos conversacionais.',
            statistics: 'Estatísticas',
            tooltipStatistics: 'Dúvidas nas Estatísticas? Clique aqui e acesse o Tutorial que nossa equipe preparou. Temos muitas novidades!',
            totalValue: 'Valor Total',
            totalValueDescription: 'Confira o valor total gerado pelas conversas no período (muito útil para quem vende produtos e serviços). Para aparecer aqui, o valor deve ser atribuído em cada conversa individualmente.'
          }
        },
        block: {
          create: {
            add: 'Adicionar',
            blocks: 'Blocos',
            welcome: 'Boas vindas',
            addButton: 'Adicionar botão',
            create: 'create',
            title: 'Título',
            description: 'Descrição',
            message: 'Mensagem',
            name: 'Nome',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            buttons: 'Botões',
            button: 'Botão',
            email: 'E-mail',
            phone: 'Telefone',
            cpf: 'CPF',
            cnpj: 'CNPJ',
            birthDate: 'Data de nascimento',
            calendar: 'Calendário',
            number: 'Número',
            checkbox: 'Checkbox',
            slider: 'Slider',
            image: 'Imagem',
            intelligence: 'Inteligência',
            liveChat: 'Chat Ao vivo',
            zipCode: 'CEP',
            city: 'Cidade',
            state: 'Estado',
            country: 'País',
            carousel: 'Carrossel',
            guideScreen: 'Tela guiada',
            money: 'Máscara de dinheiro',
            exit: 'Finalizar'
          },
          index: {
            blocks: 'Blocos',
            listblock: 'list block',
            new: 'Novo',
            index: 'index'
          }
        },
        bots: {
          bots: {
            testChatbot: 'Testar Chatbot',
            editFlow: 'Editar Fluxo',
            viewer: 'Visualizador',
            lite: 'Lite',
            pro: 'Pro',
            editor: 'Editor',
            settingsAccess: 'Acessar as Configurações',
            createNewChatbot: 'Novo Chatbot',
            createNewChatbotDesc: 'Crie um novo chatbot, edite o fluxo conversaional, defina os parâmetros e realize os testes.',
            titleChatbot: 'Título do chatbot',
            nameAttendant: 'Nome do atendente',
            cancel: 'Cancelar',
            create: 'Criar',
            createSuccessfully: 'Chatbot criado com sucesso!',
            createError: 'Erro ao criar chatbot',
            hello: 'Olá, Posso ajudar?',
            contactGlobalbot: 'Contato Globalbot',
            subject: 'assunto',
            content: 'content',
            indication: 'Seu amigo tem uma indicação',
            thanks: 'Olá{nome}, muito obrigado pelo seu interesse no ',
            introduction: 'Com o Globalbot sua empresa irá oferecer uma experiência de diálogo com seus públicos nas mais diversas situações. Essa experiência é fantástica, principalmente nos dispositivos móveis (celulares e tablets), pois as pessoas já estão acostumadas com uso de aplicativos de mensagens, como Facebook, Whatsapp e muitos outros.',
            listing: 'Eu listei aqui também algumas possíveis aplicações do ',
            inCompany: ' na sua empresa:',
            leadGeneration: 'Geração de Leads:',
            leadGenerationDescription: ' Utilização para reter o visitante do site através do diálogo e gerar leads para equipes comerciais, integrando com ferramentas de força de vendas ou disparando um e-mail.',
            marketingAndSales: 'Marketing e Vendas:',
            marketingAndSalesDescription: ' Realizar campanhas de marketing e ações focadas em vendas, apresentando um diálogo que leve o cliente a se envolver com o produto e suas características.',
            search: 'Pesquisas:',
            searchDescription: ' Transformar as extensas pesquisas de opinião e satisfação em diálogos amigáveis, evitando o tédio e a dispersão.',
            attendance: 'Atendimento ao Cliente:',
            attendanceDescription: ' Realizar serviços de atendimento ao cliente, baseando-se nas dúvidas mais frequentes e assim eliminar posições de atendimento e/ou reduzir as filas de espera.',
            schedulingAndReservations: 'Agendamento e Reservas:',
            schedulingAndReservationsDescription: ' Utilização para realizar agendamentos e reservas (consultórios, concessionárias, restaurantes, eventos e muitos outros) integrando a sistemas internos.',
            databaseAndCrm: 'Qualificação de Bases de Dados e CRM:',
            databaseAndCrmDescription: ' pode servir de interface para acesso à informações disponíveis em bases de dados, permitindo a qualificação das bases de dados através do diálogo.',
            teachingAndLearning: 'Ensino e Aprendizado:',
            teachingAndLearningDescription: ' Utilização para ações de ensino e aprendizado, usando conteúdo de aulas para os alunos, podendo conversar sobre os assuntos específicos das diversas matérias dos cursos.',
            furthermore: 'Além disso o ',
            dispatchDescription: ' pode enviar e-mail, SMS, exibir arquivos de imagem, áudio, vídeo e muito mais!',
            surpriseAudience: 'Surpreenda seu público com essa nova experiência!',
            regards: 'Atenciosamente,',
            telmaAyala: 'Telma Ayala',
            virtualAssistant: 'Assistente Virtual',
            address: ' Av. Cristovão Colombo, 3000 / salas 802 e 304 / Floresta Porto Alegre - RS - CEP 90560-002 ',
            moreInformations: 'Mais informações',
            // oneMessage: 'Ainda está por ai?',
            // twoMessage: 'Caso você não responda eu vou ter que finalizar o atendimento.',
            // threeMessage: 'Tudo bem, se você precisar de ajuda novamente me avise!',
            // closeMessage: 'O atendimento foi encerrado pelo atendente, agradecemos seu contato e caso tenha mais alguma dúvida é só me chamar novamente.',
            chatbots: 'Chatbots'
          }
        },
        broadcast: {
          broadcast: {
            campaigns: 'Campanhas',
            processing: 'Processando...',
            sending: 'Enviando: ',
            of: ' de ',
            messageCreate: 'Criar mensagem',
            messageType: 'Tipo de mensagem',
            messageSave: 'Mensagens de SMS/Whatsapp salvas',
            email: 'Email',
            messageTitle: 'Título da mensagem',
            confirmation: 'Confirmação',
            saveNewMessageWithName: 'Salvar nova mensagem com o nome ',
            save: 'Salvar',
            cancel: 'Cancelar',
            messageText: 'Texto da mensagem',
            next: 'Próximo',
            recipients: 'Destinatários',
            importNewList: 'Importar nova lista',
            myLists: 'Minhas listas',
            listJson: 'Lista Json',
            send: 'Envio',
            sendNow: 'Enviar agora',
            scheduleShipping: 'Agendar envio',
            submit: 'Enviar',
            importList: 'Importar lista',
            import: 'Importar',
            saveList: 'Salvar lista',
            listTitle: 'Título da lista',
            totalRecipients: 'Total de destinatários',
            recipientsToLowerCase: ' destinatários',
            importedList: 'Lista importada',
            messageSaveList: 'Sua lista foi salva!',
            messageOpsError: 'Oops, ocorreu algum erro.',
            allMessagesWereSent: 'Todas as mensagens foram enviadas.',
            messageNotCreditsSms: 'Oops, parece que está faltando créditos de SMS. Por favor entre em contato com a gente.',
            allMessagesWereScheduled: 'Todas as mensagens foram agendadas.',
            failedToConnect: 'Falha ao conectar ao serviço de broadcast, favor recarregar a página.',
            messageNotCreditsWhatsapp: 'Oops, parece que está faltando créditos de WhatsApp. Por favor entre em contato com a gente.',
            messageWereSent: 'Foram enviadas ',
            messageOfTheTotal: ' do total de ',
            chargedMessages: ' mensagens. Apenas as mensagens enviadas serão cobradas.',
            gupshupTemplateImport: "Importar template HSM"
          },
          broadcast2: {
            email: 'E-mail',
            communicate: 'Comunique-se através de ações de e-mail marketing ou e-mail transacionais.',
            createModel: 'Criar modelo',
            sms: 'SMS',
            sendNotificationsSms: 'Envie notificações e avisos através de mensagens de texto (SMS).',
            whatsapp: 'WhatsApp',
            sendNotificationsWhatsapp: 'Envie mensagens promocionais, notificações e pesquisas via WhatsApp.',
            requestCredits: 'Solicitar Créditos',
            requestCreditsMessaging: 'Solicite créditos adicionais para realizar campanhas.',
            models: 'Modelos',
            modelNotCreated: 'Você ainda não criou nenhum modelo. Comece escolhendo qual tipo de modelo você deseja criar.',
            modelHsm: 'Modelo de mensagem HSM (High Structured Message)<br/>utilizado para envios de mensagens pré-aprovadas<br/>através do WhatsApp Business API.',
            modelData: 'Dados do Modelo',
            messageEditor: 'Editor de Mensagem',
            preview: 'Pré-visualização',
            duplicate: 'Duplicar envio',
            duplicateDraft: 'Duplicar rascunho',
            createDispatch: 'Criar Envio',
            exclude: 'Excluir',
            dispatches: 'Envios',
            dispatchesApi: 'Api',
            dispatchNotCreated: 'Você ainda não criou nenhum envio. Crie um envio a partir de um modelo de mensagem.',
            draftNotCreated: 'Você ainda não tem rascunhos.',
            drafts: 'Rascunhos',
            static: 'Estático',
            recurring: 'Recorrente',
            draft: 'Rascunho',
            readyToSend: 'Pronto para Envio',
            scheduled: 'Agendado',
            processing: 'Processando',
            scheduledFor: 'Agendado para ',
            processed: 'Enviada',
            delivered: 'Entregue',
            device: 'Entregue',
            read: 'Lida',
            error: 'Não Entregue',
            sent: 'Enviado',
            insufficientCredits: 'Créditos insuficiente',
            editDispatch: 'Editar Envio',
            editDraft: 'Editar rascunho',
            dataDispatch: 'Dados do Envio',
            statsDispach: 'Estatísticas do Envio',
            prepareDispatch: 'Preparar Envio',
            modelExclude: 'Excluir modelo',
            cancel: 'Cancelar',
            areSureModelExclude: 'Tem certeza que deseja excluir este modelo?',
            excludeDispatch: 'Excluir envio',
            duplicateDispatch: 'Você deseja fazer uma cópia deste envio?',
            duplicateQuestionDraft: 'Você deseja fazer uma cópia deste rascunho?',
            areSureDispatchExclude: 'Tem certeza que deseja excluir este envio?',
            dispatch: 'Enviar',
            areSureDispatch: 'Tem certeza que deseja enviar?',
            found: 'Encontrados',
            repeated: 'Repetidos',
            removed: 'Retirados',
            range: 'Alcance',
            schedule: 'Agendar Envio',
            saveDraft: 'Salvar como Rascunho',
            templateChannel: 'Canal',
            templateName: 'Nome',
            templateAuthor: 'Autor',
            templateEdited: 'Última atualização',
            templateActions: 'Ações',
            dispatchChannel: 'Canal',
            dispatchName: 'Nome',
            dispatchAuthor: 'Autor',
            dispatchStatus: 'Status',
            dispatchActions: 'Ações',
            templateExclude: 'Template excluído!',
            dispatchExclude: 'Envio excluído!',
            processingDispatch: 'O envio está sendo processado.',
            dispatchScheduled: 'O envio foi agendado.',
            messageOpsNotDispatch: 'Oops, tivemos algum problema ao processar o envio.',
            messageNotCredits: 'Infelizmente seu saldo de créditos não é suficiente!',
            messageOpsNotValid: 'Oops, tivemos algum problema ao validar o envio.',
            messageNotFoundDispatch: 'Envio não encontrado',
            messageOpsErrorDispatch: 'Oops, ocorreu um erro ao recuperar o envio.',
            messageOpsFailedCompileTemplate: 'Oops, falha ao compilar template',
            campaigns: 'Campanhas',
            doubtsInMessaging: 'Dúvidas? Clique aqui e veja o tour interativo.',
            canNotDuplicateMissingTemplate: 'Template deletado, não é possível duplicar este envio'
          }
        },
        conversations: {
          conversation: {
            rowsPerPage: 'Registros por página',
            recipients: ' destinatários',
            createNewList: 'Criar nova lista',
            excludeConversationSelected: 'Excluir conversas selecionados',
            createListConversationSelected: 'Criar lista com conversas selecionadas',
            createListConversationAll: 'Criar lista com todas conversas',
            exportConversationSelected: 'Exportar conversas selecionados',
            exportAllConversationSelected: 'Exportar todas as conversas',
            forwardConversationSelected: 'Encaminhar conversas selecionados',
            forwardConversationIntegrations: 'Encaminhar conversas nas integrações',
            columns: 'Colunas',
            dateAndHour: 'Data e Hora',
            protocol: 'Protocolo',
            chatbot: 'Chatbot',
            tags: 'Tags',
            channel: 'Canal',
            actions: 'Ações',
            monetaryValue: 'Valor',
            attendedBy: 'Atendido por',
            satisfaction: 'Satisfação',
            category: 'Categoria',
            department: 'Departamento',
            name: 'Nome',
            transhipmentAttended: 'Transbordo atendido: o atendente atendeu o cliente. Tudo ok!',
            transhipmentUserLost: 'Transbordo não atendido: o cliente abandonou o chat, antes do atendente interagir!',
            transhipmentNotYetAttended: 'Transbordo ainda não atendido/espera: o atendente ainda não interagiu com o cliente.',
            transhipmentWaitigAttended: 'Transbordo não atendido: aguardando atendente assumir.',
            botDeleted: 'Bot excluído',
            transhipmentUnrealized: 'Transbordo não realizado: nenhum atendente online no momento. Chatbot retomou a conversa.',
            transhipmentRejected: 'Transbordo rejeitado: atendente finalizou a conversa antes de interagir com o usário',
            emailLeadSend: 'E-mail de lead enviado',
            nameEmailLeadSend: ', E-mail de lead enviado',
            createNewListWith: 'Crie uma Lista com as ',
            conversationSelect: ' conversas selecionadas. Utilize a lista criada no módulo Mensageria para enviar mensagens via e-mail, SMS ou WhatsApp.',
            listName: 'Nome da lista',
            cancel: 'Cancelar',
            save: 'Salvar',
            excludeConversations: 'Excluir conversas',
            areSureWantToDelete: 'Tem certeza que deseja excluir ',
            conversationsSelected: ' conversas selecionadas?',
            exclude: 'Excluir',
            reportExport: 'Exportação de relatório',
            reportExportNPS: 'Exportação de relatório NPS',
            willReportExport: 'Você irá exportar um relatório com ',
            conversationsSelectedFormatCsv: ' conversas selecionadas no formato .CSV.',
            exported: 'Exportar',
            exportedXlsx: 'Exportar em XLSX',
            exportedCsv: 'Exportar em CSV',
            forward: 'Encaminhar',
            youCanSend: 'Você poderá enviar as conversas selecionadas por email para outra pessoa.',
            internalMessage: 'Enviar com mensagens internas do chat ao vivo.',
            email: 'E-mail',
            integrations: 'Integrações',
            youCanSendCrmOrTickets: 'Você poderá enviar as conversas selecionadas para o seu CRM ou sistema de tickets',
            selectIntegration: 'Selecione sua integração',
            send: 'Enviar',
            all: 'Todos',
            primary: 'Primário',
            conversations: 'Conversas',
            messageSendEmailBut: 'Os e-mails foram enviados porém ',
            messageSendEmailSuccess: 'E-mails enviados com sucesso!',
            messageNotSendEmail: 'Oops, os e-mails não foram enviados.',
            messageOps: 'Oops, ',
            messageNeedEmail: 'Oops, precisamos de um email para encaminhar!',
            messageIntegrationsSendBut: 'Integrações enviadas porém ',
            messageIntegrationsSendSuccess: 'Integrações enviadas com sucesso!',
            messageIntegrationsNotSend: 'Oops, as integrações não foram enviadas.',
            messageIntegrationsNotSendField: 'Oops, as integrações não foram enviadas por falta de campo obrigatório.',
            messageNeedIntegrations: 'Oops, precisamos de uma integração para enviar!',
            messageSaveList: 'Sua lista foi salva!',
            messageListNeedName: 'A sua lista precisa de um nome!',
            messageOpsError: 'Oops, ocorreu algum erro.',
            date: 'Data',
            hour: 'Hora'
          }
        },
        consumption: {
          list: {
            clientsConsumption: 'Consumos por cliente',
          }
        },
        dashboard: {
          dashboard: {
            weHaveNews: 'Temos novidades!',
            helpCenter: 'Conheça a nossa Central de Ajuda. São mais de',
            articles: '100 artigos',
            featuresPlatform: 'explicando<br/> passo a passo as funcionalidades da nossa plataforma.',
            viewMore: 'Ver mais!',
            welcomeMessage: 'Aproveite os mais novos recursos e funcionalidades que preparamos para você!',
            clickItem: 'Clique nos itens para assistir à demonstração.',
            newLook: 'A plataforma em um novo visual.',
            highPerformance: 'Maior performance e processamento.',
            talkTeam: 'Converse agora também com sua equipe.',
            attendanceChanges: 'Mudanças ao assumir e finalizar atendimento.',
            emojisAndQuickResponse: 'Emojis, respostas rápidas e outras novidades.',
            commentDuringAttendance: 'Comentários e anotações durante o atendimento.',
            insights: 'Insights',
            news: 'Novidades',
            improvement: 'Sugerir melhoria',
            newsDesc: 'Confira as novidades recém lançadas na plataforma da Globalbot.',
            visitBlog: 'Para acessar todo o conteúdo, visite o nosso ',
            version: 'Versão',
            build: 'Build',
            termsOfUse: 'Termos de Uso',
            goodMorning: 'Bom dia',
            goodAfternoon: 'Boa tarde',
            goodEvening: 'Boa noite',
            accountData: 'Dados da conta',
            accountDataLabel: 'Confira abaixo as informações do plano contratado.',
            cardAccount: {
              plan: 'Plano',
              consumption: 'Consumo mensal',
              lineWhatsapp: 'Whatsapp',
              lineOtherChannel: 'Outros canais',
              toltip: `
                Confira as informações de consumo e total da franquia mensal contratada em volume de conversas (atendimentos) do mês corrente.</br></br>
                No caso do consumo ultrapassar 100% a franquia contratada, a plataforma continuará em operação e haverá cobrança do valor excedente conforme tabela de preços vigente.</br></br>
                WhatsApp: conversa é uma sessão de 24h considerando todas as mensagens trocadas durante o período. (contempla conversas recebidas dos clientes e iniciadas pela empresa)\nOutros Canais: contempla o somatório de conversas recebidas dos clientes nos canais WebChat, Messenger ou Telegram)
              `
            },
            january: 'Janeiro',
            february: 'Fevereiro',
            march: 'Março',
            april: 'Abril',
            may: 'Maio',
            june: 'Junho',
            july: 'Julho',
            august: 'Agosto',
            september: 'Setembro',
            october: 'Outubro',
            november: 'Novembro',
            december: 'Dezembro',
          },
          layout: {
            localization: 'Idioma',
            portuguese: 'Português',
            english: 'Inglês',
            spanish: 'Espanhol',
            online: 'Online',
            availableToNewAttendance: 'Você estará disponível para receber novos atendimentos do(s) seu(s) departamento(s).',
            away: 'Away',
            pauseNewAttendance: 'Entrar em pausa. Você continuará atendendo suas conversas ativas mas não receberá novos atendimentos.',
            offline: 'Offline',
            disconnectToChat: 'Você desconectará imediatamente do chat. Nenhum atendimento novo será atribuído a você.',
            myProfile: 'Meu perfil',
            notificationCenter: 'Central de notificações',
            nightMode: 'Modo noturno',
            exit: 'Sair',
            tasks: 'Tarefas',
            clients: 'Clientes',
            actions: 'Actions',
            changeLogs: 'Novidades | Change Logs',
            nps: 'Relatório NPS',
            serverWhatsapp: 'Servidores de WhatsApp',
            conversationDetails: 'Detalhes da conversa',
            home: 'Tela inicial',
            statistics: 'Estatísticas',
            conversation: 'Conversas',
            chatBots: 'Chatbots',
            intelligence: 'Inteligência',
            campaigns: 'Campanhas',
            liveChat: 'Chat ao vivo',
            library: 'Biblioteca',
            needHelp: 'Precisa de Ajuda? Informe o que você<br/> precisa e nossa equipe irá ajudar você!',
            preferences: 'Preferências',
            important: 'Importante:',
            messageImportant: ' Ao entrar em pausa você continuará atendendo suas conversas ativas mas não receberá novos atendimentos.',
            reasonPause: 'Motivo da pausa',
            cancel: 'Cancelar',
            continue: 'Continuar'
          }
        },
        flow: {
          editor: {
            save: 'Salvar',
            errorSavingInformation: 'Oops, algum erro aconteceu ao salvar as informações.',
            savedFlow: 'Fluxo salvo'
          },
          flow: {
            flow: 'Fluxo',
            save: 'Salvar',
            addOneBalloon: 'Adicionar 1 balão',
            addTenBalloons: 'Adicionar 10 balões',
            removeLastBalloon: 'Remover último balão',
            removeTheLastTenBalloons: 'Remover os últimos 10 balões',
            test: 'Testar',
            indicateBalloonLine: 'Este número indica a linha em que o balão está.',
            responseField: 'Campo de resposta',
            emptyQuestion: 'Pergunta vazia',
            clickAndEditBalloon: 'Ao clicar você poderá editar o balão inteiro.',
            clickGoToLine: 'Ao clicar você poderá ir para a linha.',
            type: 'Tipo',
            indicatorName: 'Indicador do nome do',
            balloon: 'do balão.',
            identifier: 'Identificador',
            responseWillAppearInConversation: 'Esta resposta aparecerá na aba conversas.',
            knowledge: 'Base de conhecimento',
            jumpIfNotFound: 'Salto caso não encontrar',
            ifThereNoQuestionJumpTheLine: 'Caso não houver pergunta cadastrada, saltará para a linha',
            department: 'Departamento',
            jumpIfNoOneOnline: 'Salto caso ninguém online',
            jumpIfNoAttendantOnline: 'Caso não houver nenhum atendente online, saltará para a linha',
            jumpingToLine: 'Saltando para a linha',
            botWillGoToLine: 'O bot após passar por esse balão, irá para a linha',
            clickInNumberGoToLine: 'Clique no número para ir para a linha.',
            uniqueExternalCode: 'Código externo único',
            webchatExternalCode: 'Código externo Webchat',
            facebookExternalCode: 'Código externo Facebook',
            whatsappExternalCode: 'Código externo Whatsapp',
            glpiIntegration: 'Integração com GLPI',
            sendingLeadEmail: 'Enviando e-mail de lead',
            thisBalloonCreatesTags: 'Este balão cria tag(s)',
            appearanceTime: 'Tempo de aparecimento',
            previewAppearanceTime: 'Mostra o tempo de aparecimento do balão, o padrão é 3000 milisegundos (3 segundos)',
            finishingBot: 'Finalização do bot',
            finishingBotDescription: 'Quando o bot chegar nessa linha ele vai finalizar.',
            dontResponse: 'Não responder',
            dontResponseDescription: 'Aqui surge uma opção para o usuário não responder, quando clicar nela, o bot vai para o próximo balão.',
            comment: 'Comentário',
            commentDescription: 'Comentário feito por um editor deste fluxo.',
            secureMessage: 'Mensagem segura',
            secureMessageDescription: 'Essa resposta não será salva nas Conversas na plataforma Globalbot',
            editLine: 'Editar linha',
            emptyBalloon: 'Esvaziar balão',
            done: 'Feito',
            editText: 'Editar texto',
            addRandomResponse: 'Adicionar resposta randômica',
            enterYourQuestion: 'Digite aqui sua pergunta',
            editResponseAttributes: 'Edite os atributos da resposta abaixo',
            none: 'Nenhum',
            theType: 'O tipo',
            theTypeNoneDescription: 'é quando não espera nenhuma resposta, é só para mostrar algo na pergunta.',
            text: 'Texto',
            theTypeTextDescription: 'é para só para respostas, ele permite o usuário colocar qualquer texto, números sem nenhum tipo de máscara.',
            name: 'Nome',
            theTypeNameDescription: 'é para só para respostas, ele permite o usuário colocar o nome ou como ele deseja ser chamado.',
            cpf: 'CPF',
            theTypeCpfDescription: 'é para só para respostas, ele permite o usuário colocar o CPF com a máscara de CPF.',
            phone: 'Telefone',
            theTypePhoneDescription: 'é para só para respostas, ele permite o usuário colocar o Telefone com a máscara de Telefone.',
            email: 'E-mail',
            theTypeEmailDescription: 'é para só para respostas, ele permite o usuário colocar o e-mail e valida com uma ferramenta.',
            buttons: 'Botões',
            theTypeButtonsDescription: 'é para só para respostas, ele permite o usuário escolher entre 2 ou mais opções de botões.',
            list: 'Lista',
            faq: 'FAQ',
            dataBase: 'Base de Dados',
            theTypeFaqDescription: 'é para só para respostas, ele permite o usuário digite algo e ao enviar, procura na inteligência o que ele digitou.',
            carousel: 'Carrossel',
            theTypeCarouselDescription: 'é para só para respostas, ele permite o usuário escolher uma opção entre 2 ou mais de um carrossel.',
            liveChat: 'Chat Ao Vivo',
            theTypeLiveChatDescription: 'tenta conectar ao chat ao vivo da Globalbot.',
            calendar: 'Calendário',
            theTypeCalendarDescription: 'permite o usuário escolher a data desejada no calendário.',
            time: 'Horário',
            theTypeTimeDescription: 'permite o usuário escolher o horário no relógio.',
            response: 'Resposta',
            buttonOptions: 'Opções para os botões',
            dragToSwitchPosition: 'Arrastar para trocar posição',
            value: 'Valor',
            jump: 'Salto',
            switchToSmartButton: 'Trocar para botão com inteligência',
            switchToButtons: 'Trocar para botões',
            excludeButton: 'Excluir este botão',
            addButton: 'Adicionar outro botão',
            checkboxOptions: 'Opções para os checkbox',
            genai: 'IA Generativa',
            slidersOptions: 'Opções para os sliders',
            label: 'Label',
            minValue: 'Valor mínimo',
            maxValue: 'Valor máximo',
            initialValue: 'Valor inicial',
            step: 'Passada',
            guideScreenOptions: 'Opções para Tela Guiada',
            pageLink: 'Link da página',
            returnJump: 'Salto de retorno',
            faqOptions: 'Opções para FAQ',
            dataBaseOptions: 'Opções para Base de Dados',
            knowledgeBase: 'Base de conhecimento',
            secondaryKnowledgeBase: 'Base de conhecimento secundária',
            ifNotFound: 'Se não encontrado:',
            faqButtons: 'Botões na FAQ',
            learningTime: 'Tempo de aprendizado (dias)',
            buttonLimit: 'Limite de botões',
            machineLearning: 'Máquina de aprendizado',
            imageOptions: 'Opções para imagem do Facebook ou Whatsapp',
            imageLink: 'Link da imagem',
            textOptions: 'Opções para texto.',
            mask: 'Máscara',
            messageError: 'Mensagem de erro',
            messageErrorOption: 'Mensagem de erro de opção',
            newMask: 'Nova máscara',
            typeSomething: 'Por favor, digite algo.',
            onlyFacebook: 'Só para o facebook, utilize o embebed do youtube ou outros componentes',
            chooseVideoFromLibrary: 'Escolha um vídeo da biblioteca',
            videoLink: 'Link do vídeo AVI, MP4 etc',
            moreOptions: 'Mais opções',
            liveChatOptions: 'Opções para o Chat ao vivo',
            fill: 'Preencha',
            message: 'Mensagem',
            liveErrorJump: 'Erro no salto',
            liveErrorJumpMessage: 'O salto não pode ser para a mesma linha',
            setIdentifier: 'Defina o identificador para salvar a resposta do usuário.',
            newIdentifier: 'Novo identificador',
            jumpLine: 'Salto de linha',
            defineJumpToNextLine: 'Defina onde vai ser a próxima linha para o chatbot saltar.',
            jumpToLine: 'Saltando para a linha',
            jumpTo: 'Saltar para',
            assignComment: 'Atribuir um comentário',
            assignCommentDescription: 'Você irá atribuir um comentário para esta linha dentro do fluxo.',
            notResponseOptions: 'Opção de não responder',
            createNotResponseOnlyWebchat: 'Cria uma opção de não responder a pergunta. Apenas para Webchat.',
            notResponse: 'Não responder',
            secureMessageOptions: 'Opção de mensagem segura',
            writtenMessageOne: 'A mensagem escrita pelo usuário não será guardada pela Globalbot.',
            waitDefinedValue: 'Quando ativado, ele espera o valor definido em milisegundos para aparecer.',
            timing: 'Tempo',
            addTiming: 'Adicionar Tempo',
            integrations: 'Integrações',
            ticketTitle: 'Coloque o título do Ticket que você deseja e clique no + ao lado.',
            sendTicketToGlpi: 'Após salvar o bot ao passar por essa linha irá enviar um ticket para o Sistema GLPI.',
            clickTheIcon: 'Para desativar, é apenas clicar no ícone de menos.',
            editTicketTitle: 'Editar título do ticket',
            newTicketTitle: 'Novo título do ticket',
            sendLeadTranscriptEmail: 'Enviar transcrição de lead via e-mail',
            insertEmail: 'Coloque o e-mail para enviar o lead no campo abaixo.',
            saveBotAndSendEmail: 'Após salvar o bot ao passar por essa linha irá enviar e-mail de lead para o e-mail informado.',
            editEmail: 'Editar e-mail',
            newEmail: 'Novo e-mail',
            addTagInConversation: 'Adicionar TAG na conversa',
            selectTags: 'Selecione as tags para ser adicionadas na conversa caso passar por esta linha.',
            selectOneTag: 'Selecione uma TAG',
            addTag: 'Adicionar tags',
            externalCode: 'Códigos externos',
            masterOnlyFunction: 'Função só para masters.',
            insertCode: 'Aqui vai um código em javascript puro, que irá ser executado nessa linha.',
            codeAdapted: 'Caso não seja o Callback Único, cada plataforma precisa de um código adaptado para funcionar.',
            unifiedExternalCode: 'Código externo unificado',
            newUnifiedExternalCode: 'Novo código externo unificado',
            externalCodeForWebchat: 'Código externo para Webchat',
            newExternalCodeForWebchat: 'Novo código externo para Webchat',
            externalCodeForWhatsapp: 'Código externo para Whatsapp',
            newExternalCodeForWhatsapp: 'Novo código externo para Whatsapp',
            externalCodeForFacebook: 'Código externo para Facebook',
            newExternalCodeForFacebook: 'Novo código externo para Facebook',
            close: 'Fechar',
            guideScreen: 'Tela Guiada',
            zipCode: 'CEP',
            cnpj: 'CNPJ',
            finish: 'Finalizar',
            uploadImage: 'Upload de imagem',
            checkbox: 'Checkbox',
            rating: 'Estrelas',
            number: 'Número',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            audio: 'Áudio',
            bornDate: 'Data de nascimento',
            slider: 'Slider',
            image: 'Imagem',
            facebookVideo: 'Vídeo Facebook',
            video: 'Video',
            city: 'Cidade',
            state: 'Estado',
            country: 'País',
            maskMoney: 'Máscara de dinheiro',
            noBaseAvailable: 'Sem base disponível',
            noBaseAssigned: 'Sem base atribuída',
            dataBaseNotFoundOption: 'Infelizmente não temos esta opção, escolha uma opção válida.',
            noBaseDefined: 'Sem base definida',
            noBodyOnline: 'Infelizmente não temos ninguém online no momento.',
            dataBaseNotFound: 'Dados da base não encontrados',
            messageErrorButtons: 'Oops, nomes de botões duplicados na linha ',
            messageErrorSaved: 'Oops, algum erro aconteceu ao salvar as informações.',
            saveDescription: 'Coloque uma descrição antes de salvar.',
            flowSaved: 'Fluxo salvo',
            flowPublish: 'Fluxo Publicado',
            editFlow: 'Editor de fluxo',
            title: 'Título',
            description: 'Descrição',
            select: 'Selecionar'
          },
          flowLite: {
            flowLite: 'Fluxo Lite',
            addBalloonAbove: 'Adicionar balão a cima',
            randomMessage: 'Mensagem randômica',
            message: 'Mensagem',
            name: 'Nome',
            buttons: 'Botões',
            intelligence: 'Inteligência',
            carousel: 'Carrossel',
            text: 'Texto',
            email: 'Email',
            checkbox: 'Checkbox',
            rating: 'Estrelas',
            number: 'Número',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            phone: 'Telefone',
            telephone: 'Telefone',
            audio: 'Áudio',
            liveChat: 'Chat ao vivo',
            cpf: 'CPF',
            zipCode: 'CEP',
            calendar: 'Calendário',
            slider: 'Slinder',
            image: 'Imagem',
            city: 'Cidade',
            state: 'Estado',
            country: 'País',
            money: 'Dinheiro',
            cnpj: 'CNPJ',
            uploadImage: 'Upload de imagem',
            finish: 'Finalizar',
            guideScreen: 'Tela guiada',
            done: 'Feito',
            editText: 'Editar texto',
            addRandomResponse: 'Adicionar resposta randômica',
            randomizeResponse: 'Randomizar resposta',
            edit: 'Editar',
            exclude: 'Excluir',
            identifier: 'Identificador',
            jump: 'Salto',
            excludeBalloon: 'Excluir balão',
            wantToExcludeBalloon: 'Tem certeza que deseja excluir este balão?',
            dontAskAgain: 'Não perguntar novamente',
            cancel: 'Cancelar',
            editBalloon: 'Editar balão',
            instructions: 'Instruções',
            saveRepliesDescription: 'O "identificador" é utilizado para criar saltos e ser gravado em locais como conversas, integrações, etc. Use para guardar respostas importantes de seus clientes.',
            doNotUse: 'Não use "espaços", use "_" (underscore), não use acentuações, caso tenha qualquer dúvida a respeito, entre em contato.',
            identifierName: 'Nome do identificador',
            replacementSystem: 'Caso você queira usar o sistema de substituição apenas coloque o identificador dentro de "%", exemplo: %MEU_IDENTIFICADOR%',
            useButtons: 'Use quantos botões for necessários, mas lembre-se, caso o número seja maior que 6 o mesmo irá virar uma lista (dropdown).',
            changePosition: 'Caso queira mudar os botões de posição clique no icone',
            andDrag: 'e arraste.',
            sameValue: 'Chave e valor devem sempre terem os mesmos valores. Não esqueça de escolher um identificador para saltar.',
            key: 'Chave',
            value: 'Valor',
            add: 'Adicionar',
            specificDepartment: 'Departamento: caso tenha um departamento específico, selecione, caso contrário deixe como "Primário".',
            messageErrorDescription: 'Mensagem de erro: mensagem caso a IA não consiga responser.',
            jumpErrorDescription: 'Salto de erro: saltar para algum identificador caso a IA não consiga responder.',
            departments: 'Departamentos',
            messageError: 'Mensagem de erro',
            jumpError: 'Salto de erro',
            knowledgeBaseDescription: 'Base de conhecimento: A busca da IA será apenas na base de conhecimento selecionada.',
            jumpDescription: 'Salto: Caso a IA não tenha resposta para a pergunta irá saltar para o identificador selecionado ou repetir a pergunta caso não seja selecionado nenhum identificador.',
            knowledgeBase: 'Base de conhecimento',
            jumpWithoutAiResponse: 'Salto (sem resposta da IA)',
            machineLearning: 'Máquina de aprendizado',
            importantDescription: 'Importante: Este recurso funciona apenas no Webchat.',
            machineLearningDescription: 'Máquina de aprendizado: A IA aprende com seus clientes, trazendo as respostas (em forma de botões) mais utilizadas para facilitar as dúvidas dos mesmos.',
            learningTimeDescription: 'Tempo de aprendizado: O tempo (em dias) que a IA pode utilizar para o aprendizado.',
            buttonLimitDescription: 'Limite de botões: A IA irá trazer as respostas se atendo ao número máximo de botões.',
            learningTime: 'Tempo de aprendizado (dias)',
            twoDays: 'Dois dias',
            threeDays: 'Três dias',
            oneWeek: 'Uma semana',
            twoWeek: 'Duas semanas',
            oneMonth: 'Um mês',
            oneQuarter: 'Um trimestre',
            oneSemester: 'Um semestre',
            buttonLimit: 'Limite de botões',
            three: 'Três',
            four: 'Quatro',
            five: 'Cinco',
            six: 'Seis',
            auxiliaryBases: 'Bases auxiliares',
            searchAuxiliaryBases: 'Caso a IA não tenha resposta usando a base de conhecimento escolhida a mesma irá procurar nas bases auxiliares.',
            optionToRefuseDisambiguation: 'Opção de recusar desambiguação',
            alwaysAllow: 'A IA vai sempre permitir que o cliente escolha a opção de recusar as respostas dada pela mesma.',
            onlyResponseUnambiguous: 'Lembre-se, esta opção é apenas para respostas desambiguadas.',
            fieldLabel: 'O campo Label irá aparecer junto ao número selecionado, por exemplo "25m²"',
            valueMaxAndMin: 'Valor mínimo e valor máximo definem até quanto seu cliente pode escolher.',
            selectionInitialValue: 'Escolha um valor inicial também, podendo facilitar para seu cliente.',
            stepDescription: 'A passada são os saltos de números, por exemplo se a passada for 5 o cliente pode escolher 25, 30, 35 e assim por diante.',
            label: 'Label',
            minValue: 'Valor mínimo',
            maxValue: 'Valor máximo',
            initialValue: 'Valor inicial',
            step: 'Passada',
            searchLibrary: 'Buscar na biblioteca',
            insertLink: 'Coloque o link da página que você queira guiar seu cliente.',
            restartReturnJump: 'O salto de retorno vai iniciar novamente o chat no identificador selecionado.',
            pageLink: 'Link da página',
            returnJump: 'Salto de retorno',
            useItems: 'Use quantos itens for necessários, mas lembre-se, caso o número seja maior que 6 o mesmo irá virar uma lista (dropdown).',
            changingThePositionButtons: 'Caso queira mudar os botões de posição clique no icone',
            googleAnalytics: 'Google Analytics',
            defineTheCategory: 'Defina a categoria, ação, etiqueta e o valor do evento.',
            requiredFields: 'Não esqueça, todas as informações precisam ser preenchidas.',
            category: 'Categoria',
            action: 'Ação',
            tag: 'Etiqueta',
            noInteraction: 'Sem interação',
            customerResponse: 'Resposta do cliente',
            manual: 'Manual',
            none: 'Nenhum',
            bornDate: 'Data de nascimento',
            maskMoney: 'Máscara de dinheiro',
            primary: 'Primário',
            all: 'Todas',
            clickHere: 'Clique aqui',
            select: 'Escolher',
            itemOne: 'Item um',
            noBodyOnline: 'Infelizmente não temos ninguém online no momento.',
            editFlowLite: 'Editor de fluxo lite'
          },
          list: {
            viewer: 'Visualizador',
            flowStructure: 'Visualize a estrutura do seu fluxo!.',
            editorPro: 'Explore sua criatividade no editor para profissionais, com diversos recursos interativos e novas configurações.',
            editor: 'Editor',
            editFlow: 'Edite o fluxo diretamente no código. Para quem possui conhecimentos de código HTML e JavaScript.',
            flows: 'Fluxos'
          },
          viewer: {
            flow: 'Fluxo',
            toView: 'Visualizar',
            test: 'Testar',
            indicatesTheBalloonLine: 'Este número indica a linha em que o balão está.',
            responseField: 'Campo de resposta',
            emptyQuestion: 'Pergunta vazia',
            clickGoToLine: 'Ao clicar você poderá ir para a linha.',
            type: 'Tipo',
            typeDescription: 'Indicador do nome do',
            theBalloon: 'do balão.',
            identifier: 'Identificador',
            identifierDescription: 'Esta resposta aparecerá na aba conversas.',
            knowledgeBase: 'Base de conhecimento',
            jumpCaseNotFound: 'Salto caso não encontrar',
            jumpCaseNotFoundDescription: 'Caso não houver pergunta cadastrada, saltará a linha',
            department: 'Departamento',
            noBodyOnline: 'Salto caso ninguém online',
            noBodyOnlineDescription: 'Caso não houver nenhum atendente online, saltará para a linha',
            jumpingToLine: 'Saltando para a linha',
            botWillGoToLine: 'O bot após passar por esse balão, irá para a linha',
            clickInNumberGoToLine: 'Clique no número para ir para a linha.',
            externalCode: 'Código externo',
            uniqueExternalCode: 'Código externo único',
            whatsappExternalCode: 'Código externo Whatsapp',
            facebookExternalCode: 'Código externo Facebook',
            glpiIntegration: 'Integração com GLPI',
            sendingLeadEmail: 'Enviando e-mail de lead',
            appearanceTime: 'Tempo de aparecimento',
            previewAppearanceTime: 'Mostra o tempo de aparecimento do balão, o padrão é 3000 milisegundos (3 segundos)',
            finishingBot: 'Finalização do bot',
            finishingBotDescription: 'Quando o bot chegar nessa linha ele vai finalizar.',
            dontResponse: 'Não responder',
            dontResponseDescription: 'Aqui surge uma opção para o usuário não responder, quando clicar nela, o bot vai para o próximo balão.',
            comment: 'Comentário',
            commentDescription: 'Comentário feito por um editor deste fluxo.',
            secureMessage: 'Mensagem segura',
            secureMessageDescription: 'Essa resposta não será salva nas Conversas na plataforma Globalbot',
            primary: 'Primário',
            none: 'Nenhum',
            name: 'Nome',
            faq: 'FAQ',
            buttons: 'Botões',
            list: 'Lista',
            carousel: 'Carrossel',
            text: 'Texto',
            email: 'E-mail',
            phone: 'Telefone',
            liveChat: 'Chat Ao Vivo',
            guideScreen: 'Tela guiada',
            cpf: 'CPF',
            zipCode: 'CEP',
            cnpj: 'CNPJ',
            finish: 'Finalizar',
            uploadImage: 'Upload de imagem',
            checkbox: 'Checkbox',
            rating: 'Estrelas',
            number: 'Número',
            firstName: 'Primeiro nome',
            lastName: 'Sobrenome',
            audio: 'Áudio',
            message: 'Mensagem',
            calendar: 'Calendário',
            bornDate: 'Data de nascimento',
            slider: ' Slider',
            image: 'Imagem',
            facebookVideo: 'Vídeo Facebook',
            video: 'Vídeo',
            city: 'Cidade',
            state: 'Estado',
            country: 'País',
            maskMoney: 'Máscara de dinheiro',
            noBaseAvailable: 'Sem base disponível',
            noBaseAssigned: 'Sem base atribuída',
            dataBaseNotFoundOption: 'Infelizmente não temos esta opção, escolha uma opção válida.',
            title: 'Título',
            description: 'Descrição',
            select: 'Selecionar',
            phoneIdentifierLowerCase: 'telefone',
            ratingIdentifierLowerCase: 'estrelas',
            numberIdentifierLowerCase: 'numero',
            messageLiveError: 'Infelizmente não temos ninguém online no momento.',
            noBaseDefined: 'Sem base definida',
            messageErrorSaveTheInformation: 'Oops, algum erro aconteceu ao salvar as informações.',
            flowSaved: 'Fluxo salvo',
            flowEditor: 'Editor de fluxo'
          }
        },
        help: {
          help: {
            help: 'Ajuda',
            whatIsYourDoubt: 'Qual sua dúvida?',
            needHelp: 'Precisa de ajuda?',
            tutorials: 'Tutoriais',
            messageErrorTestingIntelligence: 'Erro ao testar inteligência'
          }
        },
        kanban: {
          kanban: {
            making: 'Fazendo',
            done: 'Feito',
            createItem: 'Criar item',
            title: 'Título',
            message: 'Mensagem',
            cancel: 'Cancelar',
            add: 'Adicionar',
            newColumn: 'Nova coluna'
          }
        },
        knowledge: {
          genai: {
            card_title: "IA Generativa",
            card_description:
              "Respostas criadas a partir de grande massa de dados (modelo GPT).\nInterpreta a intenção do usuário e apresenta respostas geradas (texto e links).",
            column_name: "Nome",
            column_autor: "Autor",
            column_updatedAt: "Última atualização",
            column_action: "Ações",
            drawer_test: {
              title: "Teste da IA Generativa",
              name: 'Nome',
              text_motive: "Utilize o chat ao lado para testar as respostas da IA Generativa.<br>As respostas são geradas automaticamente baseadas nos arquivos e documentos informados.",
              note: "NOTA",
              text_note: "Lembre-se que a IA Generativa irá fazer parte de algum chatbot, portanto alguns recursos deste chat de teste são limitados (ex. não transbordo para atendentes).",
              restart: "Recomeçar"
            },
            drawer_data: {
              create: "Nova Base de Conhecimento",
              edit: "Editar Base de Conhecimento",
              prompt: 'Orientações de Contexto (Prompt)',
              promptDescription: "Defina abaixo as orientações para direcionar as respostas da IA Generativa",
              docs: "Conhecimento",
              docsDescriptions: "Submeta os arquivos e/ou informe os sites que serão utilizados pela IA Generativa (PDF, MP3, MP4, MPEG)",
              informSite: 'Informar Site',
              informSiteDescription: 'Informe o endereço de um site.',
              url: 'Endereço URL *',
              recursiveSearch: "Busca recursiva: uma vez ativada esta opção, todos os links relacionados ao domínio informado serão incluídos.",
              cancel: 'Cancelar',
              importDoc: "Importar Arquivos",
              clickOrDrag: "Clique ou arraste os arquivos para esta área.",
              rules: "Regras Adicionais",
              triggers: "Gatilhos de Escape",
              triggersDescription: "Utilize gatilhos para levar o usuário a algum ponto do fluxo do chatbot.",
              name: 'Nome *',
              requiredFieldsError: 'Preencha todos os campos obrigatórios.',
              save: 'Salvar',
              createSuccess: 'Assistente criado com sucesso.',
              updateSuccess: 'Assistente atualizado com sucesso.',
              deleteDocSuccess: 'Documento excluído com sucesso.',
              urlError: 'Erro ao atualizar URL:',
              triggerError: 'Erro ao atualizar gatilho:',
              updateFileError: 'Erro ao atualizar arquivo:',
              fileActive: 'Conteúdo Pronto e Disponível',
              fileProcessing: 'Conteúdo em Processamento',
              fileError: 'Erro ao Processar.. Apague o item e tente novamente',
              triggerError: 'Erro ao atualizar gatilho:',
              triggersPromptDescription: 'Defina abaixo as orientações para direcionar a IA Generativa a realizar a ação de gatilho.',
              flowJump: 'Ação: Ir para a seguinte linha do fluxo:',
              deleteTrigger: 'Exlcuir Gatilho',
              deletedTrigger: "Gatilho excluído.",
              errorDeleteThisTrigger: "Erro ao excluir gatilho.",
              areYouSureDeleteTrigger: "Tem certeza que deseja excluir este gatilho? Todos os dados do mesmo serão perdidos e esta ação não pode ser desfeita.",
              deleteDoc: 'Exlcuir Arquivo',
              deletedDoc: "Arquivo excluído.",
              errorDeleteThisDoc: "Erro ao excluir arquivo.",
              areYouSureDeleteDoc: "Tem certeza que deseja excluir este arquivo? Todos os dados do mesmo serão perdidos e esta ação não pode ser desfeita.",
              delete: 'Excluir',
              errorTreatment: 'Tratativa de Erro',
              errorTreatmentDescription: 'Defina o comportamento do chatbot quando não há resposta.',
              noResponse: 'Se ficar sem resposta por:',
              errorFlowJump: "Ir para a linha do fluxo",
              intervention: "Intervenção Programada",
              interventionDescription: "Defina uma intervenção programada a partir da quantidade de respostas.",
              from: "A partir de:",
              subsequentTimes: 'vezes seguidas',
              responsesRow: 'respostas seguidas'
            },
            genaiNotCreated: "Você ainda não tem assistentes",
            editGenai: "Editar assistente",
            testGenai: "Testar assistente",
            add: "Criar Assistente",
            exclude: "Excluir Assistente",
            deletedAssistant: "Assistente excluído.",
            errorDeleteThisAssistant: "Erro ao excluir assistente.",
            areYouSureDelete: "Tem certeza que deseja excluir este assistente? Todos os dados do mesmo serão perdidos e esta ação não pode ser desfeita.",
            cancel: "Cancelar"
          },
          knowledge: {
            card_title: "IA Convencional",
            card_description:
              "Respostas padronizadas. Interpreta a intenção do usuário e apresenta respostas cadastradas (texto, vídeo, imagens, documentos e links).",
            deleteQuestionSelected: 'Excluir repostas selecionadas',
            rowsPerPage: 'Registros por página:',
            conversations: 'Conversas',
            add: 'Adicionar',
            knowledgeBase: 'Base de conhecimento',
            exclude: 'Excluir',
            askQuestion: 'Faça uma pergunta (teste de intenções)',
            importQuestions: 'Importar perguntas',
            columns: 'Colunas',
            status: 'Status',
            questionKnowledgeBase: 'Pergunta / Base de conhecimento',
            response: 'Resposta',
            intentionsIa: 'Intenções (Inteligência Artificial)',
            actions: 'Ações',
            edit: 'Editar',
            save: 'Salvar',
            cancel: 'Cancelar',
            deleteQuestions: 'Excluir Perguntas',
            newQuestion: 'Nova pergunta',
            questionTitle: 'Título da pergunta',
            createNewKnowledgeBase: 'Crie uma nova Base de Conhecimento quando você precisar separar o conhecimento para públicos ou contextos distintos, uma vez que a Inteligência é compartilhada com todos os chatbots da sua conta.',
            textEditor: 'Editor de texto',
            addRandomResponse: 'Adicionar resposta randômica',
            responseNotFound: 'Resposta não encontrada ir para posição do fluxo',
            jumpToFlowPosition: 'Saltar para posição do fluxo',
            search: 'Buscar',
            noImagesFound: 'Nenhuma imagem encontrada',
            urlPage: 'URL da página',
            continueToFlowPosition: 'Continuar na posição do fluxo',
            randomizeResponse: 'Randomizar resposta',
            moveUp: 'Mover para cima',
            document: 'Documento',
            text: 'Texto',
            video: 'Vídeo',
            liveChat: 'Chat ao vivo',
            intelligence: 'Inteligência',
            image: 'Imagem',
            carousel: 'Carrossel',
            guideScreen: 'Tela guiada',
            jump: 'Salto',
            moveDown: 'Mover para baixo',
            continueFlow: 'Após a apresentação das respostas acima, o chatbot irá seguir o fluxo conforme o sequenciamento definido.',
            intentions: 'Intenções',
            registerIntentions: 'Cadastre as intenções considerando as principais palavras que poderão acionar a resposta que está sendo cadastrada. Atribua pesos maiores nas palavras que necessitam evitar desambiguação.',
            newIntention: 'Digite uma nova intenção aqui e tecle "Enter"',
            intention: 'Intenção',
            weight: 'Peso',
            minWeight: 'Peso mínimo',
            optional: 'Opcional',
            optionalDescription: 'Utilize caso queira definir o mínimo de peso a ser somado pelas intenções para exibir a resposta.',
            addKnowledgeBase: 'Adicionar Base de Conhecimento',
            title: 'Título',
            excludeQuestion: 'Excluir pergunta',
            youSureExclude: 'Tem certeza que deseja excluir esta pergunta?',
            justCopy: 'Para importar perguntas, basta copiar',
            thisModel: 'este modelo',
            standard: 'padrão',
            exportToCsv: 'e exporta-lo em CSV com as suas perguntas que deseja cadastrar.',
            chooseFileAndImport: 'Após isso, clique em Escolher arquivo logo abaixo para importar.',
            none: 'Nenhuma',
            question: 'Pergunta',
            all: 'Todas',
            messageErrorImportList: 'Erro ao importar lista do banco',
            youAlreadyHaveBase: 'Você já tem uma base com este título.',
            requiredTitle: 'O título não pode ficar em branco.',
            duplicateIntention: 'Já existe uma intenção igual, dê mais peso a mesma.',
            mustHaveMoreThanOneLetter: 'A intenção precisa ter mais de 1 letra.',
            cannotHaveMoreThanTwoWords: 'A intenção não pode ter mais de 2 palavras.',
            questionSave: 'Pergunta salva',
            messageErrorNewQuestion: 'Erro ao salvar nova pergunta',
            allFieldsRequired: 'Todos os campos precisam ser preenchidos',
            questionSuccessfullyDeleted: 'Pergunta excluída com sucesso.',
            messageErrorExcludeQuestion: 'Erro ao excluir pergunta.',
            messageErrorTestIntelligence: 'Erro ao testar inteligência',
            messageErrorFilter: 'Erro ao filtrar inteligência',
            knowledgeBaseExcluded: 'Base de conhecimento excluída.',
            itWasNotPossibleExclude: 'Não foi possível excluir esta base de conhecimento.'
          }
        },
        library: {
          library: {
            limitExceeded: 'Limite de 200 MB excedido',
            addFile: 'Adicionar mídia',
            libraryDescription: 'Aqui, na biblioteca de mídias, você pode adicionar mídias no chat ao vivo e nos carrosseis fluxo.',
            sizeLimitIs: 'Limite de tamanho é',
            video: 'Vídeo',
            videos: 'Vídeos',
            addMedia: 'Adicionar mídia',
            photos: 'Fotos',
            search: 'Buscar',
            list: 'Lista',
            gallery: 'Galeria',
            view: 'Visualizar',
            name: 'Nome',
            sent: 'Enviado',
            actions: 'Ações',
            of: 'de',
            documents: 'Documentos',
            document: 'Documento',
            exclude: 'Excluir',
            newFile: 'Novo arquivo',
            typeFile: 'Tipo de arquivo',
            image: 'Imagem',
            title: 'Título',
            description: 'Descrição',
            cancel: 'Cancelar',
            save: 'Salvar',
            areYouSure: 'Você tem certeza que deseja excluir o arquivo?',
            clickExclude: 'Ao clicar em "Excluir", o arquivo não poderá mais ser recuperado.',
            date: 'Data',
            size: 'Tamanho',
            library: 'Biblioteca',
            requiredTitle: 'O campo título precisa ser preenchido',
          }
        },
        live: {
          live2: {
            userLeaveChat: 'O usuário abandonou o chat',
            userStayOnManualQueue: 'O usuário está em fila manual',
            userStayOnAutoQueue: 'O usuário está em fila automática',
            userStayWaiting: 'O usuário está em espera',
            chatIsFinished: 'A conversa está finalizada',
            chatWaitHsm: 'A conversa está em pausa e esperando interação do usuário',
            errorOnTransferRoom: 'Identificamos um erro ao transferir esta conversa. Neste caso, por gentileza, tire um print desta tela e envie para nosso suporte (suporte@globalbot.com.br). Favor tentar repetir a transferência novamente.',
            titleErrorOnLive: 'Não foi possível transferir a conversa',
            titleRuleOnLive: 'Não foi possível realizar esta ação',
            ruleTransferRoomUnauthorizedUser: 'Você não possui autorização para transferir esta sala',
            ruleTransferRoomInvalidStatus: 'Não é possível transferir esta sala pois o status dela é inválido, caso persista peça a um gerente para realizar esta ação',
            liveChatUpperCase: 'Chat ao Vivo',
            inbox: 'Caixa de entrada do atendente',
            waiting: 'Fila do atendente',
            newConversation: 'Nova conversa',
            filter: 'Filtro',
            attendants: 'Atendentes',
            settings: 'Configurações',
            filterByAttendant: 'Filtro de atendente',
            searchCustomerName: 'Buscar nome do cliente',
            all: 'Todos',
            filterByDepartment: 'Filtro de departamento',
            internalToUpperCase: 'INTERNO',
            finishedToUpperCase: 'FINALIZADO',
            inQueueToUpperCase: 'EM FILA',
            waitingToUpperCase: 'AGUARDANDO',
            newToUpperCase: 'NOVO',
            transferToUpperCase: 'TRANSFERIDO',
            yesterday: 'Ontem',
            finishAttendance: 'Finalizar Atendimento(s)',
            finishAndArchiveAttendance: 'Finalizar e Arquivar',
            processingAction: 'Processando... Por favor, aguarde finalizar.',
            finishSuccess: 'Atendimento(s) finalizado(s) com sucesso',
            noAttendance: 'Nenhum atendimento no momento.',
            important: 'Importante',
            completionMessage: 'Escolha uma das opções, considerando que "Finalizar" você finaliza e mas ainda vê a sala de conversa nesta tela, enquanto "Finalizar e Arquivar" a conversa é finalizada e arquivada. Você poderá consultar depois no Menu Conversas.',
            triggerAgain: 'A próxima interação do mesmo irá acionar o chatbot novamente, reabrindo um novo atendimento.',
            areYouSureFinish: 'Você tem certeza que deseja finalizar?',
            cancel: 'Cancelar',
            finish: 'Apenas Finalizar',
            archiveAttendance: 'Arquivar Atendimento(s)',
            archiveSuccess: 'Atendimento(s) arquivado(s) com sucesso',
            futureConversation: 'Ao arquivar os atendimentos, as conversas ativas serão automaticamente finalizadas e encerradas. A próxima interação do(s) usuários(s) irá acionar o chatbot novamente, reabrindo um novo atendimento. Você poderá consultar as conversas no futuro, no Menu Conversas. Você tem certeza que deseja arquivar?',
            areYouSureArchive: 'Você tem certeza que deseja arquivar?',
            archive: 'Arquivar',
            transferAttendance: 'Transferir Atendimento(s)',
            transferSuccess: 'Atendimento(s) transferido(s) com sucesso',
            transferDescription: 'Transfira para outro atendente (ele receberá os atendimentos) ou para um departamento (os atendimentos serão distribuídos de acordo com as configurações do departamento de destino).',
            areYouSureTransfer: 'Você tem certeza que deseja transferir?',
            byAttendant: 'para atendente',
            byDepartment: 'para departamento',
            transfer: 'Transferir',
            withCustomer: 'com Cliente',
            viaWhatsapp: '(via WhatsApp)',
            startConversationWithCustomer: 'Inicie uma conversa com um cliente',
            sendingMessage: 'enviando uma mensagem via WhatsApp.',
            select: 'Selecionar',
            withAttendant: 'com Atendente',
            viaGlobalbot: '(via Globalbot)',
            startConversationWithSomeone: 'Inicie uma conversa com alguém do',
            teamSending: 'seu time de trabalho enviando uma',
            messageThroughTheGlobalbot: 'mensagem pela plataforma Globalbot.',
            newAttendance: 'Novo atendimento',
            waitingForYou: 'está aguardando você!',
            see: 'Ver',
            close: 'Fechar',
            waitingAttendant: 'está aguardando algum atendente.',
            take: 'Assumir',
            newTransfer: 'Nova transferência',
            transferredAndWaitingForYou: 'foi transferido e está aguardando você!',
            transferredDepartmentAndWaitingForYou: 'foi transferido para seu departamento está aguardando algum atendente.',
            anotherAttendant: 'Outro atendente já assumiu este atendimento.'
          },
          room: {
            transferAttendance: 'Transferir atendimento',
            finishAttendance: 'Finalizar atendimento',
            noAttendance: 'Nenhum atendimento no momento.',
            archive: 'Arquivar',
            group: 'grupo',
            attendance: 'atendimento',
            theaterMode: 'Modo teatro',
            defaultView: 'Visualização padrão',
            noOneToAnswer: 'Ninguém para atender no momento. O que acha de uma xícara de café?',
            chooseOneToAnswer: 'Selecione um atendimento e vamos lá!',
            loadMoreMessages: 'Carregar mais mensagens',
            isTyping: 'O usuário ainda está digitando!',
            conversation: 'Conversa',
            intelligence: 'Inteligência',
            navigation: 'Navegação',
            data: 'Dados',
            abandonedTheChat: 'O usuário abandonou o chat',
            createdIn: 'Criado em',
            attendants: 'Atendentes',
            exit: 'Sair',
            quickResponse: 'Resposta rápida',
            internalMessage: 'Mensagem interna',
            send: 'Enviar',
            takeAttendance: 'ASSUMIR ATENDIMENTO',
            important: 'Importante',
            sendTemplateHsm: 'Para falar com o usuário, envie um Template de Mensagem (HSM)',
            onlyAttendants: 'Apenas atendentes desta conversa podem enviar mensagens.',
            automaticArchiving: 'Após 5 dias a conversa será arquivada automaticamente.',
            preview: 'Pré-visualizar',
            addLegend: 'Adicionar legenda',
            cancel: 'Cancelar',
            search: 'Procurar',
            notFound: 'Nenhum emoji encontrado',
            found: 'Encontrados',
            recent: 'Usados frequentemente',
            people: 'Emojis & Pessoas',
            nature: 'Animais & Natureza',
            foods: 'Comida & Bebida',
            activity: 'Atividade',
            places: 'Viagens & Locais',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Bandeiras',
            others: 'Outros',
            fullFunctioning: 'Para o pleno funcionamento do chat ao vivo é necesário permitir o acesso para a área de transferência.',
            anotherAttendant: 'Outro atendente já assumiu este atendimento.'
          }
        },
        login: {
          forgot: {
            enterYourEmail: 'Informe seu email para enviarmos uma senha provisória',
            buttonRecover: 'Recuperar minha senha',
            goBack: 'Voltar',
            emptyEmail: 'Você precisa preencher o email',
            email: 'Email',
            pleaseEmptyEmail: 'Por favor preencha seu email',
            passwordSent: 'Sua nova senha foi enviada para seu email.',
            contact: 'Oops, por favor entre em contato com o suporte da Globalbot. (suporte@globalbot.com.br)',
            iForgotMyPassword: 'Esqueci minha senha'
          },
          login: {
            selectAccount: 'Selecione a conta desejada para acessar',
            cancel: 'Cancelar',
            forgotPassword: 'Esqueci minha senha',
            verificationCode: 'Código de verificação',
            enter: 'Entrar',
            validate: 'Validar',
            emptyEmail: 'Você precisa preencher o email',
            labelEmail: 'Email',
            fillAllData: 'Por favor preencha todos os campos',
            emptyPassword: 'Você precisa preencher a senha',
            labelPassword: 'Senha',
            userInactive: 'Este usuário está inativo, entre em contato com seu gerente.',
            outOfYourJourney: 'Você está fora da sua jornada de trabalho, entre em contato com seu gerente.',
            incorrectUsernameOrPassword: 'Usuário ou senha incorreto.',
            doubleFactor: 'Esta conta está protegida com autenticação de duplo fator, favor digitar o código de seis dígitos enviado a seu celular.',
            doubleFactorNeedNumber: 'Esta conta está protegida com autenticação de duplo fator, e seu usuário não tem um número de celular cadastrado, favor entrar em contato com seu gerente.',
            unexpectedError: 'Erro inesperado.',
            accountNotCorrect: 'Sua conta não parece correta, por favor entre em contato com a Globalbot',
            notifiedHere: 'Agora sempre que algo acontecer você será notificado por aqui.',
            notificationActivated: 'Notificação ativada',
            rememberMyPassword: 'Mantenha me conectado'
          }
        },
        notification: {
          control: {
            addNotification: 'Adicionar notificação',
            notificationDescription: 'Aqui, na central de notificações, você pode adicionar notificações.',
            dateAndTime: 'Data e Hora',
            type: 'Tipo',
            title: 'Título',
            actions: 'Ações',
            user: 'Usuário',
            tag: 'Tag',
            department: 'Departamento',
            role: 'Cargo',
            account: 'Conta',
            group: 'Grupo',
            all: 'Todos',
            edit: 'Editar',
            linkUsers: 'Vincular usuários',
            exclude: 'Excluir',
            recordsPerPage: 'Registros por página',
            save: 'Salvar',
            cancel: 'Cancelar',
            newNotification: 'Nova Notificação',
            showAvatar: 'Mostrar remetente',
            customizableLabel: 'Rótulo customizável',
            label: 'Rótulo',
            message: 'Mensagem',
            notificationType: 'Tipo de notificação',
            attachments: 'Anexos',
            loadImage: 'Carregar imagem',
            notificationControl: 'Controle de Notificações',
            errorUpload: 'Erro ao processar upload.',
            notificationSent: 'Notificação Enviada',
            errorSent: 'Erro ao enviar notificação'
          }
        },
        people: {
          people: {
            people: 'Pessoas',
            name: 'Nome',
            anonymous: 'Anônimo'
          },
          timeline: {
            people: 'Pessoa',
            fullName: 'Nome completo',
            name: 'Nome',
            nickname: 'Apelido',
            occupation: 'Ocupação',
            cpf: 'CPF',
            anonymous: 'Anônimo',
            company: 'Empresa',
            cnpj: 'CNPJ',
            country: 'País',
            city: 'Cidade',
            state: 'Estado',
            district: 'Bairro',
            conversationStarted: 'Conversa iniciada',
            of: 'de',
            at: 'as',
            hoursAnd: 'horas e',
            minutes: 'minutos'
          }
        },
        profile: {
          profile: {
            profile: 'Perfil',
            changePhoto: 'Alterar foto',
            hello: 'Olá',
            personalInformation: 'Informações Pessoais',
            name: 'Nome',
            email: 'Email',
            role: 'Cargo',
            phone: 'Celular',
            security: 'Segurança',
            newPassword: 'Nova senha',
            confirmNewPassword: 'Confirmação de nova senha',
            connectedDevices: 'Dispositivos Conectados',
            deleteDevice: 'Excluir dispositivo',
            save: 'Salvar',
            administrator: 'Administrador',
            manager: 'Gerente',
            attendant: 'Atendente',
            primary: 'Primário',
            passwordIsNotIdentical: 'Oops, sua senha não é idêntica, favor revisar.',
            informationSaved: 'Informações salvas!',
            errorSavingInformation: 'Oops, algum erro aconteceu ao salvar as informações.'
          }
        },
        realTime: {
          realTime: {
            preferences: 'Preferências',
            realTime: 'Tempo real',
            browsing: 'Navegando',
            withChatbots: 'Com chatbots',
            withAttendants: 'Com atendentes',
            alreadyTalked: 'Já conversou',
            attendedBy: 'Atendido por',
            currentPageContains: 'Página atual contém',
            columns: 'Colunas',
            channel: 'Canal',
            dateAndTime: 'Data/Hora',
            visitor: 'Visitante',
            chatbot: 'Chatbot',
            location: 'Localização',
            pages: 'Págs',
            currentPage: 'Página atual',
            time: 'Tempo',
            conversation: 'Conversa',
            actions: 'Ações',
            unknown: 'Desconhecido',
            seeOnMap: 'Ver no mapa',
            seeNavigation: 'Ver navegação',
            inAttendance: 'Em atendimento',
            waitingForAttendant: 'Aguardando atendente',
            finishAttendance: 'Atendimento finalizado',
            noAttendance: 'Nenhum atendimento no momento.',
            activeVisitor: 'Visitante ativo',
            inactiveVisitor: 'Visitante inativo',
            startedAt: 'Iniciado as',
            allConversations: 'Todas conversas',
            of: 'de',
            initialMessage: 'Mensagem inicial',
            message: 'Mensagem',
            cancel: 'Cancelar',
            intervene: 'Intervir',
            navigation: 'Navegação',
            openPage: 'Abrir página',
            close: 'Fechar',
            openConversation: 'Abrir conversa',
            all: 'Todos',
            chatbots: 'Chatbots',
            path: 'Caminho',
            conversations: 'Conversas'
          }
        },
        settings: {
          chatbot: {
            behavior: {
              chatbots: 'Chatbots',
              firstTime: 'Primeiro tempo',
              secondTime: 'Segundo tempo',
              preferences: 'Preferências',
              behavior: 'Comportamento',
              firstInteraction: 'Primeira interação *',
              secondInteraction: 'Segunda interação',
              desiredAction: {
                title: 'Ação desejada',
                actionTranshipment: 'Transbordar',
                actionEnd: 'Finalizar',
                messageTranshipment: 'Mensagem antes do transbordo',
                departmentTranshipment: 'Transferir o departamento',
                tag: 'Marcar com a Tag'
              },
              timers: 'Temporizadores',
              balloonAppearanceTime: 'Tempo de aparecimento do balão',
              chatbotShutdownTime: 'Tempo de encerramento do chatbot',
              interactionTime: 'Tempo de interação (...)',
              threeMinutes: '3 minutos',
              oneMinute: '1 minuto',
              fiveMinutes: '5 minutos',
              teenMinutes: '10 minutos',
              fifteenMinutes: '15 minutos',
              thirtyMinutes: '30 minutos',
              oneHour: '1 hora',
              twoHours: '2 horas',
              threeHours: '3 horas',
              fiveHours: '5 horas',
              teenHours: '10 horas',
              twelveHours: '12 horas',
              fifteenHours: '15 horas',
              twentyHours: '20 horas',
              twentyFourHours: '24 horas',
              whatsappCacheTime: 'Tempo de cache do WhatsApp',
              facebookCacheTime: 'Tempo de cache do Facebook',
              webchatCacheTime: 'Tempo de cache do Webchat',
              controllers: 'Controladores',
              sounds: 'Sons',
              soundType: 'Tipo de som',
              fullScreenMode: 'Modo tela-cheia',
              disableControls: 'Desabilitar controles',
              disableHeader: 'Desabilitar cabeçalho',
              automaticOpening: 'Abertura automática',
              notReopen: 'Não reabrir balão após atendimento',
              satisfactionSurvey: 'Pesquisa de satisfação',
              protocolAttendance: 'Protocolo de atendimento',
              includeInternalMessages: 'Incluir mensagens internas',
              optionFinishOrMin: 'Opção de finalizar ou minimizar.',
              imageUploader: 'Uploader de imagem (cliente)',
              fileUploader: 'Uploader de arquivo (cliente)',
              allConversations: 'Todas as conversas',
              conversationsNotTransshipment: 'Conversas do atendente e sem transbordo',
              onlyAttendantConversations: 'Apenas conversas do atendente',
              viewingConversationsForAttendants: 'Visualização de conversas para atendentes',
              persistence: 'Persistência do chatbot',
              activated: 'Ativado',
              disabled: 'Desativado',
              firstMessage: 'Primeira mensagem',
              firstMessageTime: 'Tempo da primeira mensagem',
              secondMessage: 'Segunda mensagem',
              secondMessageTime: 'Tempo da segunda mensagem',
              thirdMessage: 'Terceira mensagem',
              thirdMessageTime: 'Tempo da terceira mensagem',
              save: 'Salvar',
              areYouSure: 'Você tem certeza que deseja salvar as alterações?',
              youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
              previousContent: 'conteúdo anterior não pode ser restaurado.',
              cancel: 'Cancelar',
              interface: 'Interface',
              interfaceSettings: 'Configurações de interface',
              behaviorSettings: 'Configurações do chatbot',
              installation: 'Instalação',
              integrations: 'Integrações',
              liveChat: 'Chat ao vivo',
              // areYouStillAround: 'Ainda está por ai?',
              // ifNotResponse: 'Caso você não resposta eu vou ter que finalizar o atendimento.',
              // ifNeedHelp: 'Tudo bem, se você precisar de ajuda novamente me avise!',
              // closeMessage: 'O atendimento foi encerrado pelo atendente, agradecemos seu contato e caso tenha mais alguma dúvida é só me chamar novamente.',
              preferenceSaved: 'Preferência salva com sucesso'
            },
            installation: {
              whatsapp: {
                installation: 'Instalação Whatsapp',
                configuredWithChatbot: 'Seu WhatsApp está configurado com seu Chatbot.',
                notConfiguredWithChatbot: 'Seu WhatsApp não está configurado com seu Chatbot.',
                versionHint: 'Versão do Whatsapp a ser utilizado',
                version: 'Versão',
                instanceHint: 'ID da Instância fornecido pelo seu Gerente de Projetos',
                instance: 'Instância',
                tokenHint: 'Token da Instância fornecido pelo seu Gerente de Projetos',
                token: 'Token',
                mobileNumberHint: 'Número do celular a ser utilizado',
                mobileNumber: 'Número do celular',
                customerIdentifierHint: 'Identificador do cliente fornecido pela Globalbot',
                customerIdentifier: 'Identificador do cliente',
                instructionsMobileNumbers: 'Instruções para números móveis',
                stepOneMobileNumbers: 'Habilite o chip contendo o número que você deseja conectar o chatbot',
                stepTwoMobileNumbers: 'Você irá receber neste número um SMS contendo um código numérico',
                stepThreeMobileNumbers: 'Informe o código recebido para a equipe da Globalbot',
                stepFourMobileNumbers: 'Digite a instância e token fornecidos pela Globalbot nos campos abaixo',
                instructionsFixedNumbers: 'Instruções para números fixos',
                stepOneFixedNumbers: 'Você irá receber no seu número uma ligação contendo um código numérico',
                stepTwoFixedNumbers: 'Informe o código recebido para a equipe da Globalbot',
                stepThreeFixedNumbers: 'Digite a instância e token fornecidos pela Globalbot nos campos abaixo',
                areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
                youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
                previousContent: 'conteúdo anterior não pode ser restaurado.',
                cancel: 'Cancelar',
                save: 'Salvar',
                publish: 'Publicar',
                versioningMessage: 'Para acessar as versões anteriores, basta acessar as configurações do bot específico ir em versionamento e publicar a versão desejada para entrar em produção.',
                removeConfig: 'Remover configuração',
                reallyRemoveConfig: 'Você realmente quer remover a configuração?'
              },
              webchat: {
                installation: 'Instalação (WebChat e Botão WhatsApp)',
                configuredWithChatbot: 'O script está instalado no seu site.',
                notConfiguredWithChatbot: 'O script não está instalado no seu site.',
                installationScript: 'Script de instalação',
                authorizedDomains: 'Domínios autorizados',
                domainNumber: 'Domínio nº',
                addDomain: 'Adicionar domínio',
                instructions: 'Instruções para Instalação',
                stepOne: 'Copie o script de instalação ao lado',
                stepTwo: 'Insira o script no código HTML do seu site nas páginas que você quer acionar o chatbot (cole o script imediatamente após o final da tag </BODY>).',
                stepThree: 'Após colar o script nas páginas desejadas, você deve indicar ao lado quais domínios o chatbot foi adicionado.',
                stepFour: 'Você deve indicar abaixo em quais domínios o chatbot poderá ser acionado.',
                externalBoot: 'Inicialização externa',
                youCan: 'Você pode fazer a inicialização externa via código JavaScript.',
                accessElement: 'Acesse o elemento',
                andUse: 'E utilize as funções disponíveis para as inicializações.',
                openBalloon: 'Abrir o balão inicial',
                initialBalloon: 'Balão inicial com posição do fluxo',
                openChat: 'Abrir o chat',
                openChatWithFlowPosition: 'Abrir o chat com posição do fluxo',
                areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
                youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
                previousContent: 'conteúdo anterior não pode ser restaurado.',
                cancel: 'Cancelar',
                save: 'Salvar',
              },
              messenger: {
                installation: 'Instalação Facebook Messenger',
                configuredWithChatbot: 'Seu Facebook está configurado com seu Chatbot.',
                reset: 'Reconfigurar',
                notConfiguredWithChatbot: 'Seu Facebook não está configurado com seu Chatbot.',
                noPagesAvailable: 'Nenhuma página disponível.',
                synchronize: 'Sincronizar',
                almostConfigured: 'Falta pouco, sua página do Facebook está quase configurado com seu Chatbot.',
                yourPage: 'Sua página',
                instructions: 'Instruções',
                connectFacebook: 'Clique no botão Sincronizar',
                followSteps: 'Siga o passo-a-passo para conexão',
                youMustBeAdmin: 'Você deverá ser administrador da FanPage para conceder acesso',
                areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
                youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
                previousContent: 'conteúdo anterior não pode ser restaurado.',
                cancel: 'Cancelar',
                save: 'Salvar',
                disconnect: 'Desconectar',
                reallyDisconnectPage: 'Você realmente quer desconectar esta página?'
              },
              telegram: {
                installation: 'Instalação Telegram',
                configuredWithChatbot: 'Seu Telegram está configurado com seu Chatbot.',
                notConfiguredWithChatbot: 'Seu Telegram não está configurado com seu Chatbot.',
                tokenProvidedBot: 'Token de acesso para a API fornecida pelo Bot Father',
                token: 'Token',
                instructions: 'Instruções',
                talkToThe: 'Converse com o',
                followStep: 'Siga o passo-a-passo para criar o seu bot',
                indicateBelow: 'Indique abaixo o token de acesso para a API',
                areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
                youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
                previousContent: 'conteúdo anterior não pode ser restaurado.',
                cancel: 'Cancelar',
                save: 'Salvar',
              },
              instagram: {
                almostConfigured: 'Falta pouco, sua página do Instagram está quase configurado com seu Chatbot.',
                yourPage: 'Sua página',
                installation: 'Instalação Instagram',
                configuredWithChatbot: 'Seu Instagram está configurado com seu Chatbot.',
                reset: 'Reconfigurar',
                notConfiguredWithChatbot: 'Seu Instagram não está configurado com seu Chatbot.',
                motiveConfigure: 'a plataforma Globalbot pode gerenciar mensagens apenas de contas INSTAGRAM BUSINESS. Não é possível conectar e gerenciar mensagens em contas Instagram pessoais.',
                important: 'Importante: ',
                synchronize: 'Sincronizar',
                instructions: 'Instruções',
                stepOne: 'ADICIONAR 1° PASSO',
                stepTwo: 'ADICIONAR 2° PASSO',
                stepThree: 'ADICIONAR 3° PASSO',
                areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
                youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
                previousContent: 'conteúdo anterior não pode ser restaurado.',
                cancel: 'Cancelar',
                save: 'Salvar',
              },
              chatbots: 'Chatbots',
              preferences: 'Preferências',
              installation: 'Instalação',
              interface: 'Interface',
              interfaceSettings: 'Configurações de interface',
              behavior: 'Comportamento',
              chatbotSettings: 'Configurações de chatbot',
              integrations: 'Integrações',
              liveChat: 'Chat ao vivo',
              // areYouStillAround: 'Ainda está por ai?',
              // ifYouNotResponse: 'Caso você não resposta eu vou ter que finalizar o atendimento.',
              // ifYouNeedHelp: 'Tudo bem, se você precisar de ajuda novamente me avise!',
              // closeMessage: 'O atendimento foi encerrado pelo atendente, agradecemos seu contato e caso tenha mais alguma dúvida é só me chamar novamente.',
              youHavePermission: 'Oops, parece que está faltando permissões.',
              consultTheConsole: 'Oops, algum erro aconteceu, consulte o console.',
              preferenceSaved: 'Preferência salva com sucesso'
            },
            integrations: {
              chatbots: 'Chatbots',
              preferences: 'Preferências',
              integrations: 'Integrações',
              reset: 'Reconfigurar',
              toSetUp: 'Configurar',
              descriptions: {
                googleAnalytics: 'Esta integração é responsável por fazer uma análise de estatísticas do fluxo.',
                pipeRun: 'Com esta integração a plataforma Globalbot irá registrar (postar) informações de contatos (leads) no Piperun através dos campos compatíveis.',
                slack: 'Com esta integração a plataforma Globalbot irá realizar o envio do histórico da conversa para um canal específico. Precisa de um webhook configurado para receber o evento e realizar o envio de mensagens.',
                vistaSoft: 'Esta integração é responsável por busca imóveis para aluguel/compra de acordo com as opções selecionadas pelo usuário (numero de quartos, tipo, bairro) e registro de leads.\nCampos chave: telefone e/ou nome e e-mail e "objetivo_contato"/"interesse".',
                rdStation: 'Com esta integração a plataforma Globalbot irá registrar (postar) informações de contatos (leads) na RD Station Marketing através dos campos compatíveis.\nCampos chave: e-mail.',
                pipeDrive: 'Com esta integração a plataforma Globalbot irá registrar (postar) informações de contatos (leads) no PipeDrive através dos campos compatíveis. Realiza atribuição de atendente como responsável a partir do endereço de e-mail.',
                rdCrm: 'Com esta integração a plataforma Globalbot irá realizar o envio de negociações para o CRM.\nCampos chaves: e-mail e/ou telefone e nome.',
                ksi: 'Com esta integração a plataforma Globalbot irá registrar (postar) informações de contatos (leads) na Kurole KSI através dos campos compatíveis.\nCampos chave: e-mail, nome e telefone.',
                cv: 'Com esta integração a plataforma Globalbot irá registrar (postar) informações de contatos (leads) no Contrutor de Vendas através dos campos compatíveis.\nCampos chave: e-mail, nome e telefone.',
                movidesk: 'Com esta integração a plataforma Globalbot irá realizar a abertura de tickets.\nCampos chave: e-mail.',
                glpi: 'Com esta integração a plataforma Globalbot irá realizar a abertura de tickets.\nCampos chave: nome.',
                jira: 'Com esta integração a plataforma Globalbot irá realizar a criação de tarefas (tasks).\nCampos chave: e-mail.',
                zendesk: 'Com esta integração a plataforma Globalbot irá realizar a abertura de tickets.\nCampos chave: e-mail.',
                midasSoft: 'Com esta integração a plataforma Globalbot irá registrar (postar) informações de contatos (leads) no MidasSoft.',
                rdStationV2: 'Esta integração é responsável pela busca de informações no RD Stations Marketing (apenas para casos específicos).',
                moskit: 'Com esta integração a plataforma Globalbot será responsável pela criação de negociações.',
                funilVendas: 'Com esta integração a plataforma Globalbot será responsável pela criação de negociações.\nCampos chave: e-mail, nome e telefone.',
                amigo: 'Com esta integração a plataforma Globalbot irá realizar agendamentos de consultas médicas.',
                agendor: 'Com esta integração a plataforma Globalbot será responsável pela criação de negociações e tarefas.\nCampos chave: telefone.',
                emailLead: 'Com esta integração a plataforma Globalbot será responsável pelo envio das trancrições das conversas por e-mail.',
                shopify: 'Esta integração é responsável pelo rastreio de pedidos.\nCampos chave: e-mail.'
              },
              aboutIntegration: 'Sobre a integração'
            },
            interface: {
              chatbots: 'Chatbots',
              preferences: 'Preferências',
              interface: 'Interface WebChat',
              chatWindow: 'Janela do Chat',
              botName: 'Nome do(a) bot',
              attendantName: 'Nome do(a) atendente',
              attendantRole: 'Cargo do(a) atendente',
              avatarImage: 'Imagem do avatar',
              logo: 'Logotipo',
              balloonImage: 'Imagem do balão',
              primaryColor: 'Cor primária',
              chatHeight: 'Altura do chat',
              installationInstructions: 'Instruções de Instalação no Site',
              installationInstructionsDescription: 'Instale o script de acionamento do WebChat no seu site.',
              copyRight: 'Copyright (apenas o master)',
              disabled: 'Desativado',
              activated: 'Ativado',
              startBalloon: 'Balão de início',
              message: 'Mensagem',
              position: 'Posição',
              left: 'Esquerda',
              right: 'Direita',
              textColor: 'Cor do texto',
              backgroundColor: 'Cor do fundo',
              templates: 'Modelos',
              default: 'Padrão',
              amplified: 'Amplificado',
              ticket: 'Ticket',
              noImage: 'Sem imagem',
              noText: 'Sem texto',
              ghost: 'Invisível',
              mobile: 'Móvel',
              save: 'Salvar',
              areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
              youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
              previousContent: 'conteúdo anterior não pode ser restaurado.',
              cancel: 'Cancelar',
              sampleQuestion: 'Exemplo de pergunta',
              answerExample: 'Exemplo de resposta',
              typeYourName: 'Digite seu nome',
              typeYourPhone: 'Digite seu telefone',
              virtualAssistant: 'Assistente Virtual',
              preferenceSaved: 'Preferência salva com sucesso'
            },
            whatsapp: {
              settings: 'Configurações',
              chatbotAndNumber: 'Chatbot | Número Whatsapp do Botão',
              buttonStatus: 'Status do Botão',
              buttonMessage: 'Mensagem do Botão (opcional)',
              buttonPosition: 'Posição do Botão',
              initialMessageWhatsapp: 'Mensagem inicial no Whatsapp (opcional)',
              initialMessageWhatsappDescription: 'Este é o texto que será automaticamente inserido no campo de mensagem do aplicativo WhatsApp.',
              enabled: 'Ativado',
              disabled: 'Desativado',
              rightPosition: 'à direita (canto inferior)',
              leftPosition: 'à esquerda (canto inferior)',
              installationInstructions: 'Instruções de Instalação no Site',
              installationInstructionsDescription: 'Instale o script de acionamento do Botão WhatsApp no seu site.',
              traceOrigin: 'Rastrear Origem de Tráfego (Tracking)',
              traceOriginDescription: 'Ao habilitar este recurso, será solicitado ao usuário informar seu número de telefone WhatsApp antes de iniciar a conversa. Com isto, a página que o usuário estava no site poderá ser identificada.',
              requestPhone: 'Solicitar telefone e habilitar a rastreabilidade.',
              logoOrAvatar: 'Imagem',
              visibleName: 'Nome Vísivel (Display Name)',
              resquestPhoneMessage: 'Mensagem para pedir o número do WhatsApp',
              copyright: 'Copyright (apenas o master)'
            },
            master: {
              chatbots: 'Chatbots',
              preferences: 'Preferências',
              master: 'Master',
              locationOfServers: 'Localização dos servidores (somente master)',
              newPln: 'Novo PLN (somente master)',
              realTime: 'Tempo real (somente master)',
              cacheConversation: 'Cache da conversa (somente master)',
              offshoreLastQuestion: 'Offshore last question (somente master)',
              excludeChatbot: 'Excluir chatbot',
              createCliWithoutBackground: 'Criar CLI sem Background',
              save: 'Salvar',
              areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
              youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
              previousContent: 'conteúdo anterior não pode ser restaurado.',
              doYouReallyExclude: 'Quer mesmo excluir o chatbot?',
              cancel: 'Cancelar',
              exclude: 'Excluir',
              interface: 'Interface',
              interfaceSettings: 'Configurações de interface',
              behavior: 'Comportamento',
              chatbotSettings: 'Configurações de chatbot',
              installation: 'Instalação',
              integrations: 'Integrações',
              // areYouStillAround: 'Ainda está por ai?',
              // ifYouNotResponse: 'Caso você não resposta eu vou ter que finalizar o atendimento.',
              // ifYouNeedHelp: 'Tudo bem, se você precisar de ajuda novamente me avise!',
              // closeMessage: 'O atendimento foi encerrado pelo atendente, agradecemos seu contato e caso tenha mais alguma dúvida é só me chamar novamente.',
              preferenceSaved: 'Preferência salva com sucesso'
            },
            messages: {
              chatbots: 'Chatbots',
              preferences: 'Preferências',
              messages: 'Mensagens',
              portuguese: 'Português',
              english: 'Inglês',
              spanish: 'Espanhol',
              faqNotFound: 'FAQ não encontrada',
              treatment: 'Quando o usuário digita bom dia em vez do nome dele.',
              noName: 'Quando o usuário digita: Meu nome é...',
              ask: 'Quando o usuário faz alguma pergunta.',
              special: 'Quando o usuário coloca algum caractere especial',
              justYourName: 'Quando o usuário digita o primeiro palavrão',
              cursed: 'Quando o usuário digita o segundo palavrão e fecha o chatbot.',
              knowledgeName: 'Quando existe uma intenção na inteligência e o usuário colocou ela no nome.',
              limit: 'Quando o usuário digita além do limite permitido.',
              manyItems: 'Quando desambigua uma pergunta',
              faqNoneButton: 'Quando desambiguado, aparece um botão com essa frase',
              connectLive: 'Quando conecta no Chat ao Vivo',
              nameError: 'Quando o usuário erra o nome',
              firstNameError: 'Quando o usuário erra o primeiro nome',
              textError: 'Quando o usuário não digita nada em algum pedido de texto',
              emailError: 'Quando o usuário erra o e-mail',
              moneyError: 'Quando o usuário erra o pedido de inserir um valor monetário',
              phoneError: 'Quando o usuário erra o Telefone',
              cpfError: 'Quando o usuário erra o CPF',
              cnpjError: 'Quando o usuário erra o CNPJ',
              faqError: 'Quando o usuário não digita nada em uma FAQ',
              save: 'Salvar',
              areYouSureSave: 'Você tem certeza que deseja salvar as alterações?',
              youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
              previousContent: 'conteúdo anterior não pode ser restaurado.',
              cancel: 'Cancelar',
              interface: 'Interface',
              interfaceSettings: 'Configurações de interface',
              behavior: 'Comportamento',
              chatbotSettings: 'Configurações de chatbot',
              installation: 'Instalação',
              integrations: 'Integrações',
              // areYouStillAround: 'Ainda está por ai?',
              // ifYouNotResponse: 'Caso você não resposta eu vou ter que finalizar o atendimento.',
              // ifYouNeedHelp: 'Tudo bem, se você precisar de ajuda novamente me avise!',
              // closeMessage: 'O atendimento foi encerrado pelo atendente, agradecemos seu contato e caso tenha mais alguma dúvida é só me chamar novamente.',
              typeYourName: 'Para começar nossa conversa, digite o seu nome.',
              onlyName: 'Digite apenas o seu nome.',
              toContinueTypeYourNamePlease: 'Para continuar, por favor, digite o seu nome. ',
              typeYourNamePleaseSpecial: 'Parece que você incluiu um caractere especial. Digite apenas o seu nome.',
              continueTheConversation: 'Dessa forma não posso continuar a conversa.',
              typeYourNamePlease: 'Por favor, digite apenas o seu nome.',
              toAnswerYourQuestions: 'Para responder suas dúvidas, preciso que digite seu nome.',
              virtualAssistant: 'Assistente Virtual',
              maxLimit: 'Você ultrapassou o limite máximo de caracteres.',
              iCanHelpYou: 'Nesta sua pergunta eu posso te ajudar com mais de uma opção.',
              noneOption: 'Nenhuma das opções acima',
              notFound: 'Não entendi sua pergunta.',
              waitMoment: 'Aguarde uns instantes. Um atendente já vai falar com você.',
              selectOneOptions: 'Selecione uma das opções',
              typeName: 'Digite seu nome',
              typeNameError: 'Digite um nome válido.',
              typeFirstName: 'Digite seu primeiro nome',
              typeFirstNameError: 'Digite apenas seu primeiro nome.',
              typeLastName: 'Digite seu sobrenome',
              typeLastNameError: 'Digite apenas seu sobrenome.',
              typeHere: 'Digite aqui',
              typeHerePlease: 'Por favor digite algo.',
              typeEmail: 'Digite seu e-mail',
              typeEmailError: 'Por favor, informe um e-mail válido.',
              typeMoney: 'Digite seu valor',
              typeMoneyError: 'Por favor, informe um valor válido.',
              typePhone: 'Digite seu telefone',
              typePhoneError: 'Por favor, informe um telefone válido.',
              typeCpf: 'Digite seu CPF',
              typeCpfError: 'Por favor, informe um CPF válido.',
              typeBornDate: 'Digite sua data de nascimento',
              typeBornDateError: 'Por favor, informe sua data de nascimento válido.',
              typeCnpj: 'Digite seu CNPJ',
              typeCnpjError: 'Por favor, informe um CNPJ válido.',
              typeFaq: 'Digite sua dúvida',
              typeFaqError: 'Por favor, preencha algo.',
              selectListFaq: 'Escolha uma opção',
              selectListFaqError: 'Por favor, escolha uma opção.',
              selectRating: 'Selecione',
              search: 'Procurar',
              searchCountry: 'Procurar país',
              searchState: 'Procurar estado',
              searchCity: 'Procurar cidade',
              typeMessage: 'Digite sua mensagem...',
              typeMessageError: 'Por favor, digite sua mensagem',
              selectDate: 'Escolha uma data',
              selectDateError: 'Por favor, escolha uma data',
              selectSize: 'Escolha um tamanho',
              selectSizeError: 'Por favor, escolha um tamanho',
              typeNumber: 'Digite um número',
              typeNumberError: 'Por favor, digite um número',
              preferenceSaved: 'Preferência salva com sucesso'
            },
            chatbots: 'Chatbots',
            preferences: 'Preferências',
            interfaceChatbot: 'Interface WebChat',
            setAllVisual: 'Habilite e defina os aspectos visuais e funções do WebChat (janela do chat) instalados no site.',
            behaviorChatbot: 'Comportamento',
            accessTheBehaviorSettings: 'Acesse as configurações de comportamento do chatbot (controles de tempo, acionamento, modos de visualização da janela do chat e persistência da conversa)',
            installationChatbot: 'Instalação',
            accessTheInstallationSettings: 'Acesse as configurações de instalação e ativação dos chatbots em cada um dos canais: WebChat, WhatsApp, Instagram, Messenger e Telegram.',
            whatsappChatbot: 'Botão Whatsapp',
            whatsappChatbotDescription: 'Habilite e defina os aspectos visuais do Botão WhatsApp para seu site e configure o recurso de Rastrear Origem de Tráfego.',
            integrationsChatbot: 'Integrações',
            accessTheIntegrationSettings: 'Acesse o painel de configuração das diversas integrações do Globalbot com outros sistemas. Algumas destas configurações são realizadas pela nossa equipe técnica em virtude da complexidade e segurança.',
            masterChatbot: 'Master',
            exclusiveAccess: 'Acesso exclusivo para equipe da Globalbot para configurações avançadas, como localização dos servidores, cache conversation, comportamento da inteligência artificial e real time.',
            excludeVersionerAccess: 'Acesso excluviso para a equipe da Globalbot para visualizar os Versionamentos do fluxo.',
            messagesChatbot: 'Mensagens',
            modifyMessages: 'Aqui você pode modificar algumas das mensagens, apenas para Masters no momento.'
          },
          permission: {
            permission: {
              permission: 'Permissões',
              preferences: 'Preferências',
              conversations: 'Conversas',
              manager: 'Gerente',
              attendant: 'Atendente',
              seeAll: 'Ver todas',
              seeAttendedAndWithoutTransshipment: 'Ver atendidas e sem transbordo',
              onlyAttended: 'Ver somente atendidas',
              junior: 'Junior',
              done: 'Salvar',
              updatedPermissions: 'Permissões atualizadas!',
              connectionError: 'Oops, erro de conexão.'
            }
          },
          users: {
            access: {
              preferences: 'Preferências',
              users: 'Usuários',
              journey: 'Jornadas',
              addJourney: 'Adicionar Jornada',
              name: 'Nome',
              description: 'Descrição',
              actions: 'Ações',
              edit: 'Editar',
              linkUsers: 'Vincular usuários',
              exclude: 'Excluir',
              totalOf: 'Total de',
              usersBeing: 'usuários, sendo',
              inNone: 'em nenhuma',
              inOneAnd: 'em uma e',
              inTwoOrMore: 'em duas ou mais jornadas.',
              save: 'Salvar',
              cancel: 'Cancelar',
              journeyData: 'Dados da Jornada',
              journeyName: 'Nome da jornada *',
              comments: 'Observações',
              schedules: 'Horários',
              dayOfTheWeek: 'Dia da semana *',
              sunday: 'Domingo',
              monday: 'Segunda-feira',
              tuesday: 'Terça-feira',
              wednesday: 'Quarta-feira',
              thursday: 'Quinta-feira',
              friday: 'Sexta-feira',
              saturday: 'Sábado',
              input: 'Entrada *',
              exit: 'Saída *',
              addTime: 'Adicionar horário',
              tolerance: 'Tolerância',
              defineTheTolerance: 'Defina o tempo de tolerância desta jornada para acesso e permanência na plataforma.',
              important: 'Importante',
              onceTheTolerance: 'Uma vez atingido o tempo de tolerância, a plataforma emitirá um alerta e um contador de tempo regressivo para que o usuário finalize com tranquilidade suas atividades.',
              whenTheTotal: 'Quando o tempo total de permanência for finalmente atingido, o usuário será deslogado automaticamente. Somente usuários administradores poderão logar na plataforma fora dos seus horários de jornada.',
              ifUser: 'Caso um usuário esteja vinculado em mais de uma jornada, o acesso e permanência levará em consideração a jornada em que houver maior duração (tanto para entrada quando para saída).',
              journeyUsers: 'Usuários da Jornada',
              outOfTheJourney: 'Fora da jornada',
              withinTheJourney: 'Dentro da jornada',
              addSelected: 'Adicionar selecionados',
              addAll: 'Adicionar todos',
              removeAll: 'Remover todos',
              removeSelected: 'Remover selecionados',
              nonexistent: 'Inexistente',
              deleteJourney: 'Excluir Jornada',
              areYouSure: 'Tem certeza que deseja excluir esta jornada? Todos os dados do mesmo serão perdidos e esta ação não pode ser desfeita.',
              fiveMinutes: '5 minutos',
              tenMinutes: '10 minutos',
              fifteenMinutes: '15 minutos',
              twentyMinutes: '20 minutos',
              twentyFiveMinutes: '25 minutos',
              thirtyMinutes: '30 minutos',
              oneHour: '1 hora',
              twoHours: '2 horas',
              fourHours: '4 horas',
              sixHours: '6 horas',
              twelveHours: '12 horas',
              journeyCreated: 'Sua jornada foi criada!',
              journeyEdited: 'Sua jornada foi editada!',
              errorAtTheTime: 'Oops, tivemos um erro no momento!',
              journeyExcluded: 'Sua jornada foi excluída!',
              errorCompletingThisAction: 'Oops, tivemos um problema ao completar essa ação.'
            },
            preferences: 'Preferências',
            users: 'Usuários',
            addNewUser: 'Adicione novos usuários na plataforma. Caso atinja seu limite solicite a ampliação do mesmo.',
            createUser: 'Criar usuário',
            permissions: 'Permissões',
            managerUserPermissions: 'Administre as permissões dos usuários, habilitando ou restringindo acesso aos recursos da plataforma.',
            journeys: 'Jornadas',
            defineTheJourney: 'Defina a jornada de trabalho dos seus usuários, controlando o acesso e permanência na plataforma.',
            extendLimit: 'Ampliar Limite',
            yourCurrentLimit: 'Seu limite atual não é suficiente? Solicite agora a ampliação.',
            avatar: 'Avatar',
            addAvatar: 'Adicionar avatar',
            name: 'Nome',
            nameRequired: 'Nome *',
            email: 'Email',
            emailRequired: 'Email *',
            role: 'Cargo',
            departmentsFilter: 'Selecione um Departamento',
            chatbotsFilter: 'Selecione um Chatbot',
            linked: 'Vinculados',
            unlinked: 'Sem Vínculo',
            roleRequired: 'Cargo *',
            permission: 'Permissão',
            permissionRequired: 'Permissão *',
            actions: 'Ações',
            accessReport: 'Relatório de acesso',
            edit: 'Editar',
            exclude: 'Excluir',
            understood: 'Entendi',
            important: 'IMPORTANTE',
            impossibleToDelete: ': Impossível excluir este usuário pois ele possui ',
            activeConversations: 'conversas ativas. Você deve transferir ou finalizar as conversas deste usuário antes de solicitar exclusão.',
            save: 'Salvar',
            cancel: 'Cancelar',
            close: 'Fechar',
            userData: 'Dados do Usuário',
            ddi: 'DDI',
            phone: 'Celular',
            newPassword: 'Nova senha *',
            chatbots: 'Chatbots',
            departments: 'Departamentos',
            accessLogs: 'Logs de Acesso',
            initialDate: 'Data inicial',
            startTime: 'Hora inicial',
            finalDate: 'Data final',
            endTime: 'Hora final',
            status: 'Status',
            date: 'Data',
            intentions: 'Intenções',
            intentionsDescription: 'Selecione a data e o horário para o filtro procurar entre a área escolhida.',
            available: 'Online',
            away: 'Ausente',
            offline: 'Offline',
            connected: 'Conectado',
            disconnected: 'Desconectado',
            endJourney: 'Fim da jornada',
            excludeUser: 'Excluir usuário',
            areYouSureDelete: 'Tem certeza que deseja excluir este usuário? Todos os dados do mesmo serão perdidos e esta ação não pode ser desfeita.',
            youCanRequest: 'Você pode solicitar usuários adicionais para utilização na plataforma. Favor informar os dados abaixo que um de nossos especialistas entrará em contato com você.',
            amount: 'Quantidade *',
            request: 'Solicitar',
            primary: 'Primário',
            administrator: 'Administrador',
            manager: 'Gerente',
            attendant: 'Atendente',
            junior: 'Junior',
            january: 'Janeiro',
            february: 'Fevereiro',
            march: 'Março',
            april: 'Abril',
            may: 'Maio',
            june: 'Junho',
            july: 'Julho',
            august: 'Agosto',
            september: 'Setembro',
            october: 'Outubro',
            november: 'Novembro',
            december: 'Dezembro',
            shortJan: 'Jan',
            shortFeb: 'Fev',
            shortMar: 'Mar',
            shortApr: 'Abr',
            shortMay: 'Mai',
            shortJun: 'Jun',
            shortJul: 'Jul',
            shortAug: 'Ago',
            shortSep: 'Set',
            shortOct: 'Out',
            shortNov: 'Nov',
            shortDec: 'Dez',
            sunday: 'Domingo',
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sábado',
            shortSunday: 'Dom',
            shortMonday: 'Seg',
            shortTuesday: 'Ter',
            shortWednesday: 'Qua',
            shortThursday: 'Qui',
            shortFriday: 'Sex',
            shortSaturday: 'Sab',
            exportToSVG: 'Exportar para SVG',
            exportToPNG: 'Exportar para PNG',
            exportToCSV: 'Exportar para CSV',
            menu: 'Menu',
            selection: 'Selecionar',
            selectionZoom: 'Selecionar zoom',
            zoomIn: 'Mais zoom',
            zoomOut: 'Menos zoom',
            pan: 'Mover',
            reset: 'Retornar ao padrão',
            userDeleted: 'Usuário excluído.',
            errorUserDelete: 'Oops, não conseguimos excluir este usuário!',
            notLimit: 'Você não tem mais limite para adicionar usuários.',
            errorSavingUser: 'Preencha todos os campos obrigatórios.',
            errorPasswordLength: 'Cadastre uma senha com mais de 6 caracteres.',
            theArchitect: 'O Arquiteto',
            notFound: 'Não encontrado',
            dominion: 'Domínio',
            master: 'Mestre',
            senior: 'Sênior',
            failedToRequest: 'Oops, falha ao solicitar usuários.',
            requestedUsers: 'Usuários solicitados.',
            requiredFields: 'Você precisa preencher os campos.',
            userCreatedSuccessfully: 'Usuário criado com sucesso!',
            userUpdatedSuccessfully: 'Usuário atualizado com sucesso!'
          },
          domains: {
            preferences: 'Preferências',
            domains: 'Domínios',
            addNewDomainsToThePlatform: 'Adicione novos domínios na plataforma. Antes deve ser registrado DKIM, TXT e CNAME do domínio.',
            addDomain: 'Adicionar domínio',
            creation: 'Criação',
            domain: 'Domínio',
            emails: 'E-mails',
            name: 'Nome',
            actions: 'Ações',
            waiting: 'Aguardando',
            verified: 'Verificado',
            type: 'TIPO',
            host: 'HOST',
            data: 'DATA',
            verifyDomain: 'Verificar domínio',
            exclude: 'Excluir',
            addNewDomain: 'Adicionar novo domínio',
            insertRecords: 'Agora é necessário inserir os seguintes registros no seu DNS.',
            updateDomain: 'A propagação do seu DNS pode levar algumas horas, e o status do seu domínio será atualizado na plataforma.',
            record: 'Registro',
            nameToUpperCase: 'NOME',
            valueToUpperCase: 'VALOR',
            cancel: 'Cancelar',
            add: 'Adicionar',
            addNewEmail: 'Adicionar novo e-mail',
            confirmationEmail: 'Você receberá um email de confirmação, clique no link no corpo do email para proceguir com a verificação de email.',
            email: 'Email',
            excludeDomain: 'Excluir Domínio',
            areYouSure: 'Tem certeza que deseja excluir este domínio?',
            domainSuccessfully: 'Dominio cadastrado com sucesso.',
            connectionError: 'Erro de conexão',
            domainAlreadyRegistered: 'Domínio já cadastrado, tente novamente com outro.',
            startTheProcess: 'Para começar o processo é necessário um domínio.',
            deletedDomain: 'Domínio excluído.',
            domainSuccessfullyValidated: 'Domínio validado com sucesso!',
            fieldsNotAuthenticated: 'Há alguns campos ainda não autenticados, '
          },
          lists: {
            preferences: 'Preferências',
            lists: 'Listas',
            deleteSelectedLists: 'Excluir listas selecionadas',
            avatar: 'Avatar',
            title: 'Título',
            amount: 'Quantidade',
            actions: 'Ações',
            view: 'Visualizar',
            exclude: 'Excluir',
            save: 'Salvar',
            cancel: 'Cancelar',
            listData: 'Dados da lista',
            name: 'Nome',
            email: 'Email',
            phone: 'Telefone',
            excludeList: 'Excluir lista',
            areYouSure: 'Tem certeza que deseja excluir esta lista?',
            listDeleted: 'Sua lista foi excluída!',
            errorDeleteThisList: 'Oops, não conseguimos excluir esta lista!'
          },
          dataBases: {
            importData: 'Importar Base de Dados',
            name: 'Nome',
            cdt: 'Data/Hora',
            replaceData: 'Substituir dados',
            import: 'Importar',
            importCSV: "Impotar arquivo CSV",
            syncGoogleSheet: "Sincronizar com Google Sheets",
            invalidSpreadsheetId: 'O ID da planilha inválido',
            foundSheets: 'Planilhas encontradas',
            shareSpreadsheet: 'Você precisa compartilhar sua planilha com este email: ',
            listPages: 'Listar páginas',
            reportSpreadsheetId: 'Informe o ID da planilha',
            reportTabSheet: 'Selecione a planilha que deseja importar.',
            reportName: 'Coloque um nome',
            alreadyBaseName: 'Já existe uma base com este nome',
            alreadyBaseSheetName: 'Já existe uma base com esta página da planilha',
            descriptionSpreadsheetId: "O ID da planilha por ser extraído a partir da URL. Por exemplo: O ID da planilha nesta URL https://docs.google.com/spreadsheets/d/abc1234567/edit#gid=0 é 'abc1234567'.",
            dataGoogleSpreadSheet: 'Dados planilha Google',
            deleteDataBase: 'Deletar base de dados',
            spreadSheetId: 'ID da planilha Google',
            sheetPageName: 'Nome da página',
            close: 'Fechar',
            delete: 'Deletar'
          },
          live: {
            serviceMessages: 'Mensagens de serviço',
            portuguese: 'Português',
            english: 'Inglês',
            spanish: 'Espanhol',
            completionMessage: 'Mensagem de finalização',
            archivalMessage: 'Mensagem de arquivamento',
            transferMessage: 'Mensagem de transferência',
            automaticTransferToAttendant: 'Transbordo automático para atendente',
            transshipmentAvailableForAttendants: 'Transbordo disponível para atendentes',
            queueTransshipmentWithoutDistribution: 'Transbordo em fila (sem distribuição)',
            queueTransshipmentWithDistribution: 'Transbordo em fila (com distribuição)',
            transshipmentAwaitingAttendants: 'Transbordo aguardando atendentes (check in desabilitado)',
            noAttendantsAvailable: 'Sem atendentes disponíveis (check in habilitado)',
            tipDepartmentIsPersistent: 'Não é possível ativar o check-in pois este departamento está configurado na persistência dos bots: ',
            pauseOptions: 'Opções de pausa',
            activated: 'Ativado',
            disabled: 'Desativado',
            enterHereAll: 'Insira aqui todas as possíveis pausas que os atendentes podem selecionar.',
            add: 'Adicionar opção',
            automaticAway: 'Away automático',
            determineTheCheckInTime: 'Determine o tempo de checagem.',
            time: 'Tempo',
            viewingOptions: 'Opções de visualização',
            autoArchive: 'Tempo de arquivamento de conversas',
            autoArchiveCaption: 'Configure o arquivamento automático de conversas no chat ao vivo.',
            conversationCloseOptions: 'Opções ao finalizar conversa',
            conversationCloseOptionsDescription: 'Habilite a atribuição de tags ou valor ao finalizar uma conversa.',
            addTag: 'Atribuir Tags',
            addMonerary: 'Atribuir Valor',
            required: 'Obrigatório',
            coin: 'Moeda',
            coinTypes: {
              BRL: 'Real Brasileiro - BRL',
              USD: 'Dólar Americano - USD',
            },
            chooseTheLevel: 'Escolha o nível de acesso em que seus operadores poderão ver na lista de atendentes.',
            managers: 'Gerentes',
            attendants: 'Atendentes',
            departments: 'Departamentos',
            defineTheDepartments: 'Defina abaixo os departamentos do módulo Chat ao Vivo. Os departamentos são úteis para "rotear" as solicitações de contato, tratando-as de forma segmentada.',
            whenCreatingTheDepartments: 'Ao criar os departamentos, utilize as configurações de Usuários para definir em qual(is) departamento(s) cada usuário poderá operar.',
            name: 'Nome',
            setBehavior: 'Defina o comportamento de entrada caso não tenha nenhum atendente online no momento.',
            checkIn: 'Check-in',
            forcePass: 'Force Pass',
            forcePassDescription: 'Distribuir atendimentos também para atendentes offline.',
            forcePassDistributionNone: 'Você precisa selecionar um tipo de distribuição quando o Force Pass está habilitado.',
            queueDisable: 'A Fila de Atendimento é obrigatória com o Force Pass habilitado.',
            forcePassDisable: 'Não é possível habilitar o Force Pass com o Check-in habilitado.',
            checkInDisable: 'Não é possível habilitar o Check-in com o Force Pass habilitado.',
            attendanceQueue: 'Fila de atendimento',
            transmitQueuePosition: 'Transmitir posição de fila',
            transmitQueuePositionDescription: 'Transmitir a posição da fila para os clientes que estão aguardando.',
            maxAttendances: 'Máximo de atendimentos',
            maxAttendancesDescription: 'Defina a quantidade máxima que cada atendente pode atender simultaneamente.',
            customersByAttendants: 'Clientes por atendentes',
            distribution: 'Distribuição',
            distributionDescription: 'Escolha a forma de distribuição dos atendimentos no módulo Chat ao Vivo.',
            balancing: 'Balanceamento',
            disabledNone: 'Desabilitado',
            autoBalance: 'Auto Balanceamento',
            sequential: 'Sequencial',
            save: 'Salvar',
            comfirm: 'Confirmar',
            exclude: 'Excluir',
            addDepartment: 'Adicionar departamento',
            areYouSureSaved: 'Você tem certeza que deseja salvar as alterações?',
            areYouSureDeleted: 'Você quer mesmo excluir este departamento?',
            youCanChange: 'Você pode alterar as configurações quando quiser, mas o',
            previousContent: 'conteúdo anterior não pode ser restaurado.',
            cancel: 'Cancelar',
            finishRoom: 'A conversa foi finalizada.',
            archiveRoom: 'A conversa foi arquivada.',
            transferRoom: 'A conversa foi transferida.',
            withAttendant: 'Com atendente.',
            available: 'Disponível.',
            queue: 'Em fila.',
            queueDistribution: 'Em fila automática',
            waiting: 'Aguardando',
            noVacancy: 'Nenhum atendente no momento.',
            oneMinute: '1 minuto',
            twoMinutes: '2 minutos',
            threeMinutes: '3 minutos',
            fourMinutes: '4 minutos',
            fiveMinutes: '5 minutos',
            tenMinutes: '10 minutos',
            fifteenMinutes: '15 minutos',
            thirtyMinutes: '30 minutos',
            oneHour: 'Uma hora',
            seeAll: 'Ver todos',
            onlyDepartments: 'Ver apenas nos departamentos',
            preferences: 'Preferências',
            liveChat: 'Chat ao Vivo',
            messages: 'Mensagens',
            behavior: 'Comportamento',
            updated: 'Atualizado!',
            myDepartment: 'Meu departamento',
            departmentAdded: 'Departamento adicionado!',
            deletedDepartment: 'Departamento excluído!'
          },
          settings: {
            preferences: 'Preferências',
            users: 'Usuários',
            usersDescription: 'Administre os usuários da plataforma Globalbot. Você pode incluir, excluir usuários, além de definir e editar as permissões de acesso e funções de cada um deles de forma independente.',
            liveChat: 'Chat ao Vivo',
            liveChatDescription: 'Defina as configurações do módulo Chat ao Vivo. Crie os departamentos, defina a forma de distribuição dos atendimentos e as mensagens automáticas.',
            tags: 'Tags',
            tagsDescription: 'Acesse as configurações do sistema de tags (etiquetas), que podem ser utilizadas para marcar e classificar diversos itens em uma lista ou coleção (ex. Conversas, Inteligência e Biblioteca)',
            domainsAndEmail: 'Domínios e E-mails',
            domainsAndEmailDescription: 'Configure domínios e e-mails da sua empresa para que apareçam como remetentes em envios da Mensageria (e-mails, notificações e avisos).',
            lists: 'Listas',
            listsDescription: 'Acesse e controle todas as listas criadas e enviadas para a plataforma.',
            liveChatConfigs: {
              messages: 'Mensagens',
              messagesDescription: 'Confira e edite as mensagens prontas do Chat ao Vivo.',
              behavior: 'Comportamento',
              behaviorDescription: 'Configure o comportamento das funções no Chat ao vivo.',
              departmentsConfig: 'Departamentos',
              departmentsConfigDescription: 'Confira os principais indicadores de atendimento por canal, quantitativos e qualitativos.'
            },
            dataBases: 'Bases de Dados',
            dataBasesDescription: 'Importe bases de dados externas para serem integradas com os fluxos dos chatbots.'
          },
          tags: {
            preferences: 'Preferências',
            tags: 'Tags',
            add: 'Adicionar',
            columns: 'Colunas',
            color: 'Cor',
            category: 'Categoria',
            title: 'Título',
            description: 'Descrição',
            actions: 'Ações',
            edit: 'Editar',
            exclude: 'Excluir',
            save: 'Salvar',
            cancel: 'Cancelar',
            tagData: 'Dados da Tag',
            deleteTag: 'Excluir tag',
            areYouSureDelete: 'Tem certeza que deseja excluir esta tag? Esta ação não pode ser desfeita.',
            general: 'Geral',
            reports: 'Relatórios',
            realTime: 'Tempo real',
            analytics: 'Estatísticas',
            organization: 'Organização',
            companies: 'Empresas',
            people: 'Pessoas',
            conversation: 'Conversas',
            flow: 'Fluxo',
            knowledge: 'Inteligência',
            message: 'Campanhas',
            liveChat: 'Chat ao vivo',
            library: 'Biblioteca',
            tasks: 'Tarefas',
            deletedTag: 'Tag excluída.',
            errorDeleteThisTag: 'Oops, não conseguimos excluir esta tag!',
            errorSavingTag: 'Oops, algum erro aconteceu ao salvar a tag.',
            requiredFields: 'O campo título e cor precisam ser preenchidos.'
          }
        }
      }
    },
    'en-US': {
      components: {
        action: {
          requisition: {
            request: 'Request',
            method: 'Method',
            run: 'Run',
            messageError: 'Oops, missing information.'
          }
        },
        advancedSearch: {
          index: {
            myFilters: 'My search filters',
            custom: 'Custom',
            today: 'Today',
            yesterday: 'Yesterday',
            thisMonth: 'This month',
            lastSevenDays: 'Last 7 days',
            lastFifteenDays: 'Last 15 days',
            lastThirtyDays: 'Last 30 days',
            search: 'Search',
            initialDate: 'Initial date',
            initialHour: 'Start time',
            endDate: 'End date',
            endHour: 'End time',
            chatbots: 'Chatbots',
            channel: 'Channel',
            all: 'All',
            webchat: 'Webchat',
            dialogue: 'Dialogue',
            dialogueDescription: 'You can filter by who started the conversation, attendant or customer.',
            botDeleted: 'Bot deleted',
            active: 'Chatbot/Actives',
            receptive: 'Customer/Receptive',
            whatsapp: 'Whatsapp',
            messenger: 'Messenger',
            gbm: 'Google Business Messages',
            rcs: 'RCS',
            instagram: 'Instagram',
            telegram: 'Telegram',
            identifiers: 'Identifiers',
            filter: 'Filter',
            fill: 'Fill',
            filled: 'Filled',
            notFilled: 'Not filled out',
            transhipment: 'Transhipment',
            transhipmentDescription: 'This is a new feature, filters based on this field are not applied to dates before 03/19/2020.',
            indifferent: 'Indifferent',
            attended: 'Attended',
            notAttended: 'Not attended',
            unrealized: 'Unrealized',
            none: 'None',
            attendants: 'Attendants',
            departments: 'Departments',
            otherOptions: 'Other options',
            onlyConversations: 'Only conversations',
            allConversations: 'All',
            incompleteConversations: 'Incomplete',
            conversationFinished: 'Finished',
            satisfaction: 'Satisfaction',
            oneStar: '1 star',
            twoStar: '2 stars',
            threeStar: '3 stars',
            fourStar: '4 stars',
            fiveStar: '5 stars',
            tags: 'Tags',
            term: 'Term',
            inIdentifier: 'In the identifier',
            clear: 'Clear',
            createFilter: 'Create filter',
            title: 'Title',
            save: 'Save',
            messageError: 'Oops, there was an error.',
            listBy: 'List of ',
            savedFilter: 'Your filter has been saved!',
            of: 'of',
            selectAll: 'All'
          }
        },
        analytics: {
          quickview: {
            conversation: {
              noDataAvailable: 'No data available.'
            },
            knowledge: {
              noDataAvailable: 'No data available.'
            },
            rating: {
              noDataAvailable: 'No data available.'
            },
            usersConnected: {
              noDataAvailable: 'No data available.'
            }
          },
          attendants: {
            attendants: 'Attendants',
            satisfaction: 'Satisfaction',
            attendance: 'Attendances',
            onlineTime: 'Online time',
            reviewsAnswered: 'Reviews Answered',
            awayTime: 'Away time',
            tta: 'TAT - (Total attendance time)',
            tma: 'ATT - (Average attendance time)',
            noDataAvailable: 'No data available.',
            name: 'Name'
          },
          botAndAttendant: {
            attendants: 'Attendants',
            noDataAvailable: 'No data available.',
            chatbots: 'Chatbots'
          },
          bubble: {
            sunday: 'Sunday',
            hours: ' hours',
            attendance: ' attendances'
          },
          campaign: {
            utmSource: 'UTM Source',
            tip: 'TIP',
            utmMedium: 'UTM Medium',
            utmCampaign: 'UTM Campaign',
            utmTerm: 'UTM Term',
            utmContent: 'UTM Content',
            withCampaign: 'With campaign',
            withoutCampaign: 'Without campaign'
          },
          channel: {
            noDataAvailable: 'No data available.',
            total: 'Total',
            impressions: 'Impressions',
            impressionsDescription: 'In WebChat, it is the volume of times the chatbot appeared available for click/trigger.',
            attendance: 'Attendances',
            attendanceDescription: 'When a user (customer) activates the chatbot to start the conversation and a attendance is started.',
            messages: 'Messages',
            messagesDescription: 'Total volume of messages exchanged in all conversations (answers). Enter user (customer), chatbots and attendants (if any).',
            ttAttendance: 'TA Time',
            ttAttendanceDescription: 'It is the total service time of the channel, from the moment of activation until the end of the conversation.',
            tmAttendance: 'AT Time',
            tmAttendanceDescription: 'It is the average service time of the channel, considering the selected period.',
            totalTransshipments: 'Total transshipments',
            totalTransshipmentsDescription: 'Number of requests in which the chatbot requests assistance from an attendant, whether triggered by a flow rule or by the user (customer).',
            transshipmentsAttended: 'Transshipment attended',
            transshipmentsAttendedDescription: 'Number of calls where the chatbot passed the conversation to the attendant and he was able to answer.',
            unattendedTransshipments: 'Unattended transshipments',
            unattendedTransshipmentsDescription: 'Number of calls where the chatbot passed the conversation to the attendant and he was unable to answer.',
            unrealizedTransshipments: 'Unshipped transshipments',
            unrealizedTransshipmentsDescription: 'Number of calls where the chatbot was unable to transfer the conversation to an attendant (there were no attendants online at the time). The chatbot resumed the conversation.',
            ttWait: 'TW Time',
            ttWaitDescription: 'It is the total time that users (customers) remained on hold to be attended by attendants (when the service queue is enabled).',
            tmWait: 'AW Time',
            tmWaitDescription: 'It is the average time that users (customers) remained on hold to be attended by attendants (when the service queue is enabled).',
            satisfaction: 'Satisfaction',
            satisfactionDescription: 'It is the service satisfaction rate in general (a survey triggered at the end of the attendance). Scale from 0 to 5 and number of respondents in parentheses.',
            monetaryValueDescription: 'Check the total value generated by conversations during the period (very useful for those who sell products and services). To appear here, the value must be assigned to each conversation individually.',
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
            shortJan: 'Jan',
            shortFeb: 'Feb',
            shortMar: 'Mar',
            shortApr: 'Apr',
            shortMay: 'May',
            shortJun: 'Jun',
            shortJul: 'Jul',
            shortAug: 'Aug',
            shortSep: 'Sep',
            shortOct: 'Oct',
            shortNov: 'Nov',
            shortDec: 'Dec',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            shortSunday: 'Sun',
            shortMonday: 'Mon',
            shortTuesday: 'Tue',
            shortWednesday: 'Wed',
            shortThursday: 'Thu',
            shortFriday: 'Fri',
            shortSaturday: 'Sat',
            exportToSVG: 'Export to SVG',
            exportToPNG: 'Export to PNG',
            exportToCSV: 'Export to CSV',
            menu: 'Menu',
            selection: 'Select',
            selectionZoom: 'Select zoom',
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom out',
            pan: 'Mover',
            reset: 'Return to default'
          },
          city: {
            noDataAvailable: 'No data available.',
            others: 'Others'
          },
          country: {
            noDataAvailable: 'No data available.',
            others: 'Others'
          },
          department: {
            noDataAvailable: 'No data available.'
          },
          heat: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            hours: ' hours',
            attendance: ' attendances'
          },
          identifier: {
            selectIdentifier: 'Select an identifier',
            noDataAvailable: 'No data available.',
            others: 'Others',
            limitCards: 'Maximum quantity of 12 views. To create a new one, please delete an existing one.'
          },
          knowledgeAnswered: {
            noDataAvailable: 'No data available.',
            answered: 'Answered',
            unambiguous: 'Unambiguous',
            unanswered: 'Unanswered'
          },
          knowledgeResponses: {
            noDataAvailable: 'No data available.',
            others: 'Others'
          },
          pivot: {
            origin: 'Origin',
            total: 'Total'
          },
          platform: {
            noDataAvailable: 'No data available.',
            incognito: 'Incognito'
          },
          sankey: {
            noDataAvailable: 'No data available.',
            to: 'Para',
            from: 'De',
            amount: 'Amount'
          },
          state: {
            noDataAvailable: 'No data available.',
            others: 'Others'
          }
        },
        drawers: {
          broadcast: {
            template: {
              email: {
                save: 'Save',
                cancel: 'Cancel',
                messageEditor: 'Message Editor',
                personalizeEmail: 'You can customize your email using variables.',
                library: 'Library',
                messageErrorToCompile: 'Error compiling email!',
                templateSaved: 'Your template has been saved!',
                messageErrorConnection: 'Oops, there was a connection error!'
              },
              sms: {
                save: 'Save',
                cancel: 'Cancel',
                messageEditor: 'Message Editor',
                message: 'Message *',
                messageHas: 'Your message has',
                characters: 'characters.',
                discountCredits: 'Will be deducted',
                discountCreditsFinal: 'for each number.',
                overcomeTheCharacters: 'In cases where the SMS content exceeds 160 characters, more than one credit per 153 characters will be charged.',
                notSupportConcatenation: 'Some operators like Oi and Sercomtel do not support message concatenation, so SMS will be received separately.',
                templateSaved: 'Your template has been saved!',
                messageErrorConnection: 'Oops, there was a connection error!',
                messageNeedFilled: 'The name and message fields must be filled in!'
              },
              whatsapp: {
                save: 'Save',
                cancel: 'Cancel',
                messageEditor: 'Message Editor',
                message: 'Message *',
                messageHas: 'Your message has',
                characters: 'characters.',
                discountCredits: 'Two credits will be deducted for each number.',
                integrations: 'The integration between Globalbot and WhatsApp allows sending active messages considering the following guidelines:',
                activeMessages: 'Active Messages:',
                activeMessagesDescription: 'In this case the WhatsApp chatbot is used for notification and message to customers. And the origin of the attendance (first message) is by the company. If the customer responds to the message, a new chat session (or attendance) will be started.',
                dispatchesInLargeScale: 'Sending large scale:',
                dispatchesInLargeScaleDescription: 'It is important to pay attention to this type of use, as mass mailings (when related to promotions, disclosures, advertising and the like) are not allowed. Automated submissions to groups of customers, such as notifications and notices (within a context that the customer has chosen to receive the message, known as an opt-in), are acceptable.',
                modelSaved: 'Your template has been saved!',
                youCannotChange: 'You cannot change messages on official WhatsApp. Please contact the support team.',
                messageErrorConnection: 'Oops, there was a connection error!',
                messageNeedFilled: 'The name and message fields must be filled in!'
              },
              save: 'Save',
              cancel: 'Cancel',
              modelData: 'Template Data',
              name: 'Name *',
              tags: 'Tags',
              observations: 'Comments',
              integrationsId: 'ID for use in integrations: ',
              modelMessageRequired: 'To carry out a send, you must always use a Message Template already created.',
              newMessageModelData: 'Here you will inform the data of the new Message Template, which can be used to carry out Sends to the recipients you designate.',
              clientsWaba: 'For WABA (WhatsApp Business API) customers, notifications in HSM format are mandatory and must follow pre-approved formats.',
              whatsappTerm: 'WhatsApp Terms of Use:',
              whatsappTermDescription: ' It is very important to point out that, in order to use the integration between Globalbot and WhatsApp, the customer is aware of and accepts the WhatsApp Usage Policies, using the application so as not to violate them. If you do, the number may be blocked. ',
              whatsappUsagePolicies: 'WhatsApp Usage Policies',
              messageSavedModel: 'Your template has been saved!',
              messageErrorConnection: 'Oops, there was a connection error!',
              fieldNameRequired: 'The name and message fields must be filled in!'
            },
            dispatch: {
              save: 'Save',
              cancel: 'Cancel',
              dispatchData: 'Dispatch Data',
              draftData: 'Draft Data',
              newDispatch: 'New Dispatch |',
              createDispatch: 'Create Dispatch',
              dispatchName: 'Dispatch name *',
              observations: 'Comments',
              originAndInitialization: 'Origin and Initialization',
              indicateServer: 'Indicate which WhatsApp server you want to send (corresponds to your enabled number).',
              optional: 'Optional: Customize chatbot behavior if user replies to this message. The number refers to the point in the conversational flow that will be triggered after the response. Find out which point of the flow and its numbering in the Flow module. Leave the value 0 for the flow to run normally from the beginning.',
              whatsappServer: 'WhatsApp Server *',
              flowIndex: 'Flow index',
              message: 'Message *',
              replaceWith: 'Replace with',
              subjectAndSender: 'Subject and Sender',
              personalizeEmail: 'You can customize your email using variables. See the tips.',
              subjectEmail: 'The subject of your email has ',
              characters: ' characters. The number of characters displayed on mobile devices is 35 and on desktops 70 on average.',
              senderName: 'Sender name *',
              sender: 'Sender *',
              replyTo: 'Reply to *',
              read: 'Read',
              readDesc: 'Total number of recipients who actually viewed the message. This statistic may be approximate as some people do not enable viewing permission.',
              readDesc2: 'Percentage referring to total Deliveries.',
              recipients: 'Recipients',
              recipientsDesc: 'Total recipients selected for sending, considering the attributes and segmentations you chose.',
              dispatchTest: 'Dispatch Test',
              replaceVar: 'Replacement of variables',
              combineLists: 'You can combine lists and filters or even import a new list. ',
              selectList: 'Select an already saved mailing list or import it.',
              clickHere: 'Click here',
              downloadModelList: 'to download a list template.',
              importList: 'Import List',
              lists: 'Lists',
              filters: 'Filters',
              notSend: 'Do not send to...',
              includeCampaign: 'Include in a campaign',
              campaign: 'Campaign *',
              messageCampaignOne: 'A campaign is a collection of related submissions.',
              messageCampaignTwo: 'Campaigns provide a series of grouped reports and statistics for your submissions.',
              messageCampaignThree: 'Use campaigns to group your submissions and analyze the results over time. Examples: Weekly Campaign, January Shipments, etc.',
              integrationsId: 'ID for use in integrations: ',
              found: 'Found',
              repeated: 'Repeated',
              excluded: 'Withdrawn',
              range: 'Reach',
              var: 'List variables',
              deliveries: 'Deliveries',
              totalRecipients: 'Total recipients selected for sending, considering the attributes and targeting you previously chose.',
              sent: 'Sent',
              error: 'Not Delivered',
              errorDesc: 'Total number of recipients whose messages were not delivered, for reasons such as: non-existent WhatsApp number, user is in an open chat session, specific problem with the HSM Template, among others.',
              errorCodesWhatsApp: {
                '400': 'In Conversation',
                '403': 'In Conversation',
                '472': `User's phone number is part of an experiment`,
                '471': 'Restriction applied by META (Messages blocked or Flagged as spam)',
                '500': "Error on the broker's server, please contact Globalbot",
                '1001': 'Invalid Sender',
                '1002': 'Non-existent Number',
                '1003': 'Globalbot Credits',
                '1004': 'Problem with HSM Template',
                '1005': 'Problem with HSM Template',
                '1006': 'Problem with HSM Template',
                '1007': 'Problem with HSM Template',
                '1026': 'Undeliverable',
                '1008': 'Opt-out',
                '1010': 'Invalid URL',
                '1011': 'Invalid Media Size',
                '1012': 'Message sending failed because the phone number chose to leave this communication channel.',
                '1013': 'Non-existent Number',
                '2000': 'Problem with HSM Template',
                '4002': 'Invalid WhatsApp response',
                '4003': 'Message sending failed because the template did not match.',
                '4004': 'Message type is only supported by Meta hosted cloud API.',
                '4005': 'Message sending failed because the template is paused.',
                ///META Errors
                //'400':'Media download error - Failed to download media from sender.',
                '402': 'Company eligibility (payment issue) - Failed to send message due to one or more errors related to payment method.',
                '408': 'The message is not valid - Failed to send the message because it was pending for too long (for example, more than 1 day). This can happen when restarting the WhatsApp Business API client after more than a day of inactivity.',
                '410': 'Message expired - Message not sent within its TTL (time to live).',
                '429': 'Rate limit reached - number sent too many messages.',
                '430': 'Certificate not signed - Failed to send the message because a certificate-related error occurred.',
                '432': 'Certificate identification mismatch - Failed to send the message because a certificate-related error occurred.',
                '433': "Invalid certificate signing - Occurs when a message is sent but the company's client certificate is not properly signed with the corresponding identity keys.This indicates that the client probably re- registered with new identity keys, but did not complete the entire certificate creation flow.",
                '470': 'Re-engagement message - Failed to send the message because more than 24 hours have passed since the customer last responded to this number.',
                //'471':'Spam rate limit reached - Failed to send the message because there is a limit on how many can be sent from this phone number. It's possible that many previous messages were blocked or marked as spam.',
                //'472':'User's phone number is part of an experiment - Failed to send the message because the user's phone number is part of an',
                '480': "Possible user change - Failed to send the message due to a possible change in the user's phone number.",
                //'500':'Generic error - Failed to send message due to an unknown error.',
                '501': 'The message type is not currently supported. - Document messages with captions are currently not supported.',
                '1000': 'Generic error',
                //'1001':'Message too long - Message length is more than 4,096 characters.',
                //'1002':'Invalid recipient type - Valid recipient types:',
                //'1004':'Already existing resource - Possible UUID conflict for media load requests or already existing UUID assigned to other media.',
                //'1005':'Access denied -',
                //'1006':'Resource not found - File or resource not found.',
                //'1007':'Recipient blocked from receiving messages ( - The recipient is not whitelisted.',
                //'1008':'Required parameter missing - A required parameter is missing.',
                '1009': 'Invalid parameter value - The value entered for a parameter is not the correct type or there is another problem.',
                //'1010':'Non-required parameter - Contains a parameter that is not required.',
                //'1011':'The service is not ready -',
                //'1013':'The user is not valid -',
                '1014': 'Internal error',
                '1015': 'Excessive number of requests - Client rate limit has been reached.',
                '1016': 'System Overload - If the system receives heavy load, this error is returned to allow the system to recover.',
                '1017': 'Non-primary master - This error occurs when a master-level only request (such as set or get settings, import, export, request code, register, and so on) is sent to a node that is not a primary master. This may happen if the WhatsApp Business API client is not configured correctly or if there are internal errors.',
                '1018': 'Coreapp not primary - This error occurs when requests are sent to a Coreapp node that does not own the shard nor is it in the process of becoming the owner. You may see this error when we are doing shard failover in multiconnect configuration.',
                '1021': 'Incorrect user - This error occurs when a user sends a message to themselves.',
                '1022': "Webhooks URL not configured - This error will occur if A's webhooks format",
                '1023': 'Database error',
                '1024': 'Password change requested - Password needs to be changed.',
                '1025': 'Invalid request - The request is not valid.',
                //'1026':'Recipient unavailable - Message not delivered. See 'Why isn't my delivery rate 100%?',
                '1028': 'System notification of type - You sent a message to a WhatsApp user that may have been changed. A notification',
                '1031': "Sender's account is locked - Your account has been locked and will not be able to send messages due to an integrity policy violation.Consult",
                //'2000':'Template parameter count mismatch - The number of parameters provided does not match the expected quantity.',
                '2001': 'Missing template - The template is not active or does not exist for a language and locale.',
                '2002': 'Template fetch failed - Recipient failed to download template.',
                '2003': 'Missing template package - There are no templates for the given language and locale.',
                '2004': 'Template parameter too long - The parameter is too long.',
                '2005': 'Template hydrated text too long - The translated text is too long.',
                '2006': 'Template whitespace policy violated - Whitespace policy was violated.',
                '2007': 'Template format character policy violated - Format character policy was violated.',
                '2008': 'Unsupported model media format - The media format used is not supported.',
                '2009': 'Required template component missing - A required template component is not present.',
                '2010': 'Invalid template hydrated URL - The URL in the button component is not valid.',
                '2011': 'Invalid model phone number - The phone number in the button component is not valid.',
                '2012': 'Template parameter format mismatch - The parameter format does not match the created template.',
                '2013': 'Incompatible model buttons - The buttons are not accepted by the recipient.',
                '2014': 'The expected namespace is empty - This error occurs when the language pack for a message template has not yet been downloaded.',
                '2015': 'Invalid number of sections - The number of sections in the message request is below or above the allowed limit. Consult the',
                '2016': 'Invalid number of lines - The number of lines is not valid. For',
                '2017': "Character policy violated - The format's character policy was violated.",
                '2023': 'Invalid product number - The section object has no products or the total product count has exceeded the maximum number allowed.',
                '2024': 'Catalog ID not found - The catalog ID provided does not exist or does not belong to your WhatsApp Business (WABA) account.',
                '2025': 'Catalog ID not linked to API number - The catalog ID you provided is not linked to the phone number used to send messages.',
                '2026': 'Products not found - Some products provided in your request were not found in the catalogue.',
                '2027': 'No products found - No products were found in the provided catalogue.',
                '2028': 'List of all products with failed compliance',
                '2029': 'List of some products with compliance failure',
                '2030': 'List combining invalid and non-conforming products',
                '2036': 'Invalid header structure - This error is returned when the ',
                '2050': 'Missing compliance information',
                '2060': 'Template rejected - Template status is ',
                '2061': 'Model paused - Model status is',
                '2062': 'Template disabled - Template status is',
                '2064': 'Invalid Stream ID - The stream with specified ID does not exist, does not belong to your WhatsApp Business account, or is in an invalid state.',
                '2068': 'Stream blocked - The stream is in blocked state.',
                '2069': 'Limited flow - The flow is in the limited state, and 10 messages using this flow have already been sent in the last hour.',
                ///CLOUD API META ERROS https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes
                ///Authorization errors
                '0': 'AuthException - It was not possible to authenticate the user of the app.',
                '3': 'API Method - Indicates a problem involving resources or permissions.',
                '10': 'Permission denied - The permission was not granted or removed.',
                '190': 'The access token expired.',
                '200': 'Permissão da API - A permissão was not granted or removed.',
                '299': 'Permissão da API - A permissão was not granted or removed.',
                ///Limitation errors
                '4': 'The application limits the API call rate. - O app atingiu o limite da taxa de chamadas de API.',
                '80007': 'As soon as WhatsApp Business reaches the volume limit. - Upon WhatsApp Business atingiu or volume limit.',
                '130429': 'The Nuvem API message data transfer rate was failed. - The Nuvem API message data transfer rate was disabled.',
                '131048': 'Messages have not been sent due to a shipping limit that may be affected by this phone number. It is possible that many previous messages have been blocked or marked as spam. - You have not sent messages due to a shipping limit that may be affected by this phone number. It is possible that many previous messages have been blocked or marked as spam.',
                '131056': "Foram sent many messages from the sender's telephone number to the same recipient's telephone number in a short period. - Foram sent many messages from the sender's telephone number to the same recipient's telephone number in a short period.",
                '133016': 'I have registered or been excluded from false registration because there have been many attempts at this telephone number in a short period of time. - You register or are excluded from false registration because there are many attempts at this telephone number in a short period of time.',
                ///Integrity errors
                '368': 'Temporarily blocked due to policy violations - As a result, WhatsApp Business associated with the app was restricted or disabled for violating a platform policy.',
                '131031': 'Account blocked - The WhatsApp Business account associated with the app was restricted or disabled for violating a platform policy. So, it was not possible to verify the data included in the application in relation to the set of data in the WhatsApp Business account (for example, the pin of two stages included in the application is incorrect).',
                ///Other errors
                '1': 'There is an invalid request or a possible server error. - There is an invalid request or a possible server error.',
                '2': 'There is a temporary error due to inactivity or overload. - There is a temporary error due to inactivity or overload.',
                '33': 'The business telephone number was excluded. - The business telephone number was excluded.',
                '100': 'The order included one or more parameters that were incompatible or written incorrectly. - The order included one or more parameters that were incompatible or written incorrectly.',
                '130472': 'The message was not sent as part of an experiment.',
                '131000': 'The message has failed to be sent due to an unknown error. - Failed to send a message due to an unknown error.',
                '131005': 'The permission was not granted or removed. - Permissão was not granted or removed.',
                '131008': 'An unrequested mandatory parameter is missing. - An unrequested mandatory parameter is missing.',
                '131009': 'One or more parameter values ​​are invalid. - Some or more parameter values ​​are invalid.',
                '131016': 'Our service is temporarily unavailable. - A service is temporarily unavailable.',
                '131021': 'The telephone number of the recipient and the sender is the same. - The telephone number of the recipient and the sender is the same.',
                '131026': "The message could not be delivered. The reasons may be as follows: - The recipient's phone number is not registered with WhatsApp. / There was an attempt to send an authentication template to a WhatsApp user with the country calling code +91 (India). Currently, it is not possible to send authentication templates to WhatsApp users in India. / The recipient has not accepted the new Terms of Service and the new Privacy Policy. / The recipient is using an old version of WhatsApp / The message was not delivered to create a high-quality user experience.",
                '131042': 'There is an error related to the form of payment. - Ocorreu an error related to the form of payment.',
                '131045': 'You may have failed to send a message due to a phone number registration error. - You may notice an error in sending a message due to a phone number registration error.',
                '131047': "More than 24 hours will pass since the recipient last responded to the sender's number. - More than 24 hours will pass since the recipient last responded to the sender's number.",
                '131051': 'The message type is not compatible. - The type of message is not compatible.',
                '131052': 'It was not possible to download the message sent to the user. - It was not possible to download the information sent to the user.',
                '131053': 'It was not possible to load the used media in the message. - It was not possible to load the used media in the messages.',
                '131057': 'The commercial account is in maintenance mode. - The commercial account is in maintenance mode.',
                '132000': 'The number of variable parameter values ​​included in the request does not correspond to the number of variable parameters defined in the model. - The number of variable parameter values ​​included in the request does not correspond to the number of variable parameters defined in the model.',
                '132001': 'The model does not exist or the language specified or was not approved. - The model does not exist, the language specified or it has not been approved.',
                '132005': 'The translated text is too long. - The translated text is too long.',
                '132007': 'The content of the model violates a WhatsApp policy. - The content of the model violates a WhatsApp policy.',
                '132012': 'The parameter values ​​vary due to incorrect formats. - Parameter values ​​vary due to incorrect formats.',
                '132015': 'The model was paused by - The model was paused by ',
                '132016': 'The model was paused many times - The model was paused many times ',
                '132068': 'The stream is not in a blocked state. - Or fluxo is not in a blocked state.',
                '132069': 'The flow is not limited, and 10 messages using this flow have been sent in the last hour. - The flow is not limited, and 10 messages using this flow have been sent in the last hour.',
                '133000': 'A previous attempt to cancel the Falhou registration. - A previous attempt to cancel the false registration.',
                '133004': 'The server is temporarily unavailable. - The server is temporarily unavailable.',
                '133005': 'The confirmation PIN in both stages is incorrect. - The confirmation PIN in both stages is incorrect.',
                '133006': 'The phone number needs to be verified before registration. - The telephone number must be verified before registration.',
                '133008': 'Operate several attempts of confirmation PIN in two stages for this phone number. - Several attempts will be made to confirm the PIN in two stages for this phone number.',
                '133009': 'The confirmation PIN in both stages was inserted very quickly. - The confirmation PIN in both stages was inserted very quickly.',
                '133010': 'The phone number is not registered on the WhatsApp Business Platform. - The phone number is not registered on the WhatsApp Business Platform.',
                '133015': 'The phone number you are trying to register was recently excluded, and the exclusion has not yet been concluded. - The phone number you are trying to register was recently excluded, and the exclusion has not yet been concluded.',
                '135000': 'You have failed to send a message due to an error in your request parameters. - Failure to send a message due to an error in your request parameters.',
              },
              internalError: 'Internal Error',
              sentDesc: 'Total number of recipients who had the message delivered by Globalbot to the WhatsApp server.',
              notSent: 'Not sent',
              totalEmails: 'Total emails actually sent, excluding invalid email addresses discarded by the email trigger service.',
              handedOut: 'Handed out',
              handedOutDesc: 'Total number of recipients who had the message delivered by WhatsApp to customer devices.',
              handedOutDesc2: 'Percentage referring to the total number sent.',
              receivedTheEmail: 'Total recipients who received the email, discounting those that bounced. The percentage is about the number of emails sent.',
              softBounce: 'Soft Bounce',
              totalEmailsNotHandedTemporary: 'Total emails that were not delivered for temporary reasons such as: recipient\'s inbox full or recipient\'s offline server. The email provider will try to resend the message during the next 72h. The percentage is about the number of emails sent.',
              hardBounce: 'Hard Bounce',
              totalEmailsNotHandedPermanent: 'Total e-mails that were not delivered for permanent reasons, such as: non-existent e-mails or blocking by the recipient\'s e-mail server, considering it to be spam. The percentage is about the number of emails sent.',
              engagement: 'Engagement',
              openings: 'Openings',
              totalUniqueEmailOpenings: 'Total unique email opens. The percentage is about the number of emails delivered.',
              clicks: 'Clicks',
              totalClickUnique: 'Total unique clicks on email links. The percentage is about the number of emails opened.',
              markedSpam: 'Marked as spam',
              totalMarkedSpam: 'Total users who marked this email as Spam. The percentage is about the number of emails delivered.',
              unsubscriptions: 'Unsubscribes',
              totalUnsubscriptions: 'Total users who unsubscribed from the email sent. The percentage is about the number of emails delivered.',
              totalRecipientsSent: 'Total recipients with a message actually sent, discounting recipients discarded for containing invalid or non-existent numbers.',
              totalRecipientsSms: 'Total de destinatários que tiveram a mensagem entregue pelas operadoras de SMS.',
              totalRecipientsNotSend: 'Total recipients who could not deliver the message for permanent reasons, such as: containing an invalid or non-existent number.',
              waiting: 'Waiting',
              numberOfRecipientsWaiting: 'Number of recipients still waiting for the submission process to complete.',
              handedOutServer: 'Delivered (Server)',
              totalRecipientsFromGlobalbotToWhatsapp: 'Total recipients who had the message delivered by Globalbot to the WhatsApp server.',
              handedOutDevice: 'Delivered (Device)',
              totalRecipientsFromWhatsappToClients: 'Total recipients who had the message delivered by WhatsApp to customer devices.',
              totalRecipientsNotHandedTemporary: 'Total recipients whose messages were not delivered for temporary reasons, such as: recipient is in open chat session. The system will try to resend the message during the next 24h. The percentage is about the number of recipients sent.',
              preview: 'Preview',
              totalRecipientsViewedMessage: 'Total recipients who actually viewed the message.',
              important: 'Important:',
              messageImportantOne: ' You must import a list in .CSV file format, remembering that for sending SMS and',
              messageImportantTwo: 'WhatsApp field "phone" (phone) is required',
              messageImportantThree: 'For email submissions the "email" field is required.',
              messageImportantFour: 'For WhatsApp submissions the phone number must have the user\'s ID, for example: 554899999999 (Brazil)',
              totalFound: 'We found a total of ',
              items: ' items.',
              import: 'Import',
              modelSelectedNotHaveChannel: 'The selected template does not have a channel, please create a new template again.',
              modelSelectedNotMessageValid: 'The selected model does not have a valid message, please check the message of this model.',
              notIncludeDomain: 'Do not include the domain in the "sender" and "reply to" fields, these fields are selected through the authorized domains!',
              weNeedDomain: 'Unfortunately we need an authorized domain to continue, you can authenticate one in preferences.',
              fieldsNeedFilledOne: 'The field name, sender, reply to, and lists or filters must be filled in!',
              selectServer: 'Please select a server, if not, request one with Globalbot!',
              fieldsNeedFilledTwo: 'The name field and lists or filters need to be filled in!',
              dispatchSaved: 'Your submission has been saved!',
              draftSaved: 'Your draft has been saved!',
              messageErrorConnection: 'Oops, there was a connection error!',
              columnEmailRequired: 'The "email" columns is required.',
              columnPhoneRequired: 'The "phone" columns is required.',
              messageError: 'Oops, there was an error.',
              listSaved: 'Your list has been saved!',
              exportData: 'Do you really want to export all your records?'
            },
            list: {
              title: 'Title *',
              toggleLabel: 'Change label',
              filter: 'Filter *',
              save: 'Save',
              messageError: 'Oops, some error occurred.',
              listSaved: 'Your list has been saved!',
              messageErrorConnection: 'Oops, there was a connection error',
              fieldsRequired: 'Campaign, subject and source email fields need to be filled in!'
            }
          },
          conversation: {
            save: 'Save',
            cancel: 'Cancel',
            conversationData: 'Conversation Data',
            protocol: 'Protocol',
            identifierId: 'Identifier',
            attendants: 'Attendants',
            tags: 'Tags',
            device: 'Device',
            dispatchEmail: 'Lead email sent',
            dispatchEmailToClient: 'Email sent to customer',
            emailViewedByClient: 'Email viewed by the customer',
            dateAndHour: 'Date and Hour',
            channel: 'Channel',
            satisfaction: 'Satisfaction',
            punctuation: 'Punctuation',
            search: 'Search',
            location: 'Location',
            origin: 'Origin',
            campaigns: 'Campaigns',
            messageSent: 'Message sent:',
            comments: 'Comments',
            messageNotFound: 'Unfortunately we didn\'t find this conversation.',
            messageTryAgain: 'Oops, we had a problem retrieving this conversation, please try again. If the error persists, please contact us.'
          },
          integration: {
            save: 'Save',
            cancel: 'Cancel',
            integrationDetails: 'Integration datails',
            activeIntegration: 'Active integration',
            identifier: 'Identifier',
            hash: 'Hash',
            token: 'Token',
            hasToken: 'Has API token',
            webhook: 'Webhook',
            host: 'Host',
            key: 'Key',
            haveRestriction: 'Do you have restrictions?',
            contactPurpose: 'Put below the values for the "Contact Purpose" button that you don\'t want to go to VistaSoft',
            add: 'Add',
            customIdFields: 'Define below the ids of the custom fields and their identifiers, example %NAME%.',
            idField: 'Field id ',
            websiteAddress: 'Website address',
            userProvidedByKSI: 'User provided by KSI',
            passwordProvidedByKSI: 'Password provided by KSI',
            salesFlow: 'Sales flow',
            salesFlowValue: 'What is the identifier that has the sale value in the flow?',
            salesValue: 'Sale identifier value',
            createIdSales: 'Create a default sale property id and put it here',
            rentalFlow: 'Rent flow',
            rentalFlowValue: 'What is the identifier that has the rental value in the flow?',
            rentalValue: 'Rental identifier value',
            createIdRental: 'Create a default rental property id and enter it here',
            releasesFlow: 'Posting flow',
            releasesFlowValue: 'What identifier has the launch value in the stream?',
            releasesValue: 'Posting identifier value',
            createIdSalesInReleases: 'Create a default sale property id and place release',
            endpoint: 'Endpoint',
            enterprises: 'Enterprise',
            sendToAttendant: 'Send to attendant',
            includeInternalMessages: 'Include internal messages',
            enterpriseId: 'Enterprise ID',
            subdomain: 'Subdomain',
            clientSecret: 'Client_Secret',
            clientId: 'Client_Id',
            code: 'Code',
            user: 'User',
            password: 'Password',
            project: 'Project',
            problemType: 'Problem type',
            customField: 'Custom Field',
            customFieldValue: 'Custom Field Value',
            title: 'Title (optional)',
            sendOnlyLivechat: 'Send conversations from live chat only',
            loadLink: 'Cargo link',
            savedPreferences: 'Preference saved successfully',
            responsible: 'Responsible(Id)',
            createdBy: 'Created by(Id)',
            businessPhaseId: 'Business phase(Id)',
            salerCode: 'Saler code',
            methodologyCode: 'Methodology Code',
            salesChannelCode: 'Sales Channel Code',
            baseURL: 'URL base. ex: https://your-development-store.myshopify.com'
          },
          knowledge: {
            close: 'Close',
            cancel: 'Cancel',
            answered: 'Answered',
            unambiguous: 'Unambiguous',
            unanswered: 'Unanswered',
            channel: 'Channel',
            date: 'Data',
            question: 'Question',
            response: 'Response',
            testEnvironment: 'Test Environment',
            mode: 'Mode',
            test: 'Test',
            public: 'Public'
          },
          liveAttendants: {
            close: 'Close',
            attendants: 'Attendants',
            avatar: 'Avatar',
            attendance: 'Active conversations',
            name: 'Name',
            departments: 'Departments',
            role: 'Role',
            away: 'Away',
            clear: 'Clear',
            offline: 'Offline',
            rooms: 'Rooms',
            actions: 'Actions'
          },
          connectedUsers: {
            close: 'Close',
            users: 'Connected users',
            avatar: 'Avatar',
            attendance: 'Active conversations',
            name: 'Name',
            departments: 'Departments',
            role: 'Role',
            away: 'Away',
            clear: 'Clear',
            offline: 'Offline',
            rooms: 'Rooms',
            actions: 'Actions'
          },
          liveConfig: {
            save: 'Save',
            cancel: 'Cancel',
            settings: 'Settings',
            soundNewAttendance: 'New attendance sound',
            messageSoundReceived: 'Incoming message sound',
            listOrdering: 'List ordering',
            ascendingArrivalDate: 'Ascending arrival date',
            descendingArrivalDate: 'Descending arrival date',
            lastMessageCrescent: 'Last ascending message',
            lastMessageDecreasing: 'Last descending message',
            crescentGroup: 'By crescent group',
            decreasingGroup: 'By decrescent group',
            amountMessageCrescent: 'Increasing amount of message',
            amountMessageDecreasing: 'Descending message quantity',
            activeTimer: 'Enable Stopwatch',
            totalTimeOfAttendant: 'Total attendance time',
            lastClientInteraction: 'Last customer interaction counter',
            exhibitionAlertTime: 'Alert notice limit',
            minutes: 'minutes'
          },
          notifications: {
            notifications: 'Notification Center',
            noNotifications: 'There are no notifications for you at this time.'
          }
        },
        dashboard: {
          connectedUsers: 'Users Connected',
          connectedUsersTip: 'Check the users who are connected to the Globalbot platform at this moment.'
        },
        editable: {
          carousel: {
            idealResolution: 'Ideal resolution',
            librarySearch: 'Search the library',
            key: 'Key',
            value: 'Value',
            jump: 'Jump',
            title: 'Title',
            description: 'Description',
            select: 'Select',
            image: 'Image',
            buttons: 'Buttons'
          }
        },
        emailBuilder: {
          add: {
            campaigns: 'Messaging ',
            addEmail: ' Add e-mail',
            subject: 'Subject *',
            sender: 'Sender *',
            replyTo: 'Reply to',
            tags: 'Tags',
            save: 'Save',
            attendanceRealized: 'Attendance Realized',
            attendanceProtocol: 'Attendance Protocol',
            historic: 'Historic',
            templateSaved: 'Your template has been saved!',
            messageErrorConnection: 'Oops, there was a connection error!',
            fieldsRequired: 'The fields campaign, subject and email of origin must be filled in!'
          },
          emailBuilder: {
            messageEmailSaved: 'Saved email messages',
            subject: 'Subject: ',
            addNewTemplate: 'Add new template'
          }
        },
        lists: {
          push: {
            insertList: 'Insert in the list',
            list: 'List',
            cancel: 'Cancel',
            insert: 'Insert',
            updatedList: 'Updated list!',
            failedUpdateList: 'Failed to update list!'
          }
        },
        live: {
          container: {
            message: {
              cards: {
                startConversation: 'Start Conversation',
                insertList: 'Insert in the list'
              },
              media: {
                speechToText: 'Speech to Text (audio to text transcription)',
                notSupport: 'Your browser is not supported, please use Chrome.',
                notRegistered: 'Not registered',
                openOriginalImage: 'Open original image'
              },
              yesterday: 'Yesterday',
              today: 'Today',
              userNotFound: 'User not found',
              status: {
                noSubmitted: 'Unsent message (click to try again)',
                sending: 'Sendind message',
                sended: 'Message sent',
                delivered: 'Message delivered',
                read: 'Message read'
              },
              networkRequired: 'You must be logged in to send the message again.'
            },
            conversation: {
              identifierId: 'Identifier',
              protocol: 'Protocol',
              tags: 'Tags',
              changeName: 'Change Name',
              device: 'Device',
              dispatchEmailLead: 'Lead email sent',
              dispatchEmailClient: 'Email sent to customer',
              emailViewedClient: 'Email viewed by the customer',
              dateAndTime: 'Date and time',
              channel: 'Channel',
              satisfaction: 'Satisfaction',
              punctuation: 'Punctuation',
              search: 'Search',
              location: 'Location',
              origin: 'Origin'
            },
            knowledge: {
              reply: 'Answer',
              copyReply: 'Copy answer',
              messageErrorKnowledge: 'Error querying knowledge bases',
              messageErrorConsult: 'Error querying questions.'
            }
          },
          footer: {
            actions: {
              annex: {
                sendImage: 'Upload image! (5 MB max size)',
                sendVideo: 'Upload video! (16 MB max size)',
                sendFile: 'Send file! (100 MB max size)',
                selectLibrary: 'Select from the library!',
                library: 'Library',
                preview: 'Preview',
                addLegend: 'Add legend',
                cancel: 'Cancel',
                send: 'Send',
                file: 'File',
                avatar: 'Avatar',
                maxFileSize: 'The maximum file size is 20MB.',
                errorProcessingDoc: 'Error processing document.',
                fileFormatNotAccepted: 'File format not supported!'
              },
              audio: {
                recordAudio: 'Record audio (maximum 2 minutes)',
                cancel: 'Cancel',
                send: 'Send',
                preview: 'Preview',
                addLegend: 'Add legend',
                sendText: 'Send text',
                sendAudio: 'Send audio',
                errorProcessingAudio: 'Error processing audio.',
                accessMicrophoneRequired: 'For the live chat to work fully, it is necessary to allow access to the microphone.',
                transcribe: 'Audio transcription'
              },
              quickResponse: {
                quickResponse: 'Quick response',
                add: 'Add',
                close: 'Close',
                unshare: 'Unshare',
                reply: 'Reply',
                share: 'Share',
                exclude: 'Delete',
                createQuickResponse: 'Add quick response',
                title: 'Title',
                message: 'Message',
                cancel: 'Cancel',
                errorConsultQuickResponse: 'Error looking up quick response.',
                errorRegisterQuickResponse: 'Error registering quick response.',
                errorExcludeQuickResponse: 'Error exclude quick response.',
                errorShareQuickResponse: 'Error share quick response.'
              }
            }
          },
          utils: {
            attendants: {
              attendant: 'Attendant',
              attendants: 'Attendants',
              avatar: 'Avatar',
              name: 'Name',
              role: 'Role',
              select: 'Select',
              actions: 'Actions'
            },
            departments: {
              department: 'Department',
              attendants: 'Attendants'
            },
            roomOptions: {
              endAttendance: 'Finish attendance',
              archiveAttendance: 'Archive attendance',
              reactivateNotifications: 'Reactivate notifications',
              muteNotifications: 'Silence notifications',
              unpinAttendance: 'Unpin attendance',
              fixedAttendance: 'Fix attedance',
              transferAttendance: 'Transfer attendance',
              transferAttendanceSelected: 'Transfer selected',
              endAttendanceSelected: 'Finish selected',
              transferRestrictedAttendanceSelected: 'Transfer selected (only for Manager and Administrator)',
              archiveAttendanceSelected: 'Archive selected'
            }
          },
          createGroup: {
            newGroup: 'New group',
            newConversation: 'New conversation'
          },
          createHsm: {
            limitCharactersExceeded: 'Character limit exceeded!',
            credits: 'Credits',
            creditsDescription: 'To send messages to customers who are inactive on WhatsApp (24 hours after the last message sent by the customer) it is necessary to use a Message Template (HSM) pre-approved by WhatsApp. This shipment consumes 1 platform credit.',
            creditsInsufficient: 'Unfortunately you don\'t have enough credits.',
            attention: 'Attention:',
            attentionDescription: ' After 24 hours of customer inactivity, the company can only send a standardized and pre-approved message by WhatsApp, known as HSM. Each HSM message consumes 01 credit on the platform, as they can be charged by WhatsApp.',
            requestCredits: 'Request more credits',
            whoToSendMessage: 'Who do you want to send the message to?',
            originNumber: 'Origin number *',
            nameClient: 'Customer\'s Whatsapp Name *',
            country: 'Country *',
            nameClientWhatsapp: 'Client number *',
            attentionNinthNumber: ' Enter the customer\'s mobile number with a 2-digit area code, for example: (XX) XXXXX-XXXX. Messages will only be sent to customers who have an active WhatsApp account.\n',
            messageYouCanNot: 'Oops! You will not be able to send message to',
            messageActiveConversationWith: 'because he is already in an ACTIVE conversation with ',
            messageActiveConversationAttendants: 'because he is already in an ACTIVE conversation with another server.',
            messageConversationWithYou: 'is already in conversation with you! Send messages through the chat room.',
            goToRoom: 'Go to room',
            messageTemplate: 'Message Template (HSM)',
            notAuthorized: 'Oops! You will not be able to send a message to this person as he/she has not authorized the receipt of messages (Opt-In) according to WhatsApp policies.',
            messageUserOptIn: 'For your company to send a message to the customer (while outside the chat session), this customer must necessarily be an OPT-IN user (that is, he has given consent to receive messages). Learn more about Opt-In.',
            messageSent: 'Message sent!',
            return: 'Return',
            advance: 'Advance',
            missingMessage: 'Missing message',
            notPossibleStartConversation: 'It was not possible to start the chat as the user is already chatting with the chatbot.',
            numberNotValid: 'Oops! The number entered is not a valid WhatsApp account! Check the number and try again. Remember, do not inform the DDI',
            nameNotValid: 'Please enter the customer\'s name.',
            requiredMessage: 'Oops! You need to fill in the message to be sent!'
          },
          pushGroup: {
            add: 'Add',
            attendantAdded: 'Added attendant!',
            alreadyInGroup: 'This attendant is already in the group!'
          },
          resendHsm: {
            limitCharactersExceeded: 'Character limit exceeded!',
            credits: 'Credits',
            creditsDescription: 'To send messages to customers who are inactive on WhatsApp (24 hours after the last message sent by the customer) it is necessary to use a Message Template (HSM) pre-approved by WhatsApp. This shipment consumes 1 platform credit.',
            creditsInsufficient: 'Unfortunately you don\'t have enough credits.',
            attention: 'Attention:',
            attentionDescription: 'After 24 hours of customer inactivity, the company can only send a standardized and pre-approved message by WhatsApp, known as HSM. Each HSM message consumes 01 credit on the platform, as they can be charged by WhatsApp.',
            requestCredits: 'Request more credits',
            messageTemplate: 'Message Template (HSM)',
            send: 'Send',
            impossibleRecover: 'Unable to retrieve clientId'
          }
        },
        micro: {
          cdt: {
            yesterday: 'Yesterday',
            today: 'Today'
          },
          cdtWithHours: {
            yesterday: 'Yesterday',
            today: 'Today',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday'
          }
        },
        modals: {
          feedback: {
            newFunctions: 'Help us develop new features for the dashboard!',
            help: 'Let\'s help improve our dashboard?',
            info: 'This feedback is for improvement only, if you want to report a technical issue please contact support.',
            nowYouCan: 'Now you can help improve our platform!',
            feedbackWillBeApplied: 'This is the page where feedback will be applied.',
            whatCanBeImproved: 'Tell us what can be improved on this page?',
            example: 'Ex: This page could have an information button.',
            send: 'Send',
            feedbackSentError: 'There was an error sending the feedback',
            feedbackSentSuccessfully: 'Feedback sent successfully!',
            requireFields: 'You need to fill in the fields.',
            contactSupport: 'An error has occurred, please contact support',
            feedbackExcludeError: 'There was an error deleting feedback!',
            feedbackExcludeSuccessfully: 'Feedback successfully deleted!'
          },
          image: {
            image: 'Image'
          },
          recharge: {
            requestCredits: 'Request Credits',
            requestCreditsDescription: 'You can request additional credits for use in the Messaging. Please enter the details below and one of our specialists will contact you.',
            type: 'Type *',
            value: 'Amount *',
            cancel: 'Cancel',
            request: 'Request',
            messageFailedRequestCredits: 'Oops, failed request credits.',
            creditsRequested: 'Credits requested',
            fieldsRequired: 'You need to fill in the fields.'
          }
        },
        tags: {
          removeTag: 'Remove tag',
          addTags: 'Add tag',
          labelTag: 'Search tag',
          tagAlreadyExistsInConversation: 'This tag already exists in the conversation.',
          categoryNotFound: 'Category not found, check the mini Tags component.'
        },
        exporter: {
          mountingReport: 'Assembling report...',
          exportToCsv: 'Export to CSV',
          type: 'Type',
          answered: 'Answered',
          response: 'Response',
          disambiguated: 'Disambiguated',
          notResponse: 'Unanswered',
          active: 'Active',
          inactive: 'Inactive',
          reportGeneratedSuccessfully: 'Report generated successfully.',
          messageFailedReport: 'Oops, reporting failed, try again.'
        },
        loading: {
          connectingToServer: 'Connecting to server...',
          assemblingTheInformation: 'Assembling the information...'
        },
        zendesk: {
          title: 'Zendesk',
          brands: 'Brands',
          forms: 'Forms',
          fields: 'Tags',
          group: 'Groups',
        }
      },
      layouts: {
        default: {
          messageErrorInfo: 'Oops, some error happened while saving the information.',
          tolerancePeriod: 'Tolerance Period',
          finishedJourneyDescription: 'Your workday is over, but you have entered the grace period.',
          timeLeft: 'Finish your work in the remaining time of',
          disconnectedFromThePlatform: 'After the end of the grace period you will be disconnected from the platform.',
          iKnow: 'I know',
          finishedJourney: 'Finished Journey',
          workingEnded: 'Your working day has ended.',
          freeTime: 'Enjoy your free time!',
          seeYouLater: 'See you later',
          lostConnection: 'Connection Lost'
        }
      },
      pages: {
        actions: {
          create: {
            addAction: 'Add action',
            title: 'Title',
            description: 'Description',
            request: 'Request',
            getAndPost: 'GET, POST',
            newAction: 'New action',
            newActionDescription: 'New action description'
          },
          list: {
            actions: 'Actions',
            addAction: 'Add action'
          }
        },
        advanced: {
          logs: {
            list: {
              addLog: 'Add log',
              version: 'Version',
              change: 'Change',
              visibility: 'Visibility',
              description: 'Description',
              cancel: 'Cancel',
              add: 'Add',
              update: 'Update',
              removedColumn: 'Removed category column for unusability',
              deprecateModal: 'Deprecated observation mode.',
              updateVisualsAndProgrammatic: 'Visual and programmatic updates.'
            }
          },
          conversation: {
            conversationDetails: 'Conversation details',
            search: 'Search',
            account: 'Account',
            attendants: 'Attendants',
            device: 'Device',
            desktop: 'Desktop',
            mobile: 'Mobile',
            chrome: 'Chrome',
            safari: 'Safari',
            mozilla: 'Mozilla Firefox',
            edge: 'Edge',
            explorer: 'Internet Explorer',
            whatsapp: 'Whatsapp',
            facebook: 'Facebook Messenger',
            dispatchEmail: 'Lead email sent',
            dispatchEmailToClient: 'Email sent to customer',
            viewedEmailByClient: 'Email viewed by the customer',
            dateAndHour: 'Date and time',
            channel: 'Channel',
            origin: 'Origin',
            messageNotFoundConversation: 'Unfortunately we didn\'t find this conversation'
          },
          edit: {
            delPayment: 'Delete payment',
            paid: 'Paid',
            consumption: 'Consumption',
            status: 'Status',
            editAccount: 'Edit account',
            clientsList: 'Customer list',
            editAccountTwo: 'Edit account - ',
            credits: 'Credits',
            recurrentCredits: 'Recurring messaging franchise',
            sharedFranchise: 'Shared franchise',
            newFranchise: 'New model',
            otherChannels: 'Other channels',
            shared: 'Shared',
            add: 'Add',
            del: 'Delete',
            fa: '2FA',
            email: 'E-mail',
            active: 'Active',
            inactive: 'Inactive',
            phone: 'Phone',
            security: 'Enabled modules',
            realTime: 'Real time',
            statistics: 'Statistics',
            people: 'People',
            conversation: 'Conversations',
            interface: 'Interface',
            flow: 'Flow',
            intelligence: 'Intelligence',
            campaigns: 'Campaigns',
            liveChat: 'Live Chat',
            library: 'Library',
            preferences: 'Preferences',
            limiter: 'Users  - Limiter ',
            decraseLimit: 'Lower limit',
            increaseLimit: 'Increase limit',
            createMaster: 'Create master',
            accountDetails: 'Account details',
            accountName: 'Account name',
            addPermissions: 'Add permissions',
            addBriefing: 'Add briefing',
            companyName: 'Company name',
            fantasyName: 'Fantasy name',
            cnpj: 'CNPJ',
            address: 'Address',
            zipCode: 'Zip code',
            street: 'Street',
            edit: 'Edit',
            contacts: 'Contacts',
            plan: 'Plan and Contract',
            uploadContractLabel: 'Contract',
            contractValidityLabel: 'Contract validity',
            contractDateLabel: 'Contract date',
            seeDocument: 'See document',
            successUpdate: 'Client updated!',
            errorUpdate: 'Error updating client!',
            type: 'Type',
            characteristics: 'Franchise and channels',
            numberAttendances: 'Whatsapp - Monthly deductible',
            otherChannelAttendances: 'Other channels - Monthly allowance',
            whatsAppAttendances: 'Whatsapp - Monthly deductible',
            emailAttendances: 'E-mail - Monthly deductible',
            smsAttendances: 'SMS - Monthly deductible',
            webchat: 'Webchat',
            facebook: 'Facebook',
            whatsapp: 'Whatsapp',
            whatsappFranchise: 'Whatsapp Franchise',
            sms: 'SMS',
            integration: 'Integration',
            observations: 'Comments',
            none: 'None',
            simple: 'Simple',
            intermediate: 'Intermediary',
            advanced: 'Advanced',
            payments: 'Payments',
            form: 'Form',
            setup: 'Setup',
            description: 'Description',
            valueReal: 'Value: R$ ',
            date: 'Date',
            monthlyPayment: 'Monthly payment',
            cronogram: 'Schedule',
            planning: 'Planning',
            initialDate: 'Initial date',
            implementation: 'Implementation',
            endDate: 'End date',
            observation: 'Observation',
            customerSuccess: 'Customer Success',
            chatbots: 'Chatbots',
            online: 'Online',
            offline: 'Offline',
            versioning: 'Versioning',
            old: 'OLD',
            stable: 'STABLE',
            beta: 'BETA',
            alpha: 'ALPHA',
            editAddress: 'Edit address',
            number: 'Number',
            district: 'Neighborhood',
            city: 'City',
            state: 'State',
            cancel: 'Cancel',
            save: 'Save',
            addContact: 'Add contact',
            delContact: 'Delete contact',
            editContact: 'Edit contact',
            role: 'Role',
            name: 'Name',
            projectManager: 'Project manager',
            title: 'Title',
            addImplementationCronogram: 'Add implementation schedule',
            addCronogramByCs: 'Add CS schedule',
            addCronogramByPlanning: 'Add planning schedule',
            addRateBySetup: 'Adicionar taxa de SETUP',
            value: 'Valor',
            values: 'Values',
            ongoing: 'OnGoing',
            onboarding: 'OnBoarding',
            csStage: 'CS Stage',
            currentStatus: 'Current Status',
            accountStatusOnline: 'Online',
            accountStatusPartialOnline: 'Partial online',
            accountStatusPaused: 'Paused',
            accountStatusChurn: 'Churn',
            flag: 'Flag',
            addRateMonthly: 'Add monthly fee',
            newUser: 'Add user',
            password: 'Password',
            addCredits: 'Add credits',
            veryLow: 'Very low',
            low: 'Low',
            medium: 'Medium',
            high: 'High',
            veryHigh: 'Very high',
            important: 'IMPORTANT',
            starter: 'G-Starter',
            professional: 'G-Profissional',
            enterprise: 'G-Enterprise',
            gAdvanced: 'G-Advanced',
            orion: 'G-Orion',
            planLite: 'Lite',
            planStarter: 'Starter',
            planProfessional: 'Professional',
            planAdvanced: 'Advanced',
            planEnterprise: 'Enterprise',
            planGlobal: 'Global',
            planLite22: 'LITE-22',
            planStarter22: 'STARTER-22',
            planProfessional22: 'PROFESSIONAL-22',
            planAdvanced22: 'ADVANCED-22',
            planEnterprise22: 'ENTERPRISE-22',
            planLite23: 'LITE-23',
            planStarter23: 'STARTER-23',
            planProfessional23: 'PROFESSIONAL-23',
            planEnterprise23: 'ENTERPRISE-23',
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
            genAi: 'Generative AI',
            instagram: 'Instagram',
          },
          list: {
            exportAccounts: 'Export Accounts',
            cs: 'CS Responsible',
            csStage: 'CS Stage',
            accountStatus: 'Account status',
            plan: 'Plan',
            listClients: 'Customer list',
            createNewAccount: 'Create new account',
            wantToCreateNewAccount: 'Want to create a new account? Click here :)',
            version: 'Version',
            stable: 'Stable',
            beta: 'Beta',
            alpha: 'Alpha',
            old: 'Old',
            updateNewVersion: 'Upgrade to new version',
            edit: 'Edit',
            exclude: 'Delete',
            addAccount: 'Add account',
            companyName: 'Company name',
            steps: 'Now follow these steps',
            open: 'Open the',
            spreadsheetClients: 'customer spreadsheet',
            insertEmail: 'Put this email',
            insertPassword: 'Beside, enter the desired password for the account, it needs to be a very secure password.',
            insertPasswordAndAdd: 'Enter the password entered in the spreadsheet in the field below and click Add.',
            passwordMaster: 'Master password',
            cancel: 'Cancel',
            add: 'Add',
            excludeAccount: 'Delete account',
            sureExcludeAccount: 'Are you sure you want to delete this account?',
            allConversationsAndChatbotsDeleted: 'All conversations and all chatbots will be deleted.',
            updateVersionToStable: 'Update version to Stable',
            beAware: 'Before proceeding, be aware that',
            thisChangesVersionPanel: 'This is an action that will change the client\'s dashboard version.',
            losesAllAttendance: 'This option will cause the customer to lose all current calls from Old, and he will no longer have access, only through the Conversations tab.',
            beforeToClick: 'Before clicking on update, check if you already have departments created by accessing https://panel.globalbot.ai in the customer\'s account (if you already have, delete it as it may interfere with the migration).',
            createNewDepartment: 'This action will create new departments (with the same names) and assign them in the flows and in the users, so for security you cannot be editing users or the flow when updating.',
            switchToStableVersion: 'Switch to Stable Version',
            name: 'Name',
            actions: 'Actions',
            jan: 'Jan.',
            feb: 'Feb.',
            mar: 'Mar.',
            apr: 'Apr.',
            may: 'May.',
            jun: 'Jun.',
            jul: 'Jul',
            aug: 'Aug.',
            sep: 'Sep.',
            oct: 'Oct.',
            nov: 'Nov.',
            dec: 'Dec.',
            migratedAccountSuccess: 'Account has been migrated successfully!',
            errorHasOccurred: 'Ocorreu um erro, contate um desenvolvedor urgente!',
            normalStatus: 'Normal',
            endingStatus: 'Ending',
            exceededStatus: 'Exceeded',
            columnFranchise: 'Consumption of the franchise'
          },
          server: {
            serversWhatsapp: 'WhatsApp Servers',
            updateGit: 'Update Git',
            requestUpdateGit: 'Request git update on all servers.',
            update: 'Update',
            servers: 'Servers',
            serversDescription: 'Add new server.',
            add: 'Add',
            numbering: 'Numbering',
            version: 'Version',
            responsible: 'Responsible',
            whatsapp: 'Whatsapp',
            indifferent: 'Indifferent',
            normal: 'Normal',
            business: 'Business',
            chip: 'Chip',
            client: 'Client',
            globalbot: 'Globalbot',
            server: 'Server',
            instanceType: 'Instance type',
            ip: 'IP',
            account: 'Account',
            chatbot: 'Chatbot',
            info: 'Info',
            actions: 'Actions',
            running: 'Running!',
            serverAvailable: 'Server available',
            serverDown: 'Service down, wait manager.',
            qrCode: 'QR Code',
            gitPull: 'Git Pull',
            restartServer: 'Restart server',
            edit: 'Edit',
            excludePairing: 'Delete pairing',
            save: 'Save',
            cancel: 'Cancel',
            serverData: 'Server data',
            instanceId: 'ID da Instância',
            serverName: 'Server name',
            sureRestartServer: 'Are you sure you want to restart this server? This may take a few minutes.',
            restart: 'Restart',
            exclude: 'Delete',
            sureExcludePairing: 'Are you sure you want to delete pairing from this server? You will have to read the QR Code again.',
            cellphone: 'Cell phone',
            manager: 'Manager',
            isBusiness: 'is Business',
            isChipResponsible: 'is Chip Responsible',
            messageError: 'Error, see console!',
            routeNotFound: 'Route not found, server ',
            serverAddedSuccessfully: 'Server added successfully!',
            pairingDeleted: 'Pairing successfully deleted!'
          },
          tasks: {
            tasks: 'Tasks | ',
            newTask: 'New task',
            plan: 'Plan',
            status: 'Status',
            notStarted: 'Not started',
            ready: 'Ready',
            stoppedGlobalbot: 'Stopped Globalbot',
            stoppedClient: 'Stopped Cliente',
            date: 'Date',
            invalidDate: 'Invalid date',
            noDate: 'No date',
            description: 'Description',
            notDescription: 'Not description',
            advancedSettings: 'Advanced settings',
            do: 'Do',
            go: 'Go',
            learn: 'Learn',
            editTask: 'Edit task',
            selectUsers: 'Select users',
            selectAccount: 'Select account',
            selectBot: 'Select bot',
            selectType: 'Select type',
            title: 'Title',
            selectStatus: 'Select status',
            done: 'Done'
          },
          users: {
            listUsers: 'List users',
            user: 'User',
            name: 'Name',
            email: 'Email',
            role: 'Role',
            newPassword: 'New password',
            avatar: 'Avatar',
            permission: 'Permission',
            done: 'Done',
            supreme: 'Supreme',
            master: 'Master',
            administrator: 'Administrator',
            manager: 'Manager',
            attendant: 'Attendant',
            junior: 'Junior',
            messageErrorInfo: 'Oops, some error happened while saving the information.',
            messageErrorUser: 'Oops, some error happened while saving the user.',
            supremeCommander: 'Supreme Commander'
          }
        },
        analytics: {
          attendant: {
            attendant: {
              indicatorsByAttendants: 'Indicators by attendants',
              attendantsByTime: 'Attendances by time of day',
              chatbotsAndAttendants: 'Chatbots x Attendants',
              departments: 'Departments',
              attendants: 'Attendants',
              statistics: 'Statistics',
              exportAttendants: 'Export attendants'
            }
          },
          channels: {
            channels: {
              indicatorsByChannels: 'Indicators by Channels',
              attendantsByTime: 'Attendances by time of day',
              chatbotsAndAttendants: 'Chatbots x Attendants',
              channelOverview: 'Overview by Channel',
              statistics: 'Statistics'
            }
          },
          flow: {
            flow: {
              flowAnalysis: 'Flow Analysis',
              statistics: 'Statistics'
            }
          },
          identifiers: {
            identifiers: {
              customIdentifiers: 'Custom Identifiers',
              statistics: 'Statistics'
            }
          },
          knowledge: {
            knowledge: {
              intelligencePerformance: 'Intelligence Performance',
              conventionalIA: 'Conventional AI',
              conventionalIADescription: 'Standardized responses. Interprets the users intention and presents registered responses (text, video, images, documents and links).',
              generativeIA: 'Generative AI',
              generativeIADescription: '',
              knowledgeBase: 'Knowledge Base',
              commonQuestions: 'Common questions',
              advanced: 'Advanced',
              artificialIntelligence: 'Intelligence',
              statistics: 'Statistics'
            },
            responses: {
              response: 'Response'
            }
          },
          location: {
            location: {
              countries: 'Countries',
              states: 'States',
              cities: 'Cities',
              geolocation: 'Geolocation',
              statistics: 'Statistics'
            }
          },
          origin: {
            origin: {
              platformAndBrowsers: 'Platform and Browsers',
              campaign: 'Campaigns',
              originAttendance: 'Attendances origin',
              statistics: 'Statistics',
              url: 'Uniform Resource Locator'
            }
          },
          cards: {
            attendance: 'Attendances',
            descriptionAttendance: 'Total volume of calls (all channels) for the selected period.<br/>The comparison is relative to the immediately preceding period.',
            satisfaction: 'Satisfaction',
            descriptionSatisfaction: 'Satisfaction rate related to the User Satisfaction<br/> Survey in the selected period. The comparison is relative<br/>to the immediately preceding period. This indicator is visible<br/>only when Satisfaction Survey is enabled in the router Preferences > Chatbot > Behavior',
            intelligence: 'Intelligence',
            descriptionIntelligenceOne: 'Success rate of the Artificial Intelligence(s) of the selected chatbots <br>and period. For Conventional AI, it is the sum<br> of the Answered Questions and Disambiguated Questions.<br> For Generative AI, only the Answered Questions are considered, as <br> there is no disambiguation.<br> The comparison is relative to the immediately previous period.',
            connected: 'Connected',
            descriptionConnected: 'Total users (collaborators) currently connected<br/>to the Globalbot Platform. It refers to<br/>individuals using the platform (such as<br/>administrators, managers or attendants), not<br/>referring to the number of customers in attendance.',
            beta: 'BETA',
            descriptionBeta: 'The feature identified as BETA is under development<br/>and testing by the Globalbot team. Wait for news<br/>regarding the evolution of this feature.',
            viewByChannel: 'Vision by Channel',
            descriptionViewByChannel: 'Check out the main service indicators by channel, quantitative and qualitative.',
            attendants: 'Attendants',
            descriptionAttendants: 'See the performance indicators of the attendants individually and collectively.',
            descriptionIntelligenceTwo: 'Analyze the performance of Artificial Intelligence, including answered, unambiguous and unanswered questions.',
            geolocation: 'Geolocation',
            descriptionGeolocation: 'Check from which locations the attendances are arriving, by country, state and city.',
            origin: 'Origin',
            descriptionOrigin: 'Analyze the origin of calls, considering the sources of traffic, for example, digital campaigns.',
            flowAnalysis: 'Flow Analysis',
            descriptionFlowAnalysis: 'See how users interact with conversational flows, considering engagement and abandonment.',
            customized: 'Custom',
            descriptionCustomized: 'Check out the metrics of your custom identifiers that are part of conversational flows.',
            statistics: 'Statistics',
            tooltipStatistics: 'Doubts in Statistics? Click here and access the Tutorial that our team prepared. We have a lot of news!',
            totalValue: 'Total Value',
            totalValueDescription: 'Check the total value generated by conversations during the period (very useful for those who sell products and services). To appear here, the value must be assigned to each conversation individually.'
          }
        },
        block: {
          create: {
            add: 'Add',
            blocks: 'Blocks',
            welcome: 'Welcome',
            addButton: 'Add button',
            create: 'create',
            title: 'Title',
            description: 'Description',
            message: 'Message',
            name: 'Name',
            firstName: 'First name',
            lastName: 'Last name',
            buttons: 'Buttons',
            button: 'Button',
            email: 'E-mail',
            phone: 'Phone',
            cpf: 'CPF',
            cnpj: 'CNPJ',
            birthDate: 'Birth date',
            calendar: 'Calendar',
            number: 'Number',
            checkbox: 'Checkbox',
            slider: 'Slider',
            image: 'Image',
            intelligence: 'Intelligence',
            liveChat: 'Live chat',
            zipCode: 'Zip code',
            city: 'City',
            state: 'State',
            country: 'Country',
            carousel: 'Carosel',
            guideScreen: 'Guide screen',
            money: 'Money mask',
            exit: 'Finish'
          },
          index: {
            blocks: 'Blocks',
            listblock: 'block list',
            new: 'New',
            index: 'index'
          }
        },
        bots: {
          bots: {
            testChatbot: 'Test Chatbot',
            editFlow: 'Edit flow',
            viewer: 'Viewer',
            lite: 'Lite',
            pro: 'Pro',
            editor: 'Editor',
            settingsAccess: 'Access Settings',
            createNewChatbot: 'New Chatbot',
            createNewChatbotDesc: 'Create a new chatbot, edit the conversational flow, define the parameters and carry out tests.',
            nameAttendant: 'Attendant name',
            cancel: 'Cancel',
            create: 'Create',
            createSuccessfully: 'Chatbot successfully created!',
            createError: 'Error creating chatbot',
            hello: 'Hi. Can I help you?',
            contactGlobalbot: 'Contact Globalbot',
            subject: 'subject',
            content: 'content',
            indication: 'Your friend has a referral',
            thanks: 'Hi{name}, thank you very much for your interest in ',
            introduction: 'With Globalbot your company will offer an experience of dialog with your audiences in the most diverse situations. This experience is fantastic, especially on mobile devices (cell phones and tablets), as people are already used to using messaging apps such as Facebook, Whatsapp and many others.',
            listing: 'I have also listed here some possible applications of ',
            inCompany: ' in your company:',
            leadGeneration: 'Lead Generation:',
            leadGenerationDescription: ' Use to retain the website visitor through dialogue and generate leads for sales teams, integrating with sales force tools or triggering an email.',
            marketingAndSales: 'Marketing and Sales:',
            marketingAndSalesDescription: ' Carry out marketing campaigns and actions focused on sales, presenting a dialogue that leads the customer to engage with the product and its characteristics.',
            search: 'Search:',
            searchDescription: ' Transform extensive opinion and satisfaction surveys into friendly dialogues, avoiding boredom and dispersion.',
            attendance: 'Customer service:',
            attendanceDescription: ' Perform customer service services, based on the most frequent questions and thus eliminate service positions and/or reduce waiting lines.',
            schedulingAndReservations: 'Scheduling and Reservations:',
            schedulingAndReservationsDescription: ' Use to make appointments and reservations (offices, dealerships, restaurants, events and many others) integrating internal systems.',
            databaseAndCrm: 'Database and CRM qualification:',
            databaseAndCrmDescription: ' it can serve as an interface to access information available in databases, allowing the qualification of databases through dialogue.',
            teachingAndLearning: 'Teaching and learning:',
            teachingAndLearningDescription: ' Use for teaching and learning actions, using content from classes for students, being able to talk about specific subjects of the various subjects of the courses.',
            furthermore: 'In addition the ',
            dispatchDescription: ' can send email, SMS, display image, audio, video files and more!',
            surpriseAudience: 'Surprise your audience with this new experience!',
            regards: 'Regards,',
            telmaAyala: 'Telma Ayala',
            virtualAssistant: 'Virtual Assistant',
            address: ' Av. Cristovão Colombo, 3000 / salas 802 e 304 / Floresta Porto Alegre - RS - CEP 90560-002 ',
            moreInformations: 'More information',
            oneMessage: 'Are you still around?',
            twoMessage: 'If you don\'t answer I\'ll have to finish the attendance.',
            threeMessage: 'Alright, if you need help again let me know!',
            closeMessage: 'The attendance was closed by the attendant, we appreciate your contact and if you have any further questions, just call me again.',
            chatbots: 'Chatbots'
          }
        },
        broadcast: {
          broadcast: {
            campaigns: 'Campaigns',
            processing: 'Processing...',
            sending: 'Sending: ',
            of: ' of ',
            messageCreate: 'Create message',
            messageType: 'Message type',
            messageSave: 'SMS messages/Whatsapp saved',
            email: 'Email',
            messageTitle: 'Message title',
            confirmation: 'Confirmation',
            saveNewMessageWithName: 'Save new message with name ',
            save: 'Save',
            cancel: 'Cancel',
            messageText: 'Message text',
            next: 'Next',
            recipients: 'Recipients',
            importNewList: 'Import new list',
            myLists: 'My lists',
            listJson: 'Json List',
            send: 'Send',
            sendNow: 'Send now',
            scheduleShipping: 'Schedule shipping',
            submit: 'Send',
            importList: 'Import list',
            import: 'Import',
            saveList: 'Save list',
            listTitle: 'List title',
            totalRecipients: 'Total recipients',
            recipientsToLowerCase: ' recipients',
            importedList: 'Imported list',
            messageSaveList: 'Your list has been saved!',
            messageOpsError: 'Oops, some error occurred.',
            allMessagesWereSent: 'All messages have been sent.',
            messageNotCreditsSms: 'Oops, looks like SMS credits are missing. Please contact us.',
            allMessagesWereScheduled: 'All messages have been scheduled.',
            failedToConnect: 'Failed to connect to broadcast service, please reload the page.',
            messageNotCreditsWhatsapp: 'Oops, looks like WhatsApp credits are missing. Please contact us.',
            messageWereSent: 'Were sent ',
            messageOfTheTotal: ' of the total of ',
            chargedMessages: ' messages. Only sent messages will be charged.',
            gupshupTemplateImport: "Import template HSM"
          },
          broadcast2: {
            email: 'E-mail',
            communicate: 'Communicate through email marketing or transactional email.',
            createModel: 'Create template',
            sms: 'SMS',
            sendNotificationsSms: 'Send notifications and notices via text messages (SMS).',
            whatsapp: 'WhatsApp',
            sendNotificationsWhatsapp: 'Send promotional messages, notifications, and surveys via WhatsApp.',
            requestCredits: 'Request Credits',
            requestCreditsMessaging: 'Request additional credits for use in the campaigns.',
            models: 'Models',
            modelNotCreated: 'You haven\'t created any models yet. Start by choosing which type of template you want to create.',
            modelHsm: 'HSM (High Structured Message) message model used for sending pre-approved messages through WhatsApp Business API.',
            modelData: 'Model Data',
            messageEditor: 'Message Editor',
            preview: 'Preview',
            duplicate: 'Duplicate dispatch',
            duplicateDraft: 'Duplicate draft',
            createDispatch: 'Create dispatch',
            delivered: 'Delivered',
            device: 'Delivered',
            read: 'Read',
            error: 'Not Sent',
            processed: 'Sent',
            exclude: 'Delete',
            dispatches: 'Dispatches',
            dispatchesApi: 'Api',
            dispatchNotCreated: 'You haven\'t created any submissions yet. Create a submission from a message template.',
            draftNotCreated: 'You still don\'t have drafts.',
            static: 'Static',
            recurring: 'Recurrent',
            draft: 'Draft',
            readyToSend: 'Listo para enviar',
            scheduled: 'Scheduled',
            processing: 'Processing',
            scheduledFor: 'Scheduled to ',
            sent: 'Sent',
            insufficientCredits: 'Insufficient credits',
            editDispatch: 'Edit dispatch',
            editDraft: 'Editar draft',
            dataDispatch: 'Dispatch data',
            statsDispach: 'Dispatch statistics',
            prepareDispatch: 'Prepare dispatch',
            modelExclude: 'Delete template',
            cancel: 'Cancel',
            areSureModelExclude: 'Are you sure you want to delete this template?',
            excludeDispatch: 'Excluir envio',
            duplicateDispatch: 'Do you want to make a copy of this dispatch?',
            areSureDispatchExclude: 'Are you sure you want to delete this dispatch?',
            dispatch: 'Dispatch',
            areSureDispatch: 'Are you sure you want to send?',
            found: 'Found',
            repeated: 'Repeated',
            removed: 'Retirados',
            range: 'Range',
            schedule: 'To schedule',
            saveDraft: 'Save as Draft',
            templateChannel: 'Channel',
            templateName: 'Name',
            templateAuthor: 'Author',
            templateEdited: 'Last update',
            templateActions: 'Actions',
            dispatchChannel: 'Channel',
            dispatchName: 'Name',
            dispatchAuthor: 'Author',
            dispatchStatus: 'Status',
            dispatchActions: 'Actions',
            templateExclude: 'Template deleted!',
            dispatchExclude: 'Dispatch deleted!',
            processingDispatch: 'The dispatch is being processed.',
            dispatchScheduled: 'Dispatch has been scheduled.',
            messageOpsNotDispatch: 'Oops, we had some problem processing the dispatch.',
            messageNotCredits: 'Unfortunately your credit balance is not enough!',
            messageOpsNotValid: 'Oops, we had some problem validating the submission.',
            messageNotFoundDispatch: 'Dispatch not found',
            messageOpsErrorDispatch: 'Oops, there was an error retrieving the submission.',
            messageOpsFailedCompileTemplate: 'Oops, failed to compile template',
            campaigns: 'Campaigns',
            doubtsInMessaging: 'Doubts? Click here and check out the interactive tour.',
            canNotDuplicateMissingTemplate: 'Template deleted, it is not possible to duplicate this submission'
          }
        },
        conversations: {
          conversation: {
            rowsPerPage: 'Records per page',
            recipients: ' recipients',
            createNewList: 'Create new list',
            excludeConversationSelected: 'Delete selected conversations',
            createListConversationSelected: 'Create list with selected conversations',
            createListConversationAll: 'Create list with all conversations',
            exportConversationSelected: 'Export selected conversations',
            exportAllConversationSelected: 'Export all conversations',
            forwardConversationSelected: 'Forward selected conversations',
            forwardConversationIntegrations: 'Forward conversations in integrations',
            columns: 'Columns',
            dateAndHour: 'Date and Time',
            protocol: 'Protocol',
            chatbot: 'Chatbot',
            tags: 'Tags',
            channel: 'Channel',
            actions: 'Actions',
            attendedBy: 'Attended by',
            monetaryValue: 'Value',
            satisfaction: 'Satisfaction',
            category: 'Category',
            department: 'Department',
            transhipmentAttended: 'Attended transshipment: the attendant served the customer. Everything is OK!',
            transhipmentUserLost: 'Unattended transfer: the customer left the chat before the attendant interacted!',
            transhipmentNotYetAttended: 'Transshipment not yet attended/waiting: the attendant has not yet interacted with the customer.',
            transhipmentWaitigAttended: 'Unattended transshipment: waiting for attendant.',
            botDeleted: 'Bot deleted',
            transhipmentUnrealized: 'Transfer not carried out: No attendant online at this time. Chatbot resumed the conversation.',
            transhipmentRejected: 'transhipment rejected: attendant ended the conversation before interacting with the user',
            emailLeadSend: 'Lead email sent',
            nameEmailLeadSend: ', Lead email sent',
            createNewListWith: 'Create a List with the ',
            conversationSelect: ' selected conversations. Use the list created in the Messaging module to send messages via email, SMS or WhatsApp.',
            listName: 'List name',
            cancel: 'Cancel',
            save: 'Save',
            excludeConversations: 'Delete conversations',
            areSureWantToDelete: 'Are you sure you want to delete ',
            conversationsSelected: ' selected conversations?',
            exclude: 'Delete',
            reportExport: 'Report export',
            reportExportNPS: 'Report export NPS',
            willReportExport: 'You will export a report with ',
            conversationsSelectedFormatCsv: ' conversations selected in the format .CSV.',
            exported: 'Export',
            exportedXlsx: 'Export XLSX',
            exportedCsv: 'Export CSV',
            forward: 'Forward',
            youCanSend: 'You will be able to email the selected conversations to someone else.',
            internalMessage: 'Send with live chat internal messages.',
            email: 'E-mail',
            integrations: 'Integrations',
            youCanSendCrmOrTickets: 'You will be able to send the selected conversations to your CRM or ticket system',
            selectIntegration: 'Select your integration',
            send: 'Send',
            all: 'All',
            primary: 'Primary',
            conversations: 'Conversations',
            messageSendEmailBut: 'Emails were sent however ',
            messageSendEmailSuccess: 'Emails sent successfully!',
            messageNotSendEmail: 'Oops, the emails weren\'t sent.',
            messageOps: 'Oops, ',
            messageNeedEmail: 'Oops, we need an email to forward!',
            messageIntegrationsSendBut: 'Integrations sent however ',
            messageIntegrationsSendSuccess: 'Integrations submitted successfully!',
            messageIntegrationsNotSend: 'Oops, the integrations weren\'t sent.',
            messageIntegrationsNotSendField: 'Oops, the integrations weren\'t sent. E-mail is required.',
            messageNeedIntegrations: 'Oops, we need an integration to ship!',
            messageSaveList: 'Your list has been saved!',
            messageListNeedName: 'Your list needs a name!',
            messageOpsError: 'Oops, some error occurred.',
            date: 'Date',
            hour: 'Hour'
          }
        },
        consumption: {
          list: {
            clientsConsumption: 'Clients consumption by usage',
          }
        },
        dashboard: {
          dashboard: {
            weHaveNews: 'We have news!',
            helpCenter: 'Discover our Help Center. There are more than',
            articles: '100 articles',
            featuresPlatform: 'explaining<br/> step by step the features of our platform.',
            viewMore: 'See more!',
            welcomeMessage: 'Enjoy the newest features and functionality we\'ve prepared for you!',
            clickItem: 'Click on the items to watch the demo.',
            newLook: 'The platform in a new look.',
            highPerformance: 'Greater performance and processing.',
            talkTeam: 'Talk to your team now too.',
            attendanceChanges: 'Changes when taking over and finalizing the attendance.',
            emojisAndQuickResponse: 'Emojis, quick responses and other news.',
            commentDuringAttendance: 'Comments and notes during the attendance.',
            insights: 'Insights',
            news: 'News',
            improvement: 'Suggest improvement',
            newsDesc: 'Check out the news recently launched on the Globalbot platform.',
            visitBlog: 'To access all content, visit our ',
            version: 'Version',
            build: 'Build',
            termsOfUse: 'Terms of use',
            goodMorning: 'Good Morning',
            goodAfternoon: 'Good afternoon',
            goodEvening: 'Good night',
            accountData: 'Account data',
            accountDataLabel: 'Check out the contracted plan information below.',
            cardAccount: {
              plan: 'Plan',
              consumption: 'Monthly consumption',
              lineWhatsapp: 'Whatsapp',
              lineOtherChannel: 'Other channels'
            },
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
          },
          layout: {
            localization: 'Language',
            portuguese: 'Portuguese',
            english: 'English',
            spanish: 'Spanish',
            online: 'Online',
            availableToNewAttendance: 'You will be available to receive new calls from your department.',
            away: 'Away',
            pauseNewAttendance: 'Pause. You will continue to answer your active conversations but you will not receive new calls.',
            offline: 'Offline',
            disconnectToChat: 'You will immediately disconnect from the chat. No new calls will be assigned to you.',
            myProfile: 'My profile',
            notificationCenter: 'Notification center',
            nightMode: 'Night mode',
            exit: 'Exit',
            tasks: 'Tasks',
            clients: 'Clients',
            actions: 'Actions',
            changeLogs: 'News | Change logs',
            nps: 'Report NPS',
            serverWhatsapp: 'Whatsapp servers',
            conversationDetails: 'Conversation details',
            home: 'Home',
            statistics: 'Statistics',
            conversation: 'Conversations',
            chatBots: 'Chatbots',
            intelligence: 'Intelligence',
            campaigns: 'Campaigns',
            liveChat: 'Live chat',
            library: 'Library',
            needHelp: 'Need help? Tell us what you need and our team will help you!',
            preferences: 'Preferences',
            important: 'Important:',
            messageImportant: ' When you go into pause, you will continue to answer your active conversations but you will not receive new calls.',
            reasonPause: 'Reason of pause',
            cancel: 'Cancel',
            continue: 'Continue'
          }
        },
        flow: {
          editor: {
            save: 'Save',
            errorSavingInformation: 'Oops, some error happened while saving the information.',
            savedFlow: 'Flow saved'
          },
          flow: {
            flow: 'Flow',
            save: 'Save',
            addOneBalloon: 'Add 1 balloon',
            addTenBalloons: 'Add 10 balloons',
            removeLastBalloon: 'Remove last balloon',
            removeTheLastTenBalloons: 'Remove the last 10 balloons',
            test: 'Test',
            indicateBalloonLine: 'This number indicates the line the balloon is on.',
            responseField: 'Answer field',
            emptyQuestion: 'Empty question',
            clickAndEditBalloon: 'By clicking you can edit the entire balloon.',
            clickGoToLine: 'By clicking you can go to the line.',
            type: 'Type',
            indicatorName: 'Indicator of the name of the',
            balloon: 'of the balloon.',
            identifier: 'Identifier',
            responseWillAppearInConversation: 'This answer will appear in the conversations tab.',
            knowledge: 'Knowledge base',
            jumpIfNotFound: 'Jump if not found',
            ifThereNoQuestionJumpTheLine: 'If there is no question registered, it will jump to the line',
            department: 'Department',
            jumpIfNoOneOnline: 'Jump if no one is online',
            jumpIfNoAttendantOnline: 'If there is no online attendant, it will jump to the line',
            jumpingToLine: 'Jumping to the line',
            botWillGoToLine: 'The bot after going through this balloon, will go to the line',
            clickInNumberGoToLine: 'Click on the number to go to the line.',
            uniqueExternalCode: 'Unique external code',
            webchatExternalCode: 'External code Webchat',
            facebookExternalCode: 'External code Facebook',
            whatsappExternalCode: 'External code Whatsapp',
            glpiIntegration: 'Integration with GLPI',
            sendingLeadEmail: 'Sending lead email',
            thisBalloonCreatesTags: 'This balloon creates tag(s)',
            appearanceTime: 'Appearance time',
            previewAppearanceTime: 'Shows balloon appearance time, default is 3000 milliseconds (3 seconds)',
            finishingBot: 'Bot termination',
            finishingBotDescription: 'When the bot reaches this line it will terminate.',
            dontResponse: 'Do not answer',
            dontResponseDescription: 'Here comes an option for the user not to respond, when clicking on it, the bot goes to the next balloon.',
            comment: 'Comment',
            commentDescription: 'Comment made by an editor of this flow.',
            secureMessage: 'Secure message',
            secureMessageDescription: 'This answer will not be saved in Conversations on the Globalbot platform',
            editLine: 'Edit line',
            emptyBalloon: 'Deflate balloon',
            done: 'Done',
            editText: 'Edit text',
            addRandomResponse: 'Add random response',
            enterYourQuestion: 'Enter your question here',
            editResponseAttributes: 'Edit the answer attributes below',
            none: 'None',
            theType: 'The type',
            theTypeNoneDescription: 'it\'s when you don\'t expect any answer, it\'s just to show something in the question.',
            text: 'Text',
            theTypeTextDescription: 'is for answers only, it allows the user to put any text, numbers without any kind of mask.',
            name: 'Name',
            theTypeNameDescription: 'is for answers only, it allows the user to put the name or how he wants to be called.',
            cpf: 'CPF',
            theTypeCpfDescription: 'it is for answers only, it allows the user to put the CPF with the CPF mask.',
            phone: 'Phone',
            theTypePhoneDescription: 'is for answers only, it allows user to put Phone with Phone mask.',
            email: 'E-mail',
            theTypeEmailDescription: 'is for replies only, it allows the user to enter the email and validate it with a tool.',
            buttons: 'Buttons',
            theTypeButtonsDescription: 'is for answers only, it allows the user to choose between 2 or more button options.',
            list: 'List',
            faq: 'FAQ',
            dataBase: 'Data Base',
            theTypeFaqDescription: 'is for answers only, it allows the user to type something and when sending, it searches the intelligence for what he typed.',
            carousel: 'Carousel',
            theTypeCarouselDescription: 'is for answers only, it allows the user to choose an option between 2 or more than one carousel.',
            liveChat: 'Live chat',
            theTypeLiveChatDescription: 'tries to connect to Globalbot\'s live chat.',
            calendar: 'Calendar',
            theTypeCalendarDescription: 'allows the user to choose the desired date on the calendar.',
            time: 'Schedule',
            theTypeTimeDescription: 'allows the user to choose the time on the clock.',
            response: 'Response',
            buttonOptions: 'Options for buttons',
            dragToSwitchPosition: 'Drag to change position',
            value: 'Value',
            jump: 'Jump',
            switchToSmartButton: 'Switch to smart button',
            switchToButtons: 'Switch to buttons',
            excludeButton: 'Delete this button',
            addButton: 'Add another button',
            checkboxOptions: 'Options for checkboxes',
            genai: 'IA Generativo',
            slidersOptions: 'Options for sliders',
            label: 'Label',
            minValue: 'Minimum value',
            maxValue: 'Maximum value',
            initialValue: 'Initial value',
            step: 'Last',
            guideScreenOptions: 'Options for Guided Screen',
            pageLink: 'Page link',
            returnJump: 'Return jump',
            faqOptions: 'Options for FAQ',
            dataBaseOptions: 'Opções for Data Base',
            knowledgeBase: 'Knowledge base',
            secondaryKnowledgeBase: 'Secondary knowledge base',
            ifNotFound: 'If not found:',
            faqButtons: 'Buttons on FAQ',
            learningTime: 'Learning time (days)',
            buttonLimit: 'Button Limit',
            machineLearning: 'Learning machine',
            imageOptions: 'Options for Facebook or Whatsapp image',
            imageLink: 'Image link',
            textOptions: 'Options for text.',
            mask: 'Mask',
            messageError: 'Error message',
            messageErrorOption: 'Error message options',
            newMask: 'New mask',
            typeSomething: 'Please type something.',
            onlyFacebook: 'Just for facebook, use embebed youtube or other components',
            chooseVideoFromLibrary: 'Choose a video from the library',
            videoLink: 'Video link AVI, MP4 etc',
            dataBaseNotFound: 'Data Base not founded',
            moreOptions: 'More options',
            liveChatOptions: 'Options for Live Chat',
            fill: 'Fill',
            message: 'Message',
            liveErrorJump: 'Live error jump',
            liveErrorJumpMessage: 'The jump cannot be to the same line',
            setIdentifier: 'Set the identifier to save the user response.',
            newIdentifier: 'New identifier',
            jumpLine: 'Line jump',
            defineJumpToNextLine: 'Define where the next line for the chatbot to jump will be.',
            jumpToLine: 'Jumping to the line',
            jumpTo: 'Jump to',
            assignComment: 'Add a comment',
            assignCommentDescription: 'You will assign a comment to this line within the flow.',
            notResponseOptions: 'Option not to respond',
            createNotResponseOnlyWebchat: 'Creates an option not to answer the question. Webchat only.',
            notResponse: 'Do not answer',
            secureMessageOptions: 'Secure message option',
            writtenMessageOne: 'The message written by the user does not will be stored by Globalbot.',
            waitDefinedValue: 'When enabled, it waits for the set value in milliseconds to appear.',
            timing: 'Time',
            addTiming: 'Add time',
            integrations: 'Integrations',
            ticketTitle: 'Put the title of the Ticket you want and click on the + beside it.',
            sendTicketToGlpi: 'After saving the bot going through this line will send a ticket to the GLPI System.',
            clickTheIcon: 'To disable, just click on the minus icon.',
            editTicketTitle: 'Edit ticket title',
            newTicketTitle: 'New ticket title',
            sendLeadTranscriptEmail: 'Send lead transcript via email',
            insertEmail: 'Enter the email to send the lead in the field below.',
            saveBotAndSendEmail: 'After saving the bot going through this line will send a lead email to the email informed.',
            editEmail: 'Edit email',
            newEmail: 'New email',
            addTagInConversation: 'Add TAG in conversation',
            selectTags: 'Select the tags to be added to the conversation if you pass through this line.',
            selectOneTag: 'Select a TAG',
            addTag: 'Add tags',
            externalCode: 'External codes',
            masterOnlyFunction: 'Function for masters only.',
            insertCode: 'Here\'s some pure javascript code that will run on that line.',
            codeAdapted: 'If it is not the Callback Único, each platform needs an adapted code to work.',
            unifiedExternalCode: 'Unified external code',
            newUnifiedExternalCode: 'New unified external code',
            externalCodeForWebchat: 'External code for webchat',
            newExternalCodeForWebchat: 'New external code for Webchat',
            externalCodeForWhatsapp: 'External code for Whatsapp',
            newExternalCodeForWhatsapp: 'New external code for Whatsapp',
            externalCodeForFacebook: 'External code for Facebook',
            newExternalCodeForFacebook: 'New external code for Facebook',
            close: 'Close',
            guideScreen: 'Guide Screen',
            zipCode: 'Zip code',
            cnpj: 'CNPJ',
            finish: 'Finish',
            uploadImage: 'Image upload',
            checkbox: 'Checkbox',
            rating: 'Stars',
            number: 'Number',
            firstName: 'First name',
            lastName: 'Last name',
            audio: 'Audio',
            bornDate: 'Date of birth',
            slider: 'Slider',
            image: 'Image',
            facebookVideo: 'Facebook video',
            video: 'Video',
            city: 'City',
            state: 'State',
            country: 'Country',
            maskMoney: 'Money mask',
            noBaseAvailable: 'No base available',
            noBaseAssigned: 'No base assigned',
            dataBaseNotFoundOption: 'Unfortunately we do not have this option, please choose a valid option.',
            noBaseDefined: 'No defined base',
            noBodyOnline: 'Unfortunately we don\'t have anyone online at the moment.',
            messageErrorSaved: 'Oops, some error happened while saving the information.',
            messageErrorButtons: 'Oops, duplicate button names in row ',
            saveDescription: 'Put a description before saving.',
            flowSaved: 'Flow saved',
            editFlow: 'Flow editor',
            flowPublish: 'Flow published',
            title: 'Title',
            description: 'Description',
            select: 'Select'
          },
          flowLite: {
            flowLite: 'Flow Lite',
            addBalloonAbove: 'Add balloon above',
            randomMessage: 'Random message',
            message: 'Message',
            name: 'Name',
            buttons: 'Buttons',
            intelligence: 'Intelligence',
            carousel: 'Carousel',
            text: 'Text',
            email: 'Email',
            checkbox: 'Checkbox',
            rating: 'Rating',
            number: 'Number',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            telephone: 'Telephone',
            audio: 'Audio',
            liveChat: 'Live chat',
            cpf: 'CPF',
            zipCode: 'Zip code',
            calendar: 'Calendar',
            slider: 'Slinder',
            image: 'Image',
            city: 'City',
            state: 'State',
            country: 'Country',
            money: 'Money',
            cnpj: 'CNPJ',
            uploadImage: 'Upload image',
            finish: 'Finish',
            guideScreen: 'Guide screen',
            done: 'Done',
            editText: 'Edit text',
            addRandomResponse: 'Add random response',
            randomizeResponse: 'Randomize response',
            edit: 'Edit',
            exclude: 'Delete',
            identifier: 'Identifier',
            jump: 'Jump',
            excludeBalloon: 'Delete balloon',
            wantToExcludeBalloon: 'Are you sure you want to delete this balloon?',
            dontAskAgain: 'Don\'t ask again',
            cancel: 'Cancel',
            editBalloon: 'Edit balloon',
            instructions: 'Instructions',
            saveRepliesDescription: 'The "identifier" is used to create jumps and be recorded in places such as conversations, integrations, etc. Use to save important responses from your customers.',
            doNotUse: 'Do not use "spaces", use "_" (underscore), do not use accents, if you have any questions about it, please contact us.',
            identifierName: 'Identifier name',
            replacementSystem: 'If you want to use the replacement system just put the identifier inside "%", example: %MY_IDENTIFIER%',
            useButtons: 'Use as many buttons as necessary, but remember, if the number is greater than 6 it will become a dropdown list.',
            changePosition: 'If you want to change the position buttons click on the icon',
            andDrag: 'and drag.',
            sameValue: 'Key and value must always have the same values. Don\'t forget to choose a jump tag.',
            key: 'Key',
            value: 'Value',
            add: 'Add',
            specificDepartment: 'Department: if you have a specific department, select it, otherwise leave it as "Primary".',
            messageErrorDescription: 'Error message: message if the AI is unable to respond.',
            jumpErrorDescription: 'Error jump: jump to some identifier if the AI can\'t respond.',
            departments: 'Departments',
            messageError: 'Error message',
            jumpError: 'Error jump',
            knowledgeBaseDescription: 'Knowledge base: The AI search will only be on the selected knowledge base.',
            jumpDescription: 'Jump: If the AI does not have an answer for the question, it will jump to the selected identifier or repeat the question if no identifier is selected.',
            knowledgeBase: 'Knowledge base',
            jumpWithoutAiResponse: 'Jump (no AI response)',
            machineLearning: 'Machine Learning',
            importantDescription: 'Important: This feature only works on Webchat.',
            machineLearningDescription: 'Machine Learning: The AI learns from its customers, bringing the most used answers (in the form of buttons) to facilitate their doubts.',
            learningTimeDescription: 'Learning time: The time (in days) that the AI can use for learning.',
            buttonLimitDescription: 'Button Limit: The AI will bring the answers if you stick to the maximum number of buttons.',
            learningTime: 'Learning time (days)',
            twoDays: 'Two days',
            threeDays: 'Three days',
            oneWeek: 'One week',
            twoWeek: 'Two weeks',
            oneMonth: 'One month',
            oneQuarter: 'One quarter',
            oneSemester: 'One semester',
            buttonLimit: 'Button limit',
            three: 'Three',
            four: 'Four',
            five: 'Five',
            six: 'Six',
            auxiliaryBases: 'Auxiliary bases',
            searchAuxiliaryBases: 'If the AI does not have an answer using the chosen knowledge base, it will look for the auxiliary bases.',
            optionToRefuseDisambiguation: 'Option to refuse disambiguation',
            alwaysAllow: 'The AI will always allow the client to choose the option to decline the answers given by the AI.',
            onlyResponseUnambiguous: 'Remember, this option is for unambiguous answers only.',
            fieldLabel: 'The Label field will appear next to the selected number, for example "25m²"',
            valueMaxAndMin: 'Minimum value and maximum value define how much your customer can choose.',
            selectionInitialValue: 'Choose an initial value too, which may make it easier for your customer.',
            stepDescription: 'The pass is the number jumps, for example if the pass is 5 the customer can choose 25, 30, 35 and so on.',
            label: 'Label',
            minValue: 'Minimum value',
            maxValue: 'Maximum value',
            initialValue: 'Initial value',
            step: 'Step',
            searchLibrary: 'Search the library',
            insertLink: 'Put the link to the page you want to guide your customer.',
            restartReturnJump: 'The bounce back will restart the chat on the selected identifier.',
            pageLink: 'Page link',
            returnJump: 'Return jump',
            useItems: 'Use as many items as needed, but remember, if the number is greater than 6 it will become a dropdown list.',
            changingThePositionButtons: 'If you want to change the position buttons click on the icon',
            googleAnalytics: 'Google Analytics',
            defineTheCategory: 'Define the event category, action, tag and value.',
            requiredFields: 'Don\'t forget, all information needs to be filled in.',
            category: 'Category',
            action: 'Action',
            tag: 'Tag',
            noInteraction: 'No interaction',
            customerResponse: 'Customer response',
            manual: 'Manual',
            none: 'None',
            bornDate: 'Date of birth',
            maskMoney: 'Money mask',
            primary: 'Primary',
            all: 'All',
            clickHere: 'Click here',
            select: 'Select',
            itemOne: 'Item one',
            noBodyOnline: 'Unfortunately we don\'t have anyone online at the moment.',
            editFlowLite: 'Lite flow editor'
          },
          list: {
            viewer: 'Viewer',
            flowStructure: 'Visualize the structure of your flow!',
            editorPro: 'Explore your creativity in the editor for professionals, with many interactive features and new settings.',
            editor: 'Editor',
            editFlow: 'Edit the stream directly in code. For those who have knowledge of HTML and JavaScript code.',
            flows: 'Flows'
          },
          viewer: {
            flow: 'Flow',
            toView: 'To view',
            test: 'Test',
            indicatesTheBalloonLine: 'This number indicates the line the balloon is on.',
            responseField: 'Answer field',
            emptyQuestion: 'Empty question',
            clickGoToLine: 'By clicking you can go to the line.',
            type: 'Type',
            typeDescription: 'Indicator of the name of the',
            theBalloon: 'of the balloon.',
            identifier: 'Identifier',
            identifierDescription: 'This answer will appear in the conversations tab.',
            knowledgeBase: 'Knowledge base',
            jumpCaseNotFound: 'Jump if not found',
            jumpCaseNotFoundDescription: 'If there is no question registered, the line will be skipped',
            department: 'Deparment',
            noBodyOnline: 'Jump if no one is online',
            noBodyOnlineDescription: 'If there is no online attendant, it will jump to the line',
            jumpingToLine: 'Jumping to the line',
            botWillGoToLine: 'The bot after going through this balloon, will go to the line',
            clickInNumberGoToLine: 'Click on the number to go to the line.',
            externalCode: 'External code',
            uniqueExternalCode: 'Unique external code',
            whatsappExternalCode: 'Whatsapp external code',
            facebookExternalCode: 'External code Facebook',
            glpiIntegration: 'Integration with GLPI',
            sendingLeadEmail: 'Sending lead email',
            appearanceTime: 'Appearance time',
            previewAppearanceTime: 'Shows balloon appearance time, default is 3000 milliseconds (3 seconds)',
            finishingBot: 'Bot termination',
            finishingBotDescription: 'When the bot reaches this line it will terminate.',
            dontResponse: 'Do not answer',
            dontResponseDescription: 'Here comes an option for the user not to respond, when clicking on it, the bot goes to the next balloon.',
            comment: 'Comment',
            commentDescription: 'Comment made by an editor of this flow.',
            secureMessage: 'Secure message',
            secureMessageDescription: 'This answer will not be saved in Conversations on the Globalbot platform',
            primary: 'Primary',
            none: 'None',
            name: 'Name',
            faq: 'FAQ',
            buttons: 'Buttons',
            list: 'List',
            carousel: 'Carousel',
            text: 'Text',
            email: 'E-mail',
            phone: 'Phone',
            liveChat: 'Live chat',
            guideScreen: 'Guide screen',
            cpf: 'CPF',
            zipCode: 'Zip code',
            cnpj: 'CNPJ',
            finish: 'Finish',
            uploadImage: 'Upload Image',
            checkbox: 'Checkbox',
            rating: 'Rating',
            number: 'Number',
            firstName: 'First name',
            lastName: 'Last name',
            audio: 'Audio',
            message: 'Message',
            calendar: 'Calendar',
            bornDate: 'Born date',
            slider: ' Slider',
            image: 'Image',
            facebookVideo: 'Facebook Video',
            video: 'Video',
            city: 'City',
            state: 'State',
            country: 'Country',
            maskMoney: 'Money mask',
            noBaseAvailable: 'No base available',
            noBaseAssigned: 'No base assigned',
            dataBaseNotFoundOption: 'Unfortunately we do not have this option, please choose a valid option.',
            title: 'Title',
            description: 'Description',
            select: 'Select',
            messageLiveError: 'Unfortunately we don\'t have anyone online at the moment.',
            noBaseDefined: 'No defined base',
            messageErrorSaveTheInformation: 'Oops, some error happened while saving the information.',
            flowSaved: 'Flow saved',
            flowEditor: 'Flow editor'
          }
        },
        help: {
          help: {
            help: 'Help',
            whatIsYourDoubt: 'What is your doubt?',
            needHelp: 'Need help?',
            tutorials: 'Tutorials',
            messageErrorTestingIntelligence: 'Error testing intelligence'
          }
        },
        kanban: {
          kanban: {
            making: 'Making',
            done: 'Done',
            createItem: 'Create item',
            title: 'Title',
            message: 'Message',
            cancel: 'Cancel',
            add: 'Add',
            newColumn: 'New column'
          }
        },
        knowledge: {
          genai: {
            card_title: "Generative AI",
            card_description: "Responses created from a large dataset (GPT model).\nInterprets user intent and generates responses (text and links).",
            column_name: "Name",
            column_autor: "Author",
            column_updatedAt: "Last Updated",
            column_action: "Actions",
            drawer_test: {
              title: "Generative AI Test",
              name: 'Name',
              text_motive: "Use the chat on the side to test the Generative AI responses.<br>The responses are automatically generated based on the provided files and documents.",
              note: "NOTE",
              text_note: "Remember that the Generative AI will be part of some chatbot, so some features of this test chat are limited (e.g., no transfer to human agents).",
              restart: "Restart"
            },
            drawer_data: {
              create: "New Knowledge Base",
              edit: "Edit Knoeledge Base",
              prompt: 'Context Guidelines (Prompt)',
              promptDescription: "Define the guidelines below to direct Generative AI responses",
              docs: "Knowledge",
              docsDescriptions: "Submit the files and/or inform the websites that will be used by Generative AI (PDF, MP3, MP4, MPEG)",
              informSite: 'Inform Website',
              informSiteDescription: 'Enter the address of a website.',
              url: 'URL Eddress *',
              recursiveSearch: "Recursive search: once this option is activated, all links related to the entered domain will be included.",
              cancel: 'Cancel',
              importDoc: "Import Files",
              clickOrDrag: "Click or drag files to this area.",
              rules: "Additional Rules",
              triggers: "Escape Triggers",
              triggersDescription: "Use triggers to take the user to some point in the chatbot flow.",
              name: 'Name *',
              requiredFieldsError: 'Fill in all mandatory fields.',
              save: 'Save',
              createSuccess: 'Assistant created successfully.',
              updateFileError: 'Archive error:',
              updateSuccess: 'Assistant updated successfully.',
              deleteDocSuccess: 'file deleted successfully.',
              urlError: 'URL error:',
              triggerError: 'Trigger error:',
              fileActive: 'Ready and Available Content',
              fileProcessing: 'Content in Process',
              triggerError: 'Update trigger error:',
              fileError: 'Error Processing. Delete the item and try again',
              triggersPromptDescription: 'Define the guidelines below to direct Generative AI to perform the trigger action.',
              flowJump: 'Action: Go to the following flow line:',
              deleteTrigger: 'Delete Trigger',
              deletedTrigger: "Tigger deleted.",
              errorDeleteThisTrigger: "Error deleting trigger.",
              areYouSureDeleteTrigger: "Are you sure you want to delete this trigger? All data on it will be lost and this action cannot be undone.",
              deleteDoc: 'Delete Archive',
              deletedDoc: "Archive deleted.",
              errorDeleteThisDoc: "Error deleting archive.",
              areYouSureDeleteDoc: "Are you sure you want to delete this archive? All data on it will be lost and this action cannot be undone.",
              delete: 'Delete',
              errorTreatment: 'Error Treatment',
              errorTreatmentDescription: 'Define the chatbots behavior when there is no response.',
              noResponse: 'If you are left unanswered by:',
              errorFlowJump: "Go to the flow line",
              intervention: "Programmed Intervention",
              interventionDescription: "Define a scheduled intervention based on the number of responses.",
              from: "from:",
              subsequentTimes: 'subsequent times',
              responsesRow: 'responses in a row'
            },
            genaiNotCreated: "You don't have assistants yet",
            editGenai: "Edit assistant",
            testGenai: "Test assistant",
            add: "Create Assistant",
            exclude: "Delete Assistant",
            deletedAssistant: "Assistant deleted.",
            errorDeleteThisAssistant: "Error deleting assistant.",
            areYouSureDelete: "Are you sure you want to delete this assistant? All its data will be lost and this action cannot be undone.",
            cancel: "Cancel"
          },
          knowledge: {
            card_title: "Conventional AI",
            card_description: "Standardized responses. Interprets user intent and presents registered responses (text, video, images, documents, and links).",
            deleteQuestionSelected: 'Delete selected answers',
            rowsPerPage: 'Records per page',
            conversations: 'Conversations',
            add: 'Add',
            knowledgeBase: 'Knowledge base',
            exclude: 'Delete',
            askQuestion: 'Ask a question (intention test)',
            importQuestions: 'Import questions',
            columns: 'Columns',
            status: 'Status',
            questionKnowledgeBase: 'Question / Knowledge Base',
            response: 'Response',
            intentionsIa: 'Intentions (Artificial Intelligence)',
            actions: 'Actions',
            edit: 'Edit',
            save: 'Save',
            cancel: 'Cancel',
            newQuestion: 'New question',
            questionTitle: 'Question title',
            deleteQuestions: 'Delete Questions',
            createNewKnowledgeBase: 'Create a new Knowledge Base when you need to separate knowledge for different audiences or contexts, as Intelligence is shared with all chatbots in your account.',
            textEditor: 'Text editor',
            addRandomResponse: 'Add random answer',
            responseNotFound: 'Answer not found go to flow position',
            jumpToFlowPosition: 'Jump to flow position',
            search: 'Search',
            noImagesFound: 'No images found',
            urlPage: 'Page URL',
            continueToFlowPosition: 'Continue in flow position',
            randomizeResponse: 'Randomize answer',
            moveUp: 'Move up',
            video: 'Video',
            document: 'Document',
            text: 'Text',
            liveChat: 'Live chat',
            intelligence: 'Intelligence',
            image: 'Image',
            carousel: 'Carousel',
            guideScreen: 'Guide screen',
            jump: 'Jump',
            moveDown: 'Move down',
            continueFlow: 'After presenting the answers above, the chatbot will follow the flow according to the defined sequencing.',
            intentions: 'Intentions',
            registerIntentions: 'Register the intentions considering the main words that may trigger the response being registered. Give greater weights to words that need to avoid disambiguation.',
            newIntention: 'Enter a new intent here and hit "Enter"',
            intention: 'Intention',
            weight: 'Weight',
            minWeight: 'Minimum weight',
            optional: 'Optional',
            optionalDescription: 'Use if you want to define the minimum weight to be added by the intentions to display the answer.',
            addKnowledgeBase: 'Add Knowledge Base',
            title: 'Title',
            excludeQuestion: 'Delete question',
            youSureExclude: 'Are you sure you want to delete this question?',
            justCopy: 'To import questions, just copy',
            thisModel: 'this model',
            standard: 'standard',
            exportToCsv: 'and export it in CSV with your questions you want to register.',
            chooseFileAndImport: 'After that, click Choose file below to import.',
            none: 'None',
            question: 'Question',
            all: 'All',
            messageErrorImportList: 'Error importing bank list',
            youAlreadyHaveBase: 'You already have a base with this title.',
            requiredTitle: 'The title cannot be left blank.',
            duplicateIntention: 'There is already an equal intention, give it more weight.',
            mustHaveMoreThanOneLetter: 'Intent must be more than 1 letter.',
            cannotHaveMoreThanTwoWords: 'Intent cannot be longer than 2 words.',
            questionSave: 'Saved question',
            messageErrorNewQuestion: 'Error saving new question',
            allFieldsRequired: 'All fields need to be filled.',
            questionSuccessfullyDeleted: 'Question successfully deleted.',
            messageErrorExcludeQuestion: 'Error deleting question.',
            messageErrorTestIntelligence: 'Error testing intelligence',
            messageErrorFilter: 'Error filtering intelligence',
            knowledgeBaseExcluded: 'Knowledge base deleted.',
            itWasNotPossibleExclude: 'It was not possible to delete this knowledge base.'
          }
        },
        library: {
          library: {
            limitExceeded: '200MB limit exceeded',
            addFile: 'Add media',
            libraryDescription: 'Here, in the media library, you can add media to live chat and stream carousels.',
            sizeLimitIs: 'Size limit is',
            video: 'Video',
            videos: 'Videos',
            addMedia: 'Add media',
            photos: 'Photos',
            search: 'Search',
            list: 'List',
            gallery: 'Gallery',
            view: 'View',
            name: 'Name',
            sent: 'Sent',
            actions: 'Actions',
            of: 'of',
            documents: 'Documents',
            document: 'Document',
            exclude: 'Delete',
            newFile: 'New file',
            typeFile: 'Type file',
            image: 'Image',
            title: 'Title',
            description: 'Description',
            cancel: 'Cancel',
            save: 'Save',
            areYouSure: 'Are you sure you want to delete the file?',
            clickExclude: 'By clicking "Delete", the file can no longer be recovered.',
            date: 'Date',
            size: 'Size',
            library: 'Library',
            requiredTitle: 'Title field needs to be filled.'
          }
        },
        live: {
          live2: {
            userLeaveChat: 'User abandons chat',
            userStayOnManualQueue: 'User is in manual queue',
            userStayOnAutoQueue: 'The user is in automatic queue',
            userStayWaiting: 'User is on hold',
            chatIsFinished: 'The conversation is over',
            chatWaitHsm: 'The conversation is paused and awaiting user interaction',
            errorOnTransferRoom: 'We identified an error transferring this conversation. In this case, please take a screenshot of this screen and send it to our support (suporte@globalbot.com.br). Please try to repeat the transfer again.',
            titleErrorOnLive: 'Unable to transfer conversation',
            titleRuleOnLive: 'Unable to perform this action',
            ruleTransferRoomUnauthorizedUser: 'You are not authorized to transfer this room',
            ruleTransferRoomInvalidStatus: 'It is not possible to transfer this room as its status is invalid, if it persists ask a manager to carry out this action',
            liveChatUpperCase: 'Live Chat',
            inbox: 'Attendant inbox',
            waiting: 'Attendant queue',
            newConversation: 'New conversation',
            attendants: 'Attendants',
            settings: 'Settings',
            filter: 'Filter',
            filterByAttendant: 'Filter by attendant',
            searchCustomerName: 'Search customer name',
            all: 'All',
            filterByDepartment: 'Filter by department',
            internalToUpperCase: 'INTERNAL',
            finishedToUpperCase: 'FINISHED',
            inQueueToUpperCase: 'IN QUEUE',
            waitingToUpperCase: 'WAITING',
            newToUpperCase: 'NEW',
            transferToUpperCase: 'TRANSFERRED',
            yesterday: 'Yesterday',
            finishAttendance: 'Finish attendance(s)',
            finishAndArchiveAttendance: "Finish and Archive",
            finishSuccess: 'Service(s) completed successfully',
            processingAction: 'Processing... Please wait to finish.',
            noAttendance: 'No attendance available.',
            important: 'Important',
            completionMessage: 'Choose one of the options, considering that "Just Finish" will finish the conversation but you will still see the chat room on this screen, while "Finish and Archive" will close and archive the conversation. You can view it later in the Conversations Menu.',
            triggerAgain: 'Its next interaction will trigger the chatbot again, reopening a new attendance.',
            areYouSureFinish: 'Are you sure you want to finish?',
            cancel: 'Cancel',
            finish: 'Finish Only',
            archiveAttendance: 'Archive attendance(s)',
            archiveSuccess: 'Service(s) archived successfully',
            futureConversation: 'When archiving calls, active conversations will automatically end and close. The next interaction of the user(s) will trigger the chatbot again, reopening a new service. You will be able to consult conversations in the future, in the Conversations Menu. Are you sure you want to archive?\n',
            areYouSureArchive: 'Are you sure you want to archive?',
            archive: 'Archive',
            transferAttendance: 'Transfer Attendance(s)',
            transferSuccess: 'Attendance(s) transferred successfully',
            transferDescription: 'Transfer the attendance to another agent (they will receive the attendance) or department (your attendance will be distributed according to the established settings).',
            areYouSureTransfer: 'Are you sure you want to transfer?',
            byAttendant: 'By attendant',
            byDepartment: 'By deparment',
            transfer: 'Transfer',
            withCustomer: 'with Customer',
            viaWhatsapp: '(via WhatsApp)',
            startConversationWithCustomer: 'Start a conversation with a customer',
            sendingMessage: 'sending a message via WhatsApp.',
            select: 'Select',
            withAttendant: 'with Attendant',
            viaGlobalbot: '(via Globalbot)',
            startConversationWithSomeone: 'Start a conversation with someone from',
            teamSending: 'your work team sending a',
            messageThroughTheGlobalbot: 'message by the Globalbot platform.',
            newAttendance: 'New attendance',
            waitingForYou: 'is waiting for you!',
            see: 'See',
            close: 'Fechar',
            waitingAttendant: 'is waiting for an attendant.',
            take: 'Take',
            newTransfer: 'New transfer',
            transferredAndWaitingForYou: 'has been transferred and is waiting for you!',
            transferredDepartmentAndWaitingForYou: 'has been transferred to your department is waiting for an attendant.',
            anotherAttendant: 'Another attendant has already taken over this attendance.'
          },
          room: {
            transferAttendance: 'Transfer attendance',
            finishAttendance: 'Finish attendance',
            noAttendance: 'No attendance available.',
            archive: 'Archive',
            group: 'group',
            attendance: 'attendance',
            theaterMode: 'Theater mode',
            defaultView: 'Default view',
            noOneToAnswer: 'No one to meet at the moment. What do you think about a coffee?',
            chooseOneToAnswer: 'Select an attendance and let\'s go!',
            loadMoreMessages: 'Load more messages',
            isTyping: 'User is still typing!',
            conversation: 'Conversation',
            intelligence: 'Intelligence',
            navigation: 'Navigation',
            data: 'Data',
            abandonedTheChat: 'The user abandoned the chat',
            createdIn: 'Created in',
            attendants: 'Attendants',
            exit: 'Exit',
            quickResponse: 'Quick response',
            internalMessage: 'Internal message',
            send: 'send',
            takeAttendance: 'TAKE ATTENDANCE',
            important: 'Important',
            sendTemplateHsm: 'To talk to the user, send a Message Template (HSM)',
            onlyAttendants: 'Only attendants of this conversation can send messages.',
            automaticArchiving: 'After 5 days the conversation will be automatically archived.',
            preview: 'Preview',
            addLegend: 'Add caption',
            cancel: 'Cancel',
            search: 'Search',
            notFound: 'No emoji found',
            found: 'Found',
            recent: 'Frequently used',
            people: 'Emojis and People',
            nature: 'Animals and Nature',
            foods: 'Food and Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
            others: 'Others',
            fullFunctioning: 'For the full functioning of the live chat it is necessary to allow access to the clipboard.',
            anotherAttendant: 'Another attendant has already taken over this service.'
          }
        },
        login: {
          forgot: {
            enterYourEmail: 'Enter your email and we will send you a temporary password',
            buttonRecover: 'Recover my password',
            goBack: 'Go back',
            emptyEmail: 'You need to fill in the email',
            email: 'Email',
            pleaseEmptyEmail: 'Please fill in your email',
            passwordSent: 'Your new password has been sent to your email.',
            contact: 'Oops, por favor entre em contato com o suporte da Globalbot. (suporte@globalbot.com)',
            iForgotMyPassword: 'I forgot my password'
          },
          login: {
            selectAccount: 'Select the desired account to access',
            cancel: 'Cancel',
            forgotPassword: 'I forgot my password',
            verificationCode: 'Verification code',
            enter: 'Enter',
            validate: 'Validar',
            emptyEmail: 'You need to fill in the email',
            labelEmail: 'Email',
            fillAllData: 'Please fill in all fields',
            emptyPassword: 'You need to fill in the password',
            labelPassword: 'Password',
            userInactive: 'This user is inactive, please contact your manager.',
            outOfYourJourney: 'You are out of your workday, please contact your manager.',
            incorrectUsernameOrPassword: 'Incorrect username or password.',
            doubleFactor: 'This account is secured with two-factor authentication, please enter the six-digit code sent to your cell phone.',
            doubleFactorNeedNumber: 'This account is secured with two-factor authentication, and your user does not have a registered mobile number, please contact your manager.',
            unexpectedError: 'Unexpected error.',
            accountNotCorrect: 'Your account does not look correct, please contact Globalbot',
            notifiedHere: 'Now whenever something happens you will be notified here.',
            notificationActivated: 'Notification activated',
            rememberMyPassword: 'Keep me connected'
          }
        },
        notification: {
          control: {
            addNotification: 'Add notification',
            dateAndTime: 'Date and Time',
            type: 'Type',
            title: 'Title',
            actions: 'Actions',
            user: 'User',
            tag: 'Tag',
            department: 'Department',
            role: 'Role',
            account: 'Account',
            group: 'Group',
            all: 'All',
            edit: 'Edit',
            linkUsers: 'Link users',
            exclude: 'Delete',
            recordsPerPage: 'Records per page',
            save: 'Save',
            cancel: 'Cancel',
            newNotification: 'New Notification',
            showAvatar: 'Show avatar',
            customizableLabel: 'Customizable label',
            label: 'Label',
            message: 'Message',
            notificationType: 'Notification type',
            attachments: 'Attachments',
            loadImage: 'Load image',
            notificationControl: 'Notification Control',
            errorUpload: 'Error processing upload.',
            notificationSent: 'Notification Sent',
            errorSent: 'Error sending notification'
          }
        },
        people: {
          people: {
            people: 'People',
            name: 'Name',
            anonymous: 'Anonymous'
          },
          timeline: {
            people: 'People',
            fullName: 'Full name',
            name: 'Name',
            nickname: 'Nickname',
            occupation: 'Occupation',
            cpf: 'CPF',
            anonymous: 'Anonymous',
            company: 'Company',
            cnpj: 'CNPJ',
            country: 'Country',
            city: 'City',
            state: 'State',
            district: 'Neighborhood',
            conversationStarted: 'Conversation started',
            of: 'of',
            at: 'at',
            hoursAnd: 'hours and',
            minutes: 'minutes'
          }
        },
        profile: {
          profile: {
            profile: 'Profile',
            changePhoto: 'Change photo',
            hello: 'Hello',
            personalInformation: 'Personal information',
            name: 'Name',
            email: 'Email',
            role: 'Role',
            phone: 'Phone',
            security: 'Security',
            newPassword: 'New password',
            confirmNewPassword: 'Confirmation of new password',
            connectedDevices: 'Connected Devices',
            deleteDevice: 'Delete device',
            save: 'Save',
            administrator: 'Administrator',
            manager: 'Manager',
            attendant: 'Attendant',
            primary: 'Primary',
            passwordIsNotIdentical: 'Oops, your password is not identical, please review.',
            informationSaved: 'Information saved!',
            errorSavingInformation: 'Oops, some error happened while saving the information.'
          }
        },
        realTime: {
          realTime: {
            preferences: 'Preferences',
            realTime: 'Real time',
            browsing: 'Browsing',
            withChatbots: 'With chatbots',
            withAttendants: 'With attendants',
            alreadyTalked: 'Already talked',
            attendedBy: 'Attended by',
            currentPageContains: 'Current page contains',
            columns: 'Columns',
            channel: 'Channel',
            dateAndTime: 'Date/Time',
            visitor: 'Visitor',
            chatbot: 'Chatbot',
            location: 'Location',
            pages: 'Pages',
            currentPage: 'Current page',
            time: 'Time',
            conversation: 'Conversation',
            actions: 'Actions',
            unknown: 'Unknown',
            seeOnMap: 'See on map',
            seeNavigation: 'See navigation',
            inAttendance: 'In attendance',
            waitingForAttendant: 'Waiting for attendant',
            finishAttendance: 'Attendance finished',
            noAttendance: 'No attendance available.',
            activeVisitor: 'Active visitor',
            inactiveVisitor: 'Inactive visitor',
            startedAt: 'Started at',
            allConversations: 'All conversations',
            of: 'de',
            initialMessage: 'Initial message',
            message: 'Message',
            cancel: 'Cancel',
            intervene: 'Intervene',
            navigation: 'Navigation',
            openPage: 'Open page',
            close: 'Close',
            openConversation: 'Open conversation',
            all: 'All',
            chatbots: 'Chatbots',
            path: 'Path',
            conversations: 'Conversations'
          }
        },
        settings: {
          chatbot: {
            behavior: {
              chatbots: 'Chatbots',
              preferences: 'Preferences',
              behavior: 'Behavior',
              timers: 'Timers',
              balloonAppearanceTime: 'Balloon appearance time',
              chatbotShutdownTime: 'Chatbot shutdown time',
              interactionTime: 'Tempo de interação (...)',
              teenMinutes: '10 minutes',
              threeMinutes: '3 minutes',
              oneMinute: '1 minute',
              fiveMinutes: '5 minutes',
              fifteenMinutes: '15 minutes',
              thirtyMinutes: '30 minutes',
              oneHour: '1 hour',
              twoHours: '2 hours',
              threeHours: '3 hours',
              fiveHours: '5 hours',
              teenHours: '10 hours',
              twelveHours: '12 hours',
              fifteenHours: '15 hours',
              twentyHours: '20 hours',
              twentyFourHours: '24 hours',
              whatsappCacheTime: 'WhatsApp cache time',
              firstTime: 'First time',
              secondTime: 'Second time',
              firstInteraction: 'First interaction *',
              secondInteraction: 'Second interaction',
              desiredAction: {
                title: 'Desired Action',
                actionEnd: 'Finish',
                actionTranshipment: 'Tranship',
                messageTranshipment: 'Message before transhipment',
                departmentTranshipment: 'Transfer department',
                tag: 'Tag with'
              },
              facebookCacheTime: 'Facebook cache time',
              webchatCacheTime: 'Webchat cache time',
              controllers: 'Controllers',
              soundType: 'Sound type',
              fullScreenMode: 'Full screen mode',
              disableControls: 'Disable controls',
              disableHeader: 'Disable header',
              automaticOpening: 'Automatic opening',
              notReopen: 'Do not reopen balloon after attendance',
              satisfactionSurvey: 'Satisfaction survey',
              protocolAttendance: 'Protocol attendance',
              includeInternalMessages: 'Include internal messages',
              optionFinishOrMin: 'Option to finish or minimize.',
              imageUploader: 'Image uploader (client)',
              fileUploader: 'File uploader (client)',
              allConversations: 'All conversations',
              conversationsNotTransshipment: 'Attendant conversations and no overflow',
              onlyAttendantConversations: 'Only attendant conversations',
              viewingConversationsForAttendants: 'Viewing conversations for attendants',
              persistence: 'Persistence',
              activated: 'Activated',
              disabled: 'Disabled',
              firstMessage: 'First message',
              firstMessageTime: 'First message time',
              secondMessage: 'Second message',
              secondMessageTime: 'Second message time',
              thirdMessage: 'Third message',
              thirdMessageTime: 'Third message time',
              save: 'Save',
              areYouSure: 'Are you sure you want to save the changes?',
              youCanChange: 'You can change the settings whenever you want, but the',
              previousContent: 'previous content cannot be restored.',
              cancel: 'Cancel',
              interface: 'Interface',
              interfaceSettings: 'Interface Settings',
              behaviorSettings: 'Chatbot settings',
              installation: 'Installation',
              integrations: 'Integrations',
              liveChat: 'Live chat',
              areYouStillAround: 'Are you still around?',
              ifNotResponse: 'If you don\'t answer I\'ll have to finish the attendance.',
              ifNeedHelp: 'Alright, if you need help again let me know!',
              closeMessage: 'The attendance was closed by the attendant, we appreciate your contact and if you have any further questions, just call me again.',
              preferenceSaved: 'Preference saved successfully'
            },
            installation: {
              whatsapp: {
                installation: 'Whatsapp Installation',
                configuredWithChatbot: 'Your WhatsApp is configured with your Chatbot.',
                notConfiguredWithChatbot: 'Your WhatsApp is not configured with your Chatbot.',
                versionHint: 'Whatsapp version to be used',
                version: 'Version',
                instanceHint: 'Instance ID provided by your Project Manager',
                instance: 'Instance',
                tokenHint: 'Instance Token provided by your Project Manager',
                token: 'Token',
                mobileNumberHint: 'Mobile number to be used',
                mobileNumber: 'Cellphone number',
                customerIdentifierHint: 'Customer identifier provided by Globalbot',
                customerIdentifier: 'Customer identifier',
                instructionsMobileNumbers: 'Mobile number instructions',
                stepOneMobileNumbers: 'Enable the chip containing the number you want to connect the chatbot',
                stepTwoMobileNumbers: 'You will receive an SMS on this number containing a numeric code',
                stepThreeMobileNumbers: 'Inform the code received to the Globalbot team',
                stepFourMobileNumbers: 'Enter the instance and token provided by Globalbot in the fields below',
                instructionsFixedNumbers: 'Landline number instructions',
                stepOneFixedNumbers: 'You will receive a call on your number containing a numeric code',
                stepTwoFixedNumbers: 'Inform the code received to the Globalbot team',
                stepThreeFixedNumbers: 'Enter the instance and token provided by Globalbot in the fields below',
                areYouSureSave: 'Are you sure you want to save your changes?',
                youCanChange: 'You can change the settings whenever you want, but the',
                previousContent: 'previous content cannot be restored.',
                cancel: 'Cancel',
                save: 'Save',
                publish: 'Publish',
                versioningMessage: 'To access previous versions, just access the specific bot settings, go to versioning and publish the desired version to go into production.',
                removeConfig: 'Remove configuration',
                reallyRemoveConfig: 'Do you really want to remove the setting?'
              },
              webchat: {
                installation: 'Installation (WebChat and WhatsAppButton)',
                configuredWithChatbot: 'The script is installed on your website',
                notConfiguredWithChatbot: 'The script is not installed on your website',
                installationScript: 'Installation script',
                authorizedDomains: 'Authorized domains',
                domainNumber: 'Domain nº',
                addDomain: 'Add domain',
                instructions: 'Installation Instructions',
                stepOne: 'Copy the installation script on the right',
                stepTwo: 'Insert the script into the HTML code of your website on the pages where you want to activate the chatbot (paste the script immediately after the closing </BODY> tag).',
                stepThree: 'After pasting the script on the desired pages, you must indicate on the right which domains the chatbot was added to.',
                stepFour: 'You must indicate below which domains the chatbot can be activated on.',
                externalBoot: 'External boot',
                youCan: 'You can do external initialization via JavaScript code.',
                accessElement: 'Access the element',
                andUse: 'And use the functions available for startups.',
                openBalloon: 'Open the initial balloon',
                initialBalloon: 'Initial balloon with flow position',
                openChat: 'Open the chat',
                openChatWithFlowPosition: 'Open chat with flow position',
                areYouSureSave: 'Are you sure you want to save your changes?',
                youCanChange: 'You can change the settings whenever you want, but the',
                previousContent: 'previous content cannot be restored.',
                cancel: 'Cancel',
                save: 'Save',
              },
              messenger: {
                installation: 'Facebook Messenger Installation',
                configuredWithChatbot: 'Your Facebook is configured with your Chatbot.',
                reset: 'Reset',
                notConfiguredWithChatbot: 'Your Facebook is not configured with your Chatbot.',
                noPagesAvailable: 'No pages available.',
                synchronize: 'Synchronize',
                almostConfigured: 'Not long after, your Facebook page is almost set up with your Chatbot.',
                yourPage: 'Your page',
                instructions: 'Instructions',
                connectFacebook: 'Click on the Sync button',
                followSteps: 'Follow the step-by-step connection',
                youMustBeAdmin: 'You must be a FanPage administrator to grant access',
                areYouSureSave: 'Are you sure you want to save your changes?',
                youCanChange: 'You can change the settings whenever you want, but the',
                previousContent: 'previous content cannot be restored.',
                cancel: 'Cancel',
                save: 'Save',
                disconnect: 'Desconnect',
                reallyDisconnectPage: 'Do you really want to disconnect this page?'
              },
              telegram: {
                installation: 'Telegram Installation',
                configuredWithChatbot: 'Your Telegram is configured with your Chatbot.',
                notConfiguredWithChatbot: 'Seu Telegram não está configurado com seu Chatbot.',
                tokenProvidedBot: 'Access token for the API provided by Bot Father',
                token: 'Token',
                instructions: 'Instructions',
                talkToThe: 'Talk to the',
                followStep: 'Follow the step-by-step guide to create your bot',
                indicateBelow: 'Enter the access token for the API below',
                areYouSureSave: 'Are you sure you want to save your changes?',
                youCanChange: 'You can change the settings whenever you want, but the',
                previousContent: 'previous content cannot be restored.',
                cancel: 'Cancel',
                save: 'Save',
              },
              instagram: {
                installation: 'Instagram Installation',
                yourPage: 'Your page',
                configuredWithChatbot: 'Your Instagram is configured with your Chatbot.',
                reset: 'Reset',
                notConfiguredWithChatbot: 'Your Instagram is not set up with your Chatbot.',
                motiveConfigure: 'The Globalbot platform can only manage messages from INSTAGRAM BUSINESS accounts. It is not possible to connect and manage messages on personal Instagram accounts.',
                important: 'Important: ',
                synchronize: 'Synchronize',
                instructions: 'Instructions',
                stepOne: 'ADD 1st STEP',
                stepTwo: 'ADD 2nd STEP',
                stepThree: 'ADD 3rd STEP',
                areYouSureSave: 'Are you sure you want to save your changes?',
                youCanChange: 'You can change the settings whenever you want, but the',
                previousContent: 'previous content cannot be restored.',
                cancel: 'Cancel',
                save: 'Save',
              },
              chatbots: 'Chatbots',
              preferences: 'Preferences',
              installation: 'Installation',
              interface: 'Interface',
              interfaceSettings: 'Interface settings',
              behavior: 'Behavior',
              chatbotSettings: 'Chatbot settings',
              integrations: 'Integrations',
              liveChat: 'Live chat',
              youHavePermission: 'Oops, looks like permissions are missing.',
              consultTheConsole: 'Oops, some error happened, see the console.',
              preferenceSaved: 'Preference saved successfully'
            },
            integrations: {
              googleAnalytics: 'This integration is responsible for analyzing statistics of the flow.',
              pipeRun: 'With this integration, the Globalbot platform will record (post) contact (leads) information in Piperun through compatible fields.',
              slack: 'With this integration, the Globalbot platform will send the conversation history to a specific channel. A webhook needs to be configured to receive the event and send messages.',
              vistaSoft: 'This integration is responsible for searching for real estate for rent/purchase according to the user-selected options (number of rooms, type, neighborhood) and recording leads.\nKey fields: phone and/or name and email and "contact_objective"/"interest".',
              rdStation: 'With this integration, the Globalbot platform will record (post) contact (leads) information in RD Station Marketing through compatible fields.\nKey fields: email.',
              pipeDrive: 'With this integration, the Globalbot platform will record (post) contact (leads) information in PipeDrive through compatible fields. It assigns an attendant as responsible based on the email address.',
              rdCrm: 'With this integration, the Globalbot platform will send negotiations to the CRM.\nKey fields: email and/or phone and name.',
              ksi: 'With this integration, the Globalbot platform will record (post) contact (leads) information in Kurole KSI through compatible fields.\nKey fields: email, name, and phone.',
              cv: 'With this integration, the Globalbot platform will record (post) contact (leads) information in the Sales Builder through compatible fields.\nKey fields: email, name, and phone.',
              movidesk: 'With this integration, the Globalbot platform will open tickets.\nKey fields: email.',
              glpi: 'With this integration, the Globalbot platform will open tickets.\nKey fields: name.',
              jira: 'With this integration, the Globalbot platform will create tasks.\nKey fields: email.',
              zendesk: 'With this integration, the Globalbot platform will open tickets.\nKey fields: email.',
              midasSoft: 'With this integration, the Globalbot platform will record (post) contact (leads) information in MidasSoft.',
              rdStationV2: 'This integration is responsible for retrieving information in RD Stations Marketing (only for specific cases).',
              moskit: 'With this integration, the Globalbot platform will be responsible for creating negotiations.',
              funilVendas: 'With this integration, the Globalbot platform will be responsible for creating negotiations.\nKey fields: email, name, and phone.',
              amigo: 'With this integration, the Globalbot platform will schedule medical appointments.',
              agendor: 'With this integration, the Globalbot platform will be responsible for creating negotiations and tasks.\nKey fields: phone.',
              emailLead: 'With this integration, the Globalbot platform will be responsible for sending conversation transcripts by email.',
              shopify: 'This integration is responsible for order tracking.\nKey fields: email.'
            },
            interface: {
              chatbots: 'Chatbots',
              preferences: 'Preferences',
              interface: 'Interface WebChat',
              chatWindow: 'Chat window',
              botName: 'Bot name',
              attendantName: 'Attendant name',
              attendantRole: 'Attendant role',
              avatarImage: 'Avatar image',
              balloonImage: 'Baloon image',
              logo: 'Logo',
              primaryColor: 'Primary color',
              chatHeight: 'Chat height',
              copyRight: 'Copyright (master only)',
              disabled: 'Disabled',
              activated: 'Activated',
              startBalloon: 'Start balloon',
              message: 'Message',
              installationInstructions: 'Website Installation Instructions',
              installationInstructionsDescription: 'Install the WebChat trigger script on your website.',
              position: 'Position',
              left: 'Left',
              right: 'Right',
              textColor: 'Text color',
              backgroundColor: 'Background color',
              templates: 'Templates',
              default: 'Default',
              amplified: 'Amplified',
              ticket: 'Ticket',
              noImage: 'No image',
              noText: 'No text',
              ghost: 'Invisible',
              mobile: 'Mobile',
              save: 'Save',
              areYouSureSave: 'Are you sure you want to save the changes?',
              youCanChange: 'You can change the settings whenever you want, but the',
              previousContent: 'previous content cannot be restored.',
              cancel: 'Cancel',
              sampleQuestion: 'Sample question',
              answerExample: 'Answer example',
              typeYourName: 'Type your name',
              typeYourPhone: 'Type your phone',
              virtualAssistant: 'Virtual Assistant',
              preferenceSaved: 'Preference saved successfully'
            },
            whatsapp: {
              settigns: 'Settings',
              chatbotAndNumber: 'Chatbot | WhatsApp Button Number',
              buttonStatus: 'Button Status',
              buttonMessage: 'Button Message (optional)',
              buttonPosition: 'Button Position',
              initialMessageWhatsapp: 'Initial WhatsApp Message (optional)',
              initialMessageWhatsappDescription: 'This is the text that will be automatically inserted into the message field of the WhatsApp application.',
              enabled: 'Enabled',
              disabled: 'Disabled',
              rightPosition: 'right (bottom corner)',
              leftPosition: 'left (bottom corner)',
              installationInstructions: 'Website Installation Instructions',
              installationInstructionsDescription: 'Install the WhatsApp Button trigger script on your website.',
              traceOrigin: 'Track Traffic Source (Tracking)',
              traceOriginDescription: 'When this feature is enabled, users will be asked to provide their WhatsApp phone number before starting the conversation. This allows identifying the page the user was on when visiting the website.',
              requestPhone: 'Request phone and enable traceability.',
              logoOrAvatar: 'Image',
              visibleName: 'Visible Name (Display Name)',
              resquestPhoneMessage: 'Message to request WhatsApp number',
              copyright: 'Copyright (master only)'
            },
            master: {
              chatbots: 'Chatbots',
              preferences: 'Preferences',
              master: 'Master',
              locationOfServers: 'Location of servers (master only)',
              newPln: 'New PLN (master only)',
              realTime: 'Real time (master only)',
              cacheConversation: 'Cache conversation (master only)',
              offshoreLastQuestion: 'Offshore last question (master only)',
              excludeChatbot: 'Delete chatbot',
              createCliWithoutBackground: 'Create CLI without Background',
              save: 'Save',
              areYouSureSave: 'Are you sure you want to save the changes?',
              youCanChange: 'You can change the settings whenever you want, but the',
              previousContent: 'previous content cannot be restored.',
              doYouReallyExclude: 'Do you really want to delete the chatbot?',
              cancel: 'Cancel',
              exclude: 'Delete',
              interface: 'Interface',
              interfaceSettings: 'Interface settings',
              behavior: 'Behavior',
              chatbotSettings: 'Chatbot settings',
              installation: 'Installation',
              integrations: 'Integrations',
              areYouStillAround: 'Are you still around?',
              ifYouNotResponse: 'If you don\'t answer I\'ll have to finish the attendance.',
              ifYouNeedHelp: 'Alright, if you need help again let me know!',
              closeMessage: 'The attendance was closed by the attendant, we appreciate your contact and if you have any further questions, just call me again.',
              preferenceSaved: 'Preference saved successfully'
            },
            messages: {
              chatbots: 'Chatbots',
              preferences: 'Preferences',
              messages: 'Messages',
              portuguese: 'Portuguese',
              english: 'English',
              spanish: 'Spanish',
              faqNotFound: 'FAQ not found',
              treatment: 'When the user types good morning instead of their name.',
              noName: 'When the user type it: My name is...',
              ask: 'When the user asks a question.',
              special: 'When the user puts some special character',
              justYourName: 'When the user types the first expletive',
              cursed: 'When the user types the second expletive and closes the chatbot.',
              knowledgeName: 'When there is an intention in intelligence and the user has put it in the name.',
              limit: 'When the user types beyond the allowed limit.',
              manyItems: 'When disambiguating a question',
              faqNoneButton: 'When disambiguated, a button appears with that phrase',
              connectLive: 'When connecting to Live Chat',
              firstNameError: 'When the user misses the name',
              textError: 'When the user does not type anything in a text request',
              emailError: 'When the user misses the email',
              moneyError: 'When the user is asked to enter a monetary amount in error',
              phoneError: 'When the user misses the phone',
              cpfError: 'When the user misses the CPF',
              cnpjError: 'When the user misses the CNPJ',
              faqError: 'When the user doesn\'t type anything in a FAQ',
              save: 'Save',
              areYouSureSave: 'Are you sure you want to save the changes?',
              youCanChange: 'You can change the settings whenever you want, but the',
              previousContent: 'previous content cannot be restored.',
              cancel: 'Cancel',
              interface: 'Interface',
              interfaceSettings: 'Interface Settings',
              behavior: 'Behavior',
              chatbotSettings: 'Chatbot settings',
              installation: 'Installation',
              integrations: 'Integrations',
              areYouStillAround: 'Are you still around?',
              ifYouNotResponse: 'If you don\'t answer I\'ll have to finish the attendance.',
              ifYouNeedHelp: 'Alright, if you need help again let me know!',
              closeMessage: 'The attendance was closed by the attendant, we appreciate your contact and if you have any further questions, just call me again.',
              typeYourName: 'To start our conversation, enter your name.',
              onlyName: 'Just enter your name.',
              toContinueTypeYourNamePlease: 'To continue, please enter your name. ',
              typeYourNamePleaseSpecial: 'Looks like you added a special character. Just enter your name.',
              continueTheConversation: 'That way I can\'t continue the conversation.',
              typeYourNamePlease: 'Please enter only your name.',
              toAnswerYourQuestions: 'To answer your questions, I need you to enter your name.',
              virtualAssistant: 'Virtual Assistant',
              maxLimit: 'You have exceeded the maximum character limit.',
              iCanHelpYou: 'In this question of yours I can help you with more than one option.',
              noneOption: 'None of the above',
              notFound: 'I did not understand your question.',
              waitMoment: 'Wait a moment. An attendant will talk to you right away.',
              selectOneOptions: 'Select one of the options',
              typeName: 'Type your name',
              typeNameError: 'Please enter a valid name.',
              typeFirstName: 'Enter your first name',
              typeFirstNameError: 'Just enter your first name.',
              typeLastName: 'Enter your last name',
              typeLastNameError: 'Just enter your last name.',
              typeHere: 'Type here',
              typeHerePlease: 'Please type something.',
              typeEmail: 'Type your e-mail',
              typeEmailError: 'Please enter a valid email address.',
              typeMoney: 'Enter your value',
              typeMoneyError: 'Please enter a valid amount.',
              typePhone: 'Enter your phone',
              typePhoneError: 'Please enter a valid phone number.',
              typeCpf: 'Enter your CPF',
              typeCpfError: 'Please enter a valid CPF.',
              typeBornDate: 'Enter your birth date',
              typeBornDateError: 'Please advise your valid birth date.',
              typeCnpj: 'Enter your CNPJ',
              typeCnpjError: 'Please enter a valid CNPJ.',
              typeFaq: 'Enter your question',
              typeFaqError: 'Please fill in something.',
              selectListFaq: 'Choose an option',
              selectListFaqError: 'Please choose an option.',
              selectRating: 'Select',
              search: 'Search',
              searchCountry: 'Search country',
              searchState: 'Search state',
              searchCity: 'Search city',
              typeMessage: 'Enter your message...',
              typeMessageError: 'Please, type your message',
              selectDate: 'Choose a date',
              selectDateError: 'Please choose a date',
              selectSize: 'Choose a size',
              selectSizeError: 'Please choose a size',
              typeNumber: 'Enter a number',
              typeNumberError: 'Please enter a number',
              preferenceSaved: 'Preference saved successfully'
            },
            chatbots: 'Chatbots',
            preferences: 'Preferences',
            interfaceChatbot: 'WebChat InterFace',
            setAllVisual: 'Enable and define the visual aspects and functions of WebChat (chat window) installed on the website.',
            behaviorChatbot: 'Behavior',
            accessTheBehaviorSettings: 'Access chatbot behavior settings (time controls, trigger, chat window view modes and chat persistence).',
            installationChatbot: 'Installation',
            accessTheInstallationSettings: 'Access the chatbot installation and activation settings on each of the channels: WebChat, WhatsApp, Instagram, Messenger and Telegram.',
            whatsappChatbot: 'Whatsapp Button',
            whatsappChatbotDescription: 'Enable and define the visual aspects of the WhatsApp Button for your website and configure the Track Traffic Source feature.',
            integrationsChatbot: 'Integrations',
            accessTheIntegrationSettings: 'Access the configuration panel for the various Globalbot integrations with other systems. Some of these configurations are performed by our technical team due to complexity and security.',
            masterChatbot: 'Master',
            exclusiveAccess: 'Globalbot team exclusive access to advanced settings such as server location, conversation cache, artificial intelligence behavior and real time.',
            excludeVersionerAccess: 'Exclusive access for the Globalbot team to view Flow Versions.',
            messagesChatbot: 'Messages',
            modifyMessages: 'Here you can modify some of the messages, only for Masters at the moment.'
          },
          permission: {
            permission: {
              permission: 'Permissions',
              preferences: 'Preferences',
              conversations: 'Conversations',
              manager: 'Manager',
              attendant: 'Attendant',
              seeAll: 'See all',
              seeAttendedAndWithoutTransshipment: 'See attended and without transshipment',
              onlyAttended: 'See only answered',
              junior: 'Junior',
              done: 'Save',
              updatedPermissions: 'Permissões atualizadas!',
              connectionError: 'Oops, connection error.'
            }
          },
          users: {
            access: {
              preferences: 'Preferences',
              users: 'Users',
              journey: 'Journey',
              addJourney: 'Add Journey',
              name: 'Name',
              description: 'Description',
              actions: 'Actions',
              edit: 'Edit',
              linkUsers: 'Link users',
              exclude: 'Delete',
              totalOf: 'Total of',
              usersBeing: 'users, being',
              inNone: 'in none',
              inOneAnd: 'in one and',
              inTwoOrMore: 'in two or more journeys.',
              save: 'Save',
              cancel: 'Cancel',
              journeyData: 'Journey Details',
              journeyName: 'Journey name *',
              comments: 'Comments',
              schedules: 'Schedules',
              dayOfTheWeek: 'Day of the week *',
              sunday: 'Sunday',
              monday: 'Monday',
              tuesday: 'Tuesday',
              wednesday: 'Wednesday',
              thursday: 'Thursday',
              friday: 'Friday',
              saturday: 'Saturday',
              input: 'Input *',
              exit: 'Exit *',
              addTime: 'Add time',
              tolerance: 'Tolerance',
              defineTheTolerance: 'Define the tolerance time for this journey to access and stay on the platform.',
              important: 'Important',
              onceTheTolerance: 'Once the tolerance time is reached, the platform will issue an alert and a countdown timer for the user to calmly end their activities.',
              whenTheTotal: 'When the total length of stay is finally reached, the user will be logged out automatically. Only administrator users will be able to log into the platform outside their working hours.',
              ifUser: 'If a user is linked in more than one journey, access and permanence will take into account the journey with the longest duration (both for entry and exit).',
              journeyUsers: 'Journey Users',
              outOfTheJourney: 'Out of the journey',
              withinTheJourney: 'Within the journey',
              addSelected: 'Add selected',
              addAll: 'Add all',
              removeAll: 'Remove all',
              removeSelected: 'Remove selected',
              nonexistent: 'Nonexistent',
              deleteJourney: 'Delete Journey',
              areYouSure: 'Are you sure you want to delete this journey? All data from it will be lost and this action cannot be undone.',
              fiveMinutes: '5 minutes',
              tenMinutes: '10 minutes',
              fifteenMinutes: '15 minutes',
              twentyMinutes: '20 minutes',
              twentyFiveMinutes: '25 minutes',
              thirtyMinutes: '30 minutes',
              oneHour: '1 hour',
              twoHours: '2 hours',
              fourHours: '4 hours',
              sixHours: '6 hours',
              twelveHours: '12 hours',
              journeyCreated: 'Your journey has been created!',
              journeyEdited: 'Your journey has been edited!',
              errorAtTheTime: 'Oops, we had an error at the time!',
              journeyExcluded: 'Your journey has been deleted!',
              errorCompletingThisAction: 'Oops, we had a problem completing this action.'
            },
            preferences: 'Preferences',
            users: 'Users',
            addNewUser: 'Add new users to the platform. If you reach your limit, request the expansion of it.',
            createUser: 'Create user',
            permissions: 'Permissions',
            managerUserPermissions: 'Manage user permissions, enabling or restricting access to platform features.',
            journeys: 'Journeys',
            defineTheJourney: 'Define the working hours of your users, controlling access and permanence on the platform.',
            extendLimit: 'Extend Limit',
            yourCurrentLimit: 'Is your current limit not enough? Order the extension now.',
            avatar: 'Avatar',
            addAvatar: 'Add avatar',
            name: 'Name',
            nameRequired: 'Name *',
            email: 'Email',
            emailRequired: 'Email *',
            role: 'Role',
            roleRequired: 'Role *',
            departmentsFilter: 'Select a Department',
            chatbotsFilter: 'Select a Chatbot',
            linked: 'Linked',
            unlinked: 'Unlinked',
            permission: 'Permission',
            permissionRequired: 'Permission *',
            actions: 'Actions',
            accessReport: 'Access report',
            edit: 'Edit',
            exclude: 'Delete',
            understood: 'Understood',
            important: 'IMPORTANT',
            impossibleToDelete: ': Unable to delete this user because he has ',
            activeConversations: 'active conversations. You must transfer or terminate this user\'s conversations before requesting deletion.',
            save: 'Save',
            cancel: 'Cancel',
            close: 'Close',
            userData: 'User Data',
            ddi: 'DDI',
            phone: 'Phone',
            newPassword: 'New Password *',
            chatbots: 'Chatbots',
            departments: 'Departments',
            accessLogs: 'Access Logs',
            initialDate: 'Initial date',
            startTime: 'Start time',
            finalDate: 'Final date',
            endTime: 'End time',
            status: 'Status',
            date: 'Date',
            intentions: 'Intentions',
            intentionsDescription: 'Select the date and time for the filter to search within the chosen area.',
            available: 'Online',
            away: 'Away',
            offline: 'Offline',
            connected: 'Connected',
            disconnected: 'Disconnected',
            excludeUser: 'Delete user',
            endJourney: 'End Journey',
            areYouSureDelete: 'Are you sure you want to delete this user? All his or her data will be lost and this action cannot be undone.',
            youCanRequest: 'You can request additional users for use on the platform. Please enter your details below and one of our specialists will contact you.',
            amount: 'Amount *',
            request: 'Request',
            primary: 'Primary',
            administrator: 'Administrator',
            manager: 'Manager',
            attendant: 'Attendant',
            junior: 'Junior',
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
            shortJan: 'Jan',
            shortFeb: 'Feb',
            shortMar: 'Mar',
            shortApr: 'Apr',
            shortMay: 'May',
            shortJun: 'Jun',
            shortJul: 'Jul',
            shortAug: 'Aug',
            shortSep: 'Sep',
            shortOct: 'Oct',
            shortNov: 'Nov',
            shortDec: 'Dec',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            shortSunday: 'Sun',
            shortMonday: 'Mon',
            shortTuesday: 'Tue',
            shortWednesday: 'Wed',
            shortThursday: 'Thu',
            shortFriday: 'Fri',
            shortSaturday: 'Sat',
            exportToSVG: 'Export to SVG',
            exportToPNG: 'Export to PNG',
            exportToCSV: 'Export to CSV',
            menu: 'Menu',
            selection: 'Select',
            selectionZoom: 'Select zoom',
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom out',
            pan: 'Mover',
            reset: 'Return to default',
            userDeleted: 'Usuário excluído.',
            errorUserDelete: 'Oops, não conseguimos excluir este usuário!',
            notLimit: 'Você não tem mais limite para adicionar usuários.',
            errorPasswordLength: 'Enter a password longer than 6 characters.',
            errorSavingUser: 'Fill in all mandatory fields.',
            theArchitect: 'O Arquiteto',
            notFound: 'Não encontrado',
            dominion: 'Domínio',
            master: 'Mestre',
            senior: 'Sênior',
            failedToRequest: 'Oops, failed to request users.',
            requestedUsers: 'Requested users.',
            requiredFields: 'You need to fill in the fields.',
            userCreatedSuccessfully: 'User created successfully!',
            userUpdatedSuccessfully: 'User updated successfully!'
          },
          domains: {
            preferences: 'Preferences',
            domains: 'Domains',
            addNewDomainsToThePlatform: 'Add new domains to the platform. First, the domain\'s DKIM, TXT and CNAME must be registered.',
            addDomain: 'Add domain',
            creation: 'Creation',
            domain: 'Domain',
            emails: 'E-mails',
            name: 'Name',
            actions: 'Actions',
            waiting: 'Waiting',
            verified: 'Verified',
            type: 'TIPO',
            host: 'HOST',
            data: 'DATA',
            verifyDomain: 'Verify domain',
            exclude: 'Delete',
            addNewDomain: 'Add new domain',
            insertRecords: 'Now you need to enter the following records into your DNS.',
            updateDomain: 'A propagação do seu DNS pode levar algumas horas, e o status do seu domínio será atualizado na plataforma.',
            record: 'Record',
            nameToUpperCase: 'NAME',
            valueToUpperCase: 'VALUE',
            cancel: 'Cancel',
            add: 'Add',
            addNewEmail: 'Add new email',
            confirmationEmail: 'You will receive a confirmation email, click on the link in the body of the email to proceed with the email verification.',
            email: 'Email',
            excludeDomain: 'Delete Domain',
            areYouSure: 'Are you sure you want to delete this domain?',
            domainSuccessfully: 'Domain successfully registered.',
            connectionError: 'Connection error',
            domainAlreadyRegistered: 'Domain already registered, try again with another one.',
            startTheProcess: 'To start the process you need a domain.',
            deletedDomain: 'Deleted domain.',
            domainSuccessfullyValidated: 'Domain successfully validated!',
            fieldsNotAuthenticated: 'There are some fields not yet authenticated, '
          },
          lists: {
            preferences: 'Preferences',
            lists: 'Lists',
            deleteSelectedLists: 'Delete selected lists',
            avatar: 'Avatar',
            title: 'Title',
            amount: 'Amount',
            actions: 'Actions',
            view: 'View',
            exclude: 'Delete',
            save: 'Save',
            cancel: 'Cancel',
            listData: 'List data',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            excludeList: 'Delete list',
            areYouSure: 'Are you sure you want to delete this list?',
            listDeleted: 'Your list has been deleted',
            errorDeleteThisList: 'Oops, we couldn\'t delete this list!'
          },
          dataBases: {
            importData: 'Import data',
            name: 'Name',
            cdt: 'Date/Hour',
            replaceData: 'Replace data',
            import: 'Import',
            importCSV: "Import CSV file",
            syncGoogleSheet: "Sync with Google Sheets",
            invalidSpreadsheetId: 'Invalid worksheet ID',
            foundSheets: 'Worksheets found',
            shareSpreadsheet: 'You need to share your spreadsheet with this email: ',
            listPages: 'List pages',
            reportSpreadsheetId: 'Enter the spreadsheet ID',
            reportTabSheet: 'Select the spreadsheet you want to import.',
            reportName: 'Put a name',
            alreadyBaseName: 'There already exists a base with this name',
            alreadyBaseSheetName: 'There is already a base with this spreadsheet page',
            dataGoogleSpreadSheet: 'Google spreadsheet data',
            deleteDataBase: 'Delete database',
            spreadSheetId: 'Google spreadsheet ID',
            sheetPageName: 'Page name',
            close: 'Close',
            delete: 'Delete'

          },
          live: {
            serviceMessages: 'Service messages',
            portuguese: 'Portuguese',
            english: 'English',
            spanish: 'Spanish',
            completionMessage: 'Completion message',
            archivalMessage: 'Archival message',
            transferMessage: 'Transfer message',
            automaticTransferToAttendant: 'Automatic transfer to attendant',
            transshipmentAvailableForAttendants: 'Transshipment available for attendants',
            queueTransshipmentWithoutDistribution: 'Queue transshipment (without distribution)',
            queueTransshipmentWithDistribution: 'Queue transshipment (with distribution)',
            transshipmentAwaitingAttendants: 'Transshipment awaiting attendants (check in disabled)',
            noAttendantsAvailable: 'No attendants available (check in enabled)',
            tipDepartmentIsPersistent: 'It is not possible to activate check-in as this department is configured in the bots persistence: ',
            pauseOptions: 'Pause Options',
            activated: 'Activated',
            disabled: 'Disabled',
            enterHereAll: 'Enter here all possible breaks that attendants can select.',
            add: 'Add option',
            automaticAway: 'Automatic away',
            determineTheCheckInTime: 'Determine the check-in time.',
            time: 'Time',
            viewingOptions: 'Viewing Options',
            autoArchive: 'Time for conversations archive',
            autoArchiveCaption: 'Configure the live chat auto conversation archive.',
            conversationCloseOptions: "Conversation Close Options",
            conversationCloseOptionsDescription: "Enable tagging or assigning value when closing a conversation.",
            addTag: "Assign Tags",
            addMonetary: "Assign Value",
            required: "Required",
            coin: "Currency",
            coinTypes: {
              BRL: "Brazilian Real - BRL",
              USD: "American Dollar - USD"
            },
            chooseTheLevel: 'Choose the level of access your operators will be able to see in the attendant list.',
            managers: 'Managers',
            attendants: 'Attendants',
            departments: 'Departments',
            defineTheDepartments: 'Define the departments of the Live Chat module below. Departments are useful for "routing" contact requests, handling them in a segmented way.',
            whenCreatingTheDepartments: 'When creating the departments, use the User settings to define in which department(s) each user can operate.',
            name: 'Name',
            setBehavior: 'Set the inbound behavior if you don\'t have any attendants online at the moment.',
            checkIn: 'Check-in',
            forcePass: 'Force Pass',
            forcePassDescription: 'Also distribute calls to offline attendants.',
            forcePassDistributionNone: 'You need to select a distribution type when Force Pass is enabled.',
            queueDisable: 'The Queue is mandatory when Force Pass is enabled.',
            forcePassDisable: 'It is not possible to activate Force Pass with Check-in enabled.',
            checkInDisable: 'It is not possible to activate Check-in with Force Pass enabled.',
            attendanceQueue: 'Attendance queue',
            transmitQueuePosition: 'Transmit queue position',
            transmitQueuePositionDescription: 'Broadcast the position of the queue to the waiting customers.',
            maxAttendances: 'Maximum attendance',
            maxAttendancesDescription: 'Set the maximum amount that each server can serve simultaneously.',
            customersByAttendants: 'Customers by attendants',
            distribution: 'Distribution',
            distributionDescription: 'Choose the way to distribute the calls in the Live Chat module.',
            balancing: 'Balancing',
            disabledNone: 'Disabled',
            autoBalance: 'Auto Balance',
            sequential: 'Sequential',
            save: 'Save',
            confirm: 'Comfirm',
            exclude: 'Delete',
            addDepartment: 'Add department',
            areYouSureSaved: 'Are you sure you want to save the changes?',
            areYouSureDeleted: 'Are your sure you want to delete this deparment?',
            youCanChange: 'You can change the settings whenever you want, but the',
            previousContent: 'previous content cannot be restored.',
            cancel: 'Cancel',
            finishRoom: 'The conversation ended.',
            archiveRoom: 'The conversation has been archived.',
            transferRoom: 'The conversation was transferred.',
            withAttendant: 'With attendant.',
            available: 'Available.',
            queue: 'In line.',
            queueDistribution: 'Automatic queued',
            waiting: 'Waiting',
            noVacancy: 'No attendant at the moment.',
            oneMinute: '1 minute',
            twoMinutes: '2 minutes',
            threeMinutes: '3 minutes',
            fourMinutes: '4 minutes',
            fiveMinutes: '5 minutes',
            tenMinutes: '10 minutes',
            fifteenMinutes: '15 minutes',
            thirtyMinutes: '30 minutes',
            oneHour: 'One hour',
            seeAll: 'See all',
            onlyDepartments: 'See only in departments',
            preferences: 'Preferences',
            liveChat: 'Live chat',
            updated: 'Updated!',
            myDepartment: 'My department',
            departmentAdded: 'Department added!',
            deletedDepartment: 'Deleted department!'
          },
          settings: {
            preferences: 'Preferences',
            users: 'Users',
            usersDescription: 'Administer Globalbot platform users. You can add, exclude users, and set and edit access permissions and roles for each of them independently.',
            liveChat: 'Live chat',
            liveChatDescription: 'Configure the Live Chat module settings. Create departments, define the form of distribution of calls and automatic messages.',
            tags: 'Tags',
            tagsDescription: 'Access the tag system settings, which can be used to tag and classify various items in a list or collection (eg Conversations, Intelligence and Library)',
            domainsAndEmail: 'Domains and Emails',
            domainsAndEmailDescription: 'Configure your company\'s domains and emails so that they appear as senders in Messenger submissions (emails, notifications and notices).',
            lists: 'Lists',
            listsDescription: 'Access and control all lists created and uploaded to the platform.',
            dataBases: 'Data Base',
            dataBasesDescription: 'Import external databases to be integrated with chatbot flows.'
          },
          tags: {
            preferences: 'Preferences',
            tags: 'Tags',
            add: 'Add',
            columns: 'Colunns',
            color: 'Color',
            category: 'Category',
            title: 'Title',
            description: 'Description',
            actions: 'Actions',
            edit: 'Edit',
            exclude: 'Delete',
            save: 'Save',
            cancel: 'Cancel',
            tagData: 'Tag Data',
            deleteTag: 'Delete tag',
            areYouSureDelete: 'Are you sure you want to delete this tag? This action cannot be undone.',
            general: 'General',
            reports: 'Reports',
            realTime: 'Real time',
            analytics: 'Statistics',
            organization: 'Organization',
            companies: 'Companies',
            people: 'People',
            conversation: 'Conversations',
            flow: 'Flow',
            knowledge: 'Intelligence',
            message: 'Message',
            liveChat: 'Live chat',
            library: 'Library',
            tasks: 'Tasks',
            deletedTag: 'Deleted tag.',
            errorDeleteThisTag: 'Oops, we couldn\'t delete this tag!',
            errorSavingTag: 'Oops, some error happened while saving the tag.',
            requiredFields: 'The title and color field need to be filled.'
          }
        }
      }
    },
    'es-ES': {
      components: {
        action: {
          requisition: {
            request: 'Solicitud',
            method: 'Método',
            run: 'Run',
            messageError: 'Vaya, faltan datos.'
          }
        },
        advancedSearch: {
          index: {
            myFilters: 'Mis filtros de búsqueda',
            custom: 'Personalizado',
            today: 'Hoy dia',
            yesterday: 'El dia de ayer',
            thisMonth: 'Este mes',
            lastSevenDays: 'Los últimos 7 días',
            lastFifteenDays: 'Los últimos 15 días',
            lastThirtyDays: 'Los últimos 30 días',
            search: 'Buscar',
            initialDate: 'La fecha de inicio',
            initialHour: 'Hora de inicio',
            endDate: 'Fecha final',
            endHour: 'Hora de finalización',
            chatbots: 'Chatbots',
            channel: 'Canal',
            all: 'Todos',
            webchat: 'Webchat',
            dialogue: 'Diálogo',
            dialogueDescription: 'Puede filtrar por quién inició la conversación, asistente o cliente.',
            botDeleted: 'Robot eliminado',
            active: 'Chatbot/Activo',
            receptive: 'Cliente/Receptivos',
            whatsapp: 'Whatsapp',
            messenger: 'Messenger',
            gbm: 'Google Business Messages',
            rcs: 'RCS',
            instagram: 'Instagram',
            telegram: 'Telegram',
            identifiers: 'Identificadores',
            filter: 'Filtrar',
            fill: 'Llenar',
            filled: 'Lleno',
            notFilled: 'No llenado',
            transhipment: 'Transbordo',
            transhipmentDescription: 'Esta es una característica nueva, los filtros basados en este campo no se aplican a fechas anteriores al 19/03/2020.',
            indifferent: 'Indiferente',
            attended: 'Asistió',
            notAttended: 'No asistió',
            unrealized: 'No realizados',
            none: 'Ninguno',
            attendants: 'Acomodadores',
            departments: 'Departamentos',
            otherOptions: 'Otras opciones',
            onlyConversations: 'Solo conversaciones',
            allConversations: 'Todos',
            incompleteConversations: 'Incompleta',
            conversationFinished: 'Finalizada',
            satisfaction: 'Satisfacción',
            oneStar: '1 estrella',
            twoStar: '2 estrellas',
            threeStar: '3 estrellas',
            fourStar: '4 estrellas',
            fiveStar: '5 estrellas',
            tags: 'Tags',
            term: 'Términos',
            inIdentifier: 'En el identificador',
            clear: 'Limpiar',
            createFilter: 'Crear filtro',
            title: 'Título',
            save: 'Guardar',
            messageError: 'Vaya, hubo un error.',
            listBy: 'Lista de ',
            savedFilter: '¡Tu filtro se ha guardado!',
            of: 'de',
            selectAll: 'Todo'
          }
        },
        analytics: {
          quickview: {
            conversation: {
              noDataAvailable: 'Datos no disponibles.'
            },
            knowledge: {
              noDataAvailable: 'Datos no disponibles.'
            },
            rating: {
              noDataAvailable: 'Datos no disponibles.'
            },
            usersConnected: {
              noDataAvailable: 'Datos no disponibles.'
            }
          },
          attendants: {
            attendants: 'Acomodadores',
            satisfaction: 'Satisfacción',
            attendance: 'Asistencias',
            reviewsAnswered: 'Reseñas respondidas',
            onlineTime: 'Tiempo Online',
            awayTime: 'Tiempo Away',
            tta: 'TTA - (Tiempo total de asistencia)',
            tma: 'TMA - (Tiempo medio de asistencia)',
            noDataAvailable: 'Datos no disponibles.',
            name: 'Nombre'
          },
          botAndAttendant: {
            attendants: 'Acomodadores',
            noDataAvailable: 'Datos no disponibles.',
            chatbots: 'Chatbots'
          },
          bubble: {
            sunday: 'Domingo',
            hours: ' horas',
            attendance: ' asistencias'
          },
          campaign: {
            utmSource: 'UTM Source',
            tip: 'TIP',
            utmMedium: 'UTM Medium',
            utmCampaign: 'UTM Campaign',
            utmTerm: 'UTM Term',
            utmContent: 'UTM Content',
            withCampaign: 'Con campaña',
            withoutCampaign: 'Sin campaña'
          },
          channel: {
            noDataAvailable: 'Datos no disponibles.',
            total: 'Total',
            impressions: 'Impresiones',
            impressionsDescription: 'En WebChat, es el volumen de veces que el chatbot apareció disponible para hacer clic/activar.',
            attendance: 'Asistencias',
            attendanceDescription: 'Cuando un usuario (cliente) activa el chatbot para iniciar la conversación y se inicia una asistencia.',
            messages: 'Mensajes',
            messagesDescription: 'Volumen total de mensajes intercambiados en todas las conversaciones (respuestas). Ingrese usuario (cliente), chatbots y asistentes (si corresponde).',
            ttAttendance: 'TT Asistencia',
            ttAttendanceDescription: 'Es el tiempo total de servicio del canal, desde el momento de la activación hasta el final de la conversación.',
            tmAttendance: 'TM Asistencia',
            tmAttendanceDescription: 'Es el tiempo medio de servicio del canal, considerando el período.',
            totalTransshipments: 'Transbordos totales',
            totalTransshipmentsDescription: 'Número de solicitudes en las que el chatbot solicita la asistencia de un asistente, ya sea desencadenado por una regla de flujo o por el usuario (cliente).',
            transshipmentsAttended: 'Transbordos atendidos',
            transshipmentsAttendedDescription: 'Número de llamadas en las que el chatbot pasó la conversación al asistente y él pudo responder.',
            unattendedTransshipments: 'Transbordos desatendidos',
            unattendedTransshipmentsDescription: 'Número de llamadas en las que el chatbot pasó la conversación al asistente y no pudo responder.',
            unrealizedTransshipments: 'Transbordos no enviados',
            unrealizedTransshipmentsDescription: 'Número de llamadas en las que el chatbot no pudo transferir la conversación a un asistente (no había asistentes en línea en ese momento). El chatbot reanudó la conversación.',
            ttWait: 'TT Espera',
            ttWaitDescription: 'Es el tiempo total que los usuarios (clientes) permanecieron en espera para ser atendidos por los asistentes (cuando la cola de servicio está habilitada).',
            tmWait: 'TM Espera',
            tmWaitDescription: 'Es el tiempo medio que los usuarios (clientes) permanecieron en espera para ser atendidos por los asistentes (cuando la cola de servicio está habilitada).',
            satisfaction: 'Satisfacción',
            satisfactionDescription: 'Es el índice de satisfacción del servicio en general (una encuesta que se activa al finalizar la asistencia). Escala de 0 a 5 y número de encuestados entre paréntesis.',
            monetaryValueDescription: 'Consulta el valor total generado por las conversaciones durante el periodo (muy útil para quienes venden productos y servicios). Para aparecer aquí, el valor debe asignarse a cada conversación individualmente.',
            january: 'Enero',
            february: 'Febrero',
            march: 'Marcha',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre',
            shortJan: 'Ene',
            shortFeb: 'Feb',
            shortMar: 'Mar',
            shortApr: 'Abr',
            shortMay: 'May',
            shortJun: 'Jun',
            shortJul: 'Jul',
            shortAug: 'Ago',
            shortSep: 'Sep',
            shortOct: 'Oct',
            shortNov: 'Nov',
            shortDec: 'Dic',
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
            shortSunday: 'Dom',
            shortMonday: 'Lun',
            shortTuesday: 'Mar',
            shortWednesday: 'Mié',
            shortThursday: 'Jue',
            shortFriday: 'Vie',
            shortSaturday: 'Sab',
            exportToSVG: 'Exportar a SVG',
            exportToPNG: 'Exportar a PNG',
            exportToCSV: 'Exportar a CSV',
            menu: 'Menú',
            selection: 'Seleccione',
            selectionZoom: 'Seleccionar zoom',
            zoomIn: 'Acercarse',
            zoomOut: 'Disminuir el zoom',
            pan: 'Para mover',
            reset: 'Volver al valor predeterminado'
          },
          city: {
            noDataAvailable: 'Datos no disponibles.',
            others: 'Otros'
          },
          country: {
            noDataAvailable: 'Datos no disponibles.',
            others: 'Otros'
          },
          department: {
            noDataAvailable: 'Datos no disponibles.'
          },
          heat: {
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
            hours: ' horas',
            attendance: ' atenciones'
          },
          identifier: {
            selectIdentifier: 'Seleccione un identificador',
            noDataAvailable: 'Datos no disponibles.',
            others: 'Otros',
            limitCards: 'Cantidad máxima de 12 vistas. Para crear una nueva, por favor elimine una existente.'
          },
          knowledgeAnswered: {
            noDataAvailable: 'Datos no disponibles.',
            answered: 'Contestado',
            unambiguous: 'Desambiguación',
            unanswered: 'Sin respuesta'
          },
          knowledgeResponses: {
            noDataAvailable: 'Datos no disponibles.',
            others: 'Otros'
          },
          pivot: {
            origin: 'Origen',
            total: 'Total'
          },
          platform: {
            noDataAvailable: 'Datos no disponibles.',
            incognito: 'Incógnito'
          },
          sankey: {
            noDataAvailable: 'Datos no disponibles.',
            to: 'Para',
            from: 'De',
            amount: 'La cantidad'
          },
          state: {
            noDataAvailable: 'Datos no disponibles.',
            others: 'Otros'
          }
        },
        drawers: {
          broadcast: {
            template: {
              email: {
                save: 'Guardar',
                cancel: 'Cancelar',
                messageEditor: 'Editora de mensajes',
                personalizeEmail: 'Puede personalizar su correo electrónico usando variables.',
                library: 'Biblioteca',
                messageErrorToCompile: '¡Error al compilar el correo electrónico!',
                templateSaved: '¡Tu plantilla se ha guardado!',
                messageErrorConnection: '¡Vaya, hubo un error de conexión!'
              },
              sms: {
                save: 'Guardar',
                cancel: 'Cancelar',
                messageEditor: 'Editora de mensajes',
                message: 'Mensaje *',
                messageHas: 'Tu mensaje tiene',
                characters: 'caracteres.',
                discountCredits: 'Se descontarán',
                discountCreditsFinal: 'créditos por cada número.',
                overcomeTheCharacters: 'En los casos en que el contenido de SMS supere los 160 caracteres, se cobrará más de un crédito por cada 153 caracteres.',
                notSupportConcatenation: 'Algunos operadores como Oi y Sercomtel no admiten la concatenación de mensajes, por lo que los SMS se recibirán por separado.',
                templateSaved: '¡Tu plantilla se ha guardado!',
                messageErrorConnection: '¡Vaya, hubo un error de conexión!',
                messageNeedFilled: '¡Debes completar los campos de nombre y mensaje!'
              },
              whatsapp: {
                save: 'Guardar',
                cancel: 'Cancelar',
                messageEditor: 'Editora de mensajes',
                message: 'Mensaje *',
                messageHas: 'Tu mensaje tiene',
                characters: 'caracteres.',
                discountCredits: 'Se descontarán dos créditos por cada número.',
                integrations: 'La integración entre Globalbot y WhatsApp permite enviar mensajes activos considerando las siguientes pautas:',
                activeMessages: 'Mensajes Activos:',
                activeMessagesDescription: 'En este caso, el chatbot de WhatsApp se utiliza para notificar y enviar mensajes a los clientes. Y el origen de la asistencia (primer mensaje) es de la empresa. Si el cliente responde al mensaje, se iniciará una nueva sesión de chat (o asistencia).',
                dispatchesInLargeScale: 'Envíos a granel:',
                dispatchesInLargeScaleDescription: 'Es importante prestar atención a este tipo de uso, ya que no se permiten envíos masivos (cuando se relacionen con promociones, divulgaciones, publicidad y similares). Se aceptan envíos automatizados a grupos de clientes, como notificaciones y avisos (dentro de un contexto en el que el cliente ha elegido recibir el mensaje, conocido como opt-in).',
                modelSaved: '¡Tu modelo ha sido guardado!',
                youCannotChange: 'No puede cambiar los mensajes en WhatsApp oficial. Por favor contacte al equipo de soporte.',
                messageErrorConnection: '¡Vaya, hubo un error de conexión!',
                messageNeedFilled: '¡Debes completar los campos de nombre y mensaje!'
              },
              save: 'Guardar',
              cancel: 'Cancelar',
              modelData: 'Datos del modelo',
              name: 'Nombre *',
              tags: 'Tags',
              observations: 'Comentarios',
              integrationsId: 'ID para usar en integraciones: ',
              modelMessageRequired: 'Para realizar un envío, siempre debe utilizar una plantilla de mensaje ya creada.',
              newMessageModelData: 'Aquí informará los datos de la nueva Plantilla de Mensaje, que podrá utilizar para realizar Envíos a los destinatarios que usted designe.',
              clientsWaba: 'Para los clientes de WABA (WhatsApp Business API), las notificaciones en formato HSM son obligatorias y deben seguir formatos aprobados previamente.',
              whatsappTerm: 'Condiciones de uso de WhatsApp:',
              whatsappTermDescription: ' Es muy importante señalar que, para utilizar la integración entre Globalbot y WhatsApp, el cliente conoce y acepta las Políticas de Uso de WhatsApp, utilizando la aplicación para no violarlas. Si lo hace, es posible que el número esté bloqueado. ',
              whatsappUsagePolicies: 'Políticas de uso de WhatsApp',
              messageSavedModel: '¡Tu modelo ha sido guardado!',
              messageErrorConnection: '¡Vaya, hubo un error de conexión!',
              fieldNameRequired: '¡Debes completar los campos de nombre y mensaje!'
            },
            dispatch: {
              save: 'Guardar',
              cancel: 'Cancelar',
              dispatchData: 'Datos de Envio',
              draftData: 'Datos de Bosquejo',
              createDispatch: 'Crear Envío',
              newDispatch: 'Nuevo Envio |',
              dispatchName: 'Nombre de envío *',
              read: 'Lidos',
              readDesc: 'Número total de destinatarios que realmente vieron el mensaje. Esta estadística puede ser aproximada ya que algunas personas no habilitan el permiso de visualización.',
              readDesc2: 'Porcentaje referido al total de Entregas.',
              observations: 'Comentarios',
              originAndInitialization: 'Origen e Inicialización',
              indicateServer: 'Indique qué servidor de WhatsApp desea enviar (corresponde a su número habilitado).',
              optional: 'Opcional: personalice el comportamiento del chatbot si el usuario responde a este mensaje. El número se refiere al punto en el flujo de la conversación que se activará después de la respuesta. Descubra qué punto del flujo y su numeración en el módulo de flujo. Deje el valor 0 para que el flujo se ejecute normalmente desde el principio.',
              whatsappServer: 'Servidor de WhatsApp *',
              flowIndex: 'Índice de flujo',
              message: 'Mensaje *',
              replaceWith: 'Reemplazar con',
              subjectAndSender: 'Asunto y Remitente',
              personalizeEmail: 'Puede personalizar su correo electrónico usando variables. Vea los consejos.',
              subjectEmail: 'El asunto de su correo electrónico tiene ',
              characters: ' caracteres. La cantidad de caracteres que se muestran en los dispositivos móviles es de 35 y en los equipos de escritorio 70 en promedio.',
              senderName: 'Nombre del remitente *',
              sender: 'Remitente *',
              replyTo: 'Responder a *',
              error: 'No Entregado',
              errorCodesWhatsApp: {
                '400': 'En conversación',
                '403': 'En conversación',
                '472': 'El número de teléfono del usuario es parte de un experimento.',
                '471': 'Restricción aplicada por META (Mensajes bloqueados o Marcados como spam)',
                '500': "Error en el servidor del broker, por favor contacte a Globalbot",
                '1001': 'Remitente no Válido',
                '1002': 'Número Inexistente',
                '1003': 'Créditos Globalbot',
                '1004': 'Problema con la Template HSM',
                '1005': 'Problema con la Template HSM',
                '1006': 'Problema con la Template HSM',
                '1007': 'Problema con la Template HSM',
                '1008': 'Opt-out',
                '1010': 'URL inválido',
                '1011': 'Tamaño de Medio no Válido',
                '1012': 'El envío del mensaje falló porque el número de teléfono eligió abandonar este canal de comunicación.',
                '1013': 'Número Inexistente',
                '1026': 'Problema con la Template HSM',
                '2000': 'Problema con la Template HSM',
                '4002': 'Respuesta de WhatsApp no ​​válida',
                '4003': 'Falló el envío del mensaje porque la plantilla no coincide.',
                '4004': 'El tipo de mensaje sólo es compatible con la API de nube alojada en Meta.',
                '4005': 'Falló el envío del mensaje porque la plantilla está en pausa.',
                ///Errores META
                //'400':'Error de descarga de medios: no se pudo descargar los medios del remitente.',
                '402': 'Elegibilidad de la empresa (problema de pago): no se pudo enviar el mensaje debido a uno o más errores relacionados con el método de pago.',
                '408': 'El mensaje no es válido - No se pudo enviar el mensaje porque estuvo pendiente durante demasiado tiempo (por ejemplo, más de 1 día). Esto puede suceder al reiniciar el cliente API de WhatsApp Business después de más de un día de inactividad.',
                '410': 'Mensaje caducado - Mensaje no enviado dentro de su TTL (tiempo de vida).',
                '429': 'Límite de velocidad alcanzado - el número envió demasiados mensajes.',
                '430': 'Certificado no firmado - No se pudo enviar el mensaje porque ocurrió un error relacionado con el certificado.',
                '432': 'La identificación del certificado no coincide - No se pudo enviar el mensaje porque ocurrió un error relacionado con el certificado.',
                '433': "Firma de certificado no válida: ocurre cuando se envía un mensaje pero el certificado de cliente de la empresa no está firmado correctamente con las claves de identidad correspondientes. Esto indica que el cliente probablemente se volvió a registrar con nuevas claves de identidad, pero no completó el proceso completo. flujo de creación de certificados.",
                '470': 'Mensaje de reactivación: no se pudo enviar el mensaje porque han pasado más de 24 horas desde la última vez que el cliente respondió a este número.',
                //'471':'Límite de tasa de spam alcanzado: no se pudo enviar el mensaje porque hay un límite en la cantidad de mensajes que se pueden enviar desde este número de teléfono. Es posible que muchos mensajes anteriores hayan sido bloqueados o marcados como spam.',
                //'472':'El número de teléfono del usuario es parte de un experimento. No se pudo enviar el mensaje porque el número de teléfono del usuario es parte de un',
                '480': "Posible cambio de usuario - No se pudo enviar el mensaje debido a un posible cambio en el número de teléfono del usuario.",
                //'500':'Error genérico: no se pudo enviar el mensaje debido a un error desconocido.',
                '501': 'El tipo de mensaje no es compatible actualmente. - Actualmente no se admiten mensajes de documentos con subtítulos.',
                '1000': 'Error genérico',
                //'1001':'Mensaje demasiado largo: la longitud del mensaje es superior a 4096 caracteres.',
                //'1002':'Tipo de destinatario no válido - Tipos de destinatario válidos:',
                //'1004':'Recurso ya existente - Posible conflicto de UUID para solicitudes de carga de medios o UUID ya existente asignado a otros medios.',
                //'1005':'Acceso denegado -',
                //'1006':'Recurso no encontrado - Archivo o recurso no encontrado.',
                //'1007':'Destinatario bloqueado para recibir mensajes ( - El destinatario no está en la lista blanca.',
                //'1008':'Falta el parámetro requerido - Falta un parámetro requerido.',
                '1009': 'Valor de parámetro no válido - El valor ingresado para un parámetro no es del tipo correcto o hay otro problema.',
                //'1010':'Parámetro no requerido: contiene un parámetro que no es requerido.',
                //'1011':'El servicio no está listo -',
                //'1013':'El usuario no es válido -',
                '1014': 'Error interno',
                '1015': 'Número excesivo de solicitudes: se alcanzó el límite de tarifa del cliente.',
                '1016': 'Sobrecarga del sistema: si el sistema recibe una carga pesada, se devuelve este error para permitir que el sistema se recupere.',
                '1017': 'Maestro no principal: este error se produce cuando una solicitud de nivel maestro únicamente (como establecer u obtener configuraciones, importar, exportar, solicitar código, registrarse, etc.) se envía a un nodo que no es un maestro primario. Esto puede suceder si el cliente API de WhatsApp Business no está configurado correctamente o si hay errores internos.',
                '1018': 'Coreapp no principal: este error se produce cuando las solicitudes se envían a un nodo Coreapp que no es propietario del fragmento ni está en proceso de convertirse en propietario. Es posible que vea este error cuando realicemos una conmutación por error de fragmentos en una configuración de conexión múltiple.',
                '1021': 'Usuario incorrecto: este error ocurre cuando un usuario se envía un mensaje a sí mismo.',
                '1022': "URL de webhooks no configurada: este error ocurrirá si el formato de webhooks de A",
                '1023': 'Error de base de datos',
                '1024': 'Cambio de contraseña solicitado - La contraseña debe cambiarse.',
                '1025': 'Solicitud no válida - La solicitud no es válida.',
                //'1026':'Destinatario no disponible - Mensaje no entregado. Consulte '¿Por qué mi tasa de entrega no es del 100%?',
                '1028': 'Tipo de notificación del sistema: enviaste un mensaje a un usuario de WhatsApp que puede haber sido modificado. Una notificación',
                '1031': "La cuenta del remitente está bloqueada - Su cuenta ha sido bloqueada y no podrá enviar mensajes debido a una violación de la política de integridad. Consulte",
                //'2000':'El recuento de parámetros de la plantilla no coincide: el número de parámetros proporcionados no coincide con la cantidad esperada.',
                '2001': 'Falta plantilla: la plantilla no está activa o no existe para un idioma y configuración regional.',
                '2002': 'Error al recuperar la plantilla - El destinatario no pudo descargar la plantilla.',
                '2003': 'Falta un paquete de plantilla - No hay plantillas para el idioma y la configuración regional indicados.',
                '2004': 'Parámetro de plantilla demasiado largo - El parámetro es demasiado largo.',
                '2005': 'Texto hidratado de plantilla demasiado largo - El texto traducido es demasiado largo.',
                '2006': 'Se violó la política de espacios en blanco de la plantilla - Se violó la política de espacios en blanco.',
                '2007': 'Se violó la política de caracteres de formato de plantilla - Se violó la política de caracteres de formato.',
                '2008': 'Formato de medios de modelo no admitido - El formato de medios utilizado no es compatible.',
                '2009': 'Falta el componente de plantilla requerido - No hay un componente de plantilla requerido.',
                '2010': 'URL hidratada de plantilla no válida - La URL en el componente del botón no es válida.',
                '2011': 'Número de teléfono de modelo no válido - El número de teléfono en el componente del botón no es válido.',
                '2012': 'El formato del parámetro de plantilla no coincide - El formato del parámetro no coincide con la plantilla creada.',
                '2013': 'Botones de modelo incompatibles: el destinatario no acepta los botones.',
                '2014': 'El espacio de nombres esperado está vacío. Este error ocurre cuando el paquete de idioma para una plantilla de mensaje aún no se ha descargado.',
                '2015': 'Número de secciones no válido: el número de secciones en la solicitud de mensaje está por debajo o por encima del límite permitido. Consulta el',
                '2016': 'Número de líneas no válido - El número de líneas no es válido. Para',
                '2017': "Se violó la política de caracteres - Se violó la política de caracteres del formato.",
                '2023': 'Número de producto no válido: el objeto de la sección no tiene productos o el recuento total de productos ha excedido el número máximo permitido.',
                '2024': 'ID de catálogo no encontrado - El ID de catálogo proporcionado no existe o no pertenece a su cuenta de WhatsApp Business (WABA).',
                '2025': 'ID de catálogo no vinculado al número API - El ID de catálogo que proporcionó no está vinculado al número de teléfono utilizado para enviar mensajes.',
                '2026': 'Productos no encontrados - Algunos productos proporcionados en su solicitud no se encontraron en el catálogo.',
                '2027': 'No se encontraron productos - No se encontraron productos en el catálogo proporcionado.',
                '2028': 'Lista de todos los productos con incumplimiento',
                '2029': 'Lista de algunos productos con fallas de cumplimiento',
                '2030': 'Lista que combina productos no válidos y no conformes',
                '2036': 'Estructura de encabezado no válida: este error se devuelve cuando ',
                '2050': 'Falta información de cumplimiento',
                '2060': 'Plantilla rechazada - El estado de la plantilla es ',
                '2061': 'Modelo en pausa - El estado del modelo es',
                '2062': 'Plantilla deshabilitada - El estado de la plantilla es',
                '2064': 'ID de transmisión no válida: la transmisión con la ID especificada no existe, no pertenece a su cuenta de WhatsApp Business o se encuentra en un estado no válido.',
                '2068': 'Transmisión bloqueada: la transmisión está en estado bloqueado.',
                '2069': 'Flujo limitado: el flujo está en estado limitado y ya se han enviado 10 mensajes usando este flujo en la última hora.',
                /// META ERRORES DE LA API DE LA NUBE https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes
                ///Errores de autorización
                '0': 'AuthException - No se puede autenticar al usuario de la aplicación.',
                '3': 'Método API: indica un problema relacionado con recursos o permisos.',
                '10': 'Permiso denegado - El permiso no fue otorgado o fue eliminado.',
                '190': 'El token de acceso ha caducado.',
                '200': 'Permiso de API: el permiso no se otorgó o se eliminó.',
                '299': 'Permiso API - El permiso no fue otorgado o fue eliminado.',
                ///Limitar errores
                '4': 'La aplicación ha alcanzado el límite de tasa de llamadas API. - La aplicación ha alcanzado el límite de tasa de llamadas API.',
                '80007': 'La cuenta de WhatsApp Business ha alcanzado el límite de volumen. - La cuenta de WhatsApp Business ha alcanzado el límite de volumen.',
                '130429': 'Se alcanzó el rendimiento de datos de mensajería de la API de la nube. - Se alcanzó el rendimiento de datos de mensajería de la API de la nube.',
                '131048': 'Error al enviar el mensaje debido a un límite en la cantidad de mensajes que se pueden enviar desde este número de teléfono. Es posible que muchos mensajes anteriores hayan sido bloqueados o marcados como spam. - No se pudo enviar el mensaje debido a un límite en la cantidad de mensajes que se pueden enviar desde este número de teléfono. Es posible que muchos mensajes anteriores hayan sido bloqueados o marcados como spam.',
                '131056': 'Se enviaron demasiados mensajes desde el número de teléfono del remitente al mismo número de teléfono del destinatario en un corto período de tiempo. - Se enviaron muchos mensajes desde el número de teléfono del remitente al mismo número de teléfono del destinatario en un corto período de tiempo.',
                '133016': 'El registro o la eliminación del registro fallaron porque se realizaron demasiados intentos a este número de teléfono en un corto período de tiempo. - El registro o la cancelación del registro fallaron porque se realizaron demasiados intentos en este número de teléfono en un corto período de tiempo.',
                ///Errores de integridad
                '368': 'Bloqueada temporalmente debido a violaciones de políticas - La cuenta de WhatsApp Business asociada con la aplicación ha sido restringida o deshabilitada por violar una política de la plataforma.',
                '131031': 'Cuenta bloqueada: la cuenta de WhatsApp Business asociada con la aplicación ha sido restringida o deshabilitada por violar una política de la plataforma. O bien, los datos incluidos en la solicitud no se pudieron verificar con el conjunto de datos de la cuenta de WhatsApp Business (por ejemplo, el pin de dos pasos incluido en la solicitud es incorrecto).',
                ///Otros errores
                '1': 'Hay una solicitud no válida o posible error del servidor. - Hay una solicitud no válida o posible error del servidor.',
                '2': 'Hay un error temporal por inactividad o sobrecarga. - Hay un error temporal por inactividad o sobrecarga.',
                '33': 'El número de teléfono del trabajo ha sido eliminado. - El número de teléfono comercial ha sido eliminado.',
                '100': 'La solicitud incluía uno o más parámetros incompatibles o escritos incorrectamente. - La solicitud incluía uno o más parámetros incompatibles o escritos incorrectamente.',
                '130472': 'El mensaje no fue enviado como parte de un experimento.',
                '131000': 'No se pudo enviar el mensaje debido a un error desconocido. - No se pudo enviar el mensaje debido a un error desconocido.',
                '131005': 'El permiso no se concedió o se eliminó. - El permiso no fue concedido o fue eliminado.',
                '131008': 'A la solicitud le falta un parámetro obligatorio. - A la solicitud le falta un parámetro obligatorio.',
                '131009': 'Uno o más valores de parámetros no son válidos. - Uno o más valores de parámetros no son válidos.',
                '131016': 'Un servicio no está disponible temporalmente. - Un servicio no está disponible temporalmente.',
                '131021': 'El número de teléfono del destinatario y del remitente es el mismo. - El número de teléfono del destinatario y del remitente es el mismo.',
                '131026': 'No se pudo entregar el mensaje. Los motivos pueden ser los siguientes: - El número de teléfono del destinatario no está registrado en WhatsApp. / Se intentó enviar una plantilla de autenticación a un usuario de WhatsApp con el código de llamada del país +91 (India). Actualmente, no es posible enviar plantillas de autenticación a usuarios de WhatsApp en India. / El destinatario no ha aceptado los nuevos Términos de Servicio y la nueva Política de Privacidad. / El destinatario está utilizando una versión antigua de WhatsApp / El mensaje no se entregó para crear una experiencia de usuario de alta calidad.',
                '131042': 'Ocurrió un error con respecto al método de pago. - Se produjo un error con respecto al método de pago.',
                '131045': 'El mensaje no se pudo enviar debido a un error de registro del número de teléfono. - El mensaje no se pudo enviar debido a un error de registro del número de teléfono.',
                '131047': 'Han pasado más de 24 horas desde la última vez que el destinatario respondió al número del remitente. - Han pasado más de 24 horas desde la última vez que el destinatario respondió al número del remitente.',
                '131051': 'El tipo de mensaje no es compatible. - El tipo de mensaje no es compatible.',
                '131052': 'No se pueden descargar los medios subidos por el usuario. - No se pueden descargar los medios subidos por el usuario.',
                '131053': 'No se pudo cargar el medio utilizado en el mensaje. - No se pudo cargar el medio utilizado en el mensaje.',
                '131057': 'La cuenta comercial está en modo de mantenimiento. - La cuenta comercial está en modo de mantenimiento.',
                '132000': 'El número de valores de parámetros variables incluidos en la solicitud no coincide con el número de parámetros variables definidos en la plantilla. - El número de valores de parámetros variables incluidos en la solicitud no coincide con el número de parámetros variables definidos en la plantilla.',
                '132001': 'La plantilla no existe en el idioma especificado o no ha sido aprobada. - La plantilla no existe en el idioma especificado o no ha sido aprobada.',
                '132005': 'El texto traducido es demasiado largo. - El texto traducido es demasiado largo.',
                '132007': 'El contenido de la plantilla viola la política de WhatsApp. - El contenido de la plantilla viola la política de WhatsApp.',
                '132012': 'Los valores de los parámetros variables estaban formateados incorrectamente. - Los valores de los parámetros variables estaban formateados incorrectamente.',
                '132015': 'El modelo fue pausado por - El modelo fue pausado por ',
                '132016': 'El modelo ha sido pausado demasiadas veces por - El modelo ha sido pausado demasiadas veces por ',
                '132068': 'La transmisión está en estado bloqueado. - La transmisión está en estado bloqueado.',
                '132069': 'El flujo está en estado limitado y ya se han enviado 10 mensajes usando este flujo en la última hora. - El flujo está en estado limitado y ya se han enviado 10 mensajes utilizando este flujo en la última hora.',
                '133000': 'Falló un intento anterior de cancelar el registro. - Un intento anterior de cancelar el registro falló.',
                '133004': 'El servidor no está disponible temporalmente. - El servidor no está disponible temporalmente.',
                '133005': 'El PIN de confirmación de dos pasos es incorrecto. - El PIN de confirmación de dos pasos es incorrecto.',
                '133006': 'El número de teléfono debe verificarse antes del registro. - El número de teléfono debe verificarse antes del registro.',
                '133008': 'Hubo demasiados intentos de confirmación de PIN en dos pasos para este número de teléfono. - Hubo demasiados intentos de confirmación de PIN en dos pasos para este número de teléfono.',
                '133009': 'El PIN de confirmación de dos pasos se ingresó demasiado rápido. - El PIN de confirmación de dos pasos se ingresó muy rápidamente.',
                '133010': 'El número de teléfono no está registrado en la plataforma WhatsApp Business. - El número de teléfono no está registrado en la plataforma WhatsApp Business.',
                '133015': 'El número de teléfono que está intentando registrar se eliminó recientemente y la eliminación aún no se ha completado. - El número de teléfono que está intentando registrar se eliminó recientemente y la eliminación aún no se ha completado.',
                '135000': 'Error al enviar el mensaje debido a un error desconocido con los parámetros de su solicitud. - No se pudo enviar el mensaje debido a un error desconocido con los parámetros de su solicitud.',
              },
              internalError: 'Erro Interno',
              errorDesc: 'Número total de destinatarios cuyos mensajes no fueron entregados, por motivos como: número de WhatsApp inexistente, usuario está en sesión de chat abierta, problema específico con la Plantilla HSM, entre otros.',
              recipients: 'Destinatarios',
              recipientsDesc: 'Destinatarios totales seleccionados para envío, considerando los atributos y segmentaciones que elegiste.',
              dispatchTest: 'Prueba de Envío',
              replaceVar: 'Sustitución de variables',
              combineLists: 'Puede combinar listas y filtros o incluso importar una nueva lista. ',
              selectList: 'Selecciona una lista de correo ya guardada o impórtala.',
              clickHere: 'Haga clic aquí',
              downloadModelList: 'para descargar una plantilla de lista.',
              importList: 'Importar lista',
              lists: 'Liza',
              filters: 'Filtros',
              notSend: 'No enviar a...',
              includeCampaign: 'Incluir en una campaña',
              campaign: 'Campaña *',
              messageCampaignOne: 'Una campaña es una colección de presentaciones relacionadas.',
              messageCampaignTwo: 'Las campañas proporcionan una serie de informes y estadísticas agrupados para sus envíos.',
              messageCampaignThree: 'Utilice campañas para agrupar sus envíos y analizar los resultados a lo largo del tiempo. Ejemplos: campaña semanal, envíos de enero, etc.',
              integrationsId: 'ID para usar en integraciones: ',
              found: 'Fundar',
              repeated: 'Repetido',
              excluded: 'Retirado',
              range: 'Alcanzar',
              var: 'Variáveis da lista',
              deliveries: 'Entregas',
              totalRecipients: 'Total de destinatarios seleccionados para enviar, teniendo en cuenta los atributos y la orientación que eligió anteriormente.',
              sent: 'Enviado',
              sentDesc: 'Número total de destinatarios a quienes Globalbot entregó el mensaje al servidor de WhatsApp.',
              notSent: 'No enviado',
              totalEmails: 'Total de correos electrónicos realmente enviados, excluidas las direcciones de correo electrónico no válidas descartadas por el servicio de activación de correo electrónico.',
              handedOut: 'Entregado',
              handedOutDesc: 'Número total de destinatarios a quienes se les entregó el mensaje por WhatsApp en los dispositivos de los clientes.',
              handedOutDesc2: 'Porcentaje referido al total enviado.',
              receivedTheEmail: 'Total de destinatarios que recibieron el correo electrónico, descontando los que rebotaron. El porcentaje se refiere a la cantidad de correos electrónicos enviados.',
              softBounce: 'Soft Bounce',
              totalEmailsNotHandedTemporary: 'Total de correos electrónicos que no se entregaron por motivos temporales como: bandeja de entrada del destinatario llena o servidor fuera de línea del destinatario. El proveedor de correo electrónico intentará reenviar el mensaje durante las próximas 72 horas. El porcentaje se refiere a la cantidad de correos electrónicos enviados.',
              hardBounce: 'Hard Bounce',
              totalEmailsNotHandedPermanent: 'Total de correos que no fueron entregados por motivos permanentes, tales como: correos inexistentes o bloqueados por el servidor de correo del destinatario por considerarlos spam. El porcentaje se refiere a la cantidad de correos electrónicos enviados.',
              engagement: 'Compromiso',
              openings: 'Aberturas',
              totalUniqueEmailOpenings: 'Se abre el correo electrónico único total. El porcentaje se refiere a la cantidad de correos electrónicos entregados.',
              clicks: 'Clics',
              totalClickUnique: 'Total de clics únicos en enlaces de correo electrónico. El porcentaje se refiere a la cantidad de correos electrónicos abiertos.',
              markedSpam: 'Marcado como spam',
              totalMarkedSpam: 'Total de usuarios que marcaron este correo electrónico como spam. El porcentaje está en la cantidad de correos electrónicos entregados.',
              unsubscriptions: 'Darse de baja',
              totalUnsubscriptions: 'Total de usuarios que se dieron de baja del correo electrónico enviado. El porcentaje se refiere a la cantidad de correos electrónicos entregados.',
              totalRecipientsSent: 'Total de destinatarios con un mensaje realmente enviado, descontando los destinatarios descartados por contener números no válidos o inexistentes.',
              totalRecipientsSms: 'Total de destinatarios que recibieron el mensaje entregado por operadores de SMS.',
              totalRecipientsNotSend: 'Total de destinatarios que no pudieron entregar el mensaje por motivos permanentes, como: contener un número inválido o inexistente.',
              waiting: 'Esperando',
              numberOfRecipientsWaiting: 'Número de destinatarios que aún esperan a que se complete el proceso de envío.',
              handedOutServer: 'Entregado (servidor)',
              totalRecipientsFromGlobalbotToWhatsapp: 'Total de destinatarios que recibieron el mensaje entregado por Globalbot al servidor de WhatsApp.',
              handedOutDevice: 'Entregado (Dispositivo)',
              totalRecipientsFromWhatsappToClients: 'Total de destinatarios que recibieron el mensaje de WhatsApp en los dispositivos de los clientes.',
              totalRecipientsNotHandedTemporary: 'Total de destinatarios cuyos mensajes no se entregaron por motivos temporales, como: el destinatario está en una sesión de chat abierta. El sistema intentará reenviar el mensaje durante las próximas 24 horas. El porcentaje se refiere al número de destinatarios enviados.',
              preview: 'Visualización',
              totalRecipientsViewedMessage: 'Total de destinatarios que realmente vieron el mensaje.',
              important: 'Importante:',
              messageImportantOne: ' Debe importar una lista en formato de archivo .CSV, recordando que para enviar SMS y',
              messageImportantTwo: 'El campo de WhatsApp "teléfono" (phone) es obligatorio.',
              messageImportantThree: 'Para envíos por correo electrónico, el campo "email" es obligatorio.',
              messageImportantFour: 'Para los envíos de WhatsApp, el número de teléfono debe tener la identificación del usuario, por ejemplo: 554899999999 (Brasil)',
              totalFound: 'Encontramos un total de ',
              items: ' elementos.',
              import: 'Importar',
              modelSelectedNotHaveChannel: 'La plantilla seleccionada no tiene canal. Vuelva a crear una nueva plantilla.',
              modelSelectedNotMessageValid: 'El modelo seleccionado no tiene un mensaje válido, consulte el mensaje de este modelo.',
              notIncludeDomain: 'No incluya el dominio en los campos "remitente" y "responder a", estos campos se seleccionan a través de los dominios autorizados.',
              weNeedDomain: 'Desafortunadamente, necesitamos un dominio autorizado para continuar, puede autenticar uno en las preferencias.',
              fieldsNeedFilledOne: 'Se deben completar el nombre del campo, el remitente, la respuesta y las listas o filtros.',
              selectServer: 'Seleccione un servidor, si no es así, solicite uno con Globalbot.',
              fieldsNeedFilledTwo: '¡Es necesario completar el campo de nombre y las listas o filtros!',
              dispatchSaved: 'Su presentación se ha grabado!',
              draftSaved: 'Su rascunho se ha grabado!',
              messageErrorConnection: '¡Vaya, hubo un error de conexión!',
              columnEmailRequired: 'Las columnas "email" son obligatorias.',
              columnPhoneRequired: 'Las columnas "phone" son obligatorias.',
              messageError: 'Vaya, hubo un error.',
              listSaved: '¡Tu lista se ha guardado!',
              exportData: '¿Realmente desea exportar todos sus registros?'
            },
            list: {
              title: 'Título *',
              toggleLabel: 'Cambiar etiqueta',
              filter: 'Filtrar *',
              save: 'Salvar',
              messageError: 'Vaya, se produjo un error.',
              listSaved: '¡Tu lista se ha guardado!',
              messageErrorConnection: '¡Vaya, hubo un error de conexión!',
              fieldsRequired: 'Es necesario completar los campos de la campaña, el asunto y el correo electrónico de origen.'
            }
          },
          conversation: {
            save: 'Salvar',
            cancel: 'Cancelar',
            conversationData: 'Datos de Conversación',
            protocol: 'Protocolo',
            identifierId: 'Identificador',
            attendants: 'Acomodadoras',
            tags: 'Tags',
            device: 'Dispositivo',
            dispatchEmail: 'Correo electrónico de cliente potencial enviado',
            dispatchEmailToClient: 'Correo electrónico enviado al cliente',
            emailViewedByClient: 'Correo electrónico visto por el cliente',
            dateAndHour: 'Fecha y hora',
            channel: 'Canal',
            satisfaction: 'Satisfacción',
            punctuation: 'Puntuación',
            search: 'Buscar',
            location: 'Localización',
            origin: 'Origen',
            campaigns: 'Campañas',
            messageSent: 'Mensaje enviado:',
            comments: 'Comentarios',
            messageNotFound: 'Desafortunadamente, no encontramos esta conversación.',
            messageTryAgain: 'Vaya, tuvimos un problema al recuperar esta conversación. Vuelve a intentarlo. Si el error persiste, contáctanos.'
          },
          integration: {
            save: 'Guardar',
            cancel: 'Cancelar',
            integrationDetails: 'Detalles de integración',
            activeIntegration: 'Integración activa',
            identifier: 'Identificador',
            hash: 'Hash',
            token: 'Token',
            hasToken: 'Tiene API token',
            webhook: 'Webhook',
            host: 'Host',
            key: 'Key',
            haveRestriction: '¿Tiene restricciones?',
            contactPurpose: 'Ponga debajo los valores para el botón "Propósito de contacto" que no desea ir a VistaSoft',
            add: 'Agregar',
            customIdFields: 'Defina a continuación los identificadores de los campos personalizados y sus identificadores, ejemplo %NAME%.',
            idField: 'Id de campo ',
            websiteAddress: 'Dirección web',
            userProvidedByKSI: 'Usuario proporcionado por KSI',
            passwordProvidedByKSI: 'Contraseña proporcionada por KSI',
            salesFlow: 'Flujo de ventas',
            salesFlowValue: '¿Cuál es el identificador que tiene el valor de venta en el flujo?',
            salesValue: 'Valor del identificador de venta',
            createIdSales: 'Cree un ID de propiedad de venta predeterminado y colóquelo aquí',
            rentalFlow: 'Flujo de alquiler',
            rentalFlowValue: '¿Cuál es el identificador que tiene el valor de alquiler en el flujo?',
            rentalValue: 'Valor del identificador de alquiler',
            createIdRental: 'Cree un ID de propiedad de alquiler predeterminado e ingréselo aquí',
            releasesFlow: 'Flujo de publicación',
            releasesFlowValue: 'Qual o identificador que tem o valor de lançamento no fluxo?',
            releasesValue: 'Valor del identificador de publicación',
            createIdSalesInReleases: 'Cree un ID de propiedad de venta predeterminado y coloque la liberación',
            endpoint: 'Punto final',
            enterprises: 'Empresa',
            enterpriseId: 'ID da Empresa',
            sendToAttendant: 'Enviar al asistente',
            includeInternalMessages: 'Incluir mensajes internos',
            subdomain: 'Subdominio',
            clientSecret: 'Client_Secret',
            user: 'Usuario',
            clientId: 'Client_Id',
            code: 'Code',
            password: 'Contraseña',
            project: 'Proyecto',
            problemType: 'Tipo de problema',
            customField: 'Campo personalizado',
            customFieldValue: 'Valor de campo personalizado',
            title: 'Título (opcional)',
            sendOnlyLivechat: 'Envía conversaciones solo desde el chat en vivo',
            loadLink: 'Enlace de carga',
            savedPreferences: 'Preferencia guardada con éxito',
            responsible: 'Responsable(Id)',
            createdBy: 'Creado por(Id)',
            businessPhaseId: 'Fase comercial(Id)',
            salerCode: 'Código de vendedor',
            methodologyCode: 'Código de metodología',
            salesChannelCode: 'Código de canal de ventas',
            baseURL: 'URL base. ex: https://tu-tienda-de-desarrollo.myshopify.com'
          },
          knowledge: {
            close: 'Cerrar',
            cancel: 'Cancelar',
            answered: 'Contestada',
            unambiguous: 'Desambiguación',
            unanswered: 'Sin respuesta',
            channel: 'Canal',
            date: 'Fecha',
            question: 'Pregunta',
            response: 'Respuesta',
            testEnvironment: 'Entorno de prueba',
            mode: 'Modo',
            test: 'Prueba',
            public: 'Público'
          },
          liveAttendants: {
            close: 'Cerrar',
            attendants: 'Acomodadores',
            avatar: 'Avatar',
            attendance: 'Conversaciones activas',
            name: 'Nombre',
            departments: 'Departamentos',
            role: 'Cargo',
            away: 'Away',
            clear: 'Limpio',
            offline: 'Offline',
            rooms: 'Habitaciones',
            actions: 'Comportamiento'
          },
          connectedUsers: {
            close: 'Cerrar',
            users: 'Usuarios conectados',
            avatar: 'Avatar',
            attendance: 'Conversaciones activas',
            name: 'Nombre',
            departments: 'Departamentos',
            role: 'Cargo',
            away: 'Away',
            clear: 'Limpio',
            offline: 'Offline',
            rooms: 'Habitaciones',
            actions: 'Comportamiento'
          },
          liveConfig: {
            save: 'Guardar',
            cancel: 'Cancelar',
            settings: 'Ajustes',
            soundNewAttendance: 'Nuevo sonido de asistencia',
            messageSoundReceived: 'Sonido de mensaje entrante',
            listOrdering: 'Orden de lista',
            ascendingArrivalDate: 'Fecha de llegada ascendente',
            descendingArrivalDate: 'Fecha de llegada descendente',
            lastMessageCrescent: 'Último mensaje ascendente',
            lastMessageDecreasing: 'Último mensaje descendente',
            crescentGroup: 'Por grupo crescente',
            decreasingGroup: 'Por grupo decrescente',
            amountMessageCrescent: 'Quantidade de mensagens crescente',
            amountMessageDecreasing: 'Quantidade de mensagens decrescente',
            activeTimer: 'Habilitar Cronómetro',
            totalTimeOfAttendant: 'Tiempo total de asistencia',
            lastClientInteraction: 'Contador de última interacción con el cliente',
            exhibitionAlertTime: 'Limite de Aviso de Alerta',
            minutes: 'minutos'
          },
          notifications: {
            notifications: 'Centro de Notificaciones',
            noNotifications: 'No hay notificaciones para ti en este momento.'
          }
        },
        dashboard: {
          connectedUsers: 'Usuarios Conectados',
          connectedUsersTip: 'Verifica los usuarios que están conectados a la plataforma Globalbot en este momento.',
        },
        editable: {
          carousel: {
            idealResolution: 'Resolucion ideal',
            librarySearch: 'Buscar en la biblioteca',
            key: 'Llave',
            value: 'Valor',
            jump: 'Salto',
            title: 'Título',
            description: 'Descripción',
            select: 'Seleccione',
            image: 'Imagen',
            buttons: 'Botones'
          }
        },
        emailBuilder: {
          add: {
            campaigns: 'Mensajería ',
            addEmail: ' Agregar e-mail',
            subject: 'Sujeto *',
            sender: 'Remitente *',
            replyTo: 'Responder a',
            tags: 'Tags',
            save: 'Guardar',
            attendanceRealized: 'Asistencia realizada',
            attendanceProtocol: 'Protocolo de asistencia',
            historic: 'Histórico',
            templateSaved: '¡Tu modelo ha sido guardado!',
            messageErrorConnection: 'Oops, ocorreu um erro de conexão!',
            fieldsRequired: '¡Debes completar los campos campaña, asunto y correo electrónico de origen!'
          },
          emailBuilder: {
            messageEmailSaved: 'Mensajes de correo electrónico guardados',
            subject: 'Asunto: ',
            addNewTemplate: 'Agregar nuevo modelo'
          }
        },
        lists: {
          push: {
            insertList: 'Insertar en la lista',
            list: 'Lista',
            cancel: 'Cancelar',
            insert: 'Inserir',
            updatedList: '¡Lista actualizada!',
            failedUpdateList: '¡No se pudo actualizar la lista!'
          }
        },
        live: {
          container: {
            message: {
              cards: {
                startConversation: 'Iniciar Conversación',
                insertList: 'Insertar en la lista'
              },
              media: {
                speechToText: 'Speech to Text (transcripción de audio a texto)',
                notSupport: 'Su navegador no es compatible, utilice Chrome.',
                notRegistered: 'No registrado',
                openOriginalImage: 'Abrir imagen original'
              },
              yesterday: 'El dia de ayer',
              today: 'Hoy dia',
              userNotFound: 'Usuario no encontrado',
              status: {
                noSubmitted: 'Mensaje no enviada(haga clic para intentarlo de nuevo)',
                sending: 'Enviando mensaje',
                sended: 'Mensaje enviada',
                delivered: 'Mensaje entregado',
                read: 'Mensaje leído'
              },
              networkRequired: 'Debes iniciar sesión para enviar el mensaje nuevamente.'
            },
            conversation: {
              identifierId: 'Identificador',
              protocol: 'Protocolo',
              tags: 'Tags',
              changeName: 'Alterar nome',
              device: 'Dispositivo',
              dispatchEmailLead: 'Correo electrónico de cliente potencial enviado',
              dispatchEmailClient: 'Correo electrónico enviado al cliente',
              emailViewedClient: 'Correo electrónico visto por el cliente',
              dateAndTime: 'Fecha y hora',
              channel: 'Canal',
              satisfaction: 'Satisfacción',
              punctuation: 'Puntuación',
              search: 'Buscar',
              location: 'Localización',
              origin: 'Origen'
            },
            knowledge: {
              reply: 'Respuesta',
              copyReply: 'Copiar respuesta',
              messageErrorKnowledge: 'Error al consultar las bases de conocimientos',
              messageErrorConsult: 'Error al consultar preguntas.'
            }
          },
          footer: {
            actions: {
              annex: {
                sendImage: '¡Cargar imagen! (5 MB talla máxima)',
                sendVideo: '¡Enviar video! (16 MB talla máxima)',
                sendFile: '¡Enviar archivo! (100 MB talla máxima)',
                selectLibrary: '¡Seleccione de la biblioteca!',
                library: 'Biblioteca',
                preview: 'Avance',
                addLegend: 'Agregar leyenda',
                cancel: 'Cancelar',
                send: 'Enviar',
                file: 'Expediente',
                avatar: 'Avatar',
                maxFileSize: 'El tamaño máximo de archivo es de 20 MB.',
                errorProcessingDoc: 'Error al procesar el documento.',
                fileFormatNotAccepted: '¡Formato de archivo no soportado!'
              },
              audio: {
                recordAudio: 'Grabar audio (máximo 2 minutos)',
                cancel: 'Cancelar',
                send: 'Enviar',
                preview: 'Avance',
                addLegend: 'Agregar leyenda',
                sendText: 'Enviar texto',
                sendAudio: 'Enviar audio',
                errorProcessingAudio: 'Error al procesar el audio.',
                accessMicrophoneRequired: 'Para que el chat en vivo funcione completamente, es necesario permitir el acceso al micrófono.',
                transcribe: 'Transcripción de audio'
              },
              quickResponse: {
                quickResponse: 'Respuesta rápida',
                add: 'Agregar',
                close: 'Cerrar',
                unshare: 'Dejar de compartir',
                reply: 'Respuesta',
                share: 'Compartir',
                exclude: 'Eliminar',
                createQuickResponse: 'Agregar una respuesta rápida',
                title: 'Título',
                message: 'Mensaje',
                cancel: 'Cancelar',
                errorConsultQuickResponse: 'Error al buscar la respuesta rápidas.',
                errorRegisterQuickResponse: 'Error al registrar la respuesta rápida.',
                errorExcludeQuickResponse: 'Error al eliminar la respuesta rápida.',
                errorShareQuickResponse: 'Error al compartir la respuesta rápida.'
              }
            }
          },
          utils: {
            attendants: {
              attendant: 'Asistente',
              attendants: 'Acomodadores',
              avatar: 'Avatar',
              name: 'Nombre',
              role: 'Ocupación',
              select: 'Seleccione',
              actions: 'Comportamiento'
            },
            departments: {
              department: 'Departamento',
              attendants: 'Acomodadores'
            },
            roomOptions: {
              endAttendance: 'Terminar asistencia',
              archiveAttendance: 'Arquivar asistencia',
              reactivateNotifications: 'Reactivar notificaciones',
              muteNotifications: 'Silenciar notificaciones',
              unpinAttendance: 'Desanclar asistencia',
              fixedAttendance: 'Arreglar asistencia',
              transferAttendance: 'Transferir asistencia',
              transferAttendanceSelected: 'Transferir seleccionado',
              endAttendanceSelected: 'Terminar seleccionado',
              transferRestrictedAttendanceSelected: 'Transferir seleccionado (olo para Gerente y Administrador)',
              archiveAttendanceSelected: 'Arquivar seleccionado'
            }
          },
          createGroup: {
            newGroup: 'Nuevo grupo',
            newConversation: 'Nueva conversación'
          },
          createHsm: {
            limitCharactersExceeded: '¡Se superó el límite de caracteres!',
            credits: 'Créditos',
            creditsDescription: 'Para enviar mensajes a los clientes que están inactivos en WhatsApp (24 horas después del último mensaje enviado por el cliente) es necesario utilizar una Plantilla de Mensaje (HSM) preaprobada por WhatsApp. Este envío consume 1 crédito de plataforma.',
            creditsInsufficient: 'Desafortunadamente, no tienes suficientes créditos.',
            attention: 'Atención:',
            attentionDescription: ' Después de 24 horas de inactividad del cliente, la empresa solo puede enviar un mensaje estandarizado y preaprobado por WhatsApp, conocido como HSM. Cada mensaje HSM consume 01 crédito en la plataforma, ya que pueden ser cobrados por WhatsApp.',
            requestCredits: 'Solicita más créditos',
            whoToSendMessage: '¿A quién quieres enviar el mensaje?',
            originNumber: 'Número de origen *',
            nameClient: 'Client name *',
            country: 'País *',
            nameClientWhatsapp: 'Número de WhatsApp del cliente *',
            attentionNinthNumber: ' Ingresa el número de celular del cliente con un código de área de 2 dígitos, por ejemplo: (XX) XXXXX-XXXX. Los mensajes solo se enviarán a los clientes que tengan una cuenta de WhatsApp activa.',
            messageYouCanNot: '¡UPS! No podrás enviar mensajes a',
            messageActiveConversationWith: 'porque ya está en una conversación ACTIVA con ',
            messageActiveConversationAttendants: 'porque ya está en una conversación ACTIVA con otro servidor.',
            messageConversationWithYou: 'ya está en conversación contigo! Envía mensajes a través de la sala de chat.',
            goToRoom: 'Ir a la habitación',
            messageTemplate: 'Plantilla de mensaje (HSM)',
            notAuthorized: '¡UPS! No podrás enviar un mensaje a esta persona ya que no ha autorizado la recepción de mensajes (Opt-In) según las políticas de WhatsApp.',
            messageUserOptIn: 'Para que su empresa envíe un mensaje al cliente (mientras está fuera de la sesión de chat), este cliente debe ser necesariamente un usuario OPT-IN (es decir, ha dado su consentimiento para recibir mensajes). Obtenga más información sobre Opt-In.',
            messageSent: '¡Mensaje enviado!',
            return: 'Regreso',
            advance: 'Avance',
            missingMessage: 'Mensaje faltante',
            notPossibleStartConversation: 'No fue posible iniciar el chat porque el usuario ya está chateando con el chatbot.',
            numberNotValid: '¡UPS! ¡El número ingresado no es una cuenta de WhatsApp válida! Verifique el número y vuelva a intentarlo. Recuerde, no informe a la DDI.',
            nameNotValid: 'Ingrese el nombre del cliente.',
            requiredMessage: '¡UPS! ¡Debe completar el mensaje que se enviará!'
          },
          pushGroup: {
            add: 'Agregar',
            attendantAdded: 'Asistente agregado!',
            alreadyInGroup: '¡Este asistente ya está en el grupo!'
          },
          resendHsm: {
            limitCharactersExceeded: '¡Se superó el límite de caracteres!',
            credits: 'Créditos',
            creditsDescription: 'Para enviar mensajes a los clientes que están inactivos en WhatsApp (24 horas después del último mensaje enviado por el cliente) es necesario utilizar una Plantilla de Mensaje (HSM) preaprobada por WhatsApp. Este envío consume 1 crédito de plataforma.',
            creditsInsufficient: 'Desafortunadamente, no tienes suficientes créditos.',
            attention: 'Atención:',
            attentionDescription: 'Después de 24 horas de inactividad del cliente, la empresa solo puede enviar un mensaje estandarizado y preaprobado por WhatsApp, conocido como HSM. Cada mensaje HSM consume 01 crédito en la plataforma, ya que pueden ser cobrados por WhatsApp.',
            requestCredits: 'Solicita más créditos',
            messageTemplate: 'Plantilla de mensaje (HSM)',
            send: 'Enviar',
            impossibleRecover: 'No se puede recuperar el clientId'
          }
        },
        micro: {
          cdt: {
            yesterday: 'El dia de ayer',
            today: 'Hoy dia'
          },
          cdtWithHours: {
            yesterday: 'El dia de ayer',
            today: 'Hoy dia',
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado'
          }
        },
        modals: {
          feedback: {
            newFunctions: '¡Ayúdanos a desarrollar nuevas funciones para el tablero!',
            help: '¿Ayudemos a mejorar nuestro panel de control?',
            info: 'Estos comentarios son solo para mejorar, si desea informar un problema técnico, comuníquese con el soporte.',
            nowYouCan: '¡Ahora puedes ayudar a mejorar nuestra plataforma!',
            feedbackWillBeApplied: 'Esta es la página donde se aplicarán los comentarios.',
            whatCanBeImproved: 'Cuéntanos qué se puede mejorar en esta página.',
            example: 'Ej .: Esta página podría tener un botón de información.',
            send: 'Enviar',
            feedbackSentError: 'Hubo un error al enviar los comentarios.',
            feedbackSentSuccessfully: '¡Comentarios enviados con éxito!',
            requireFields: 'Debes completar los campos.',
            contactSupport: 'Se ha producido un error, póngase en contacto con el servicio de asistencia.',
            feedbackExcludeError: 'Se produjo un error al eliminar los comentarios.',
            feedbackExcludeSuccessfully: '¡Comentarios eliminados correctamente!'
          },
          image: {
            image: 'Imagen'
          },
          recharge: {
            requestCredits: 'Solicitar Créditos',
            requestCreditsDescription: 'Puede solicitar créditos adicionales para usar en la mensajería. Ingrese los detalles a continuación y uno de nuestros especialistas se comunicará con usted.',
            type: 'Escribe *',
            value: 'La cantidad *',
            cancel: 'Cancelar',
            request: 'Solicitud',
            messageFailedRequestCredits: 'Oops, falha ao solicitar créditos.',
            creditsRequested: 'Créditos solicitados.',
            fieldsRequired: 'Debes completar los campos.'
          }
        },
        tags: {
          removeTag: 'Remover tag',
          addTags: 'Add tag',
          labelTag: 'Buscar tag',
          tagAlreadyExistsInConversation: 'Esta etiqueta ya existe en la conversación.',
          categoryNotFound: 'Categoría no encontrada, verifique el componente mini Tags.'
        },
        exporter: {
          mountingReport: 'Montaje de informe ...',
          exportToCsv: 'Exporta a CSV',
          type: 'Escribe',
          answered: 'Contestado',
          response: 'Respuesta',
          disambiguated: 'Desambiguación',
          notResponse: 'Sin respuesta',
          active: 'Activo',
          inactive: 'Inactivo',
          reportGeneratedSuccessfully: 'Informe generado con éxito.',
          messageFailedReport: 'Vaya, fallaron los informes. Vuelve a intentarlo.'
        },
        loading: {
          connectingToServer: 'Conectando al servidor...',
          assemblingTheInformation: 'Reuniendo la información...'
        },
        zendesk: {
          title: 'Zendesk',
          brands: 'Marcas',
          forms: 'Formas',
          fields: 'Etiquetas',
          group: 'Grupo',
        }
      },
      layouts: {
        default: {
          messageErrorInfo: 'Vaya, se produjo un error al guardar la información.',
          tolerancePeriod: 'Período de tolerancia',
          finishedJourneyDescription: 'Su jornada laboral ha terminado, pero ha entrado en el período de gracia.',
          timeLeft: 'Termine su trabajo en el tiempo restante de',
          disconnectedFromThePlatform: 'Una vez finalizado el período de gracia, se le desconectará de la plataforma.',
          iKnow: 'Estoy consciente',
          finishedJourney: 'Viaje terminado',
          workingEnded: 'Tu jornada laboral ha terminado.',
          freeTime: '¡Disfruta tu tiempo libre!',
          seeYouLater: 'Hasta luego',
          lostConnection: 'Conexión Perdida'
        }
      },
      pages: {
        actions: {
          create: {
            addAction: 'Agregar acción',
            title: 'Título',
            description: 'Descripción',
            request: 'Solicitud',
            getAndPost: 'GET, POST',
            newAction: 'Nueva acción',
            newActionDescription: 'Descripción de la nueva acción'
          },
          list: {
            actions: 'Comportamiento',
            addAction: 'Agregar acción'
          }
        },
        advanced: {
          logs: {
            list: {
              addLog: 'Agregar log',
              version: 'Versión',
              change: 'Cambio',
              description: 'Description',
              cancel: 'Cancelar',
              add: 'Adicionar',
              update: 'Atualizar',
              removedColumn: 'Columna de categoría eliminada por inutilización',
              deprecateModal: 'Modo de observación obsoleto.',
              updateVisualsAndProgrammatic: 'Actualizaciones visuales y programáticas.'
            }
          },
          conversation: {
            conversationDetails: 'Detalles de la conversación',
            search: 'Buscar',
            account: 'Cuenta',
            attendants: 'Acomodadores',
            device: 'Dispositivo',
            desktop: 'Desktop',
            mobile: 'Mobile',
            chrome: 'Chrome',
            safari: 'Safari',
            mozilla: 'Mozilla Firefox',
            edge: 'Edge',
            explorer: 'Internet Explorer',
            whatsapp: 'Whatsapp',
            facebook: 'Facebook Messenger',
            dispatchEmail: 'Correo electrónico de cliente potencial enviado',
            dispatchEmailToClient: 'Correo electrónico enviado al cliente',
            viewedEmailByClient: 'Correo electrónico visto por el cliente',
            dateAndHour: 'Fecha y hora',
            channel: 'Canal',
            origin: 'Origen',
            messageNotFoundConversation: 'Desafortunadamente, no encontramos esta conversación.'
          },
          edit: {
            delPayment: 'Borrar pagamiento',
            paid: 'Pagado',
            consumption: 'Consumo',
            status: 'Status',
            editAccount: 'Editar cuenta',
            clientsList: 'Lista de clientes',
            editAccountTwo: 'Editar cuenta - ',
            credits: 'Créditos',
            recurrentCredits: 'Franquicia de mensajería recurrente',
            sharedFranchise: 'Franquicia compartida',
            otherChannels: 'Otros canales',
            newFranchise: 'Nuevo modelo',
            shared: 'Compartida',
            add: 'Agregar',
            del: 'Borrar',
            fa: '2FA',
            email: 'E-mail',
            active: 'Activo',
            inactive: 'Inactivo',
            phone: 'Teléfono',
            security: 'Módulos habilitados',
            realTime: 'Tiempo real',
            statistics: 'Estadísticas',
            people: 'Gente',
            conversation: 'Conversaciones',
            interface: 'Interface',
            flow: 'Fluir',
            intelligence: 'Inteligencia',
            campaigns: 'Campañas',
            liveChat: 'Chat en vivo',
            library: 'Biblioteca',
            preferences: 'Preferencias',
            limiter: 'Usuarios  - Limitador ',
            decraseLimit: 'Límite inferior',
            increaseLimit: 'Aumentar el límite',
            createMaster: 'Crear master',
            accountDetails: 'Detalles de la cuenta',
            accountName: 'Nombre de la cuenta',
            addPermissions: 'Agregar permisos',
            addBriefing: 'Agregar briefing',
            companyName: 'Nombre de empresa',
            fantasyName: 'Nombre de fantasía',
            cnpj: 'CNPJ',
            address: 'Dirección',
            zipCode: 'Código postal',
            street: 'Calle',
            edit: 'Editar',
            contacts: 'Contactos',
            plan: 'Planificar y Contratar',
            uploadContractLabel: 'Contrato',
            contractValidityLabel: 'Vigencia del contrato',
            contractDateLabel: 'Fecha del contrato',
            seeDocument: 'Ver documento',
            successUpdate: '¡Cliente actualizado!',
            errorUpdate: '¡Error al actualizar el cliente!',
            type: 'Tipo',
            characteristics: 'Franquicias y canales',
            numberAttendances: 'Whatsapp - deducible mensual',
            otherChannelAttendances: 'Otros canales - Asignación mensual',
            whatsAppAttendances: 'Whatsapp - deducible mensual',
            emailAttendances: 'E-mail - deducible mensual',
            smsAttendances: 'SMS - deducible mensual',
            webchat: 'Webchat',
            facebook: 'Facebook',
            whatsapp: 'Whatsapp',
            whatsappFranchise: 'Whatsapp Franquicia',
            sms: 'SMS',
            integration: 'Integración',
            observations: 'Comentarios',
            none: 'Ninguno',
            simple: 'Simples',
            intermediate: 'Intermediario',
            advanced: 'Avanzado',
            payments: 'Pagos',
            form: 'Formulario',
            setup: 'Configuración',
            description: 'Descripción',
            valueReal: 'Valor: R$ ',
            date: 'Fecha',
            monthlyPayment: 'Mensualidad',
            cronogram: 'Cronograma',
            planning: 'Planificación',
            initialDate: 'La fecha de inicio',
            implementation: 'Implementación',
            endDate: 'Fecha final',
            observation: 'Observación',
            customerSuccess: 'Éxito del cliente',
            chatbots: 'Chatbots',
            online: 'Online',
            offline: 'Offline',
            versioning: 'Control de versiones',
            old: 'VIEJO',
            stable: 'ESTABLE',
            beta: 'BETA',
            alpha: 'ALFA',
            editAddress: 'Editar dirección',
            number: 'Número',
            district: 'Vecindario',
            city: 'Ciudad',
            state: 'Estado',
            cancel: 'Cancelar',
            save: 'Guardar',
            addContact: 'Agregar contacto',
            delContact: 'Borrar contacto',
            editContact: 'Editar contacto',
            role: 'Oficina',
            name: 'Nombre',
            projectManager: 'Gerente de proyecto',
            title: 'Titulo',
            addImplementationCronogram: 'Agregar programa de implementación',
            addCronogramByCs: 'Agregar programa de CS',
            addCronogramByPlanning: 'Agregar programa de planificación',
            addRateBySetup: 'Agregar tarifa de CONFIGURACIÓN',
            value: 'Valor',
            values: 'Valores',
            csStage: 'Etapa CS',
            currentStatus: 'Estado actual',
            accountStatusOnline: 'Online',
            accountStatusPartialOnline: 'Online parcial',
            accountStatusPaused: 'En pausa',
            accountStatusChurn: 'Churn',
            flag: 'Flag',
            ongoing: 'OnGoing',
            onboarding: 'OnBoarding',
            addRateMonthly: 'Agregar tarifa mensual',
            newUser: 'Agregar usuario',
            password: 'Contraseña',
            addCredits: 'Agregar créditos',
            veryLow: 'Muy bajo',
            low: 'Bajo',
            medium: 'Promedio',
            high: 'Alta',
            veryHigh: 'Muy alta',
            important: 'IMPORTANTE',
            starter: 'G-Principiante',
            professional: 'G-Profesional',
            enterprise: 'G-Enterprise',
            gAdvanced: 'G-Advanced',
            orion: 'G-Orion',
            planLite: 'Lite',
            planStarter: 'Starter',
            planProfessional: 'Professional',
            planAdvanced: 'Advanced',
            planEnterprise: 'Enterprise',
            planGlobal: 'Global',
            planLite22: 'LITE-22',
            planStarter22: 'STARTER-22',
            planProfessional22: 'PROFESSIONAL-22',
            planAdvanced22: 'ADVANCED-22',
            planEnterprise22: 'ENTERPRISE-22',
            planLite23: 'LITE-23',
            planStarter23: 'STARTER-23',
            planProfessional23: 'PROFESSIONAL-23',
            planEnterprise23: 'ENTERPRISE-23',
            january: 'Enero',
            february: 'Febrero',
            march: 'Marcha',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre',
            genAi: 'IA generativa',
            instagram: 'Instagram',
          },
          list: {
            exportAccounts: 'Exportar cuentas',
            plan: 'Plan',
            csStage: 'Etapa CS',
            accountStatus: 'Estado de la cuenta',
            cs: 'CS Responsable',
            listClients: 'Lista de clientes',
            createNewAccount: 'Crear una nueva cuenta',
            wantToCreateNewAccount: '¿Quiere crear una nueva cuenta? Haga clic aquí :)',
            version: 'Versión',
            stable: 'Estable',
            beta: 'Beta',
            alpha: 'Alfa',
            old: 'Viejo',
            updateNewVersion: 'Actualizar a una nueva versión',
            edit: 'Editar',
            exclude: 'Borrar',
            addAccount: 'Añadir cuenta',
            companyName: 'Nombre de la empresa',
            steps: 'Ahora sigue estos pasos',
            open: 'Abre el',
            spreadsheetClients: 'hoja de cálculo del cliente',
            insertEmail: 'Poner este correo electrónico',
            insertPassword: 'Además, ingrese la contraseña deseada para la cuenta, debe ser una contraseña muy segura.',
            insertPasswordAndAdd: 'Ingrese la contraseña ingresada en la hoja de cálculo en el campo a continuación y haga clic en Agregar.',
            passwordMaster: 'Contraseña maestra',
            cancel: 'Cancelar',
            add: 'Agregar',
            excludeAccount: 'Borrar cuenta',
            sureExcludeAccount: '¿Estás segura de que quieres eliminar esta cuenta?',
            allConversationsAndChatbotsDeleted: 'Se eliminarán todas las conversaciones y todos los chatbots.',
            updateVersionToStable: 'Actualizar la versión a estable',
            beAware: 'Antes de continuar, tenga en cuenta que',
            thisChangesVersionPanel: 'Esta es una acción que cambiará la versión del panel del cliente.',
            losesAllAttendance: 'Esta opción hará que el cliente pierda todas las llamadas actuales de Old y ya no tendrá acceso, solo a través de la pestaña Conversaciones.',
            beforeToClick: 'Antes de hacer clic en actualizar, verifique si ya tiene departamentos creados accediendo a https://panel.globalbot.ai en la cuenta del cliente (si ya los tiene, elimínelos, ya que puede interferir con la migración).',
            createNewDepartment: 'Esta acción creará nuevos departamentos (con los mismos nombres) y los asignará en los flujos y en los usuarios, por lo que por seguridad no puede estar editando usuarios o el flujo al actualizar.',
            switchToStableVersion: 'Cambiar a versión estable',
            name: 'Nombre',
            actions: 'Comportamiento',
            jan: 'Ene.',
            feb: 'Feb.',
            mar: 'Mar.',
            apr: 'Abr.',
            may: 'May.',
            jun: 'Jun.',
            jul: 'Jul',
            aug: 'Ago.',
            sep: 'Sep.',
            oct: 'Oct.',
            nov: 'Nov.',
            dec: 'Dic.',
            migratedAccountSuccess: '¡La cuenta se ha migrado correctamente!',
            errorHasOccurred: 'Ha ocurrido un error, ¡contacta a un desarrollador urgentemente!',
            normalStatus: 'Normal',
            endingStatus: 'Finalizando',
            exceededStatus: 'Excedido',
            columnFranchise: 'Consumo de la franquicia'
          },
          server: {
            serversWhatsapp: 'Servidores de WhatsApp',
            updateGit: 'Atualizar Git',
            requestUpdateGit: 'Solicite la actualización de git en todos los servidores.',
            update: 'Actualizar',
            servers: 'Servidores',
            serversDescription: 'Agregar nuevo servidor.',
            add: 'Agregar',
            numbering: 'Numeración',
            version: 'Versión',
            responsible: 'Responsable',
            whatsapp: 'Whatsapp',
            indifferent: 'Indiferente',
            normal: 'Normal',
            business: 'Business',
            chip: 'Chip',
            client: 'Cliente',
            globalbot: 'Globalbot',
            server: 'Servidor',
            instanceType: 'Tipo de Instancia',
            ip: 'IP',
            account: 'Cuenta',
            chatbot: 'Chatbot',
            info: 'Información',
            actions: 'Comportamiento',
            running: 'Corriendo!',
            serverAvailable: 'Servidor disponible',
            serverDown: 'Servicio caído, gerente de espera.',
            qrCode: 'QR Code',
            gitPull: 'Git Pull',
            restartServer: 'Reiniciar servidor',
            edit: 'Editar',
            excludePairing: 'Eliminar emparejamiento',
            save: 'Guardar',
            cancel: 'Cancelar',
            serverData: 'Datos del servidor',
            instanceId: 'ID de Instancia',
            serverName: 'Nombre del servidor',
            sureRestartServer: '¿Está seguro de que desea reiniciar este servidor? Esto puede tomar unos pocos minutos.',
            restart: 'Reiniciar',
            exclude: 'Borrar',
            sureExcludePairing: '¿Está seguro de que desea eliminar el emparejamiento de este servidor? Tendrá que volver a leer el código QR.',
            cellphone: 'Teléfono móvil',
            manager: 'Gerente',
            isBusiness: 'is Business',
            isChipResponsible: 'is Chip Responsible',
            messageError: '¡Error, ver consola!',
            routeNotFound: 'Ruta no encontrada, servidor ',
            serverAddedSuccessfully: '¡Servidor agregado exitosamente!',
            pairingDeleted: '¡Emparejamiento eliminado correctamente!'
          },
          tasks: {
            tasks: 'Tareas | ',
            newTask: 'Nueva tarea',
            plan: 'Plan',
            status: 'Status',
            notStarted: 'No empezado',
            ready: 'Listo',
            stoppedGlobalbot: 'Interrumpido Globalbot',
            stoppedClient: 'Interrumpido Cliente',
            date: 'Fecha',
            invalidDate: 'Fecha invalida',
            noDate: 'Sin fecha',
            description: 'Descripción',
            notDescription: 'No hay una descripción',
            advancedSettings: 'Configuraciones avanzadas',
            do: 'Do',
            go: 'Go',
            learn: 'Learn',
            editTask: 'Editar Tarefa',
            selectUsers: 'Seleccionar usuarios',
            selectAccount: 'Seleccionar cuenta',
            selectBot: 'Seleccionar bot',
            selectType: 'Seleccione tipo',
            title: 'Título',
            selectStatus: 'Seleccionar estado',
            done: 'Hecho'
          },
          users: {
            listUsers: 'Lista de usuarios',
            user: 'Usuario',
            name: 'Nombre',
            email: 'Email',
            role: 'Oficina',
            newPassword: 'Nueva contrasenã',
            avatar: 'Avatar',
            permission: 'Permiso',
            done: 'Hecho',
            supreme: 'Supreme',
            master: 'Master',
            administrator: 'Administrador',
            manager: 'Gerente',
            attendant: 'Asistente',
            junior: 'Junior',
            messageErrorInfo: 'Vaya, se produjo un error al guardar la información.',
            messageErrorUser: 'Vaya, se produjo un error al guardar al usuario.',
            supremeCommander: 'Supreme Commander'
          }
        },
        analytics: {
          attendant: {
            attendant: {
              indicatorsByAttendants: 'Indicadores por asistentes',
              attendantsByTime: 'Asistencias por hora del día',
              chatbotsAndAttendants: 'Chatbots x Acomodadores',
              departments: 'Departamentos',
              attendants: 'Acomodadores',
              statistics: 'Estadísticas',
              exportAttendants: 'Exportar llamadas'
            }
          },
          channels: {
            channels: {
              indicatorsByChannels: 'Indicadores por Canales',
              attendantsByTime: 'Asistencias por hora del día',
              chatbotsAndAttendants: 'Chatbots x Acomodadores',
              channelOverview: 'Resumen por canal',
              statistics: 'Estadísticas'
            }
          },
          flow: {
            flow: {
              flowAnalysis: 'Análisis de flujo',
              statistics: 'Estadísticas'
            }
          },
          identifiers: {
            identifiers: {
              customIdentifiers: 'Identificadores personalizados',
              statistics: 'Estadísticas'
            }
          },
          knowledge: {
            knowledge: {
              intelligencePerformance: 'Rendimiento de inteligencia',
              conventionalIA: 'IA Convencional',
              conventionalIADescription: 'Respuestas estandarizadas. Interpreta la intención del usuario y presenta respuestas registradas (texto, vídeo, imágenes, documentos y enlaces).',
              generativeIA: 'IA Generativa',
              generativeIADescription: '',
              knowledgeBase: 'Base de conhecimento',
              commonQuestions: 'Preguntas comunes',
              advanced: 'Avanzado',
              artificialIntelligence: 'Inteligencia',
              statistics: 'Estadísticas'
            },
            responses: {
              response: 'Respuesta'
            }
          },
          location: {
            location: {
              countries: 'Países',
              states: 'Estados',
              cities: 'Ciudades',
              geolocation: 'Geolocalización',
              statistics: 'Estadísticas'
            }
          },
          origin: {
            origin: {
              platformAndBrowsers: 'Plataforma y navegadores',
              campaign: 'Campañas',
              originAttendance: 'Origen de las asistencias',
              statistics: 'Estadísticas',
              url: 'Localizador Uniforme de Recursos'
            }
          },
          cards: {
            attendance: 'Asistencias',
            descriptionAttendance: 'Volumen total de llamadas (todos los canales) para el período seleccionado.<br/>La comparación es relativa al período inmediatamente anterior.',
            satisfaction: 'Satisfacción',
            descriptionSatisfaction: 'Tasa de satisfacción relacionada con la Encuesta de satisfacción del<br/>usuario en el período seleccionado. La comparación es relativa al período inmediatamente anterior.<br/>Este indicador es visible solo cuando la Encuesta de satisfacción está habilitada en la ruta Preferencias > Chatbot > Comportamiento.',
            intelligence: 'Inteligencia',
            descriptionIntelligenceOne: 'Tasa de éxito de la(s) Inteligencia(s) Artificial(es) de los chatbots seleccionados <br>y punto. Para la IA convencional, es la suma<br> de las preguntas respondidas y las preguntas desambiguadas.<br> Para la IA generativa, solo se consideran las preguntas respondidas, ya que <br> no hay desambiguación.<br> La comparación es relativa a el período inmediatamente anterior.',
            connected: 'Conectado',
            descriptionConnected: 'Total de usuarios (colaboradores) conectados<br/>actualmente a la Plataforma Globalbot.<br/>Se refiere a las personas que utilizan la<br/>plataforma (como administradores, gerentes<br/>o asistentes), sin referirse al número de<br/>clientes en servicio.',
            beta: 'BETA',
            descriptionBeta: 'El equipo de Globalbot está desarrollando<br/>y probando la función identificada como BETA. Espere noticias sobre<br/>la evolución de esta función.',
            viewByChannel: 'Visión por Canal',
            descriptionViewByChannel: 'Consulta los principales indicadores de servicio por canal, cuantitativos y cualitativos.',
            attendants: 'Acomodadores',
            descriptionAttendants: 'Ver los indicadores de desempeño de los asistentes de forma individual y colectiva.',
            descriptionIntelligenceTwo: 'Analizar el desempeño de la Inteligencia Artificial, incluyendo preguntas respondidas, inequívocas y sin respuesta.',
            geolocation: 'Geolocalización',
            descriptionGeolocation: 'Verifique desde qué lugares llegan los asistentes, por país, estado y ciudad.',
            origin: 'Origen',
            descriptionOrigin: 'Analizar el origen de las llamadas, considerando las fuentes de tráfico, por ejemplo, campañas digitales.',
            flowAnalysis: 'Análisis de flujo',
            descriptionFlowAnalysis: 'Vea cómo los usuarios interactúan con los flujos de conversación, considerando el compromiso y el abandono.',
            customized: 'Personalizado',
            descriptionCustomized: 'Consulte las métricas de sus identificadores personalizados que forman parte de los flujos de conversación.',
            statistics: 'Estadísticas',
            tooltipStatistics: '¿Dudas en estadística? Haga clic aquí y acceda al Tutorial que preparó nuestro equipo. ¡Tenemos muchas novedades!',
            totalValue: 'Valor Total',
            totalValueDescription: 'Consulta el valor total generado por las conversaciones durante el período (muy útil para quienes venden productos y servicios). Para aparecer aquí, el valor debe asignarse a cada conversación de forma individual.'
          }
        },
        block: {
          create: {
            add: 'Agregar',
            blocks: 'Bloques',
            welcome: 'Bienvenido',
            addButton: 'Botón agregar',
            create: 'Crear',
            title: 'Título',
            description: 'Descripción',
            message: 'Mensaje',
            name: 'Nombre',
            firstName: 'Primer nombre',
            lastName: 'Apellido',
            buttons: 'Botones',
            button: 'Botón',
            email: 'E-mail',
            phone: 'Teléfono',
            cpf: 'CPF',
            cnpj: 'CNPJ',
            birthDate: 'Fecha de nacimiento',
            calendar: 'Calendario',
            number: 'Número',
            checkbox: 'Checkbox',
            slider: 'Slider',
            image: 'Imagen',
            intelligence: 'Inteligencia',
            liveChat: 'Chat en vivo',
            zipCode: 'Código postal',
            city: 'Ciudad',
            state: 'Estado',
            country: 'País',
            carousel: 'Carrusel',
            guideScreen: 'Pantalla de guía',
            money: 'Máscara de dinero',
            exit: 'Terminar'
          },
          index: {
            blocks: 'Bloques',
            listblock: 'lista de bloqueos',
            new: 'Nuevo',
            index: 'index'
          }
        },
        bots: {
          bots: {
            testChatbot: 'Testar Chatbot',
            editFlow: 'Editar Flujo',
            viewer: 'Espectador',
            lite: 'Lite',
            pro: 'Pro',
            editor: 'Editor',
            settingsAccess: 'Configuración de acceso',
            createNewChatbot: 'Nuevo Chatbot',
            createNewChatbotDesc: 'Crea un nuevo chatbot, edita el flujo conversacional, define parámetros y prueba.',
            titleChatbot: 'Título del chatbot',
            nameAttendant: 'Nombre del asistente',
            cancel: 'Cancelar',
            create: 'Crear',
            createSuccessfully: '¡Chatbot creado exitosamente!',
            createError: 'Error al crear chatbot',
            hello: 'Hola. ¿Puedo ayudarte?',
            contactGlobalbot: 'Contacto Globalbot',
            subject: 'assunto',
            content: 'contenido',
            indication: 'Tu amigo tiene una referencia',
            thanks: 'Hola, {nombre}, muchas gracias por tu interés en ',
            introduction: 'Con Globalbot su empresa ofrecerá una experiencia de diálogo con sus públicos en las situaciones más diversas. Esta experiencia es fantástica, especialmente en dispositivos móviles (teléfonos celulares y tabletas), ya que la gente ya está acostumbrada a usar aplicaciones de mensajería como Facebook, Whatsapp y muchas otras.',
            listing: 'También he enumerado aquí algunas posibles aplicaciones de ',
            inCompany: ' en tu compañía:',
            leadGeneration: 'Generación líder:',
            leadGenerationDescription: ' Úselo para retener al visitante del sitio web a través del diálogo y generar clientes potenciales para los equipos de ventas, integrándolo con herramientas de la fuerza de ventas o activando un correo electrónico.',
            marketingAndSales: 'Marketing y ventas:',
            marketingAndSalesDescription: ' Realizar campañas y acciones de marketing enfocadas a las ventas, presentando un diálogo que lleve al cliente a involucrarse con el producto y sus características.',
            search: 'Buscar:',
            searchDescription: ' Transforma extensas encuestas de opinión y satisfacción en diálogos amigables, evitando el aburrimiento y la dispersión.',
            attendance: 'Asistencia al Cliente:',
            attendanceDescription: ' Realizar servicios de atención al cliente, basados en las preguntas más frecuentes y así eliminar puestos de servicio y / o reducir filas de espera.',
            schedulingAndReservations: 'Programación y reservas:',
            schedulingAndReservationsDescription: ' Utilícelo para realizar citas y reservas (oficinas, concesionarios, restaurantes, eventos y muchos otros) integrando sistemas internos.',
            databaseAndCrm: 'Calificación de base de datos y CRM:',
            databaseAndCrmDescription: ' puede servir como interfaz para acceder a la información disponible en las bases de datos, permitiendo la calificación de las bases de datos a través del diálogo.',
            teachingAndLearning: 'Enseñando y aprendiendo:',
            teachingAndLearningDescription: ' Utilizar para acciones de enseñanza y aprendizaje, utilizando los contenidos de la clase para los estudiantes, pudiendo hablar sobre temas específicos de las distintas materias de los cursos.',
            furthermore: 'Además el ',
            dispatchDescription: ' ¡Puede enviar correo electrónico, SMS, mostrar imágenes, audio, archivos de video y más!',
            surpriseAudience: '¡Sorprende a tu audiencia con esta nueva experiencia!',
            regards: 'Graciosamente,',
            telmaAyala: 'Telma Ayala',
            virtualAssistant: 'Asistente virtual',
            address: ' Av. Cristovão Colombo, 3000 / salas 802 e 304 / Floresta Porto Alegre - RS - CEP 90560-002 ',
            moreInformations: 'Más información',
            oneMessage: '¿Sigues por aquí?',
            twoMessage: 'Si no contesta tendré que terminar la asistencia.',
            threeMessage: 'Muy bien, si necesitas ayuda nuevamente, avísame.',
            closeMessage: 'La asistencia fue cerrada por el asistente, agradecemos su contacto y si tiene más preguntas, simplemente llámeme nuevamente.',
            chatbots: 'Chatbots'
          }
        },
        broadcast: {
          broadcast: {
            campaigns: 'Mensaje',
            processing: 'Procesando...',
            sending: 'Enviando: ',
            of: ' en ',
            messageCreate: 'Crear un mensaje',
            messageType: 'Tipo de mensaje',
            messageSave: 'Mensajes SMS/Whatsapp guardados',
            email: 'Email',
            messageTitle: 'Título del mensaje',
            confirmation: 'Confirmación',
            saveNewMessageWithName: 'Guardar mensaje nuevo con nombre ',
            save: 'Guardar',
            cancel: 'Cancelar',
            messageText: 'Mensaje de texto',
            next: 'Próximo',
            recipients: 'Destinatarios',
            importNewList: 'Importar nueva lista',
            myLists: 'Mis listas',
            listJson: 'Lista Json',
            send: 'Enviar',
            sendNow: 'Enviar agora',
            scheduleShipping: 'Programar envío',
            submit: 'Enviar',
            importList: 'Lista de importación',
            import: 'Importar',
            saveList: 'Guardar lista',
            listTitle: 'Título de la lista',
            totalRecipients: 'Destinatarios totales',
            recipientsToLowerCase: ' destinatarios',
            importedList: 'Lista importada',
            messageSaveList: '¡Tu lista se ha guardado!',
            messageOpsError: 'Oops, some error occurred.',
            allMessagesWereSent: 'Se han enviado todos los mensajes.',
            messageNotCreditsSms: 'Vaya, parece que faltan créditos de SMS. Por favor contáctenos.',
            allMessagesWereScheduled: 'Se han programado todos los mensajes.',
            failedToConnect: 'No se pudo conectar al servicio de transmisión, vuelva a cargar la página.',
            messageNotCreditsWhatsapp: 'Vaya, parece que faltan créditos de WhatsApp. Por favor contáctenos.',
            messageWereSent: 'Fueron enviados ',
            messageOfTheTotal: ' del total de ',
            chargedMessages: ' Mensajes. Solo se cobrarán los mensajes enviados.',
            gupshupTemplateImport: "Importar pantilha HSM"
          },
          broadcast2: {
            email: 'E-mail',
            communicate: 'Comuníquese a través de marketing por correo electrónico o correo electrónico transaccional.',
            createModel: 'Crear plantilla',
            sms: 'SMS',
            sendNotificationsSms: 'Envíe notificaciones y avisos a través de mensajes de texto (SMS).',
            whatsapp: 'WhatsApp',
            sendNotificationsWhatsapp: 'Envía mensajes promocionales, notificaciones y encuestas a través de WhatsApp.',
            requestCredits: 'Solicitar Créditos',
            requestCreditsMessaging: 'Solicite créditos adicionales para usar en la campañas.',
            models: 'Modelos',
            modelNotCreated: 'Aún no has creado ningún modelo. Comience eligiendo qué tipo de plantilla desea crear..',
            modelHsm: 'Modelo de mensaje HSM (High Structured Message) utilizado para enviar mensajes preaprobados a través de la API de WhatsApp Business.',
            modelData: 'Datos del modelo',
            messageEditor: 'Editor de mensajes',
            preview: 'Avance',
            duplicate: 'Duplicar envio',
            duplicateDraft: 'Duplicar bosquejo',
            createDispatch: 'Crear envío',
            exclude: 'Borrar',
            dispatches: 'Transporte',
            dispatchesApi: 'Api',
            dispatchNotCreated: 'Aún no ha creado ningún envío. Crea un envío a partir de una plantilla de mensaje.',
            draftNotCreated: 'Todavía no tienes borradores',
            static: 'Estático',
            recurring: 'Recurrente',
            draft: 'Borrador',
            readyToSend: 'Pronto para Envio',
            scheduled: 'Programado',
            processing: 'Procesando',
            scheduledFor: 'Programado para ',
            delivered: 'Entregado',
            device: 'Entregado',
            read: 'Leer',
            error: 'No Enviada',
            processed: 'Enviada',
            sent: 'Enviado',
            insufficientCredits: 'Créditos insuficientes',
            editDispatch: 'Editar Envío',
            editDraft: 'Editar bosquejo',
            dataDispatch: 'Detalles de envío',
            statsDispach: 'Estadísticas de envío',
            prepareDispatch: 'Preparar Envio',
            modelExclude: 'Eliminar modelo',
            cancel: 'Cancelar',
            areSureModelExclude: '¿Está seguro de que desea eliminar esta plantilla?',
            excludeDispatch: 'Eliminar envio',
            duplicateDispatch: '¿Desea hacer una copia de este envío?',
            areSureDispatchExclude: '¿Está seguro de que desea eliminar este envío?',
            dispatch: 'Envío',
            areSureDispatch: '¿Estás seguro de que quieres enviar?',
            found: 'Encontrado',
            repeated: 'Repetido',
            removed: 'Retirados',
            range: 'Alcanzar',
            schedule: 'Para programar',
            saveDraft: 'Guardar como Borrador',
            templateChannel: 'Canal',
            templateName: 'Nombre',
            templateAuthor: 'Autor',
            templateEdited: 'Última actualización',
            templateActions: 'Comportamiento',
            dispatchChannel: 'Canal',
            dispatchName: 'Nombre',
            dispatchAuthor: 'Autor',
            dispatchStatus: 'Status',
            dispatchActions: 'Comportamiento',
            templateExclude: '¡Plantilla eliminada!',
            dispatchExclude: '¡Envío excluido!',
            processingDispatch: 'Se está procesando el envío.',
            dispatchScheduled: 'Se ha programado el envío.',
            messageOpsNotDispatch: 'Vaya, tuvimos algún problema al procesar el envío.',
            messageNotCredits: 'Lamentablemente, su saldo de crédito no es suficiente.',
            messageOpsNotValid: 'Vaya, tuvimos algún problema al validar el envío.',
            messageNotFoundDispatch: 'Envío no encontrado',
            messageOpsErrorDispatch: 'Vaya, se produjo un error al recuperar el envío.',
            messageOpsFailedCompileTemplate: 'Vaya, no se pudo compilar la plantilla',
            campaigns: 'Campañas',
            doubtsInMessaging: '¿Dudas? Haz clic aquí y mira el recorrido interactivo.',
            canNotDuplicateMissingTemplate: 'Plantilla eliminada, no es posible duplicar este envío.'
          }
        },
        conversations: {
          conversation: {
            rowsPerPage: 'Registros por página',
            recipients: ' destinatarios',
            createNewList: 'Crear nueva lista',
            excludeConversationSelected: 'Eliminar conversaciones seleccionadas',
            createListConversationSelected: 'Crear lista con conversaciones seleccionadas',
            createListConversationAll: 'Crear lista con todas las conversaciones',
            exportConversationSelected: 'Exportar conversaciones seleccionadas',
            exportAllConversationSelected: 'Exportar todas conversaciones',
            forwardConversationSelected: 'Reenviar conversaciones seleccionadas',
            forwardConversationIntegrations: 'Reenviar conversaciones en integraciones',
            columns: 'Columnas',
            dateAndHour: 'Fecha y hora',
            protocol: 'Protocolo',
            chatbot: 'Chatbot',
            tags: 'Tags',
            channel: 'Canal',
            actions: 'Comportamiento',
            attendedBy: 'Atendido por',
            monetaryValue: 'Valor',
            satisfaction: 'Satisfacción',
            category: 'Categoría',
            department: 'Departamento',
            transhipmentAttended: 'Transbordo servido: el asistente atendió al cliente. ¡Todo bien!',
            transhipmentUserLost: 'Transferencia desatendida: ¡el cliente abandonó el chat antes de que el asistente interactuara!',
            transhipmentNotYetAttended: 'Transbordo aún no atendido/en espera: el asistente aún no ha interactuado con el cliente.',
            transhipmentWaitigAttended: 'Transbordo desatendido: esperando al asistente.',
            botDeleted: 'Robot eliminado',
            transhipmentUnrealized: 'Transbordo no realizado: No hay asistente en línea en este momento. Chatbot reanudó la conversación.',
            transhipmentRejected: 'Transferencia rechazada: el asistente finalizó la conversación antes de interactuar con el usuario',
            emailLeadSend: 'Correo electrónico de cliente potencial enviado',
            nameEmailLeadSend: ', Correo electrónico de cliente potencial enviado',
            createNewListWith: 'Crea una lista con el ',
            conversationSelect: ' conversaciones seleccionadas. Utilice la lista creada en el módulo de Mensajería para enviar mensajes por correo electrónico, SMS o WhatsApp.',
            listName: 'Lista de nombres',
            cancel: 'Cancelar',
            save: 'Guardar',
            excludeConversations: 'Eliminar conversaciones',
            areSureWantToDelete: '¿Estás seguro de que quieres eliminar ',
            conversationsSelected: ' conversaciones seleccionadas?',
            exclude: 'Eliminar',
            reportExport: 'Exportación de informes',
            reportExportNPS: 'Exportación de informes NPS',
            willReportExport: 'Exportarás un informe con ',
            conversationsSelectedFormatCsv: ' conversaciones seleccionadas en el formato .CSV.',
            exported: 'Exportar',
            exportedXlsx: 'Exportar XLSX',
            exportedCsv: 'Exportar CSV',
            forward: 'Hacia adelante',
            youCanSend: 'Podrá enviar por correo electrónico las conversaciones seleccionadas a otra persona.',
            internalMessage: 'Enviar con mensajes internos de chat en vivo.',
            email: 'E-mail',
            integrations: 'Integraciones',
            youCanSendCrmOrTickets: 'Podrás enviar las conversaciones seleccionadas a tu CRM o sistema de tickets',
            selectIntegration: 'Seleccione su integración',
            send: 'Enviar',
            all: 'Todos',
            primary: 'Primario',
            conversations: 'Conversaciones',
            messageSendEmailBut: 'Sin embargo, se enviaron correos electrónicos ',
            messageSendEmailSuccess: '¡Correos enviados con éxito!',
            messageNotSendEmail: 'Vaya, los correos electrónicos no se enviaron.',
            messageOps: 'UPS, ',
            messageNeedEmail: '¡Vaya, necesitamos un correo electrónico para reenviar!',
            messageIntegrationsSendBut: 'Integraciones enviadas sin embargo ',
            messageIntegrationsSendSuccess: '¡Integraciones enviadas con éxito!',
            messageIntegrationsNotSend: 'Vaya, las integraciones no se enviaron.',
            messageIntegrationsNotSendField: 'Vaya, las integraciones no se enviaron. Email es requerido.',
            messageNeedIntegrations: '¡Vaya, necesitamos una integración para enviar!',
            messageSaveList: '¡Tu lista se ha guardado!',
            messageListNeedName: '¡Tu lista necesita un nombre!',
            messageOpsError: 'Vaya, se produjo un error.',
            date: 'Fecha',
            hour: 'Hora'
          }
        },
        dashboard: {
          dashboard: {
            weHaveNews: '¡Tenemos novedades!',
            helpCenter: 'Descubra nuestro Centro de ayuda. Hay más de',
            articles: '100 artículos',
            featuresPlatform: 'que explican<br/> paso a paso las características de nuestra plataforma.',
            viewMore: '¡Ver más!',
            welcomeMessage: '¡Disfrute de las funciones y funciones más nuevas que hemos preparado para usted!',
            clickItem: 'Haga clic en los elementos para ver la demostración.',
            newLook: 'La plataforma con un nuevo look.',
            highPerformance: 'Mayor rendimiento y procesamiento.',
            talkTeam: 'Habla con tu equipo ahora también.',
            attendanceChanges: 'Cambios al hacerse cargo y finalizar el servicio.',
            emojisAndQuickResponse: 'Emojis, respuestas rápidas y otras novedades.',
            commentDuringAttendance: 'Comentarios y notas durante la asistencia.',
            insights: 'Insights',
            news: 'Noticias',
            improvement: 'Sugerir mejora',
            visitBlog: 'Para acceder a todo el contenido, visite nuestro ',
            newsDesc: 'Consulta las novedades lanzadas recientemente en la plataforma Globalbot.',
            version: 'Versión',
            build: 'Build',
            termsOfUse: 'Condiciones de uso',
            goodMorning: 'Buenos días',
            goodAfternoon: 'Buenas tardes',
            goodEvening: 'Buenas noches',
            accountData: 'Dados de la cuenta ',
            accountDataLabel: 'Consulta la información del plan contratado a continuación.',
            cardAccount: {
              plan: 'Plan',
              consumption: 'Consumo mensual',
              lineWhatsapp: 'Whatsapp',
              lineOtherChannel: 'Otros canales'
            },
            january: 'Enero',
            february: 'Febrero',
            march: 'Marcha',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre',
          },
          layout: {
            localization: 'Idioma',
            portuguese: 'Portugués',
            english: 'Inglés',
            spanish: 'Español',
            online: 'Online',
            availableToNewAttendance: 'Estará disponible para recibir nuevas llamadas de su departamento.',
            away: 'Away',
            pauseNewAttendance: 'Pausa. Continuará respondiendo a sus conversaciones activas pero no recibirá nuevas llamadas.',
            offline: 'Offline',
            disconnectToChat: 'Inmediatamente se desconectará del chat. No se le asignarán nuevas llamadas.',
            myProfile: 'Mi perfil',
            notificationCenter: 'Centro de notificaciones',
            nightMode: 'Modo nocturno',
            exit: 'Salida',
            tasks: 'Tareas',
            clients: 'Clientes',
            actions: 'Comportamiento',
            changeLogs: 'Noticias | Change Logs',
            nps: 'Informe NPS',
            serverWhatsapp: 'Servidores de WhatsApp',
            conversationDetails: 'Detalles de la Conversación',
            home: 'Pantalla de inicio',
            statistics: 'Estadísticas',
            conversation: 'Conversaciones',
            chatBots: 'Chatbots',
            intelligence: 'Inteligencia',
            campaigns: 'Campañas',
            liveChat: 'Chat en vivo',
            library: 'Biblioteca',
            needHelp: '¿Necesitas ayuda? ¡Cuéntanos qué necesitas y nuestro equipo te ayudará!',
            preferences: 'Preferencia',
            important: 'Importante:',
            messageImportant: ' Cuando entre en pausa, continuará respondiendo a sus conversaciones activas pero no recibirá nuevas llamadas.',
            reasonPause: 'Motivo de la pausa',
            cancel: 'Cancelar',
            continue: 'Continuar'
          }
        },
        flow: {
          editor: {
            save: 'Guardar',
            errorSavingInformation: 'Vaya, se produjo un error al guardar la información.',
            savedFlow: 'Se guardó la transmisión'
          },
          flow: {
            flow: 'Fluxo',
            save: 'Salvar',
            addOneBalloon: 'Agregar 1 globo',
            addTenBalloons: 'Agrega 10 globos',
            removeLastBalloon: 'Quitar el último globo',
            removeTheLastTenBalloons: 'Retire los últimos 10 globos',
            test: 'Prueba',
            indicateBalloonLine: 'Este número indica la línea en la que se encuentra el globo.',
            responseField: 'Campo de respuesta',
            emptyQuestion: 'Pregunta vacía',
            clickAndEditBalloon: 'Al hacer clic, puede editar todo el globo.',
            clickGoToLine: 'Haciendo clic puede ir a la línea.',
            type: 'Tipo',
            indicatorName: 'Indicador del nombre del',
            balloon: 'del globo.',
            identifier: 'Identificador',
            responseWillAppearInConversation: 'Esta respuesta aparecerá en la pestaña de conversaciones.',
            knowledge: 'Base de conocimientos',
            jumpIfNotFound: 'Saltar si no se encuentra',
            ifThereNoQuestionJumpTheLine: 'Si no hay ninguna pregunta registrada, saltará a la línea',
            department: 'Departamento',
            jumpIfNoOneOnline: 'Salta si no hay nadie en línea',
            jumpIfNoAttendantOnline: 'Si no hay un asistente en línea, saltará a la línea',
            jumpingToLine: 'Saltando a la línea',
            botWillGoToLine: 'El bot después de pasar por este globo, irá a la línea.',
            clickInNumberGoToLine: 'Haga clic en el número para ir a la línea.',
            uniqueExternalCode: 'Código externo único',
            webchatExternalCode: 'Código Webchat externo',
            facebookExternalCode: 'Código externo Facebook',
            whatsappExternalCode: 'Código externo Whatsapp',
            glpiIntegration: 'Integración con GLPI',
            sendingLeadEmail: 'Envío de correo electrónico de clientes potenciales',
            thisBalloonCreatesTags: 'Este globo crea etiquetas',
            appearanceTime: 'Tiempo de aparición',
            previewAppearanceTime: 'Muestra el tiempo de aparición del globo, el valor predeterminado es 3000 milisegundos (3 segundos)',
            finishingBot: 'Terminación de bot',
            finishingBotDescription: 'Cuando el bot llegue a esta línea, terminará.',
            dontResponse: 'No respondas',
            dontResponseDescription: 'Aquí viene una opción para que el usuario no responda, al hacer clic en ella, el bot pasa al siguiente globo.',
            comment: 'Comentario',
            commentDescription: 'Comentario realizado por un editor de este flujo.',
            secureMessage: 'Mensaje seguro',
            secureMessageDescription: 'Esta respuesta no se guardará en Conversaciones en la plataforma Globalbot',
            editLine: 'Editar línea',
            emptyBalloon: 'Desinflar globo',
            done: 'Hecho',
            editText: 'Editar texto',
            addRandomResponse: 'Agregar respuesta aleatoria',
            enterYourQuestion: 'Ingrese su pregunta aquí',
            editResponseAttributes: 'Edite los atributos de respuesta a continuación',
            none: 'Ninguno',
            theType: 'El tipo',
            theTypeNoneDescription: 'es cuando no espera ninguna respuesta, es solo para mostrar algo en la pregunta.',
            text: 'Texto',
            theTypeTextDescription: 'es solo para respuestas, permite al usuario poner cualquier texto, números sin ningún tipo de máscara.',
            name: 'Nombre',
            theTypeNameDescription: 'es solo para respuestas, permite al usuario poner el nombre o cómo quiere que lo llamen.',
            cpf: 'CPF',
            theTypeCpfDescription: 'es solo para respuestas, permite al usuario colocar el CPF con la máscara de CPF.',
            phone: 'Teléfono',
            theTypePhoneDescription: 'es solo para respuestas, permite al usuario poner el teléfono con la máscara del teléfono.',
            email: 'E-mail',
            theTypeEmailDescription: 'es solo para respuestas, permite al usuario ingresar el correo electrónico y validarlo con una herramienta.',
            buttons: 'Botones',
            theTypeButtonsDescription: 'es solo para respuestas, permite al usuario elegir entre 2 o más opciones de botones.',
            list: 'Lista',
            faq: 'FAQ',
            dataBase: 'Base de Datos',
            dataBaseNotFound: 'Datos da base não enontrados',
            theTypeFaqDescription: 'es solo para respuestas, le permite al usuario escribir algo y al enviarlo, busca en la inteligencia lo que escribió.',
            carousel: 'Carrusel',
            theTypeCarouselDescription: 'es solo para respuestas, permite al usuario elegir una opción entre 2 o más de un carrusel.',
            liveChat: 'Chat en vivo',
            theTypeLiveChatDescription: 'intenta conectarse al chat en vivo de Globalbot.',
            calendar: 'Calendario',
            theTypeCalendarDescription: 'permite al usuario elegir la fecha deseada en el calendario.',
            time: 'Tiempo',
            theTypeTimeDescription: 'permite al usuario elegir la hora en el reloj.',
            response: 'Respuesta',
            buttonOptions: 'Opciones para botones',
            dragToSwitchPosition: 'Arrastra para cambiar de posición',
            value: 'Valor',
            jump: 'Salto',
            switchToSmartButton: 'Cambiar a botón inteligente',
            switchToButtons: 'Cambiar a botones',
            excludeButton: 'Eliminar este botón',
            addButton: 'Agregar otro botón',
            checkboxOptions: 'Opciones para casillas de verificación',
            slidersOptions: 'Opciones para controles deslizantes',
            label: 'Label',
            minValue: 'Valor mínimo',
            maxValue: 'Valor máximo',
            initialValue: 'Valor inicial',
            step: 'Último',
            guideScreenOptions: 'Opciones para la pantalla guiada',
            pageLink: 'Enlace de página',
            returnJump: 'Salto de regreso',
            faqOptions: 'Opciones para FAQ',
            dataBaseOptions: 'Opciones para Base de Datos',
            knowledgeBase: 'Base de conocimientos',
            secondaryKnowledgeBase: 'Base de conocimientos secundaria',
            ifNotFound: 'Si no se encuentra:',
            faqButtons: 'Botones en FAQ',
            learningTime: 'Tiempo de aprendizaje (días)',
            buttonLimit: 'Límite de botones',
            machineLearning: 'Máquina de aprendizaje',
            imageOptions: 'Opciones para imagen de Facebook o Whatsapp',
            imageLink: 'Enlace de imágen',
            textOptions: 'Opciones de texto.',
            mask: 'Máscara',
            messageError: 'Mensaje de error',
            messageErrorOption: 'Mensaje de error de opciones',
            newMask: 'Nueva mascara',
            typeSomething: 'Por favor escriba algo.',
            onlyFacebook: 'Solo para facebook, use youtube incrustado u otros componentes',
            chooseVideoFromLibrary: 'Elige un vídeo de la biblioteca',
            videoLink: 'Enlace de video AVI, MP4 etc',
            moreOptions: 'Mas opciones',
            liveChatOptions: 'Opciones para chat en vivo',
            fill: 'Llenar',
            message: 'Mensaje',
            liveErrorJump: 'Error de salto',
            liveErrorJumpMessage: 'El salto no puede ser a la misma línea.',
            setIdentifier: 'Establezca el identificador para guardar la respuesta del usuario.',
            newIdentifier: 'Nuevo identificador',
            jumpLine: 'Salto de línea',
            defineJumpToNextLine: 'Defina dónde estará la siguiente línea para que salte el chatbot.',
            jumpToLine: 'Saltando a la línea',
            jumpTo: 'Salta a',
            assignComment: 'Añadir un comentario',
            assignCommentDescription: 'Asignará un comentario a esta línea dentro del flujo.',
            notResponseOptions: 'Opción de no responder',
            createNotResponseOnlyWebchat: 'Crea una opción para no responder a la pregunta. Solo Webchat.',
            notResponse: 'No respondas',
            secureMessageOptions: 'Opción de mensaje seguro',
            writtenMessageOne: 'El mensaje escrito por el usuario no será almacenado por Globalbot.',
            waitDefinedValue: 'Cuando está habilitado, espera a que aparezca el valor establecido en milisegundos.',
            timing: 'Tiempo',
            addTiming: 'Agregar tiempo',
            integrations: 'Integraciones',
            ticketTitle: 'Ponga el título del Ticket que desee y haga clic en el + al lado.',
            sendTicketToGlpi: 'Después de guardar, el bot que pasa por esta línea enviará un ticket al Sistema GLPI.',
            clickTheIcon: 'Para deshabilitar, simplemente haga clic en el icono menos.',
            editTicketTitle: 'Editar el título del boleto',
            newTicketTitle: 'Nuevo título del boleto',
            sendLeadTranscriptEmail: 'Envíe la transcripción del cliente potencial por correo electrónico',
            insertEmail: 'Ingrese el correo electrónico para enviar el cliente potencial en el campo a continuación.',
            saveBotAndSendEmail: 'Después de guardar el bot pasando por esta línea se enviará un correo electrónico de plomo al correo electrónico informado.',
            editEmail: 'Edit email',
            newEmail: 'New email',
            addTagInConversation: 'Agregar TAG en la conversación',
            selectTags: 'Seleccione las etiquetas que se agregarán a la conversación si pasa por esta línea.',
            selectOneTag: 'Seleccione una TAG',
            addTag: 'Agregar tags',
            externalCode: 'Códigos externos',
            masterOnlyFunction: 'Función solo para maestros.',
            insertCode: 'Aquí hay un código javascript puro que se ejecutará en esa línea.',
            codeAdapted: 'Si no es el Callback Único, cada plataforma necesita un código adaptado para funcionar.',
            unifiedExternalCode: 'Código externo unificado',
            newUnifiedExternalCode: 'Nuevo código externo unificado',
            externalCodeForWebchat: 'Código externo para Webchat',
            newExternalCodeForWebchat: 'Nuevo código externo para Webchat',
            externalCodeForWhatsapp: 'Código externo para Whatsapp',
            newExternalCodeForWhatsapp: 'Nuevo código externo para Whatsapp',
            externalCodeForFacebook: 'Código externo para Facebook',
            newExternalCodeForFacebook: 'Nuevo código externo para Facebook',
            close: 'Cerrar',
            guideScreen: 'Pantalla guiada',
            zipCode: 'Código postal',
            cnpj: 'CNPJ',
            finish: 'Terminar',
            uploadImage: 'Carga de imagen',
            checkbox: 'Checkbox',
            rating: 'Estrellas',
            number: 'Número',
            firstName: 'Nombre de pila',
            lastName: 'Apellido',
            audio: 'Audio',
            bornDate: 'Fecha de nacimiento',
            slider: 'Slider',
            image: 'Imagen',
            facebookVideo: 'Video de Facebook',
            video: 'Video',
            city: 'Ciudad',
            state: 'Estado',
            country: 'País',
            maskMoney: 'Máscara de dinero',
            noBaseAvailable: 'No hay base disponible',
            noBaseAssigned: 'Sin base asignada',
            dataBaseNotFoundOption: 'Lamentablemente no tenemos esta opción, elija una opción válida.',
            noBaseDefined: 'Sin base definida',
            noBodyOnline: 'Desafortunadamente, no tenemos a nadie en línea en este momento.',
            messageErrorSaved: 'Vaya, se produjo un error al guardar la información.',
            messageErrorButtons: 'Oops, nombres de botones duplicados en la fila ',
            saveDescription: 'Poner una descripción antes de guardar',
            flowSaved: 'Se guardó la transmisión',
            flowPublish: 'Flujo publicado',
            editFlow: 'Editor de flujo',
            title: 'Título',
            description: 'Descripción',
            select: 'Seleccione'
          },
          flowLite: {
            flowLite: 'Flujo Lite',
            addBalloonAbove: 'Agregar globo a la parte superior',
            randomMessage: 'Mensaje aleatorio',
            message: 'Mensaje',
            name: 'Nombre',
            buttons: 'Botones',
            intelligence: 'Inteligencia',
            carousel: 'Carrusel',
            text: 'Texto',
            email: 'Email',
            checkbox: 'Checkbox',
            rating: 'Estrellas',
            number: 'Número',
            firstName: 'Primer nombre',
            lastName: 'Apellido',
            phone: 'Teléfono',
            telephone: 'El teléfono',
            audio: 'Audio',
            liveChat: 'Chat en vivo',
            cpf: 'CPF',
            zipCode: 'Código postal',
            calendar: 'Calendario',
            slider: 'Slinder',
            image: 'Imagen',
            city: 'Ciudad',
            state: 'Estado',
            country: 'El país',
            money: 'Dinero',
            cnpj: 'CNPJ',
            uploadImage: 'Carga de imagen',
            finish: 'Terminar',
            guideScreen: 'Pantalla guiada',
            done: 'Hecho',
            editText: 'Editar texto',
            addRandomResponse: 'Agregar respuesta aleatoria',
            randomizeResponse: 'Respuesta aleatoria',
            edit: 'Edit',
            exclude: 'Borrar',
            identifier: 'Identificador',
            jump: 'Salto',
            excludeBalloon: 'Eliminar globo',
            wantToExcludeBalloon: '¿Está seguro de que desea eliminar este globo?',
            dontAskAgain: 'No vuelvas a preguntar',
            cancel: 'Cancelar',
            editBalloon: 'Editar globo',
            instructions: 'Instrucciones',
            saveRepliesDescription: 'El "identificador" se utiliza para crear saltos y grabarse en lugares como conversaciones, integraciones, etc. Úselo para guardar respuestas importantes de sus clientes.',
            doNotUse: 'No use "espacios", use "_" (guión bajo), no use acentos, si tiene alguna pregunta al respecto, comuníquese con nosotros.',
            identifierName: 'Nombre del identificador',
            replacementSystem: 'Si desea utilizar el sistema de reemplazo, simplemente coloque el identificador dentro de "%", por ejemplo: %MY_IDENTIFIER%',
            useButtons: 'Use tantos botones como sea necesario, pero recuerde, si el número es mayor que 6, se convertirá en una lista desplegable.',
            changePosition: 'Si desea cambiar los botones de posición, haga clic en el icono',
            andDrag: 'y arrastre.',
            sameValue: 'La clave y el valor siempre deben tener los mismos valores. No olvide elegir una etiqueta de salto.',
            key: 'Llave',
            value: 'Valor',
            add: 'Agregar',
            specificDepartment: 'Departamento: si tiene un departamento específico, selecciónelo, de lo contrario déjelo como "Principal".',
            messageErrorDescription: 'Mensaje de error: mensaje si la IA no puede responder.',
            jumpErrorDescription: 'Salto de error: salta a algún identificador si la IA no puede responder.',
            departments: 'Departamentos',
            messageError: 'Mensaje de error',
            jumpError: 'Salto de error',
            knowledgeBaseDescription: 'Base de conocimientos: la búsqueda de IA solo se realizará en la base de conocimientos seleccionada.',
            jumpDescription: 'Saltar: si la IA no tiene una respuesta para la pregunta, saltará al identificador seleccionado o repetirá la pregunta si no se selecciona ningún identificador.',
            knowledgeBase: 'Base de conocimientos',
            jumpWithoutAiResponse: 'Saltar (sin respuesta de IA)',
            machineLearning: 'Aprendizaje automático',
            importantDescription: 'Importante: esta función solo funciona en Webchat.',
            machineLearningDescription: 'Aprendizaje automático: La IA aprende de sus clientes, aportando las respuestas (en forma de botones) más utilizadas para facilitar sus dudas.',
            learningTimeDescription: 'Tiempo de aprendizaje: el tiempo (en días) que la IA puede usar para aprender.',
            buttonLimitDescription: 'Límite de botones: la IA traerá las respuestas si te ciñes al número máximo de botones.',
            learningTime: 'Tiempo de aprendizaje (días)',
            twoDays: 'Dos dias',
            threeDays: 'Tres dias',
            oneWeek: 'Una semana',
            twoWeek: 'Dos semanas',
            oneMonth: 'Un mes',
            oneQuarter: 'Un cuarto',
            oneSemester: 'Un semestre',
            buttonLimit: 'Límite de botones',
            three: 'Tres',
            four: 'Cuatro',
            five: 'Cinco',
            six: 'Seis',
            auxiliaryBases: 'Bases auxiliares',
            searchAuxiliaryBases: 'Si la IA no tiene una respuesta utilizando la base de conocimiento elegida, buscará las bases auxiliares.',
            optionToRefuseDisambiguation: 'Opción de rechazar la desambiguación',
            alwaysAllow: 'La IA siempre permitirá al cliente elegir la opción de rechazar las respuestas dadas por la IA.',
            onlyResponseUnambiguous: 'Recuerde, esta opción es solo para respuestas inequívocas.',
            fieldLabel: 'El campo Etiqueta aparecerá junto al número seleccionado, por ejemplo, "25m²".',
            valueMaxAndMin: 'El valor mínimo y el valor máximo definen cuánto puede elegir su cliente.',
            selectionInitialValue: 'Elija también un valor inicial, que puede facilitarle las cosas a su cliente.',
            stepDescription: 'El pase es el número de saltos, por ejemplo si el pase es 5 el cliente puede elegir 25, 30, 35 y así sucesivamente.',
            label: 'Label',
            minValue: 'Valor mínimo',
            maxValue: 'Valor máximo',
            initialValue: 'Valor inicial',
            step: 'Paso',
            searchLibrary: 'Buscar en la biblioteca',
            insertLink: 'Coloque el enlace a la página que desea guiar a su cliente.',
            restartReturnJump: 'El rebote reiniciará el chat en el identificador seleccionado.',
            pageLink: 'Link de página',
            returnJump: 'Salto de regreso',
            useItems: 'Use tantos elementos como necesite, pero recuerde, si el número es mayor que 6, se convertirá en una lista desplegable.',
            changingThePositionButtons: 'Si desea cambiar los botones de posición, haga clic en el icono',
            googleAnalytics: 'Google Analytics',
            defineTheCategory: 'Defina la categoría, acción, etiqueta y valor del evento.',
            requiredFields: 'No olvide que debe completar toda la información.',
            category: 'Categoria',
            action: 'Acción',
            tag: 'Etiqueta',
            noInteraction: 'Sin interacción',
            customerResponse: 'Respuesta del cliente',
            manual: 'Manual',
            none: 'Ninguno',
            bornDate: 'Fecha de nacimiento',
            maskMoney: 'Máscara de dinero',
            primary: 'Primario',
            all: 'Todas',
            clickHere: 'Haga clic aquí',
            select: 'Escoger',
            itemOne: 'Artículo uno',
            noBodyOnline: 'Desafortunadamente, no tenemos a nadie en línea en este momento.',
            editFlowLite: 'Editor de flujo lite'
          },
          list: {
            viewer: 'Espectador',
            flowStructure: '¡Visualiza la estructura de tu flujo!',
            editorPro: 'Explore su creatividad en el editor para profesionales, con muchas funciones interactivas y nuevas configuraciones.',
            editor: 'Editor',
            editFlow: 'Edite la transmisión directamente en el código. Para aquellos que tienen conocimientos de código HTML y JavaScript.',
            flows: 'Flujos'
          },
          viewer: {
            flow: 'Fluxo',
            toView: 'Para ver',
            test: 'Prueba',
            indicatesTheBalloonLine: 'Este número indica la línea en la que se encuentra el globo.',
            responseField: 'Campo de respuesta',
            emptyQuestion: 'Pregunta vacía',
            clickGoToLine: 'Haciendo clic puede ir a la línea.',
            type: 'Escribe',
            typeDescription: 'Indicador del nombre del',
            theBalloon: 'del globo.',
            identifier: 'Identificador',
            identifierDescription: 'Esta respuesta aparecerá en la pestaña de conversaciones.',
            knowledgeBase: 'Base de conocimientos',
            jumpCaseNotFound: 'Saltar si no se encuentra',
            jumpCaseNotFoundDescription: 'Si no hay ninguna pregunta registrada, se saltará la línea.',
            department: 'Departamento',
            noBodyOnline: 'Salta si no hay nadie en línea',
            noBodyOnlineDescription: 'Si no hay un asistente en línea, saltará a la línea',
            jumpingToLine: 'Saltando a la línea',
            botWillGoToLine: 'El bot después de pasar por este globo, irá a la línea.',
            clickInNumberGoToLine: 'Haga clic en el número para ir a la línea.',
            externalCode: 'Código externo',
            uniqueExternalCode: 'Código externo único',
            whatsappExternalCode: 'Código externo de Whatsapp',
            facebookExternalCode: 'Código externo Facebook',
            glpiIntegration: 'Integración con GLPI',
            sendingLeadEmail: 'Envío de correo electrónico de clientes potenciales',
            appearanceTime: 'Tiempo de aparición',
            previewAppearanceTime: 'Muestra el tiempo de aparición del globo, el valor predeterminado es 3000 milisegundos (3 segundos)',
            finishingBot: 'Terminación de bot',
            finishingBotDescription: 'Cuando el bot llegue a esta línea, terminará.',
            dontResponse: 'No responder',
            dontResponseDescription: 'Aquí viene una opción para que el usuario no responda, al hacer clic en ella, el bot pasa al siguiente globo.',
            comment: 'Comentario',
            commentDescription: 'Comentario realizado por un editor de esta corriente.',
            secureMessage: 'Mensaje seguro',
            secureMessageDescription: 'Esta respuesta no se guardará en Conversaciones en la plataforma Globalbot.',
            primary: 'Primario',
            none: 'Ninguno',
            name: 'Nombre',
            faq: 'FAQ',
            buttons: 'Botones',
            list: 'Lista',
            carousel: 'Carrusel',
            text: 'Texto',
            email: 'E-mail',
            phone: 'Teléfono',
            liveChat: 'Chat en vivo',
            guideScreen: 'Pantalla guiada',
            cpf: 'CPF',
            zipCode: 'Código postal',
            cnpj: 'CNPJ',
            finish: 'Terminar',
            uploadImage: 'Carga de imagen',
            checkbox: 'Checkbox',
            rating: 'Estrellas',
            number: 'Número',
            firstName: 'Primer nombre',
            lastName: 'Apellido',
            audio: 'Audio',
            message: 'Mensaje',
            calendar: 'Calendario',
            bornDate: 'Fecha de nacimiento',
            slider: ' Slider',
            image: 'Imagen',
            facebookVideo: 'Vídeo de Facebook',
            video: 'Video',
            city: 'Ciudad',
            state: 'Estado',
            country: 'País',
            maskMoney: 'Máscara de dinero',
            noBaseAvailable: 'No hay base disponible',
            noBaseAssigned: 'Sin base asignada',
            dataBaseNotFoundOption: 'Lamentablemente no tenemos esta opción, elija una opción válida.',
            title: 'Título',
            description: 'Descripción',
            select: 'Seleccione',
            messageLiveError: 'Desafortunadamente, no tenemos a nadie en línea en este momento.',
            noBaseDefined: 'Sin base definida',
            messageErrorSaveTheInformation: 'Vaya, se produjo un error al guardar la información.',
            flowSaved: 'Se guardó lo flujo',
            flowEditor: 'Editor de flujo'
          }
        },
        help: {
          help: {
            help: 'Ajuda',
            whatIsYourDoubt: '¿Cuál es su duda?',
            needHelp: '¿Necesita ayuda?',
            tutorials: 'Tutoriales',
            messageErrorTestingIntelligence: 'Inteligencia de prueba de errores'
          }
        },
        kanban: {
          kanban: {
            making: 'Haciendo',
            done: 'Hecho',
            createItem: 'Crear artículo',
            title: 'Título',
            message: 'Mensaje',
            cancel: 'Cancelar',
            add: 'Agregar',
            newColumn: 'Nueva columna'
          }
        },
        knowledge: {
          genai: {
            card_title: "IA Generativa",
            card_description: "Respuestas creadas a partir de un gran conjunto de datos (modelo GPT).\nInterpreta la intención del usuario y genera respuestas (texto y enlaces).",
            column_name: "Nombre",
            column_autor: "Autor",
            column_updatedAt: "Última actualización",
            column_action: "Acciones",
            drawer_test: {
              title: "Prueba de IA Generativa",
              name: 'Nombre',
              text_motive: "Utilice el chat al lado para probar las respuestas de la IA Generativa.<br>Las respuestas se generan automáticamente en función de los archivos y documentos proporcionados.",
              note: "NOTA",
              text_note: "Recuerde que la IA Generativa formará parte de algún chatbot, por lo que algunas funciones de este chat de prueba son limitadas (por ejemplo, no hay transferencia a agentes humanos).",
              restart: "Reiniciar"
            },
            drawer_data: {
              create: "Nueva Base de Conocimientos",
              edit: "Editar Base de Conocimientos",
              prompt: 'Pautas de Contexto (Prompt)',
              promptDescription: "Defina las pautas a continuación para dirigir las respuestas de IA generativa.",
              docs: "Conocimiento",
              docsDescriptions: "Enviar los archivos y/o informar los sitios web que serán utilizados por Generative AI (PDF, MP3, MP4, MPEG)",
              informSite: 'Informar al Sitio Eeb',
              informSiteDescription: 'Introduzca la dirección de un sitio web.',
              url: 'Dirección URL *',
              recursiveSearch: "Búsqueda recursiva: una vez activada esta opción se incluirán todos los enlaces relacionados con el dominio ingresado.",
              cancel: 'Cancelar',
              importDoc: "Importar Archivos",
              clickOrDrag: "Haga clic o arrastre archivos a esta área.",
              rules: "Reglas Adicionales",
              updateFileError: 'Error archivo:',
              triggers: "Desencadenantes de Escape",
              triggersDescription: "Utilice disparadores para llevar al usuario a algún punto del flujo del chatbot.",
              name: 'Nombre *',
              requiredFieldsError: 'Complete todos los campos obligatorios.',
              save: 'Salvar',
              createSuccess: 'Asistente creado exitosamente.',
              updateSuccess: 'Asistente actualizado exitosamente.',
              deleteDocSuccess: 'Archivo eliminado exitosamente.',
              urlError: 'Error URL:',
              triggerError: 'Error disparador:',
              fileActive: 'Contenido listo y disponible',
              fileProcessing: 'Contenido en proceso',
              fileError: 'Procesamiento de errores. Elimina el elemento y vuelve a intentarlo.',
              triggerError: 'Erro al actualizar gatilho:',
              triggersPromptDescription: 'Defina las pautas a continuación para indicarle a la IA generativa que realice la acción desencadenante.',
              flowJump: 'Acción: Vaya a la siguiente línea de flujo:',
              deleteTrigger: 'Eliminar Disparador',
              deletedTrigger: "Disparador eliminado.",
              errorDeleteThisTrigger: "Error al eliminar el disparador.",
              areYouSureDeleteTrigger: "¿Está seguro de que desea eliminar este disparador? Todos los datos que contenga se perderán y esta acción no se podrá deshacer.",
              deleteDoc: 'Eliminar Archivo',
              deletedDoc: "Archivo eliminado.",
              errorDeleteThisDoc: "Error al eliminar el archivo.",
              areYouSureDeleteDoc: "¿Está seguro de que desea eliminar este archivo? Todos los datos que contenga se perderán y esta acción no se podrá deshacer.",
              delete: 'Eliminar',
              errorTreatment: 'Manejo de Errores',
              errorTreatmentDescription: 'Definir el comportamiento del chatbot cuando no hay respuesta.',
              noResponse: 'Si no te responde:',
              errorFlowJump: "Ir a la línea de flujo",
              intervention: "Intervención programada",
              interventionDescription: "Definir una intervención programada en función del número de respuestas.",
              from: "Desde:",
              subsequentTimes: 'tiempos posteriores',
              responsesRow: 'respuestas seguidas'
            },
            genaiNotCreated: "Aún no tienes asistentes",
            editGenai: "Editar asistente",
            testGenai: "Probar asistente",
            add: "Crear Asistente",
            exclude: "Eliminar Asistente",
            deletedAssistant: "Asistente eliminado.",
            errorDeleteThisAssistant: "Error al eliminar el asistente.",
            areYouSureDelete: "¿Está seguro de que desea eliminar este asistente? Todos sus datos se perderán y esta acción no se puede deshacer.",
            cancel: "Cancelar"
          },
          knowledge: {
            card_title: "IA Convencional",
            card_description: "Respuestas estandarizadas. Interpreta la intención del usuario y presenta respuestas registradas (texto, video, imágenes, documentos y enlaces).",
            deleteQuestionSelected: 'Eliminar respuestas seleccionadas',
            rowsPerPage: 'Registros por página',
            conversations: 'Conversaciones',
            add: 'Agregar',
            knowledgeBase: 'Base de conocimientos',
            exclude: 'Borrar',
            askQuestion: 'Hacer una pregunta (prueba de intención)',
            importQuestions: 'Importar preguntas',
            columns: 'Columnas',
            status: 'Status',
            questionKnowledgeBase: 'Pregunta / Base de conocimientos',
            response: 'Respuesta',
            intentionsIa: 'Intenciones (inteligencia artificial)',
            actions: 'Comportamiento',
            edit: 'Para editar',
            save: 'Salvar',
            cancel: 'Cancelar',
            deleteQuestions: 'Borrar Preguntas',
            newQuestion: 'Nueva pregunta',
            questionTitle: 'Título de la pregunta',
            createNewKnowledgeBase: 'Cree una nueva base de conocimientos cuando necesite separar el conocimiento para diferentes audiencias o contextos, ya que la inteligencia se comparte con todos los chatbots de su cuenta.',
            textEditor: 'Editor de texto',
            addRandomResponse: 'Agregar respuesta aleatoria',
            responseNotFound: 'Respuesta no encontrada ir a la posición de flujo',
            jumpToFlowPosition: 'Saltar a la posición de flujo',
            search: 'Buscar',
            noImagesFound: 'No se encontraron imágenes',
            urlPage: 'URL de la página',
            continueToFlowPosition: 'Continuar en posición de flujo',
            randomizeResponse: 'Respuesta aleatoria',
            moveUp: 'Ascender',
            document: 'Documento',
            video: 'Vídeo',
            text: 'Texto',
            liveChat: 'Chat en vivo',
            intelligence: 'Inteligencia',
            image: 'Imagen',
            carousel: 'Carrusel',
            guideScreen: 'Pantalla guiada',
            jump: 'Salto',
            moveDown: 'Mover hacia abajo',
            continueFlow: 'Después de presentar las respuestas anteriores, el chatbot seguirá el flujo de acuerdo con la secuencia definida.',
            intentions: 'Intenciones',
            registerIntentions: 'Registre las intenciones considerando las principales palabras que pueden desencadenar el registro de la respuesta. Dé mayor peso a las palabras que deben evitar la desambiguación.',
            newIntention: 'Ingrese una nueva intención aquí y presione "Enter"',
            intention: 'Intención',
            weight: 'Peso',
            minWeight: 'Peso mínimo',
            optional: 'Opcional',
            optionalDescription: 'Úselo si desea definir el peso mínimo que se agregará por las intenciones para mostrar la respuesta.',
            addKnowledgeBase: 'Agregar base de conocimientos',
            title: 'Título',
            excludeQuestion: 'Eliminar pregunta',
            youSureExclude: '¿Está seguro de que desea eliminar esta pregunta?',
            justCopy: 'Para importar preguntas, simplemente copie',
            thisModel: 'este modelo',
            standard: 'patrón',
            exportToCsv: 'y expórtalo en CSV con tus preguntas que quieras registrar.',
            chooseFileAndImport: 'Después de eso, haga clic en Elegir archivo a continuación para importar.',
            none: 'Ninguno',
            question: 'Pregunta',
            all: 'Todos',
            messageErrorImportList: 'Error al importar la lista de bancos',
            youAlreadyHaveBase: 'Ya tienes una base con este título.',
            requiredTitle: 'El título no puede dejarse en blanco.',
            duplicateIntention: 'Ya hay igual intención, dale más peso.',
            mustHaveMoreThanOneLetter: 'La intención debe tener más de 1 letra.',
            cannotHaveMoreThanTwoWords: 'La intención no puede tener más de 2 palabras.',
            questionSave: 'Pregunta guardada',
            messageErrorNewQuestion: 'Error al guardar la nueva pregunta',
            allFieldsRequired: 'Es necesario completar todos los campos.',
            questionSuccessfullyDeleted: 'Pregunta eliminada correctamente.',
            messageErrorExcludeQuestion: 'Error al eliminar la pregunta.',
            messageErrorTestIntelligence: 'Inteligencia de prueba de errores',
            messageErrorFilter: 'Error al filtrar la inteligencia',
            knowledgeBaseExcluded: 'Base de conocimientos eliminada.',
            itWasNotPossibleExclude: 'No fue posible eliminar esta base de conocimientos.'
          }
        },
        library: {
          library: {
            limitExceeded: 'Se superó el límite de 200 MB',
            addFile: 'Agregar media',
            addMedia: 'Agregar media',
            libraryDescription: 'Aquí, en la biblioteca de medios, puede agregar medios al chat en vivo y a los carruseles de flujo.',
            sizeLimitIs: 'El límite de tamaño es',
            video: 'Video',
            videos: 'Videos',
            photos: 'Fotos',
            search: 'Buscar',
            list: 'Lista',
            gallery: 'Galería',
            view: 'Para ver',
            name: 'Nombre',
            sent: 'Enviado',
            actions: 'Comportamiento',
            of: 'en',
            documents: 'Documentos',
            document: 'Documento',
            exclude: 'Borrar',
            newFile: 'Archivo nuevo',
            typeFile: 'Tipo de archivo',
            image: 'Imagen',
            title: 'Título',
            description: 'Descripción',
            cancel: 'Cancelar',
            save: 'Guardar',
            areYouSure: '¿Está seguro de que desea eliminarlo o archivarlo?',
            clickExclude: 'Al hacer clic en "Eliminar", el archivo ya no se puede recuperar.',
            date: 'Fecha',
            size: 'Tamaño',
            library: 'Biblioteca',
            requiredTitle: 'Es necesario completar el campo de título.'
          }
        },
        live: {
          live2: {
            userLeaveChat: 'El usuario abandonó el chat',
            userStayOnManualQueue: 'El usuario está en cola manual',
            userStayOnAutoQueue: 'El usuario está en cola automática',
            userStayWaiting: 'El usuario está en espera',
            chatIsFinished: 'La conversación ha terminado',
            chatWaitHsm: 'La conversación está en pausa y esperando la interacción del usuario',
            errorOnTransferRoom: 'Identificamos un error al transferir esta conversación. En este caso, tome una captura de pantalla de esta pantalla y envíela a nuestro soporte (suporte@globalbot.com.br). Intente repetir la transferencia nuevamente.',
            titleErrorOnLive: 'No se puede transferir la conversación',
            titleRuleOnLive: 'No se puede realizar esta acción',
            ruleTransferRoomUnauthorizedUser: 'No estás autorizado a transferir esta habitación.',
            ruleTransferRoomInvalidStatus: 'No es posible transferir esta sala ya que su estado no es válido, si persiste solicite a un gerente que realice esta acción',
            liveChatUpperCase: 'Chat en Vivo',
            inbox: 'Bandeja de entrada del asistente',
            waiting: 'Cola de asistente',
            newConversation: 'Nueva conversación',
            filter: 'Filtro',
            attendants: 'Acomodadores',
            settings: 'Ajustes',
            filterByAttendant: 'Filtro de asistente',
            all: 'Todos',
            searchCustomerName: 'Obtener el nombre del cliente',
            filterByDepartment: 'Filtro de departamento',
            internalToUpperCase: 'INTERNO',
            finishedToUpperCase: 'FINALIZADO',
            inQueueToUpperCase: 'EN FILA',
            waitingToUpperCase: 'ESPERANDO',
            newToUpperCase: 'NUEVO',
            transferToUpperCase: 'TRANSFERIDO',
            yesterday: 'El dia de ayer',
            finishAttendance: 'Asistencia(s) final',
            finishAndArchiveAttendance: "Finalizar y Archivar",
            finishSuccess: 'Servicio(s) completado(s) exitosamente',
            processingAction: 'Procesando... Espere hasta finalizar.',
            noAttendance: 'No hay asistencia disponible.',
            important: 'Importante',
            completionMessage: 'Elija una de las opciones, considerando que "Solo Finalizar" finaliza, pero aún verá la sala de chat en esta pantalla, mientras que "Finalizar y Archivar" finalizará y archivará la conversación. Podrá consultarlo más tarde en el Menú de Conversaciones.',
            triggerAgain: 'Su próxima interacción activará el chatbot nuevamente, reabriendo una nueva asistencia.',
            areYouSureFinish: '¿Seguro que quieres terminar?',
            cancel: 'Cancelar',
            finish: 'Solo Finalizar',
            archiveAttendance: 'Asistencia(s) de archivo',
            archiveSuccess: 'Atención(s) arquivado(s) con éxito',
            futureConversation: 'When archiving calls, active conversations will automatically end and close. The next interaction of the user(s) will trigger the chatbot again, reopening a new service. You will be able to consult conversations in the future, in the Conversations Menu. Are you sure you want to archive?',
            areYouSureArchive: '¿Está seguro de que desea archivar?',
            archive: 'Archivar',
            transferAttendance: 'Asistencia(s) de transferencia',
            transferSuccess: 'Atención(s) transferida(s) con éxito',
            transferDescription: 'Transferir la asistencia a otro operador (ellos recibirán la asistencia) o departamento (su asistencia se distribuirá según la configuración establecida).',
            areYouSureTransfer: '¿Estás seguro de que deseas realizar la transferencia?',
            byAttendant: 'Por asistente',
            byDepartment: 'Por departamento',
            transfer: 'Transferir',
            withCustomer: 'con el cliente',
            viaWhatsapp: '(a través de WhatsApp)',
            startConversationWithCustomer: 'Iniciar una conversación con un cliente',
            sendingMessage: 'enviando un mensaje a través de WhatsApp.',
            select: 'Seleccione',
            withAttendant: 'con Asistente',
            viaGlobalbot: '(a través de Globalbot)',
            startConversationWithSomeone: 'Inicie una conversación con alguien de',
            teamSending: 'tu equipo de trabajo enviando un',
            messageThroughTheGlobalbot: 'mensaje de la plataforma Globalbot.',
            newAttendance: 'Nueva asistencia',
            waitingForYou: '¡te está esperando!',
            see: 'Ver',
            close: 'Fechar',
            waitingAttendant: 'está esperando un asistente.',
            take: 'Asumir',
            newTransfer: 'Nueva transferencia',
            transferredAndWaitingForYou: 'ha sido transferido y te está esperando!',
            transferredDepartmentAndWaitingForYou: 'ha sido transferido a su departamento está esperando un asistente.',
            anotherAttendant: 'Otro asistente ya se ha hecho cargo de esta asistencia.'
          },
          room: {
            transferAttendance: 'Transferir la asistencia',
            finishAttendance: 'Terminar la asistencia',
            noAttendance: 'No hay asistencia disponible.',
            archive: 'Archivar',
            group: 'grupo',
            attendance: 'asistencia',
            theaterMode: 'Modo teatro',
            defaultView: 'Vista predeterminada',
            noOneToAnswer: 'Nadie con quien reunirse en este momento. ¿Qué tal un café?',
            chooseOneToAnswer: '¡Selecciona un servicio y vamos!',
            loadMoreMessages: 'Cargar más mensajes',
            isTyping: '¡El usuario todavía está escribiendo!',
            conversation: 'Hablar',
            intelligence: 'Inteligencia',
            navigation: 'Navegación',
            data: 'Datos',
            abandonedTheChat: 'El usuario abandonó el chat.',
            createdIn: 'Creado en',
            attendants: 'Acomodadores',
            exit: 'Salir',
            quickResponse: 'Respuesta rápida',
            internalMessage: 'Mensaje interno',
            send: 'Mandar',
            takeAttendance: 'ASUMIR ASISTENCIA',
            important: 'Importante',
            sendTemplateHsm: 'Para hablar con el usuario, envíe una plantilla de mensaje (HSM)',
            onlyAttendants: 'Solo los asistentes de esta conversación pueden enviar mensajes.',
            automaticArchiving: 'Después de 5 días, la conversación se archivará automáticamente.',
            preview: 'Avance',
            addLegend: 'Adicionar legenda',
            cancel: 'Cancelar',
            search: 'Buscar',
            notFound: 'No se encontró ningún emoji',
            found: 'Fundar',
            recent: 'Usado frecuentemente',
            people: 'Emojis y Personas',
            nature: 'Animales y naturaleza',
            foods: 'Comida y Bebida',
            activity: 'Actividad',
            places: 'Viajes y lugares',
            objects: 'Objetos',
            symbols: 'Simbolos',
            flags: 'Banderas',
            others: 'Otros',
            fullFunctioning: 'Para el pleno funcionamiento del chat en vivo es necesario permitir el acceso al portapapeles.',
            anotherAttendant: 'Otro asistente ya se ha hecho cargo de esta asistencia.'
          }
        },
        login: {
          forgot: {
            enterYourEmail: 'Ingrese su correo electrónico y le enviaremos una contraseña temporal',
            buttonRecover: 'Recuperar mi contraseña',
            goBack: 'Vuelve',
            emptyEmail: 'Necesitas completar el correo electrónico',
            email: 'Correo electrónico',
            pleaseEmptyEmail: 'Por favor complete su correo electrónico',
            passwordSent: 'Su nueva contraseña ha sido enviada a su correo electrónico.',
            contact: 'Vaya, comuníquese con el soporte de Globalbot. (support@globalbot.com.br)',
            iForgotMyPassword: 'Olvide mi contraseña'
          },
          login: {
            selectAccount: 'Seleccione la cuenta deseada para acceder',
            cancel: 'Cancelar',
            forgotPassword: 'Olvide mi contraseña',
            verificationCode: 'Código de verificación',
            enter: 'Entrar',
            validate: 'Validar',
            emptyEmail: 'Necesitas completar el correo electrónico',
            labelEmail: 'Correo electrónico',
            fillAllData: 'Por favor rellena todos los campos',
            emptyPassword: 'Necesitas completar la contraseña',
            labelPassword: 'Contraseña',
            userInactive: 'Este usuario está inactivo, comuníquese con su gerente.',
            outOfYourJourney: 'Está fuera de su jornada laboral, comuníquese con su gerente.',
            incorrectUsernameOrPassword: 'Nombre de usuario o contraseña incorrecta.',
            doubleFactor: 'Esta cuenta está protegida con autenticación de dos factores, ingrese el código de seis dígitos enviado a su teléfono celular.',
            doubleFactorNeedNumber: 'Esta cuenta está protegida con autenticación de dos factores y su usuario no tiene un número de teléfono móvil registrado, comuníquese con su gerente.',
            unexpectedError: 'Error inesperado.',
            accountNotCorrect: 'Su cuenta no parece correcta, comuníquese con Globalbot',
            notifiedHere: 'Ahora, siempre que suceda algo, se le notificará aquí.',
            notificationActivated: 'Notificación activada',
            rememberMyPassword: 'Mantenme conectado'
          }
        },
        notification: {
          control: {
            addNotification: 'Agregar notificación',
            dateAndTime: 'Fecha y hora',
            type: 'Escribe',
            title: 'Título',
            actions: 'Comportamiento',
            user: 'Usuario',
            tag: 'Tag',
            department: 'Departamento',
            role: 'Oficina',
            account: 'Cuenta',
            group: 'Grupo',
            all: 'Todos',
            edit: 'Editar',
            linkUsers: 'Vincular usuarios',
            exclude: 'Borrar',
            recordsPerPage: 'Registros por página',
            save: 'Guardar',
            cancel: 'Cancelar',
            newNotification: 'Nueva notificación',
            showAvatar: 'Mostrar avatar',
            customizableLabel: 'Etiqueta personalizable',
            label: 'Etiqueta',
            message: 'Mensaje',
            notificationType: 'Tipo de notificación',
            attachments: 'Archivos adjuntos',
            loadImage: 'Cargar imagen',
            notificationControl: 'Control de notificaciones',
            errorUpload: 'Error al procesar la carga.',
            notificationSent: 'Notificación enviada',
            errorSent: 'Error al enviar notificación'
          }
        },
        people: {
          people: {
            people: 'Gente',
            name: 'Nombre',
            anonymous: 'Anónimo'
          },
          timeline: {
            people: 'Gente',
            fullName: 'Nombre completo',
            name: 'Nombre',
            nickname: 'Apellido',
            occupation: 'Ocupación',
            cpf: 'CPF',
            anonymous: 'Anónimo',
            company: 'Empresa',
            cnpj: 'CNPJ',
            country: 'País',
            city: 'Ciudad',
            state: 'Estado',
            district: 'Vecindario',
            conversationStarted: 'Conversación iniciada',
            of: 'de',
            at: 'a las',
            hoursAnd: 'horas y',
            minutes: 'minutos'
          }
        },
        profile: {
          profile: {
            profile: 'Perfil',
            changePhoto: 'Cambiar foto',
            hello: 'Hola',
            personalInformation: 'Informaciones personales',
            name: 'Nombre',
            email: 'Correo electrónico',
            role: 'Papel',
            phone: 'Teléfono',
            security: 'Seguridad',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmación de nueva contraseña',
            connectedDevices: 'Dispositivos conectados',
            deleteDevice: 'Eliminar dispositivo',
            save: 'Guardar',
            administrator: 'Administrador',
            manager: 'Gerente',
            attendant: 'Empleado',
            primary: 'Primario',
            passwordIsNotIdentical: 'Vaya, su contraseña no es idéntica, revísela.',
            informationSaved: '¡Información guardada!',
            errorSavingInformation: 'Vaya, se produjo un error al guardar la información.'
          }
        },
        realTime: {
          realTime: {
            preferences: 'Preferencias',
            realTime: 'Tiempo real',
            browsing: 'Hojeada',
            withChatbots: 'Con chatbots',
            withAttendants: 'Con asistentes',
            alreadyTalked: 'Ya hablé',
            attendedBy: 'Atendido por',
            currentPageContains: 'La página actual contiene',
            columns: 'Columnas',
            channel: 'Canal',
            dateAndTime: 'Fecha y hora',
            visitor: 'Visitante',
            chatbot: 'Chatbot',
            location: 'Localización',
            pages: 'Paginas',
            currentPage: 'Pagina actual',
            time: 'Tiempo',
            conversation: 'Conversacion',
            actions: 'Comportamiento',
            unknown: 'Desconocido',
            seeOnMap: 'Ver en el mapa',
            seeNavigation: 'Ver navegación',
            inAttendance: 'En asistencia',
            waitingForAttendant: 'Esperando asistente',
            finishAttendance: 'Finished attendance',
            noAttendance: 'No hay asistencia disponible.',
            activeVisitor: 'Visitante activo',
            inactiveVisitor: 'Visitante inactivo',
            startedAt: 'Empezó a las',
            allConversations: 'Todas las conversaciones',
            of: 'en',
            initialMessage: 'Mensaje inicial',
            message: 'Mensaje',
            cancel: 'Cancelar',
            intervene: 'Intervenir',
            navigation: 'Navegación',
            openPage: 'Abre la pagina',
            close: 'Cerrar',
            openConversation: 'Conversación abierta',
            all: 'Todos',
            chatbots: 'Chatbots',
            path: 'Sendero',
            conversations: 'Conversaciones'
          }
        },
        settings: {
          chatbot: {
            behavior: {
              chatbots: 'Chatbots',
              preferences: 'Preferencias',
              behavior: 'Comportamiento',
              timers: 'Temporizadores',
              balloonAppearanceTime: 'Tiempo de aparición del globo',
              chatbotShutdownTime: 'Tiempo de apagado del chatbot',
              interactionTime: 'Tiempo de interacción (...)',
              teenMinutes: '10 minutos',
              threeMinutes: '3 minutos',
              fiveMinutes: '5 minutos',
              fifteenMinutes: '15 minutos',
              thirtyMinutes: '30 minutos',
              oneHour: '1 hora',
              twoHours: '2 horas',
              threeHours: '3 horas',
              fiveHours: '5 horas',
              teenHours: '10 horas',
              twelveHours: '12 horas',
              twentyHours: '20 horas',
              fifteenHours: '15 horas',
              twentyFourHours: '24 horas',
              whatsappCacheTime: 'Tiempo de caché de WhatsApp',
              firstTime: 'Primeiro tempo',
              secondTime: 'Segundo tempo',
              firstInteraction: 'Primera interacción *',
              secondInteraction: 'Segunda interacción',
              desiredAction: {
                title: 'Acción deseada',
                actionEnd: 'Finalizar',
                actionTranshipment: 'Transbordar',
                messageTranshipment: 'Mensaje antes del transbordo',
                departmentTranshipment: 'Transferir departamento',
                tag: 'Etiquetar con'
              },
              facebookCacheTime: 'Tiempo de caché de Facebook',
              webchatCacheTime: 'Tiempo de caché de chat web',
              controllers: 'Controladores',
              soundType: 'Tipo de sonido',
              fullScreenMode: 'Modo de pantalla completa',
              disableControls: 'Deshabilitar controles',
              disableHeader: 'Deshabilitar encabezado',
              automaticOpening: 'Apertura automatica',
              notReopen: 'No vuelva a abrir el globo después de la asistencia.',
              satisfactionSurvey: 'Búsqueda de satisfacción',
              protocolAttendance: 'Asistencia de protocolo',
              includeInternalMessages: 'Incluir mensajes internos',
              optionFinishOrMin: 'Opción de terminar o minimizar.',
              imageUploader: 'Cargador de imágenes (cliente)',
              fileUploader: 'Cargador de archivos (cliente)',
              allConversations: 'Todas las conversaciones',
              conversationsNotTransshipment: 'Conversaciones de asistente y sin desbordamiento',
              onlyAttendantConversations: 'Solo conversaciones de asistente',
              viewingConversationsForAttendants: 'Ver conversaciones para asistentes',
              persistence: 'Persistencia',
              activated: 'Activado',
              disabled: 'Discapacitado',
              firstMessage: 'Primer mensaje',
              firstMessageTime: 'Hora del primer mensaje',
              secondMessage: 'Segundo mensaje',
              secondMessageTime: 'Hora del segundo mensaje',
              thirdMessage: 'Tercer mensaje',
              thirdMessageTime: 'Hora del tercer mensaje',
              save: 'Guardar',
              areYouSure: '¿Está seguro de que desea guardar los cambios?',
              youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
              previousContent: 'el contenido anterior no se puede restaurar.',
              cancel: 'Cancelar',
              interface: 'Interface',
              interfaceSettings: 'Configuración de la interface',
              behaviorSettings: 'Configuración del chatbot',
              installation: 'Instalación',
              integrations: 'Integraciones',
              liveChat: 'Chat en vivo',
              areYouStillAround: '¿Sigues por aquí?',
              ifNotResponse: 'Si no contesta tendré que terminar la asistencia.',
              ifNeedHelp: 'Muy bien, si necesitas ayuda nuevamente, avísame.',
              closeMessage: 'La asistencia fue cerrado por el asistente, agradecemos su contacto y si tiene más preguntas, simplemente llámeme nuevamente.',
              preferenceSaved: 'Preferencia guardada con éxito'
            },
            installation: {
              whatsapp: {
                installation: 'Instalación de Whatsapp',
                configuredWithChatbot: 'Tu WhatsApp está configurado con tu Chatbot.',
                notConfiguredWithChatbot: 'Tu WhatsApp no está configurado con tu Chatbot.',
                versionHint: 'Versión de Whatsapp a utilizar',
                version: 'Versión',
                instanceHint: 'ID de instancia proporcionado por su administrador de proyectos',
                instance: 'Ejemplo',
                tokenHint: 'Token de instancia proporcionado por su administrador de proyectos',
                token: 'Token',
                mobileNumberHint: 'Mobile number to be used',
                mobileNumber: 'Número de teléfono',
                customerIdentifierHint: 'Identificador de cliente proporcionado por Globalbot',
                customerIdentifier: 'Identificador de cliente',
                instructionsMobileNumbers: 'Instrucciones de número de móvil',
                stepOneMobileNumbers: 'Habilite el chip que contiene el número que desea conectar el chatbot',
                stepTwoMobileNumbers: 'Recibirá un SMS en este número que contiene un código numérico',
                stepThreeMobileNumbers: 'Informar el código recibido al equipo de Globalbot',
                stepFourMobileNumbers: 'Ingrese la instancia y el token provistos por Globalbot en los campos a continuación',
                instructionsFixedNumbers: 'Instrucciones de número de teléfono fijo',
                stepOneFixedNumbers: 'Recibirá una llamada en su número que contiene un código numérico',
                stepTwoFixedNumbers: 'Informar el código recibido al equipo de Globalbot',
                stepThreeFixedNumbers: 'Ingrese la instancia y el token provistos por Globalbot en los campos a continuación',
                areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
                youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
                previousContent: 'el contenido anterior no se puede restaurar.',
                cancel: 'Cancelar',
                save: 'Guardar',
                publish: 'Publicar',
                versioningMessage: 'Para acceder a versiones anteriores basta con acceder a la configuración específica del bot, ir a versionado y publicar la versión deseada para entrar en producción.',
                removeConfig: 'Eliminar configuración',
                reallyRemoveConfig: '¿Realmente quieres eliminar la configuración?'
              },
              webchat: {
                installation: 'Instalación (WebChat and Botão WhatsApp)',
                configuredWithChatbot: 'Tu script está configurado con tu website.',
                notConfiguredWithChatbot: 'Tu script no está configurado con tu website.',
                installationScript: 'Script de instalación',
                authorizedDomains: 'Dominios autorizados',
                domainNumber: 'Número de dominio',
                addDomain: 'Agregar dominio',
                instructions: 'Instrucciones de Instalación',
                stepOne: 'Copia el script de instalación a la derecha',
                stepTwo: 'Inserta el script en el código HTML de tu sitio web en las páginas donde deseas activar el chatbot (pega el script inmediatamente después del cierre de la etiqueta </BODY>).',
                stepThree: 'Después de pegar el script en las páginas deseadas, debes indicar a la derecha en qué dominios se agregó el chatbot.',
                stepFour: 'Debes indicar abajo en qué dominios se puede activar el chatbot.',
                externalBoot: 'Arranque externo',
                youCan: 'Puede realizar una inicialización externa a través del código JavaScript.',
                accessElement: 'Accede al elemento',
                andUse: 'Y usa las funciones disponibles para startups.',
                openBalloon: 'Abre el globo inicial',
                initialBalloon: 'Balón inicial con posición de flujo',
                openChat: 'Abre el chat',
                openChatWithFlowPosition: 'Abrir chat con posición de flujo',
                areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
                youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
                previousContent: 'el contenido anterior no se puede restaurar.',
                cancel: 'Cancelar',
                save: 'Guardar',
              },
              messenger: {
                installation: 'Instalación de Facebook Messenger',
                configuredWithChatbot: 'Tu Facebook está configurado con tu Chatbot.',
                reset: 'Reconfigurar',
                notConfiguredWithChatbot: 'Tu Facebook no está configurado con tu Chatbot.',
                noPagesAvailable: 'No hay páginas disponibles.',
                synchronize: 'Sincronizar',
                almostConfigured: 'No mucho después, su página de Facebook está casi configurada con su Chatbot.',
                yourPage: 'Tu pagina',
                instructions: 'Instrucciones',
                connectFacebook: 'Haga clic en el botón Sincronizar',
                followSteps: 'Sigue la conexión paso a paso',
                youMustBeAdmin: 'Debes ser administrador de FanPage para otorgar acceso',
                areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
                youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
                previousContent: 'el contenido anterior no se puede restaurar.',
                cancel: 'Cancelar',
                save: 'Guardar',
                disconnect: 'Desconectar',
                reallyDisconnectPage: '¿Realmente quieres desconectar esta página?'
              },
              telegram: {
                installation: 'Instalación de Telegramas',
                configuredWithChatbot: 'Tu Telegram está configurado con tu Chatbot.',
                notConfiguredWithChatbot: 'Tu Telegram no está configurado con tu Chatbot.',
                tokenProvidedBot: 'Token de acceso para la API proporcionada por Bot Father',
                token: 'Token',
                instructions: 'Instrucciones',
                talkToThe: 'Habla al',
                followStep: 'Sigue la guía paso a paso para crear tu bot',
                indicateBelow: 'Ingrese el token de acceso para la API a continuación',
                areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
                youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
                previousContent: 'el contenido anterior no se puede restaurar.',
                cancel: 'Cancelar',
                save: 'Guardar',
              },
              instagram: {
                installation: 'Instalación de Instagram',
                yourPage: 'Tu pagina',
                configuredWithChatbot: 'Tu Instagram está configurado con tu Chatbot.',
                reset: 'Reconfigurar',
                notConfiguredWithChatbot: 'Tu Instagram no está configurado con tu Chatbot.',
                motiveConfigure: 'La plataforma Globalbot solo puede administrar mensajes de cuentas de INSTAGRAM BUSINESS. No es posible conectarse y administrar mensajes en cuentas personales de Instagram.',
                important: 'Importante: ',
                synchronize: 'Sincronizar',
                instructions: 'Instrucciones',
                stepOne: 'AGREGAR 1er PASO',
                stepTwo: 'AGREGAR 2do PASO',
                stepThree: 'AGREGAR 3er PASO',
                areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
                youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
                previousContent: 'el contenido anterior no se puede restaurar.',
                cancel: 'Cancelar',
                save: 'Guardar',
              },
              chatbots: 'Chatbots',
              preferences: 'Preferencias',
              installation: 'Instalación',
              interface: 'Interface',
              interfaceSettings: 'Configuración de la interface',
              behavior: 'Conducta',
              chatbotSettings: 'Configuración del chatbot',
              integrations: 'Integraciones',
              liveChat: 'Chat en vivo',
              youHavePermission: 'Ups, parece que faltan permisos.',
              consultTheConsole: 'Ups, ocurrió algún error, mira la consola.',
              preferenceSaved: 'Preferencia guardada con éxito'
            },
            integrations: {
              chatbots: 'Chatbots',
              preferences: 'Preferencias',
              integrations: 'Integraciones',
              reset: 'Reiniciar',
              toSetUp: 'Configurar',
              descriptions: {
                googleAnalytics: 'Esta integración es responsable de analizar estadísticas del flujo.',
                pipeRun: 'Con esta integración, la plataforma Globalbot registrará (publicará) información de contactos (leads) en Piperun a través de campos compatibles.',
                slack: 'Con esta integración, la plataforma Globalbot enviará el historial de conversaciones a un canal específico. Se debe configurar un webhook para recibir el evento y enviar mensajes.',
                vistaSoft: 'Esta integración se encarga de buscar bienes raíces en alquiler/compra según las opciones seleccionadas por el usuario (número de habitaciones, tipo, vecindario) y registrar leads. Campos clave: teléfono y/o nombre y correo electrónico y "objetivo de contacto"/"interés".',
                rdStation: 'Con esta integración, la plataforma Globalbot registrará (publicará) información de contacto (leads) en RD Station Marketing a través de campos compatibles. Campos clave: correo electrónico.',
                pipeDrive: 'Con esta integración, la plataforma Globalbot registrará (publicará) información de contacto (leads) en PipeDrive a través de campos compatibles. Asigna a un asistente como responsable según la dirección de correo electrónico.',
                rdCrm: 'Con esta integración, la plataforma Globalbot enviará negociaciones al CRM. Campos clave: correo electrónico y/o teléfono y nombre.',
                ksi: 'Con esta integración, la plataforma Globalbot registrará (publicará) información de contacto (leads) en Kurole KSI a través de campos compatibles. Campos clave: correo electrónico, nombre y teléfono.',
                cv: 'Con esta integración, la plataforma Globalbot registrará (publicará) información de contacto (leads) en Sales Builder a través de campos compatibles. Campos clave: correo electrónico, nombre y teléfono.',
                movidesk: 'Con esta integración, la plataforma Globalbot abrirá tickets. Campos clave: correo electrónico.',
                glpi: 'Con esta integración, la plataforma Globalbot abrirá tickets. Campos clave: nombre.',
                jira: 'Con esta integración, la plataforma Globalbot creará tareas. Campos clave: correo electrónico.',
                zendesk: 'Con esta integración, la plataforma Globalbot abrirá tickets. Campos clave: correo electrónico.',
                midasSoft: 'Con esta integración, la plataforma Globalbot registrará (publicará) información de contacto (leads) en MidasSoft.',
                rdStationV2: 'Esta integración se encarga de recuperar información en RD Stations Marketing (solo para casos específicos).',
                moskit: 'Con esta integración, la plataforma Globalbot será responsable de crear negociaciones.',
                funilVendas: 'Con esta integración, la plataforma Globalbot será responsable de crear negociaciones. Campos clave: correo electrónico, nombre y teléfono.',
                amigo: 'Con esta integración, la plataforma Globalbot programará citas médicas.',
                agendor: 'Con esta integración, la plataforma Globalbot será responsable de crear negociaciones y tareas. Campos clave: teléfono.',
                emailLead: 'Con esta integración, la plataforma Globalbot será responsable de enviar transcripciones de conversaciones por correo electrónico.',
                shopify: 'Esta integración es responsable del seguimiento de pedidos. Campos clave: correo electrónico.'
              },
              aboutIntegration: 'Sobre la Integración'
            },
            interface: {
              chatbots: 'Chatbots',
              preferences: 'Preferencias',
              interface: 'Interface WebChat',
              chatWindow: 'Ventana de chat',
              botName: 'Nombre del bot',
              attendantName: 'Nombre del asistente',
              attendantRole: 'Puesto de asistente',
              avatarImage: 'Imagen de avatar',
              balloonImage: 'Imagen de globo',
              installationInstructions: 'Instrucciones de Instalación en el Sitio Web',
              installationInstructionsDescription: 'Instale el script de activación del WebChat en su sitio web.',
              logo: 'Logotipo',
              primaryColor: 'Color primario',
              chatHeight: 'Altura del chat',
              copyRight: 'Copyright (solo maestro)',
              disabled: 'Discapacitado',
              activated: 'Activado',
              startBalloon: 'Globo de inicio',
              message: 'Mensaje',
              position: 'Posición',
              left: 'Izquierda',
              right: 'Derecha',
              textColor: 'Color de texto',
              backgroundColor: 'Color de fondo',
              templates: 'Modelos',
              default: 'Patrón',
              amplified: 'Amplificado',
              ticket: 'Ticket',
              noImage: 'Sin imagen',
              noText: 'Sin texto',
              ghost: 'invisible',
              mobile: 'Móvil',
              save: 'Salvar',
              areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
              youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
              previousContent: 'el contenido anterior no se puede restaurar.',
              cancel: 'Cancelar',
              sampleQuestion: 'Pregunta de muestra',
              answerExample: 'Ejemplo de respuesta',
              typeYourName: 'Escriba su nombre',
              typeYourPhone: 'Digite seu telefone',
              virtualAssistant: 'Asistente virtual',
              preferenceSaved: 'Preferencia guardada con éxito'
            },
            whatsapp: {
              settigns: 'Configuraciones',
              chatbotAndNumber: 'Chatbot | Número del Botón de WhatsApp',
              buttonStatus: 'Estado del Botón',
              buttonMessage: 'Mensaje del Botón (opcional)',
              buttonPosition: 'Posición del Botón',
              initialMessageWhatsapp: 'Mensaje inicial en WhatsApp (opcional)',
              initialMessageWhatsappDescription: 'Este es el texto que se insertará automáticamente en el campo de mensaje de la aplicación WhatsApp.',
              enabled: 'Activado',
              disabled: 'Desactivado',
              rightPosition: 'a la derecha (esquina inferior)',
              leftPosition: 'a la izquierda (esquina inferior)',
              installationInstructions: 'Instrucciones de Instalación en el Sitio Web',
              installationInstructionsDescription: 'Instale el script de activación del Botón de WhatsApp en su sitio web.',
              traceOrigin: 'Rastrear Origen de Tráfico (Tracking)',
              traceOriginDescription: 'Al habilitar esta función, se solicitará al usuario que proporcione su número de teléfono de WhatsApp antes de iniciar la conversación. Esto permitirá identificar la página en la que el usuario estaba al visitar el sitio.',
              requestPhone: 'Solicitar teléfono y habilitar la trazabilidad.',
              logoOrAvatar: 'Imagen',
              visibleName: 'Nombre Visible (Display Name)',
              resquestPhoneMessage: 'Mensaje para solicitar el número de WhatsApp',
              copyright: 'Copyright (solo para el master)'
            },
            master: {
              chatbots: 'Chatbots',
              preferences: 'Preferencias',
              master: 'Master',
              locationOfServers: 'Localização dos servidores (solamente master)',
              newPln: 'Nuevo PLN (solamente master)',
              realTime: 'Tiempo real (solamente master)',
              cacheConversation: 'Cache del chat (solamente master)',
              offshoreLastQuestion: 'Offshore last question (solamente master)',
              excludeChatbot: 'Eliminar chatbot',
              createCliWithoutBackground: 'Crear CLI sin fondo',
              save: 'Guardar',
              areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
              youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
              previousContent: 'el contenido anterior no se puede restaurar.',
              doYouReallyExclude: '¿Realmente quieres eliminar el chatbot?',
              cancel: 'Cancelar',
              exclude: 'Borrar',
              interface: 'Interface',
              interfaceSettings: 'Configuración de la interface',
              behavior: 'Comportamiento',
              chatbotSettings: 'Configuración del chatbot',
              installation: 'Instalación',
              integrations: 'Integraciones',
              areYouStillAround: '¿Sigues por aquí?',
              ifYouNotResponse: 'Si no contesta tendré que terminar el servicio.',
              ifYouNeedHelp: 'Muy bien, si necesitas ayuda nuevamente, avísame.',
              closeMessage: 'La asistencia fue cerrado por el asistente, agradecemos su contacto y si tiene más preguntas, simplemente llámeme nuevamente.',
              preferenceSaved: 'Preferencia guardada con éxito'
            },
            messages: {
              chatbots: 'Chatbots',
              preferences: 'Preferências',
              messages: 'Mensagens',
              portuguese: 'Portugués',
              english: 'Inglés',
              spanish: 'Español',
              faqNotFound: 'FAQ não encontrada',
              treatment: 'Cuando el usuario escribe buenos días en lugar de su nombre.',
              noName: 'Cuando el usuario escribe: Mi nombre es...',
              ask: 'Cuando el usuario hace una pregunta.',
              special: 'Cuando el usuario pone algún carácter especial',
              justYourName: 'Cuando el usuario escribe el primer improperio',
              cursed: 'Cuando el usuario escribe la segunda palabrota y cierra el chatbot.',
              knowledgeName: 'Cuando hay una intención en la inteligencia y el usuario la ha puesto en el nombre.',
              limit: 'Cuando el usuario escribe más allá del límite permitido.',
              manyItems: 'Al eliminar la ambigüedad de una pregunta',
              faqNoneButton: 'Cuando se desambigua, aparece un botón con esta frase',
              connectLive: 'Al conectarse al chat en vivo',
              firstNameError: 'Cuando el usuario pierde el nombre',
              textError: 'Cuando el usuario no escribe nada en una solicitud de texto',
              emailError: 'Cuando el usuario pierde el correo electrónico',
              moneyError: 'Cuando se le pide al usuario que ingrese una cantidad monetaria por error',
              phoneError: 'Cuando el usuario pierde el teléfono',
              cpfError: 'Cuando el usuario pierde el CPF',
              cnpjError: 'Cuando el usuario extraña el CNPJ',
              faqError: 'Cuando el usuario no escribe nada en una pregunta frecuente',
              save: 'Guardar',
              areYouSureSave: '¿Está seguro de que desea guardar los cambios?',
              youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
              previousContent: 'el contenido anterior no se puede restaurar.',
              cancel: 'Cancelar',
              interface: 'Interface',
              interfaceSettings: 'Configuración de la interface',
              behavior: 'Comportamiento',
              chatbotSettings: 'Configurações de chatbot',
              installation: 'Instalación',
              integrations: 'Integraciones',
              areYouStillAround: '¿Sigues por aquí?',
              ifYouNotResponse: 'Si no contesta tendré que terminar la asistencia',
              ifYouNeedHelp: 'Muy bien, si necesitas ayuda nuevamente, avísame.',
              closeMessage: 'La asistencia fue cerrado por el asistente, agradecemos su contacto y si tiene más preguntas, simplemente llámeme nuevamente.',
              typeYourName: 'Para iniciar nuestra conversación, ingrese su nombre.',
              onlyName: 'Solo ingrese su nombre.',
              toContinueTypeYourNamePlease: 'Para continuar, ingrese su nombre. ',
              typeYourNamePleaseSpecial: 'Parece que agregó un carácter especial. Solo ingrese su nombre.',
              continueTheConversation: 'De esa forma no puedo continuar la conversación.',
              typeYourNamePlease: 'Por favor ingrese solo su nombre.',
              toAnswerYourQuestions: 'Para responder a sus preguntas, necesito que ingrese su nombre.',
              virtualAssistant: 'Asistente virtual',
              maxLimit: 'Has superado el límite máximo de caracteres.',
              iCanHelpYou: 'En esta pregunta tuya puedo ayudarte con más de una opción.',
              noneOption: 'Ninguna de las anteriores',
              notFound: 'No entendí su pregunta.',
              waitMoment: 'Espera un momento. Un asistente le hablará de inmediato.',
              selectOneOptions: 'Seleccione una de las opciones',
              typeName: 'Escriba su nombre',
              typeNameError: 'Por favor ingrese un nombre valido.',
              typeFirstName: 'Ponga su primer nombre',
              typeFirstNameError: 'Solo ingrese su nombre.',
              typeLastName: 'Ingrese su apellido',
              typeLastNameError: 'Solo ingrese su apellido.',
              typeHere: 'Digite aquí',
              typeHerePlease: 'Por favor escriba algo.',
              typeEmail: 'Escriba su correo electrónico',
              typeEmailError: 'Por favor, introduce una dirección de correo electrónico válida.',
              typeMoney: 'Ingrese su valor',
              typeMoneyError: 'Por favor ingrese una cifra válida.',
              typePhone: 'Ingrese su telefono',
              typePhoneError: 'Por favor ingrese un número de teléfono válido.',
              typeCpf: 'Ingrese su CPF',
              typeCpfError: 'Ingrese un CPF válido.',
              typeBornDate: 'Introduzca su fecha de nacimiento',
              typeBornDateError: 'Indique su fecha de nacimiento válida.',
              typeCnpj: 'Ingrese su CNPJ',
              typeCnpjError: 'Ingrese un CNPJ válido.',
              typeFaq: 'Ingrese su pregunta',
              typeFaqError: 'Por favor, complete algo.',
              selectListFaq: 'Elija una opción',
              selectListFaqError: 'Por favor, elija una opción.',
              selectRating: 'Seleccione',
              search: 'Buscar',
              searchCountry: 'Países de búsqueda',
              searchState: 'Estado de búsqueda',
              searchCity: 'Buscar ciudad',
              typeMessage: 'Ingrese su mensaje...',
              typeMessageError: 'Por favor, escriba su mensaje',
              selectDate: 'Elige una fecha',
              selectDateError: 'Por favor elige una fecha',
              selectSize: 'Elige una talla',
              selectSizeError: 'Por favor, elija un tamaño',
              typeNumber: 'Ingrese un numero',
              typeNumberError: 'Por favor, introduzca un número',
              preferenceSaved: 'Preferencia guardada con éxito'
            },
            chatbots: 'Chatbots',
            preferences: 'Preferencias',
            interfaceChatbot: 'Interface WebChat',
            setAllVisual: 'Habilitar y definir los aspectos visuales y funciones del WebChat (ventana de chat) instalado en el sitio web.',
            behaviorChatbot: 'Comportamiento',
            accessTheBehaviorSettings: 'Acceda a la configuración de comportamiento del chatbot (controles de tiempo, disparador, modos de visualización de la ventana de chat y persistencia del chat).',
            installationChatbot: 'Instalación',
            accessTheInstallationSettings: 'Accede a la configuración de instalación y activación del chatbot en cada uno de los canales: WebChat, WhatsApp, Instagram, Messenger y Telegram.',
            whatsappChatbot: 'Boton Whatsapp',
            whatsappChatbotDescription: 'Habilite y defina los aspectos visuales del botón de WhatsApp para su sitio web y configure la función Seguimiento de fuente de tráfico.',
            integrationsChatbot: 'Integraciones',
            accessTheIntegrationSettings: 'Acceda al panel de configuración de las distintas integraciones de Globalbot con otros sistemas. Algunas de estas configuraciones son realizadas por nuestro equipo técnico debido a su complejidad y seguridad.',
            masterChatbot: 'Master',
            exclusiveAccess: 'Acceso exclusivo del equipo de Globalbot a configuraciones avanzadas como ubicación del servidor, caché de conversación, comportamiento de inteligencia artificial y tiempo real.',
            excludeVersionerAccess: 'Acceso exclusivo para el equipo de Globalbot para ver las versiones de flujo.',
            messagesChatbot: 'Mensajes',
            modifyMessages: 'Aquí puedes modificar algunos de los mensajes, solo para Maestros por el momento.'
          },
          permission: {
            permission: {
              permission: 'Permisos',
              preferences: 'Preferencias',
              conversations: 'Conversaciones',
              manager: 'Gerente',
              attendant: 'Asistente',
              seeAll: 'Ver todo',
              seeAttendedAndWithoutTransshipment: 'Ver atendido y sin transbordo',
              onlyAttended: 'Ver solo respondido',
              junior: 'Júnior',
              done: 'Guardar',
              updatedPermissions: 'Permisos actualizados!',
              connectionError: 'Vaya, error de conexión.'
            }
          },
          users: {
            access: {
              preferences: 'Preferencias',
              users: 'Usuarios',
              journey: 'Viajes',
              addJourney: 'Agregar viaje',
              name: 'Nombre',
              description: 'Descripción',
              actions: 'Comportamiento',
              edit: 'Editar',
              linkUsers: 'Vincular usuarios',
              exclude: 'Borrar',
              totalOf: 'Total de',
              usersBeing: 'usuários, sendo',
              inNone: 'en ninguno',
              inOneAnd: 'en uno y',
              inTwoOrMore: 'en dos o más viajes.',
              save: 'Guardar',
              cancel: 'Cancelar',
              journeyData: 'Detalles del viaje',
              journeyName: 'Nombre del viaje *',
              comments: 'Comentarios',
              schedules: 'Horarios',
              dayOfTheWeek: 'Dia de la semana *',
              sunday: 'Domingo',
              monday: 'Lunes',
              tuesday: 'Martes',
              wednesday: 'Miércoles',
              thursday: 'Jueves',
              friday: 'Viernes',
              saturday: 'Sábado',
              input: 'Aporte *',
              exit: 'Salida *',
              addTime: 'Agregar tiempo',
              tolerance: 'Tolerancia',
              defineTheTolerance: 'Defina el tiempo de tolerancia del viaje para acceder y permanecer en la plataforma.',
              important: 'Importante',
              onceTheTolerance: 'Una vez que se alcanza el tiempo de tolerancia, la plataforma emitirá una alerta y un temporizador de cuenta atrás para que el usuario finalice tranquilamente sus actividades.',
              whenTheTotal: 'Cuando finalmente se alcance la duración total de la estadía, el usuario cerrará la sesión automáticamente. Solo los usuarios administradores podrán iniciar sesión en la plataforma fuera de su horario laboral.',
              ifUser: 'Si un usuario está vinculado en más de un viaje, el acceso y la permanencia tendrán en cuenta el viaje de mayor duración (tanto de entrada como de salida).',
              journeyUsers: 'Usuarios de viaje',
              outOfTheJourney: 'Fuera del viaje',
              withinTheJourney: 'Dentro del viaje',
              addSelected: 'Agregar seleccionados',
              addAll: 'Agregar todo',
              removeAll: 'Eliminar todo',
              removeSelected: 'Eliminar selección',
              nonexistent: 'Inexistente',
              deleteJourney: 'Eliminar viaje',
              areYouSure: '¿Está seguro de que desea eliminar este viaje? Se perderán todos los datos y esta acción no se puede deshacer.',
              fiveMinutes: '5 minutos',
              tenMinutes: '10 minutos',
              fifteenMinutes: '15 minutos',
              twentyMinutes: '20 minutos',
              twentyFiveMinutes: '25 minutos',
              thirtyMinutes: '30 minutos',
              oneHour: '1 hora',
              twoHours: '2 horas',
              fourHours: '4 horas',
              sixHours: '6 horas',
              twelveHours: '12 horas',
              journeyCreated: '¡Tu viaje ha sido creado!',
              journeyEdited: '¡Tu viaje ha sido editado!',
              errorAtTheTime: '¡Vaya, tuvimos un error en ese momento!',
              journeyExcluded: '¡Tu viaje ha sido eliminado!',
              errorCompletingThisAction: 'Vaya, tuvimos un problema al completar esta acción.'
            },
            preferences: 'Preferências',
            users: 'Usuarios',
            addNewUser: 'Agrega nuevos usuarios a la plataforma. Si alcanza su límite, solicite la expansión del mismo.',
            createUser: 'Crear usuario',
            permissions: 'Permisos',
            managerUserPermissions: 'Administre los permisos de los usuarios, habilitando o restringiendo el acceso a las funciones de la plataforma.',
            journeys: 'Viajes',
            defineTheJourney: 'Defina el horario laboral de sus usuarios, controlando el acceso y permanencia en la plataforma.',
            extendLimit: 'Límite extendido',
            yourCurrentLimit: '¿Tu límite actual no es suficiente? Solicite la extensión ahora.',
            avatar: 'Avatar',
            addAvatar: 'Agregar avatar',
            name: 'Nombre',
            nameRequired: 'Nombre *',
            email: 'Correo electrónico',
            emailRequired: 'Correo electrónico *',
            role: 'Ocupación',
            roleRequired: 'Ocupación *',
            departmentsFilter: 'Seleccione un departamento',
            chatbotsFilter: 'Seleccione un chatbot',
            linked: 'Vinculado',
            unlinked: 'Sin vínculo',
            permission: 'Permiso',
            permissionRequired: 'Permiso *',
            actions: 'Comportamiento',
            accessReport: 'Informe de acceso',
            edit: 'Editar',
            exclude: 'Borrar',
            understood: 'Comprendido',
            important: 'IMPORTANTE',
            impossibleToDelete: ': No se puede eliminar este usuario porque tiene ',
            activeConversations: 'conversaciones activas. Debe transferir o finalizar las conversaciones de este usuario antes de solicitar la eliminación.',
            save: 'Guardar',
            cancel: 'Cancelar',
            close: 'Cerrar',
            userData: 'Datos del usuario',
            ddi: 'DDI',
            phone: 'Teléfono móvil',
            newPassword: 'Nueva contraseña *',
            chatbots: 'Chatbots',
            departments: 'Departamentos',
            accessLogs: 'Registros de acceso',
            initialDate: 'Fecha de inicio',
            startTime: 'Hora de inicio',
            finalDate: 'Fecha final',
            endTime: 'Hora de finalización',
            status: 'Status',
            date: 'Fecha',
            intentions: 'Intenciones',
            intentionsDescription: 'Seleccione la fecha y la hora para que el filtro busque dentro del área elegida.',
            available: 'Online',
            away: 'Lejos',
            offline: 'Offline',
            connected: 'Conectado',
            disconnected: 'Desconectado',
            endJourney: 'Final del Jornada',
            excludeUser: 'Borrar usuario',
            areYouSureDelete: '¿Está seguro de que desea eliminar este usuario? Se perderán todos los datos y esta acción no se puede deshacer.',
            youCanRequest: 'Puede solicitar usuarios adicionales para usar la plataforma. Ingrese los detalles a continuación y uno de nuestros especialistas se comunicará con usted.',
            amount: 'Cantidad *',
            request: 'Solicitud',
            primary: 'Primario',
            administrator: 'Administrador',
            manager: 'Administrador',
            attendant: 'Atendente',
            junior: 'Júnior',
            january: 'Enero',
            february: 'Febrero',
            march: 'Marcha',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre',
            shortJan: 'Ene',
            shortFeb: 'Feb',
            shortMar: 'Mar',
            shortApr: 'Abr',
            shortMay: 'May',
            shortJun: 'Jun',
            shortJul: 'Jul',
            shortAug: 'Ago',
            shortSep: 'Sep',
            shortOct: 'Oct',
            shortNov: 'Nov',
            shortDec: 'Dic',
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
            shortSunday: 'Dom',
            shortMonday: 'Lun',
            shortTuesday: 'Mar',
            shortWednesday: 'Mié',
            shortThursday: 'Jue',
            shortFriday: 'Vie',
            shortSaturday: 'Sab',
            exportToSVG: 'Exportar a SVG',
            exportToPNG: 'Exportar a PNG',
            exportToCSV: 'Exportar a CSV',
            menu: 'Menú',
            selection: 'Seleccione',
            selectionZoom: 'Seleccionar zoom',
            zoomIn: 'Acercarse',
            zoomOut: 'Disminuir el zoom',
            pan: 'Para mover',
            reset: 'Volver al valor predeterminado',
            userDeleted: 'Usuário excluído.',
            errorUserDelete: 'Oops, não conseguimos excluir este usuário!',
            notLimit: 'Você não tem mais limite para adicionar usuários.',
            errorPasswordLength: 'Introduzca una contraseña de más de 6 caracteres.',
            errorSavingUser: 'Rellene todos los campos obligatorios.',
            theArchitect: 'O Arquiteto',
            notFound: 'Não encontrado',
            dominion: 'Domínio',
            master: 'Mestre',
            senior: 'Sênior',
            failedToRequest: 'Vaya, no se pudo solicitar a los usuarios.',
            requestedUsers: 'Usuarios solicitados.',
            requiredFields: 'Debes completar los campos.',
            userCreatedSuccessfully: '¡Usuario creado con éxito!',
            userUpdatedSuccessfully: '¡Usuario actualizado correctamente!'
          },
          domains: {
            preferences: 'Preferências',
            domains: 'Domínios',
            addNewDomainsToThePlatform: 'Agrega nuevos dominios a la plataforma. Primero, se debe registrar el DKIM, TXT y CNAME del dominio.',
            addDomain: 'Agregar dominio',
            creation: 'Creación',
            domain: 'Dominio',
            emails: 'Correos electrónicos',
            name: 'Nombre',
            actions: 'Comportamiento',
            waiting: 'Esperando',
            verified: 'Verificado',
            type: 'TIPO',
            host: 'HOST',
            data: 'DATA',
            verifyDomain: 'Verificar dominio',
            exclude: 'Borrar',
            addNewDomain: 'Agregar nuevo dominio',
            insertRecords: 'Ahora debe ingresar los siguientes registros en su DNS.',
            updateDomain: 'A propagação do seu DNS pode levar algumas horas, e o status do seu domínio será atualizado na plataforma.',
            record: 'Registro',
            nameToUpperCase: 'NOMBRE',
            valueToUpperCase: 'VALOR',
            cancel: 'Cancelar',
            add: 'Agregar',
            addNewEmail: 'Agregar un nuevo correo electrónico',
            confirmationEmail: 'Recibirá un correo electrónico de confirmación, haga clic en el enlace en el cuerpo del correo electrónico para continuar con la verificación del correo electrónico.',
            email: 'Correo electrónico',
            excludeDomain: 'Eliminar dominio',
            areYouSure: '¿Estás seguro de que deseas eliminar este dominio?',
            domainSuccessfully: 'Dominio registrado correctamente.',
            connectionError: 'Error de conexión',
            domainAlreadyRegistered: 'Dominio ya registrado, vuelve a intentarlo con otro.',
            startTheProcess: 'Para iniciar el proceso necesitas un dominio.',
            deletedDomain: 'Dominio eliminado.',
            domainSuccessfullyValidated: '¡Dominio validado con éxito!',
            fieldsNotAuthenticated: 'Hay algunos campos que aún no se han autenticado. '
          },
          lists: {
            preferences: 'Preferencias',
            lists: 'Liza',
            deleteSelectedLists: 'Eliminar listas seleccionadas',
            avatar: 'Avatar',
            title: 'Título',
            amount: 'La cantidad',
            actions: 'Comportamiento',
            view: 'Ver',
            exclude: 'Borrar',
            save: 'Guardar',
            cancel: 'Cancelar',
            listData: 'Dados da lista',
            name: 'Nombre',
            email: 'Correo electrónico',
            phone: 'Teléfono',
            excludeList: 'Borrar lista',
            areYouSure: '¿Está seguro de que desea eliminar esta lista?',
            listDeleted: 'Tu lista ha sido eliminada',
            errorDeleteThisList: '¡Vaya, no pudimos eliminar esta lista!'
          },
          dataBases: {
            importData: 'Datos de importacion',
            name: 'Nombre',
            cdt: 'Data/Hora',
            replaceData: 'Reemplazar datos',
            import: 'Importar',
            importCSV: "Importar archivo CSV",
            syncGoogleSheet: "Sincronizar con Google Sheets",
            invalidSpreadsheetId: 'ID de hoja de cálculo no válido',
            foundSheets: 'Hojas de trabajo encontradas',
            shareSpreadsheet: 'Necesitas compartir tu hoja de cálculo con este correo electrónico: ',
            listPages: 'Lista de páginas',
            reportSpreadsheetId: 'Ingrese el ID de la hoja de cálculo',
            reportTabSheet: 'Seleccione la hoja de cálculo que desea importar.',
            reportName: 'Pon un nombre',
            alreadyBaseName: 'Ya existe una base con este nombre',
            alreadyBaseSheetName: 'Ya existe una base con esta página de hoja de cálculo',
            dataGoogleSpreadSheet: 'Datos de la hoja de cálculo de Google',
            deleteDataBase: 'Eliminar base de datos',
            spreadSheetId: 'ID de hoja de cálculo de Google',
            sheetPageName: 'Nombre de la página',
            close: 'Cerrar',
            delete: 'Borrar'
          },
          live: {
            serviceMessages: 'Mensajes de servicio',
            portuguese: 'Portugués',
            english: 'Inglés',
            spanish: 'Español',
            completionMessage: 'Mensaje de finalización',
            archivalMessage: 'Mensaje de archivo',
            transferMessage: 'Transferir mensaje',
            automaticTransferToAttendant: 'Transferencia automática a asistente',
            transshipmentAvailableForAttendants: 'Transbordo disponible para asistentes',
            queueTransshipmentWithoutDistribution: 'Transbordo en cola (sin distribución)',
            queueTransshipmentWithDistribution: 'Transbordo en cola (con distribución)',
            transshipmentAwaitingAttendants: 'Transbordo en espera de asistentes (check in discapacitados)',
            noAttendantsAvailable: 'No hay asistentes disponibles (registro habilitado)',
            tipDepartmentIsPersistent: 'No es posible activar el check-in ya que este departamento está configurado en la persistencia de los bots: ',
            pauseOptions: 'Opciones de pausa',
            activated: 'Activado',
            disabled: 'Discapacitado',
            enterHereAll: 'Ingrese aquí todos los posibles descansos que los asistentes pueden seleccionar.',
            add: 'Agregar opción',
            automaticAway: 'Automático de distancia',
            determineTheCheckInTime: 'Determine el horario de check-in.',
            time: 'Tiempo',
            viewingOptions: 'Opciones de visualización',
            autoArchive: 'Tiempo de archivo de conversaciones',
            autoArchiveCaption: 'Configure el archivo automático de conversaciones en el chat en vivo.',
            conversationCloseOptions: "Opciones al cerrar conversación",
            conversationCloseOptionsDescription: "Habilite la asignación de etiquetas o valor al cerrar una conversación.",
            addTag: "Asignar Etiquetas",
            addMonetary: "Asignar Valor",
            required: "Obligatorio",
            coin: "Moneda",
            coinTypes: {
              BRL: "Real Brasileño - BRL",
              USD: "Dólar Americano - USD"
            },
            chooseTheLevel: 'Elija el nivel de acceso que sus operadores podrán ver en la lista de asistentes.',
            managers: 'Gerentes',
            attendants: 'Acomodadores',
            departments: 'Departamentos',
            defineTheDepartments: 'Defina los departamentos del módulo Chat en vivo a continuación. Los departamentos son útiles para "enrutar" las solicitudes de contacto, manejándolas de forma segmentada.',
            whenCreatingTheDepartments: 'Al crear los departamentos, utilice la configuración de usuario para definir en qué departamento (s) puede operar cada usuario.',
            name: 'Nombre',
            setBehavior: 'Configure el comportamiento entrante si no tiene ningún asistente en línea en este momento.',
            checkIn: 'Check-in',
            forcePass: 'Force Pass',
            forcePassDescription: 'También distribuya las llamadas a los asistentes fuera de línea.',
            forcePassDistributionNone: 'Debe seleccionar un tipo de distribución cuando Force Pass está habilitado.',
            queueDisable: 'La cola de servicio es obligatoria cuando Forzar paso está habilitado.',
            forcePassDisable: 'No es posible activar Force Pass con el Check-in habilitado.',
            checkInDisable: 'No es posible activar Check-in con el Force Pass habilitado.',
            attendanceQueue: 'Cola de asistencia',
            transmitQueuePosition: 'Transmitir la posición de la cola',
            transmitQueuePositionDescription: 'Transmita la posición de la cola a los clientes en espera.',
            maxAttendances: 'Asistencia máxima',
            maxAttendancesDescription: 'Establezca la cantidad máxima que cada servidor puede servir simultáneamente.',
            customersByAttendants: 'Clientes por asistentes',
            distribution: 'Distribución',
            distributionDescription: 'Elija la forma de distribuir las llamadas en el módulo Chat en vivo.',
            balancing: 'Equilibrio',
            disabledNone: 'Discapacitado',
            autoBalance: 'Autoequilibrio',
            sequential: 'Secuencial',
            save: 'Guardar',
            confirm: 'Confirmar',
            exclude: 'Borrar',
            addDepartment: 'Agregar departamento',
            areYouSureSaved: '¿Está seguro de que desea guardar los cambios?',
            areYouSureDeleted: '¿Está seguro de que desea guardar los cambios?',
            youCanChange: 'Puede cambiar la configuración cuando lo desee, pero el',
            previousContent: 'el contenido anterior no se puede restaurar.',
            cancel: 'Cancelar',
            finishRoom: 'La conversación terminó.',
            archiveRoom: 'La conversación se ha archivado.',
            transferRoom: 'La conversación fue transferida.',
            withAttendant: 'Con asistente.',
            available: 'Disponible.',
            queue: 'En fila.',
            queueDistribution: 'Cola automática',
            waiting: 'Esperando',
            noVacancy: 'No hay asistentes por el momento.',
            oneMinute: '1 minuto',
            twoMinutes: '2 minutos',
            threeMinutes: '3 minutos',
            fourMinutes: '4 minutos',
            fiveMinutes: '5 minutos',
            tenMinutes: '10 minutos',
            fifteenMinutes: '15 minutos',
            thirtyMinutes: '30 minutos',
            oneHour: 'Una hora',
            seeAll: 'Ver todo',
            onlyDepartments: 'Ver solo en departamentos',
            preferences: 'Preferencias',
            liveChat: 'Chat en Vivo',
            updated: '¡Actualizado!',
            myDepartment: 'Mi departamento',
            departmentAdded: 'Departamento agregado!',
            deletedDepartment: 'Departamento eliminado!'
          },
          settings: {
            preferences: 'Preferencias',
            users: 'Usuarios',
            usersDescription: 'Administrar usuarios de la plataforma Globalbot. Puede agregar, excluir usuarios y establecer y editar permisos y roles de acceso para cada uno de ellos de forma independiente.',
            liveChat: 'Chat en Vivo',
            liveChatDescription: 'Configure los ajustes del módulo de chat en vivo. Crea departamentos, define la forma de distribución de llamadas y mensajes automáticos.',
            tags: 'Tags',
            tagsDescription: 'Acceda a la configuración del sistema de etiquetas, que se puede usar para etiquetar y clasificar varios elementos en una lista o colección (por ejemplo, Conversaciones, Inteligencia y Biblioteca)',
            domainsAndEmail: 'Dominios y correos electrónicos',
            domainsAndEmailDescription: 'Configure los dominios y correos electrónicos de su empresa para que aparezcan como remitentes en los envíos de Messenger (correos electrónicos, notificaciones y avisos).',
            lists: 'Liza',
            listsDescription: 'Acceda y controle todas las listas creadas y cargadas a la plataforma.',
            dataBases: 'Bases de Datos',
            dataBasesDescription: 'Importe bases de datos externas para integrarlas con los flujos de chatbot.'
          },
          tags: {
            preferences: 'Preferencias',
            tags: 'Tags',
            add: 'Agregar',
            columns: 'Columnas',
            color: 'Color',
            category: 'Categoría',
            title: 'Título',
            description: 'Descripción',
            actions: 'Comportamiento',
            edit: 'Editar',
            exclude: 'Borrar',
            save: 'Guardar',
            cancel: 'Cancelar',
            tagData: 'Datos de etiqueta',
            deleteTag: 'Eliminar tag',
            areYouSureDelete: '¿Está seguro de que desea eliminar esta etiqueta? Esta acción no se puede deshacer.',
            general: 'General',
            reports: 'Informes',
            realTime: 'Tiempo real',
            analytics: 'Estadísticas',
            organization: 'Organización',
            companies: 'Compañías',
            people: 'Gente',
            conversation: 'Conversaciones',
            flow: 'Flujo',
            knowledge: 'Inteligencia',
            message: 'Mensaje',
            liveChat: 'Chat en vivo',
            library: 'Biblioteca',
            tasks: 'Tareas',
            deletedTag: 'Tag eliminada.',
            errorDeleteThisTag: '¡Vaya! No pudimos eliminar esta etiqueta.',
            errorSavingTag: 'Vaya, se produjo un error al guardar la tag.',
            requiredFields: 'Es necesario completar el campo de título y color.'
          }
        }
      }
    }
  }
}
