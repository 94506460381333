const routes = [
  {
    path: '/',
    component: () => import('pages/Index'),
    children: [
      {
        path: '/login',
        component: () => import('pages/login/login')
      },
      {
        path: '/login/forgot',
        component: () => import('pages/login/forgot')
      },
      {
        path: '/dashboard',
        component: () => import('layouts/default'),
        children: [
          {
            path: '/dashboard',
            component: () => import('pages/dashboard/dashboard')
          },
          {
            path: '/bots',
            component: () => import('pages/bots/bots')
          },
          {
            path: '/kanban',
            component: () => import('pages/kanban/kanban')
          },
          {
            path: '/profile',
            component: () => import('pages/profile/profile')
          },
          {
            path: '/library/',
            component: () => import('pages/library/library')
          },
          {
            path: '/notification/',
            component: () => import('pages/notification/control')
          },
          {
            path: '/help',
            component: () => import('pages/help/help')
          },
          {
            path: '/realTime',
            component: () => import('pages/realTime/realTime')
          },
          {
            path: '/people',
            component: () => import('pages/people/people')
          },
          {
            path: '/people/:id',
            component: () => import('pages/people/timeline')
          },
          {
            path: '/live',
            component: () => import('pages/live/live'),
            children: [
              {
                path: '/live/:_id?',
                component: () => import('pages/live/room')
              }
            ]
          },
          {
            path: '/analytics',
            component: () => import('pages/analytics/analytics'),
            children: [
              {
                path: '',
                component: () => import('pages/analytics/cards')
              },
              {
                path: 'channels',
                component: () => import('pages/analytics/channels/channels')
              },
              {
                path: 'location',
                component: () => import('pages/analytics/location/location')
              },
              {
                name: 'locationTable',
                path: '/analytics/location/locationsTable/:type',
                component: () => import('pages/analytics/location/locationsTable'),
                props: true
              },
              {
                path: 'attendant',
                component: () => import('pages/analytics/attendant/attendant')
              },
              {
                path: 'identifiers',
                component: () => import('pages/analytics/identifiers/identifiers')
              },
              {
                path: 'knowledge',
                component: () => import('pages/analytics/knowledge/knowledge')
              },
              {
                path: 'knowledge/responses',
                component: () => import('pages/analytics/knowledge/responses')
              },
              {
                path: 'origin',
                component: () => import('pages/analytics/origin/origin')
              },
              {
                path: 'flow',
                component: () => import('pages/analytics/flow/flow')
              }
            ]
          },
          {
            path: '/conversations/:id',
            component: () => import('pages/conversations/conversation')
          },
          {
            path: '/conversations',
            component: () => import('pages/conversations/conversation')
          },
          {
            path: '/flow/advanced/:id',
            component: () => import('pages/flow/flow')
          },
          {
            path: '/flow/viewer/:id',
            component: () => import('pages/flow/viewer')
          },
          {
            path: '/flow/editor/:id',
            component: () => import('pages/flow/editor')
          },
          {
            path: '/knowledge/',
            component: () => import('pages/knowledge/knowledge')
          },
          {
            path: '/knowledge/knowledge_match',
            component: () => import('pages/knowledge/knowledge_match')
          },
          {
            path: '/knowledge/genai',
            component: () => import('pages/knowledge/genai')
          },
          {
            path: '/preview/:id',
            component: () => import('pages/preview/preview')
          },
          {
            path: '/settings/',
            component: () => import('pages/settings/settings')
          },
          {
            path: '/settings/live/',
            component: () => import('pages/settings/live')
          },
          {
            path: '/settings/live/messages',
            component: () => import('pages/settings/live/messages')
          },
          {
            path: '/settings/live/behavior',
            component: () => import('pages/settings/live/behavior')
          },
          {
            path: '/settings/live/departments',
            component: () => import('pages/settings/live/departments')
          },
          {
            path: '/settings/lists/',
            component: () => import('pages/settings/lists')
          },
          {
            path: '/settings/bases/',
            component: () => import('pages/settings/bases')
          },
          {
            path: '/settings/chatbot/:id',
            component: () => import('pages/settings/chatbot')
          },
          {
            path: '/settings/chatbot/:id/behavior',
            component: () => import('pages/settings/chatbot/behavior')
          },
          {
            path: '/settings/chatbot/:id/whatsapp',
            component: () => import('pages/settings/chatbot/whatsapp')
          },
          {
            path: '/settings/chatbot/:id/installation',
            component: () => import('pages/settings/chatbot/installation')
          },
          {
            path: '/settings/chatbot/:id/integrations',
            component: () => import('pages/settings/chatbot/integrations')
          },
          {
            path: '/settings/chatbot/:id/interface',
            component: () => import('pages/settings/chatbot/interface')
          },
          {
            path: '/settings/chatbot/:id/master',
            component: () => import('pages/settings/chatbot/master')
          },
          {
            path: '/settings/chatbot/:id/messages',
            component: () => import('pages/settings/chatbot/messages')
          },
          {
            path: '/settings/chatbot/:id/publish',
            component: () => import('pages/settings/chatbot/publish')
          },
          {
            path: '/settings/users',
            component: () => import('pages/settings/users')
          },
          {
            path: '/settings/domains',
            component: () => import('pages/settings/domains')
          },
          {
            path: '/settings/tags',
            component: () => import('pages/settings/tags')
          },
          {
            path: '/settings/access/control',
            component: () => import('pages/settings/users/access')
          },
          {
            path: '/broadcast/:id',
            component: () => import('pages/broadcast/broadcast')
          },
          {
            path: '/broadcast/',
            component: () => import('pages/broadcast/broadcast')
          },
          {
            path: '/advanced',
            component: () => import('pages/advanced/list')
          },
          {
            name: 'advanced',
            path: '/advanced/:id',
            component: () => import('pages/advanced/edit')
          },
          {
            path: '/advanced/logs/list',
            component: () => import('pages/advanced/logs/list')
          },
          {
            path: '/server',
            component: () => import('pages/advanced/server')
          },
          {
            path: '/conversation',
            component: () => import('pages/advanced/conversation')
          },
          {
            path: '/action/create',
            component: () => import('pages/actions/create')
          }
        ]
      }
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
